import styled from 'styled-components';

const GroupLabel = styled.span`
  display: inline-block;
  margin: 4px 6px 0 0;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.hoverFade};
  border-radius: 24px;

  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};
`;
export default GroupLabel;
