// Generated (in part) by:
// https://www.smooth-code.com/open-source/svgr/playground/
// (a project based on https://github.com/smooth-code/svgr)

import { type ComponentProps, memo } from 'react';
import styled from 'styled-components';

interface Props extends ComponentProps<'svg'> {
  prefix?: string;
  tint?: boolean;
  width?: string;
  height?: string;
  inverted?: boolean;
  className?: string;
}
// e4e7eb
// 046adb
const SvgComponent = memo(function KidcardLogo({ tint, inverted, ...props }: Props) {
  return inverted ? (
    // Dark logo
    <svg
      viewBox='0 0 760 197'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      style={{ marginTop: '-4px' }}
    >
      <path
        d='M203.696 59V137.188H207.923C209.455 137.188 210.649 137.004 211.507 136.636C212.426 136.208 213.345 135.381 214.263 134.156L237.695 105.214C238.675 103.928 239.747 102.948 240.911 102.274C242.136 101.601 243.698 101.264 245.597 101.264H266.364L237.052 136.269C234.969 139.025 232.702 141.138 230.252 142.609C231.538 143.527 232.672 144.599 233.652 145.824C234.693 147.049 235.673 148.366 236.592 149.775L268.018 195.53H247.527C245.75 195.53 244.219 195.254 242.932 194.703C241.646 194.091 240.574 193.019 239.716 191.487L215.642 155.747C214.784 154.338 213.896 153.419 212.977 152.991C212.058 152.562 210.68 152.348 208.842 152.348H203.696V195.53H181V59H203.696Z'
        fill='#e4e7eb'
      />
      <path
        d='M302.693 101.264V195.53H279.904V101.264H302.693ZM305.817 73.7923C305.817 75.7523 305.419 77.5899 304.622 79.3049C303.826 81.02 302.754 82.5206 301.406 83.8069C300.12 85.0932 298.588 86.1345 296.812 86.9308C295.035 87.6658 293.136 88.0333 291.115 88.0333C289.154 88.0333 287.286 87.6658 285.51 86.9308C283.794 86.1345 282.293 85.0932 281.007 83.8069C279.721 82.5206 278.679 81.02 277.883 79.3049C277.148 77.5899 276.78 75.7523 276.78 73.7923C276.78 71.771 277.148 69.8722 277.883 68.0959C278.679 66.3196 279.721 64.7883 281.007 63.502C282.293 62.2157 283.794 61.2051 285.51 60.47C287.286 59.6738 289.154 59.2756 291.115 59.2756C293.136 59.2756 295.035 59.6738 296.812 60.47C298.588 61.2051 300.12 62.2157 301.406 63.502C302.754 64.7883 303.826 66.3196 304.622 68.0959C305.419 69.8722 305.817 71.771 305.817 73.7923Z'
        fill='#e4e7eb'
      />
      <path
        d='M385.007 125.336C382.434 122.212 379.616 120.007 376.553 118.72C373.552 117.434 370.305 116.791 366.813 116.791C363.383 116.791 360.289 117.434 357.532 118.72C354.776 120.007 352.417 121.967 350.457 124.601C348.497 127.173 346.996 130.481 345.955 134.523C344.913 138.505 344.392 143.221 344.392 148.672C344.392 154.185 344.821 158.871 345.679 162.73C346.598 166.527 347.884 169.651 349.538 172.101C351.192 174.49 353.214 176.236 355.603 177.338C357.992 178.379 360.657 178.9 363.597 178.9C368.314 178.9 372.326 177.92 375.634 175.96C378.942 174 382.067 171.213 385.007 167.599V125.336ZM407.703 59V195.53H393.828C390.826 195.53 388.927 194.152 388.131 191.395L386.201 182.3C382.403 186.648 378.023 190.17 373.061 192.866C368.161 195.561 362.433 196.908 355.878 196.908C350.733 196.908 346.016 195.836 341.728 193.692C337.44 191.549 333.733 188.455 330.609 184.413C327.546 180.309 325.157 175.256 323.442 169.253C321.788 163.25 320.961 156.39 320.961 148.672C320.961 141.69 321.911 135.197 323.81 129.194C325.709 123.192 328.435 117.985 331.988 113.575C335.541 109.165 339.798 105.735 344.76 103.285C349.722 100.774 355.296 99.518 361.484 99.518C366.752 99.518 371.254 100.345 374.991 101.999C378.728 103.652 382.067 105.888 385.007 108.706V59H407.703Z'
        fill='#e4e7eb'
      />
      <path
        d='M497.64 121.201C496.966 122.059 496.292 122.732 495.618 123.222C495.006 123.712 494.087 123.957 492.862 123.957C491.698 123.957 490.565 123.621 489.462 122.947C488.359 122.212 487.042 121.415 485.511 120.558C483.979 119.639 482.142 118.843 479.997 118.169C477.915 117.434 475.311 117.067 472.187 117.067C468.205 117.067 464.713 117.802 461.712 119.272C458.71 120.68 456.198 122.732 454.177 125.427C452.217 128.122 450.746 131.399 449.766 135.258C448.786 139.056 448.296 143.374 448.296 148.213C448.296 153.236 448.817 157.707 449.858 161.627C450.961 165.547 452.523 168.855 454.545 171.55C456.566 174.184 459.016 176.205 461.896 177.614C464.775 178.961 468.021 179.635 471.636 179.635C475.25 179.635 478.16 179.206 480.365 178.349C482.632 177.43 484.531 176.45 486.062 175.409C487.594 174.306 488.911 173.326 490.013 172.469C491.177 171.55 492.464 171.091 493.873 171.091C495.71 171.091 497.089 171.795 498.008 173.204L504.532 181.473C502.02 184.413 499.294 186.893 496.354 188.915C493.413 190.875 490.35 192.467 487.165 193.692C484.041 194.856 480.794 195.683 477.425 196.173C474.117 196.663 470.809 196.908 467.501 196.908C461.681 196.908 456.199 195.836 451.053 193.692C445.907 191.487 441.405 188.302 437.545 184.137C433.686 179.972 430.623 174.888 428.356 168.885C426.151 162.822 425.049 155.931 425.049 148.213C425.049 141.292 426.029 134.891 427.989 129.011C430.01 123.069 432.951 117.955 436.81 113.667C440.669 109.318 445.448 105.919 451.145 103.469C456.842 101.019 463.396 99.7936 470.809 99.7936C477.853 99.7936 484.01 100.927 489.278 103.193C494.608 105.459 499.386 108.706 503.613 112.932L497.64 121.201Z'
        fill='#e4e7eb'
      />
      <path
        d='M568.563 155.288C562.008 155.594 556.495 156.176 552.023 157.033C547.551 157.83 543.968 158.871 541.272 160.157C538.577 161.443 536.647 162.944 535.483 164.659C534.319 166.374 533.738 168.242 533.738 170.264C533.738 174.245 534.901 177.093 537.229 178.808C539.618 180.523 542.712 181.381 546.51 181.381C551.166 181.381 555.178 180.554 558.547 178.9C561.978 177.185 565.316 174.613 568.563 171.182V155.288ZM516.555 114.402C527.397 104.479 540.445 99.518 555.699 99.518C561.212 99.518 566.143 100.437 570.493 102.274C574.842 104.051 578.518 106.562 581.519 109.808C584.521 112.993 586.787 116.822 588.319 121.293C589.912 125.764 590.708 130.664 590.708 135.993V195.53H580.417C578.273 195.53 576.619 195.224 575.455 194.611C574.291 193.937 573.372 192.62 572.698 190.66L570.676 183.862C568.287 186.005 565.96 187.904 563.693 189.558C561.426 191.15 559.068 192.498 556.618 193.601C554.167 194.703 551.533 195.53 548.715 196.081C545.959 196.694 542.896 197 539.526 197C535.545 197 531.869 196.479 528.5 195.438C525.131 194.336 522.221 192.712 519.771 190.569C517.32 188.425 515.421 185.76 514.074 182.575C512.726 179.39 512.052 175.684 512.052 171.458C512.052 169.069 512.45 166.711 513.247 164.383C514.043 161.995 515.329 159.728 517.106 157.585C518.944 155.441 521.302 153.419 524.181 151.521C527.06 149.622 530.583 147.968 534.748 146.559C538.975 145.15 543.876 144.017 549.45 143.16C555.025 142.241 561.396 141.69 568.563 141.506V135.993C568.563 129.684 567.215 125.029 564.52 122.028C561.825 118.965 557.935 117.434 552.85 117.434C549.175 117.434 546.112 117.863 543.661 118.72C541.272 119.578 539.159 120.558 537.321 121.66C535.483 122.702 533.799 123.651 532.267 124.509C530.797 125.366 529.143 125.795 527.305 125.795C525.713 125.795 524.365 125.397 523.262 124.601C522.16 123.743 521.271 122.763 520.598 121.66L516.555 114.402Z'
        fill='#e4e7eb'
      />
      <path
        d='M633.493 117.618C636.434 111.983 639.925 107.573 643.969 104.387C648.012 101.141 652.79 99.518 658.303 99.518C662.652 99.518 666.144 100.467 668.778 102.366L667.308 119.364C667.002 120.466 666.542 121.262 665.93 121.752C665.378 122.181 664.613 122.395 663.633 122.395C662.714 122.395 661.335 122.242 659.498 121.936C657.721 121.63 655.975 121.477 654.26 121.477C651.748 121.477 649.512 121.844 647.552 122.579C645.592 123.314 643.815 124.386 642.223 125.795C640.691 127.142 639.313 128.796 638.088 130.756C636.924 132.716 635.821 134.952 634.78 137.463V195.53H612.083V101.264H625.407C627.735 101.264 629.358 101.692 630.277 102.55C631.196 103.346 631.809 104.816 632.115 106.96L633.493 117.618Z'
        fill='#e4e7eb'
      />
      <path
        d='M737.304 125.336C734.731 122.212 731.913 120.007 728.85 118.72C725.848 117.434 722.602 116.791 719.11 116.791C715.679 116.791 712.586 117.434 709.829 118.72C707.073 120.007 704.714 121.967 702.754 124.601C700.794 127.173 699.293 130.481 698.251 134.523C697.21 138.505 696.689 143.221 696.689 148.672C696.689 154.185 697.118 158.871 697.976 162.73C698.895 166.527 700.181 169.651 701.835 172.101C703.489 174.49 705.51 176.236 707.9 177.338C710.289 178.379 712.953 178.9 715.894 178.9C720.611 178.9 724.623 177.92 727.931 175.96C731.239 174 734.363 171.213 737.304 167.599V125.336ZM760 59V195.53H746.125C743.123 195.53 741.224 194.152 740.428 191.395L738.498 182.3C734.7 186.648 730.32 190.17 725.358 192.866C720.458 195.561 714.73 196.908 708.175 196.908C703.029 196.908 698.313 195.836 694.024 193.692C689.736 191.549 686.03 188.455 682.906 184.413C679.843 180.309 677.454 175.256 675.739 169.253C674.085 163.25 673.258 156.39 673.258 148.672C673.258 141.69 674.207 135.197 676.106 129.194C678.005 123.192 680.731 117.985 684.284 113.575C687.837 109.165 692.095 105.735 697.057 103.285C702.019 100.774 707.593 99.518 713.78 99.518C719.049 99.518 723.551 100.345 727.288 101.999C731.025 103.652 734.363 105.888 737.304 108.706V59H760Z'
        fill='#e4e7eb'
      />
      <path
        d='M16.1093 188.99H129.88C138.22 188.99 142.169 178.701 135.97 173.121L22.1988 70.6565C16.3393 65.3768 7 69.5365 7 77.4261V179.89C7 184.92 11.0797 189 16.1093 189V188.99Z'
        stroke='#046adb'
        strokeWidth='14'
      />
      <path
        d='M43.8471 7H16.1493C11.0963 7 7 11.0964 7 16.1495V179.841C7 184.894 11.0963 188.99 16.1493 188.99H43.8471C48.9001 188.99 52.9963 184.894 52.9963 179.841V16.1495C52.9963 11.0964 48.9001 7 43.8471 7Z'
        stroke='#046adb'
        strokeWidth='14'
      />
      <path
        d='M52.9963 97.975L88.7935 65.7268C94.653 60.4471 103.992 64.6069 103.992 72.4964V98.515C103.992 101.095 102.902 103.555 100.983 105.285L52.9963 148.512V97.985V97.975Z'
        stroke='#046adb'
        strokeWidth='14'
      />
      <path
        d='M52.9963 98.0251V148.492L81.0141 123.254L52.9963 98.0251Z'
        stroke='#046adb'
        strokeWidth='14'
      />
      <path
        d='M43.8871 188.99C48.8967 188.99 52.9963 184.89 52.9963 179.88V97.9649L22.1588 70.4764C16.2893 65.2467 7 69.4065 7 77.2761V179.88C7 184.89 11.0997 188.99 16.1093 188.99H43.8971H43.8871Z'
        stroke='#046adb'
        strokeWidth='14'
      />
    </svg>
  ) : (
    // Primary Logo
    <svg
      viewBox='0 0 760 197'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      style={{ marginTop: '-4px' }}
    >
      <path
        d='M203.696 59V137.188H207.923C209.455 137.188 210.649 137.004 211.507 136.636C212.426 136.208 213.345 135.381 214.263 134.156L237.695 105.214C238.675 103.928 239.747 102.948 240.911 102.274C242.136 101.601 243.698 101.264 245.597 101.264H266.364L237.052 136.269C234.969 139.025 232.702 141.138 230.252 142.609C231.538 143.527 232.672 144.599 233.652 145.824C234.693 147.049 235.673 148.366 236.592 149.775L268.018 195.53H247.527C245.75 195.53 244.219 195.254 242.932 194.703C241.646 194.091 240.574 193.019 239.716 191.487L215.642 155.747C214.784 154.338 213.896 153.419 212.977 152.991C212.058 152.562 210.68 152.348 208.842 152.348H203.696V195.53H181V59H203.696Z'
        fill='#252F3F'
      />
      <path
        d='M302.693 101.264V195.53H279.904V101.264H302.693ZM305.817 73.7923C305.817 75.7523 305.419 77.5899 304.622 79.3049C303.826 81.02 302.754 82.5206 301.406 83.8069C300.12 85.0932 298.588 86.1345 296.812 86.9308C295.035 87.6658 293.136 88.0333 291.115 88.0333C289.154 88.0333 287.286 87.6658 285.51 86.9308C283.794 86.1345 282.293 85.0932 281.007 83.8069C279.721 82.5206 278.679 81.02 277.883 79.3049C277.148 77.5899 276.78 75.7523 276.78 73.7923C276.78 71.771 277.148 69.8722 277.883 68.0959C278.679 66.3196 279.721 64.7883 281.007 63.502C282.293 62.2157 283.794 61.2051 285.51 60.47C287.286 59.6738 289.154 59.2756 291.115 59.2756C293.136 59.2756 295.035 59.6738 296.812 60.47C298.588 61.2051 300.12 62.2157 301.406 63.502C302.754 64.7883 303.826 66.3196 304.622 68.0959C305.419 69.8722 305.817 71.771 305.817 73.7923Z'
        fill='#252F3F'
      />
      <path
        d='M385.007 125.336C382.434 122.212 379.616 120.007 376.553 118.72C373.552 117.434 370.305 116.791 366.813 116.791C363.383 116.791 360.289 117.434 357.532 118.72C354.776 120.007 352.417 121.967 350.457 124.601C348.497 127.173 346.996 130.481 345.955 134.523C344.913 138.505 344.392 143.221 344.392 148.672C344.392 154.185 344.821 158.871 345.679 162.73C346.598 166.527 347.884 169.651 349.538 172.101C351.192 174.49 353.214 176.236 355.603 177.338C357.992 178.379 360.657 178.9 363.597 178.9C368.314 178.9 372.326 177.92 375.634 175.96C378.942 174 382.067 171.213 385.007 167.599V125.336ZM407.703 59V195.53H393.828C390.826 195.53 388.927 194.152 388.131 191.395L386.201 182.3C382.403 186.648 378.023 190.17 373.061 192.866C368.161 195.561 362.433 196.908 355.878 196.908C350.733 196.908 346.016 195.836 341.728 193.692C337.44 191.549 333.733 188.455 330.609 184.413C327.546 180.309 325.157 175.256 323.442 169.253C321.788 163.25 320.961 156.39 320.961 148.672C320.961 141.69 321.911 135.197 323.81 129.194C325.709 123.192 328.435 117.985 331.988 113.575C335.541 109.165 339.798 105.735 344.76 103.285C349.722 100.774 355.296 99.518 361.484 99.518C366.752 99.518 371.254 100.345 374.991 101.999C378.728 103.652 382.067 105.888 385.007 108.706V59H407.703Z'
        fill='#252F3F'
      />
      <path
        d='M497.64 121.201C496.966 122.059 496.292 122.732 495.618 123.222C495.006 123.712 494.087 123.957 492.862 123.957C491.698 123.957 490.565 123.621 489.462 122.947C488.359 122.212 487.042 121.415 485.511 120.558C483.979 119.639 482.142 118.843 479.997 118.169C477.915 117.434 475.311 117.067 472.187 117.067C468.205 117.067 464.713 117.802 461.712 119.272C458.71 120.68 456.198 122.732 454.177 125.427C452.217 128.122 450.746 131.399 449.766 135.258C448.786 139.056 448.296 143.374 448.296 148.213C448.296 153.236 448.817 157.707 449.858 161.627C450.961 165.547 452.523 168.855 454.545 171.55C456.566 174.184 459.016 176.205 461.896 177.614C464.775 178.961 468.021 179.635 471.636 179.635C475.25 179.635 478.16 179.206 480.365 178.349C482.632 177.43 484.531 176.45 486.062 175.409C487.594 174.306 488.911 173.326 490.013 172.469C491.177 171.55 492.464 171.091 493.873 171.091C495.71 171.091 497.089 171.795 498.008 173.204L504.532 181.473C502.02 184.413 499.294 186.893 496.354 188.915C493.413 190.875 490.35 192.467 487.165 193.692C484.041 194.856 480.794 195.683 477.425 196.173C474.117 196.663 470.809 196.908 467.501 196.908C461.681 196.908 456.199 195.836 451.053 193.692C445.907 191.487 441.405 188.302 437.545 184.137C433.686 179.972 430.623 174.888 428.356 168.885C426.151 162.822 425.049 155.931 425.049 148.213C425.049 141.292 426.029 134.891 427.989 129.011C430.01 123.069 432.951 117.955 436.81 113.667C440.669 109.318 445.448 105.919 451.145 103.469C456.842 101.019 463.396 99.7936 470.809 99.7936C477.853 99.7936 484.01 100.927 489.278 103.193C494.608 105.459 499.386 108.706 503.613 112.932L497.64 121.201Z'
        fill='#252F3F'
      />
      <path
        d='M568.563 155.288C562.008 155.594 556.495 156.176 552.023 157.033C547.551 157.83 543.968 158.871 541.272 160.157C538.577 161.443 536.647 162.944 535.483 164.659C534.319 166.374 533.738 168.242 533.738 170.264C533.738 174.245 534.901 177.093 537.229 178.808C539.618 180.523 542.712 181.381 546.51 181.381C551.166 181.381 555.178 180.554 558.547 178.9C561.978 177.185 565.316 174.613 568.563 171.182V155.288ZM516.555 114.402C527.397 104.479 540.445 99.518 555.699 99.518C561.212 99.518 566.143 100.437 570.493 102.274C574.842 104.051 578.518 106.562 581.519 109.808C584.521 112.993 586.787 116.822 588.319 121.293C589.912 125.764 590.708 130.664 590.708 135.993V195.53H580.417C578.273 195.53 576.619 195.224 575.455 194.611C574.291 193.937 573.372 192.62 572.698 190.66L570.676 183.862C568.287 186.005 565.96 187.904 563.693 189.558C561.426 191.15 559.068 192.498 556.618 193.601C554.167 194.703 551.533 195.53 548.715 196.081C545.959 196.694 542.896 197 539.526 197C535.545 197 531.869 196.479 528.5 195.438C525.131 194.336 522.221 192.712 519.771 190.569C517.32 188.425 515.421 185.76 514.074 182.575C512.726 179.39 512.052 175.684 512.052 171.458C512.052 169.069 512.45 166.711 513.247 164.383C514.043 161.995 515.329 159.728 517.106 157.585C518.944 155.441 521.302 153.419 524.181 151.521C527.06 149.622 530.583 147.968 534.748 146.559C538.975 145.15 543.876 144.017 549.45 143.16C555.025 142.241 561.396 141.69 568.563 141.506V135.993C568.563 129.684 567.215 125.029 564.52 122.028C561.825 118.965 557.935 117.434 552.85 117.434C549.175 117.434 546.112 117.863 543.661 118.72C541.272 119.578 539.159 120.558 537.321 121.66C535.483 122.702 533.799 123.651 532.267 124.509C530.797 125.366 529.143 125.795 527.305 125.795C525.713 125.795 524.365 125.397 523.262 124.601C522.16 123.743 521.271 122.763 520.598 121.66L516.555 114.402Z'
        fill='#252F3F'
      />
      <path
        d='M633.493 117.618C636.434 111.983 639.925 107.573 643.969 104.387C648.012 101.141 652.79 99.518 658.303 99.518C662.652 99.518 666.144 100.467 668.778 102.366L667.308 119.364C667.002 120.466 666.542 121.262 665.93 121.752C665.378 122.181 664.613 122.395 663.633 122.395C662.714 122.395 661.335 122.242 659.498 121.936C657.721 121.63 655.975 121.477 654.26 121.477C651.748 121.477 649.512 121.844 647.552 122.579C645.592 123.314 643.815 124.386 642.223 125.795C640.691 127.142 639.313 128.796 638.088 130.756C636.924 132.716 635.821 134.952 634.78 137.463V195.53H612.083V101.264H625.407C627.735 101.264 629.358 101.692 630.277 102.55C631.196 103.346 631.809 104.816 632.115 106.96L633.493 117.618Z'
        fill='#252F3F'
      />
      <path
        d='M737.304 125.336C734.731 122.212 731.913 120.007 728.85 118.72C725.848 117.434 722.602 116.791 719.11 116.791C715.679 116.791 712.586 117.434 709.829 118.72C707.073 120.007 704.714 121.967 702.754 124.601C700.794 127.173 699.293 130.481 698.251 134.523C697.21 138.505 696.689 143.221 696.689 148.672C696.689 154.185 697.118 158.871 697.976 162.73C698.895 166.527 700.181 169.651 701.835 172.101C703.489 174.49 705.51 176.236 707.9 177.338C710.289 178.379 712.953 178.9 715.894 178.9C720.611 178.9 724.623 177.92 727.931 175.96C731.239 174 734.363 171.213 737.304 167.599V125.336ZM760 59V195.53H746.125C743.123 195.53 741.224 194.152 740.428 191.395L738.498 182.3C734.7 186.648 730.32 190.17 725.358 192.866C720.458 195.561 714.73 196.908 708.175 196.908C703.029 196.908 698.313 195.836 694.024 193.692C689.736 191.549 686.03 188.455 682.906 184.413C679.843 180.309 677.454 175.256 675.739 169.253C674.085 163.25 673.258 156.39 673.258 148.672C673.258 141.69 674.207 135.197 676.106 129.194C678.005 123.192 680.731 117.985 684.284 113.575C687.837 109.165 692.095 105.735 697.057 103.285C702.019 100.774 707.593 99.518 713.78 99.518C719.049 99.518 723.551 100.345 727.288 101.999C731.025 103.652 734.363 105.888 737.304 108.706V59H760Z'
        fill='#252F3F'
      />
      <path
        d='M16.1093 188.99H129.88C138.22 188.99 142.169 178.701 135.97 173.121L22.1988 70.6565C16.3393 65.3768 7 69.5365 7 77.4261V179.89C7 184.92 11.0797 189 16.1093 189V188.99Z'
        stroke='#1A56DB'
        strokeWidth='14'
      />
      <path
        d='M43.8471 7H16.1493C11.0963 7 7 11.0964 7 16.1495V179.841C7 184.894 11.0963 188.99 16.1493 188.99H43.8471C48.9001 188.99 52.9963 184.894 52.9963 179.841V16.1495C52.9963 11.0964 48.9001 7 43.8471 7Z'
        stroke='#1A56DB'
        strokeWidth='14'
      />
      <path
        d='M52.9963 97.975L88.7935 65.7268C94.653 60.4471 103.992 64.6069 103.992 72.4964V98.515C103.992 101.095 102.902 103.555 100.983 105.285L52.9963 148.512V97.985V97.975Z'
        stroke='#1A56DB'
        strokeWidth='14'
      />
      <path
        d='M52.9963 98.0251V148.492L81.0141 123.254L52.9963 98.0251Z'
        stroke='#1A56DB'
        strokeWidth='14'
      />
      <path
        d='M43.8871 188.99C48.8967 188.99 52.9963 184.89 52.9963 179.88V97.9649L22.1588 70.4764C16.2893 65.2467 7 69.4065 7 77.2761V179.88C7 184.89 11.0997 188.99 16.1093 188.99H43.8971H43.8871Z'
        stroke='#1A56DB'
        strokeWidth='14'
      />
    </svg>
  );
});

const KidcardLogo = styled(SvgComponent).attrs(props => ({
  inverted: props.theme.isDark,
}))`
  filter: ${({ tint, theme }) => (tint ? theme.imgFilter : 'none')};
`;

export default KidcardLogo;
