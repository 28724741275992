import styled from 'styled-components';
// Common components
import Spacer from '@/components/common/Spacer';
import Icon from '@/components/common/Icon';

// Props
interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  required?: boolean;
}

// Style
const StyledFormLabel = styled.label`
  flex: 1;
  display: block;
`;
const LabelCopy = styled.div`
  display: flex;
  align-items: baseline;
  color: ${({ theme }) => theme.textTertiary};
  font-weight: bold;
  letter-spacing: 0.025em;
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.textFaded};
    font-weight: normal;
  }
  i {
    align-self: center;
    margin-right: 4px;
    color: ${({ theme }) => theme.avatarText};
  }
  .danger {
    justify-self: flex-end;
    color: ${({ theme }) => theme.danger600};
    flex: 1;
    text-align: right;
    font-size: 14px;
  }
`;

// Component
const FormLabel = ({ label, labelIcon, optional, children, required, ...rest }: Props) =>
  label ? (
    <StyledFormLabel {...rest}>
      <LabelCopy>
        {!!labelIcon && <Icon icon={labelIcon} iconSize='20px' />}
        <div>
          {label}
          {!!optional && <span>&nbsp;(optional)</span>}
        </div>
        {required && <div className='danger'>REQUIRED</div>}
      </LabelCopy>
      {!!label && <Spacer height='4px' />}
      {children}
    </StyledFormLabel>
  ) : (
    <>{children}</>
  );
export default FormLabel;
