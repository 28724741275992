// import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { getStorage } from 'firebase/storage';

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize firebase (if isn't already)
export const fbApp = firebase.initializeApp(config);
// App check
// const appCheckKey = env.VITE_FIREBASE_APP_CHECK_KEY;
// if (appCheckKey) {
//   // Localhost debug token
//   if (window.location.hostname === 'localhost') {
//     console.log('localhost detected!');
//     // eslint-disable-next-line no-restricted-globals
//     (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = env.VITE_FIREBASE_APP_CHECK_DEBUG_TOKEN;
//   }
//   const appCheckProvider = new ReCaptchaEnterpriseProvider(appCheckKey);
//   initializeAppCheck(fbApp, { provider: appCheckProvider, isTokenAutoRefreshEnabled: true });
// }

// Initialize Auth through Firestore
export const fbAuth = getAuth(fbApp);

// Initialize Cloud Firestore through Firebase
export const db = firebase.firestore();
// To correct for some users experiencing no connection w/ error:
// "Could not reach Cloud Firestore backend. Backend didn't respond within 10 seconds"
// https://github.com/firebase/firebase-js-sdk/issues/1674
db.settings({
  experimentalAutoDetectLongPolling: true,
  merge: true,
  ignoreUndefinedProperties: true,
});

// Initialize Cloud Storage through Firebase
export const fbStorage = getStorage(fbApp);

// Initialize Cloud Functions through Firebase
export const fbFunctions = getFunctions(fbApp);

// Initialize Performance Monitoring through Firebase
export const fbPerf = getPerformance(fbApp);

// Initialize Analytics through Firebase
export const fbAnalytics = getAnalytics(fbApp);

export default firebase;

// Timestamp helpers
export type FlattenedTimestamp = {
  _seconds: number;
  _nanoseconds: number;
};
/** Convert all Firestore timestamps into their flattened counterparts */
export type FlattenedTimestamps<T> = T extends firebase.firestore.Timestamp
  ? FlattenedTimestamp
  : T extends object
    ? {
        [P in keyof T]: FlattenedTimestamps<T[P]>;
      }
    : T;
/** Revert all flattened Firestore timestamps into their unflattened counterparts */
export type UnflattenedTimestamps<T> = T extends FlattenedTimestamp
  ? firebase.firestore.Timestamp
  : T extends object
    ? {
        [P in keyof T]: UnflattenedTimestamps<T[P]>;
      }
    : T;
export const convertToTimestamp = (timestamp: FlattenedTimestamp): firebase.firestore.Timestamp => {
  return new firebase.firestore.Timestamp(timestamp._seconds, timestamp._nanoseconds);
};

/** Recursively loop through a data object,
 *  making sure previously serialized timestamps are restored  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function restoreTimestamps<T extends Record<string, any>>(
  input: T
): UnflattenedTimestamps<T> {
  if (!input) return input;
  if (typeof input !== 'object') return input as UnflattenedTimestamps<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (Array.isArray(input)) return input.map(restoreTimestamps) as any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: Record<string, any> = { ...input };
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (!value) continue;
      if (typeof value?._seconds === 'number' && typeof value?._nanoseconds === 'number') {
        obj[key] = convertToTimestamp(value);
      } else if (typeof value === 'object') {
        obj[key] = restoreTimestamps(value);
      }
    }
  }
  return obj as UnflattenedTimestamps<T>;
}
