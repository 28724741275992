import type { DefaultTheme } from 'styled-components';

import primaryTheme, { colors } from './primaryTheme';

export const darkSemantics = {
  sheetBackgroundColor: colors.grey900,
  sheetFadedBackgroundColor: '#171F26',
  backgroundColor: '#0F1419',

  textPrimary: '#FFFFFF',
  textSecondary: colors.grey100,
  textTertiary: colors.grey300,
  textFaded: colors.grey500,

  lightColor: '#0F1419',
  offLight: colors.grey800,
  lightAccent: colors.grey700,
  darkColor: '#FFFFFF',
  darkAccent: colors.grey100,

  dividerColor: colors.grey800,
  hoverFade: '#0F1419',
  hoverAsideFade: colors.grey900,
  avatarBG: colors.grey800,
  avatarText: colors.grey500,
};
export const darkShadows = {
  shadow100: '0px 1px 3px rgba(15, 20, 25, 0.5)',
  shadow200: '0px 2px 4px rgba(15, 20, 25, 0.5)',
  shadow200Inverted: '0px -2px 4px rgba(15, 20, 25, 0.5), 0px 2px 4px rgba(15, 20, 25, 0.025)',
  shadow300: '0px 4px 8px rgba(15, 20, 25, 0.6)',
  shadow300Inverted: '0px -4px 8px rgba(15, 20, 25, 0.6), 0px 4px 8px rgba(15, 20, 25, 0.025)',
  shadow400: '0px 0px 16px rgba(15, 20, 25, 0.6)',
  sheetShadows:
    '0px 0 32px rgba(15, 20, 25, 1), 0px 0px 12px rgba(15, 20, 25, 1), 0px 0px 6px rgba(15, 20, 25, 0.5)',
  headerShadow: `0 -0.3px 0.5px rgba(0, 0, 0, 0.061),
                 0 -0.9px 1.8px rgba(0, 0, 0, 0.089),
                 0 -4px 8px rgba(0, 0, 0, 0.15),
                 0 0.1px 0.3px rgba(0, 0, 0, 0.081),
                 0 0.4px 0.9px rgba(0, 0, 0, 0.119),
                 0 2px 4px rgba(0, 0, 0, 0.2)`,
  headerShadowHover: `0 -0.3px 0.7px rgba(0, 0, 0, 0.081),
                      0 -1.1px 2.2px rgba(0, 0, 0, 0.119),
                      0 -5px 10px rgba(0, 0, 0, 0.2),
                      0 0.3px 0.3px rgba(0, 0, 0, 0.081),
                      0 0.9px 0.9px rgba(0, 0, 0, 0.119),
                      0 4px 4px rgba(0, 0, 0, 0.2)`,
};

const darkTheme: DefaultTheme = {
  ...primaryTheme,
  isDark: true,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  ...darkSemantics,
  linkColor: colors.primary300,
  linkHover: colors.primary200,
  primaryDark: colors.primary100,

  /////////////
  // Shadows //
  /////////////
  ...darkShadows,
};

export default darkTheme;
