import styled from 'styled-components';

const Expanded = styled.div`
  flex: 1;
  /* Fix for potential non-truncating text on long strings: */
  /* https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
  /* IE10+ CSS styles go here */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 auto;
  }
`;

export default Expanded;
