import styled from 'styled-components';

const LinkButton = styled.button<{ dull?: boolean }>`
  -webkit-appearance: none;
  display: inline;
  padding: 0;

  color: ${({ dull, theme }) => (dull ? theme.textFaded : theme.linkColor)};
  text-decoration: none;
  &:focus {
    outline: none;
    text-decoration: underline;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export default LinkButton;
