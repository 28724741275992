import { memo } from 'react';
import styled from 'styled-components';

const DescriptionBlock = styled.div<{ danger?: boolean }>`
  padding: 12px 24px;
  background-color: ${({ danger, theme }) => (danger ? theme.danger000 : theme.hoverFade)};
  border-bottom: 1px solid ${({ danger, theme }) => (danger ? theme.danger100 : theme.dividerColor)};

  font-size: 15px;
  line-height: 1.25;
  color: ${({ theme, danger }) => (danger ? theme.grey900 : theme.textPrimary)};
  a {
    white-space: nowrap;
    font-weight: bold;
    color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.primary500)};
  }
`;
export default memo(DescriptionBlock);
