import type { DefaultTheme } from 'styled-components';
import primaryTheme, { colors } from './primaryTheme';

// Green theme
export const parakeetColors = {
  ...colors,
  primary000: '#E3F9E5',
  primary100: '#C1F2C7',
  primary200: '#91E697',
  primary300: '#51CA58',
  primary400: '#31B237',
  primary500: '#18981D',
  primary600: '#0F8613',
  primary700: '#0E7817',
  primary800: '#07600E',
  primary900: '#014807',
};

const parakeetTheme: DefaultTheme = {
  ...primaryTheme,
  // Image hue to rotate (to green)
  imgFilter: 'hue-rotate(238deg) contrast(0.6) brightness(1.06) saturate(1.6)',

  /////////////////////
  // Color Overrides //
  /////////////////////
  ...parakeetColors,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  linkColor: parakeetColors.primary500,
  linkHover: parakeetColors.primary600,
  primaryDark: parakeetColors.primary800,
};

export default parakeetTheme;
