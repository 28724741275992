import Meta from './Meta';
import Photo from './Photo';
import Place from './Place';
import Relationship from './Relationship';

export const PHONE_TYPES = ['Mobile', 'Home', 'Work', 'Other'] as const;
export const EMAIL_TYPES = ['Personal', 'Work', 'Other'] as const;
export const ADDRESS_TYPES = ['Home', 'Work', 'Other'] as const;
export const RELATIONSHIP_STATUSES = [
  'Single',
  'In a relationship',
  'Engaged',
  'Married',
  'Civil union',
  'Domestic partnership',
  'Separated',
  'Divorced',
  'Remarried',
  'Widowed',
] as const;

export type PhoneType = (typeof PHONE_TYPES)[number];
export type EmailType = (typeof EMAIL_TYPES)[number];
export type AddressType = (typeof ADDRESS_TYPES)[number];
export type RelationshipStatus = (typeof RELATIONSHIP_STATUSES)[number];
export type Gender = 'Male' | 'Female' | string | null;

export class PersonProfile {
  photo: Photo = null;
  name = {
    first: '',
    last: '',
    full: '',
  };
}

export const INTEGRATION_SERVICES = [
  'spreadsheet',
  'breeze',
  'planningCenter',
  'shulCloud',
  'chaverware',
  'rakefet',
  'acs',
  // Less common
  'faithTeams',
  'instantChurchDirectory',
  'realm',
] as const;
export type IntegrationService = (typeof INTEGRATION_SERVICES)[number];

export default class Person {
  meta = { ...new Meta() };
  integration: {
    id: string | null;
    households: string[];
    isChild: boolean;
    service: IntegrationService | string;
    inSync: boolean;
  } | null = null;
  isArchived = false;
  // Permissions and references
  locationId = '';
  groups = ['ungrouped'];
  // Core info
  profile = { ...new PersonProfile() };
  sortFirstName = '';
  sortLastName = '';
  notes = '';
  // Contact info
  phones: { type: PhoneType; number: string }[] = [];
  emails: { type: EmailType; address: string }[] = [];
  addresses: { type: AddressType; place: Place }[] = [];
  // Extra details
  birthday: string | null = null;
  relationshipStatus: RelationshipStatus | null = null;
  anniversary: string | null = null;
  gender: Gender = null;
  customId = '';
  joinDate: string | null = null;
  // Relationships
  relationships: Relationship[] = [];
  // Last updated ( by group )
  lastUpdated: {
    latest: firebase.firestore.Timestamp | null;
    [groupId: string]: firebase.firestore.Timestamp | null;
  } = { latest: null };
}
