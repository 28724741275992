// Generated (in part) by:
// https://www.smooth-code.com/open-source/svgr/playground/
// (a project based on https://github.com/smooth-code/svgr)
import { type ComponentProps } from 'react';
import { useTheme } from 'styled-components';

interface Props extends ComponentProps<'svg'> {
  width?: string;
  height?: string;
  dull?: boolean;
}
export default function NotebirdIcon({ dull, ...props }: Props) {
  const theme = useTheme();
  const fillColor = dull ? theme.lightAccent : theme.linkColor;
  return (
    <svg viewBox='0 0 256 256' {...props}>
      <defs>
        <radialGradient
          id='notebird-flat-icon__a'
          cx={41.8}
          cy={95.49}
          r={174.24}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#1f2933' stopOpacity={0.3} />
          <stop offset={0.94} stopColor='#323f4b' stopOpacity={0} />
        </radialGradient>
      </defs>
      <title>{'Notebird - Icon'}</title>
      <g data-name='Layer 2'>
        <path
          d='M42.82 96.25L86 256h170l-46.6-56.71S92.68 80.85 90.68 80.19 42.82 96.25 42.82 96.25z'
          fillOpacity={0.5}
          opacity={0.5}
          fill='url(#notebird-flat-icon__a)'
        />
        <path
          d='M209.4 199.29c-15 6-38.06 7.81-54.62 7.81-35.45 0-56.83-14.25-68.48-32.54-19.26-30.24-11.21-62-9.55-71.8 1.48-8.72-35.81-1.44-33.88-7 1.87-5.33 42.76-51.26 47.49-51.26 7.97 0 65.38 91.5 119.04 154.79z'
          fill={fillColor}
        />
      </g>
    </svg>
  );
}
