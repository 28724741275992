import styled, { keyframes } from 'styled-components';

import { db } from '@/lib/firebase';

import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import PrimaryButton, { PrimaryButtonLink } from '@/components/common/Buttons';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import Spacer from '@/components/common/Spacer';

const ScheduleACallNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 12px 5vw;
  @media (min-height: 768px) {
    padding: 0 12px 96px;
  }
`;
const noticeBoxAnimateIn = keyframes`
    0% { opacity: 0; transform: translateX(-33%); }
  100% { opacity: 1; transform: translateX(0); }
`;
const ScheduleACallNoticeBox = styled.div`
  width: 100%;
  max-width: 460px;
  padding: 16px 12px 8px;
  @media (min-height: 768px) {
    padding: 24px 16px 16px;
  }
  background-color: ${({ theme }) => theme.hoverFade};
  border: 2px solid ${({ theme }) => theme.offLight};
  border-radius: 6px;
  h3 {
    font-size: 26px;
    color: ${({ theme }) => theme.textTertiary};
    margin: 0 0 8px;
  }
  p {
    margin: 0;
    padding: 0 0 8px;
    @media (min-width: 375px) {
      font-size: 18px;
      padding: 0 0 12px;
    }
    color: ${({ theme }) => theme.textTertiary};
  }
  opacity: 0;
  transform: translateX(-33%);
  animation: ${noticeBoxAnimateIn} 800ms ${({ theme }) => theme.easeDecelerate} 1200ms forwards;
`;
const NoticeCloseButton = styled.button`
  appearance: none;
  position: absolute;
  top: 4px;
  right: 4px;
`;
const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  button,
  a {
    margin-right: 12px;
    margin-bottom: 8px;
  }
`;
const ScheduleACallNotice = () => {
  const { organizationDocPath } = useAppState();
  const setAppState = useSetAppState();

  const [user] = useUser();
  const [organization] = useOrganization();

  return (
    <>
      <ScheduleACallNoticeWrapper>
        <ScheduleACallNoticeBox>
          <NoticeCloseButton
            onClick={() =>
              user &&
              organizationDocPath &&
              db.doc(organizationDocPath).update({
                ...generateUpdatedByDottedMeta(user),
                'profile.setupMeetingStatus': 'dismissed',
              })
            }
          >
            <Icon icon='close' />
          </NoticeCloseButton>
          <h3>Hey {user?.profile.name.first || 'there'} 👋</h3>
          <p>
            We’ve added a few test profiles to show you what Notebird can do, so feel free to
            explore!
          </p>
          <p>
            To bring in your own data, schedule a quick call with our team or
            {['Breeze', 'Planning Center', 'Church Windows', 'ShulCloud'].some(
              cms => cms === organization?.profile.cms
            )
              ? ` sync with ${organization?.profile.cms}.`
              : ' use our spreadsheet importer.'}
          </p>
          <Spacer height='12px' />

          {/* Schedule / import / sync buttons */}
          <ButtonRow>
            <PrimaryButton
              small
              leadingIcon='event'
              outlined
              onClick={() => setAppState({ isScheduleACallModalOpen: true })}
            >
              <strong>Schedule a call</strong>
            </PrimaryButton>
            {['Breeze', 'Planning Center', 'Church Windows', 'ShulCloud'].some(
              cms => cms === organization?.profile.cms
            ) ? (
              <PrimaryButtonLink small to='/account/integrations'>
                <Icon icon='sync' iconSize='20px' />
                <Expanded>
                  <strong>Sync with {organization?.profile.cms}</strong>
                </Expanded>
              </PrimaryButtonLink>
            ) : (
              <PrimaryButtonLink small to='/import'>
                <Icon icon='system_update_alt' iconSize='20px' />
                <Expanded>
                  <strong>Import your data</strong>
                </Expanded>
              </PrimaryButtonLink>
            )}
          </ButtonRow>
        </ScheduleACallNoticeBox>
      </ScheduleACallNoticeWrapper>
    </>
  );
};

export default ScheduleACallNotice;
