import { memo } from 'react';
import Notebirb from '@/classes/Notebirb';

// SVG birb shapes
// Generated in part by: // https://www.smooth-code.com/open-source/svgr/playground/
// (a project based on https://github.com/smooth-code/svgr)
const notebirbs = [
  // 00
  (colorIndex: number) => (
    <path
      d='M33.623 9.225a8.381 8.381 0 00-13.286 3.45.562.562 0 00.234.672l3.832 2.361a.563.563 0 01.06.917L7.968 29.97a2.066 2.066 0 00-.396 2.783c3.194 4.62 8.531 7.647 14.57 7.647 9.775 0 17.7-7.925 17.7-17.702a17.662 17.662 0 00-6.22-13.473z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 01
  (colorIndex: number) => (
    <path
      d='M13.424 9.225a8.382 8.382 0 0113.286 3.45.563.563 0 01-.234.672l-3.832 2.361a.561.561 0 00-.06.917L39.08 29.97a2.066 2.066 0 01.395 2.783c-3.194 4.62-8.53 7.647-14.57 7.647-9.775 0-17.7-7.925-17.7-17.702a17.662 17.662 0 016.22-13.473z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 02
  (colorIndex: number) => (
    <path
      d='M40.542 13.025a11.776 11.776 0 00-7.985-5.282 3.558 3.558 0 00-3.094.997L8.199 29.995a2.062 2.062 0 00-.31 2.519c2.725 4.564 7.713 7.62 13.416 7.62 8.625 0 15.619-6.992 15.619-15.618a15.547 15.547 0 00-1.955-7.57.562.562 0 01.257-.785l5.076-2.32a.563.563 0 00.24-.816z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 03
  (colorIndex: number) => (
    <path
      d='M7.458 13.025a11.776 11.776 0 017.985-5.282 3.558 3.558 0 013.094.997l21.264 21.255a2.063 2.063 0 01.31 2.519c-2.725 4.564-7.713 7.62-13.416 7.62-8.625 0-15.619-6.992-15.619-15.618a15.547 15.547 0 011.955-7.57.562.562 0 00-.257-.785l-5.076-2.32a.562.562 0 01-.24-.816z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 04
  (colorIndex: number) => (
    <path
      d='M40.724 14.11a.563.563 0 00.24-.816 11.709 11.709 0 00-9.886-5.419c-.442 0-.883.024-1.322.074a1.5 1.5 0 00-1.26 1.942c.042.133.083.268.121.402a3.57 3.57 0 01-1.396 3.92L7.415 27.907a.562.562 0 00.098.98c1.077.463 2.202.802 3.355 1.01l-1.765 1.427a1.496 1.496 0 00-.314 1.984c2.785 4.221 7.57 7.005 13.004 7.005 8.597 0 15.566-6.968 15.566-15.563a15.493 15.493 0 00-1.947-7.543.56.56 0 01.257-.785l5.055-2.313z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 05
  (colorIndex: number) => (
    <path
      d='M7.276 14.11a.563.563 0 01-.24-.816 11.709 11.709 0 019.886-5.419c.442 0 .883.024 1.322.074a1.5 1.5 0 011.26 1.942c-.042.133-.083.268-.121.402a3.57 3.57 0 001.396 3.92l19.806 13.694a.562.562 0 01-.098.98 15.44 15.44 0 01-3.355 1.01l1.765 1.427a1.495 1.495 0 01.314 1.984c-2.785 4.221-7.57 7.005-13.003 7.005-8.598 0-15.567-6.968-15.567-15.563a15.493 15.493 0 011.947-7.543.56.56 0 00-.257-.785L7.276 14.11z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 06
  (colorIndex: number) => (
    <path
      d='M40.146 13.297a11.776 11.776 0 00-7.987-5.282 3.557 3.557 0 00-3.093.996L21.72 16.35 7.01 31.06a.56.56 0 00-.109.644 15.637 15.637 0 002.473 3.617l2.184-2.048a11.766 11.766 0 0010.707 6.882 11.713 11.713 0 004.81-1.026c-.151.073-.31.143-.476.21 5.812-2.275 9.93-7.932 9.93-14.55a15.544 15.544 0 00-1.955-7.57.562.562 0 01.253-.786l5.077-2.32a.562.562 0 00.24-.815z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 07
  (colorIndex: number) => (
    <path
      d='M7.854 13.297a11.775 11.775 0 017.987-5.282 3.557 3.557 0 013.094.996l7.344 7.338 14.71 14.71a.56.56 0 01.109.644 15.638 15.638 0 01-2.473 3.617l-2.184-2.048a11.766 11.766 0 01-10.707 6.882 11.713 11.713 0 01-4.81-1.026c.151.073.31.143.476.21-5.812-2.275-9.93-7.932-9.93-14.55a15.544 15.544 0 011.955-7.57.562.562 0 00-.253-.786l-5.077-2.32a.562.562 0 01-.24-.815z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 08
  (colorIndex: number) => (
    <path
      d='M38.935 25.553a13.612 13.612 0 00-4.773-10.366l2.348-1.45a.563.563 0 00.25-.618 8.468 8.468 0 00-12.088-5.385 3.53 3.53 0 00-1.524 1.558L14.23 27.125a2.062 2.062 0 01-.761.833l-4.884 3.015a1.5 1.5 0 00-.412 2.178c3.262 4.331 8.447 7.132 14.287 7.132a17.808 17.808 0 0012-4.627 13.606 13.606 0 004.475-10.103z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 09
  (colorIndex: number) => (
    <path
      d='M9.065 25.553a13.613 13.613 0 014.773-10.366l-2.348-1.45a.563.563 0 01-.25-.618 8.468 8.468 0 0112.088-5.385 3.53 3.53 0 011.524 1.558l8.918 17.833c.171.343.435.632.761.833l4.884 3.015a1.501 1.501 0 01.412 2.178c-3.262 4.331-8.447 7.132-14.287 7.132a17.808 17.808 0 01-12-4.627 13.606 13.606 0 01-4.475-10.103z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 10
  (colorIndex: number) => (
    <path
      d='M37.245 22.781L31.667 8.214a3.552 3.552 0 00-2.723-2.238 8.716 8.716 0 00-2.816-.01c-4.109.656-7.247 4.27-7.247 8.564 1.07-.439 2.207-.695 3.392-.855L15.306 27.61a3.564 3.564 0 01-1.316 1.439l-5 3.084a1.124 1.124 0 00-.327 1.608c3.303 4.63 8.717 7.65 14.837 7.65.638 0 1.276-.033 1.91-.099C32.496 40.653 38.05 34.7 38.05 27.45a13.87 13.87 0 00-.804-4.668z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 11
  (colorIndex: number) => (
    <path
      d='M10.755 22.781l5.578-14.567a3.552 3.552 0 012.723-2.238 8.716 8.716 0 012.816-.01c4.109.656 7.247 4.27 7.247 8.564-1.07-.439-2.207-.695-3.392-.855l6.966 13.934c.297.593.752 1.09 1.316 1.439l5 3.084a1.125 1.125 0 01.327 1.608c-3.303 4.63-8.717 7.65-14.837 7.65-.638 0-1.276-.033-1.91-.099C15.504 40.653 9.95 34.7 9.95 27.45c-.001-1.59.27-3.17.804-4.668z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 12
  (colorIndex: number) => (
    <path
      d='M40.43 13.605a19.061 19.061 0 00-2.63-3.391 4.141 4.141 0 00-2.966-1.25h-2.797a5.653 5.653 0 00-3.998 1.656L13.912 24.75a.565.565 0 01-.906-.147l-1.37-2.742a2.073 2.073 0 00-1.855-1.146H6.953a.567.567 0 00-.546.712l2.64 9.886c1.448 5.429 6.31 9.287 11.93 9.366h.224c8.617 0 15.603-6.984 15.603-15.604a15.53 15.53 0 00-1.95-7.559.565.565 0 01.255-.785l5.072-2.318a.564.564 0 00.25-.808z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
  // 13
  (colorIndex: number) => (
    <path
      d='M7.57 13.605a19.058 19.058 0 012.63-3.391 4.141 4.141 0 012.966-1.25h2.797a5.653 5.653 0 013.998 1.656l14.127 14.13a.565.565 0 00.906-.147l1.37-2.742a2.073 2.073 0 011.855-1.146h2.828a.567.567 0 01.546.712l-2.64 9.886c-1.448 5.429-6.31 9.287-11.93 9.366H26.8c-8.617 0-15.603-6.984-15.603-15.604a15.53 15.53 0 011.95-7.559.565.565 0 00-.255-.785l-5.072-2.318a.564.564 0 01-.25-.808z'
      fill={notebirbColorways[colorIndex].foregroundColor}
    />
  ),
];

// Color combinations (found in figma)
const notebirbColorways = [
  {
    backgroundColor: '#C27AC3',
    foregroundColor: '#741675',
  },
  {
    backgroundColor: '#AC9BE0',
    foregroundColor: '#463385',
  },
  {
    backgroundColor: '#9DA5E3',
    foregroundColor: '#3540B8',
  },
  {
    backgroundColor: '#7CA5EB',
    foregroundColor: '#0C5CC9',
  },
  {
    backgroundColor: '#79B3CE',
    foregroundColor: '#17659A',
  },
  {
    backgroundColor: '#76C2B2',
    foregroundColor: '#226E6B',
  },
  {
    backgroundColor: '#9ACCAB',
    foregroundColor: '#286B4B',
  },
  {
    backgroundColor: '#BED6A5',
    foregroundColor: '#2F692B',
  },
  {
    backgroundColor: '#EDDC77',
    foregroundColor: '#CCA000',
  },
  {
    backgroundColor: '#F2C783',
    foregroundColor: '#C27800',
  },
  {
    backgroundColor: '#EB9A63',
    foregroundColor: '#BD560F',
  },
  {
    backgroundColor: '#E3897B',
    foregroundColor: '#A83C28',
  },
  {
    backgroundColor: '#DB7893',
    foregroundColor: '#942342',
  },
  {
    backgroundColor: '#CF79AB',
    foregroundColor: '#841C5B',
  },
];

// Props
interface Props {
  notebirb: Notebirb;
  size?: number;
}

// Component
const NotebirbAvatar = memo(function NotebirbAvatar({ notebirb, size = 48, ...props }: Props) {
  const Birb = notebirbs[notebirb.index](notebirb.colorwayIndex);
  return (
    <svg
      viewBox='0 0 48 48'
      width={size}
      height={size}
      fill='none'
      style={{ borderRadius: size <= 24 ? '4px' : '6px', flexShrink: 0 }}
      {...props}
    >
      {/* Square BG */}
      <path d='M48 0H0v48h48V0z' fill={notebirbColorways[notebirb.colorwayIndex].backgroundColor} />
      {/* Birb */}
      {Birb}
    </svg>
  );
});
export default NotebirbAvatar;
