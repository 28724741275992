import kebabCase from 'lodash/kebabCase';
// Form elements
import FormLabel from './FormLabel';
import FormInput from './FormInput';
import FormError from './FormError';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  dense?: boolean;
  error?: string;
}

const FormInputGroup = ({
  label,
  labelIcon,
  optional,
  name,
  error,
  type = 'text',
  ...rest
}: Props) => (
  <FormLabel
    label={label}
    labelIcon={labelIcon}
    htmlFor={kebabCase((label || '') + name)}
    optional={optional}
  >
    <FormInput
      id={kebabCase((label || '') + name)}
      name={name}
      type={type}
      {...rest}
      hasError={!!error}
    />
    {typeof error === 'string' ? <FormError>{error}</FormError> : null}
  </FormLabel>
);
export default FormInputGroup;
