import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
// Libs
import { db } from '@/lib/firebase';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
import { useFormik } from 'formik';
// Context data
import useUser from '@/contexts/user';
// Form elements
import { Input } from './FormElements';
import FormLabel from './styled/FormLabel';
// Common components
import PrimaryButton, { SecondaryButton } from '../common/Buttons';
import Icon from '../common/Icon';
import Loader from '../common/Loader';
import Spacer from '../common/Spacer';

// Styles
export const ValueWrapper = styled.div<{ locked?: boolean }>`
  display: inline-flex;
  align-items: center;

  cursor: ${({ locked }) => (locked ? 'not-allowed' : 'pointer')};
  padding: 10px 24px;
  margin-left: -10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;

  ${Icon} {
    margin-left: 8px;
    color: ${props => props.theme.lightAccent};
  }

  ${({ locked }) =>
    !locked &&
    css`
      &:hover {
        background-color: ${props => props.theme.hoverFade};
        ${Icon} {
          color: ${props => props.theme.linkColor};
        }
      }
    `};

  .placeholder {
    color: ${props => props.theme.textFaded};
    font-weight: normal;
    letter-spacing: 0.025em;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    border-radius: 4px 0 0 4px;
  }
  ${PrimaryButton} {
    padding: 9px 16px;
    border-radius: 0px 4px 4px 0px;
  }
  /* Cancel/Delete */
  ${SecondaryButton} {
    margin-left: 8px;
  }
`;
const InputError = styled.div`
  margin-top: 2px;
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.danger400};
`;

// Props
interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  locked?: boolean;
  editOnMount?: boolean;
  error?(value: string): string | false | null | undefined;
  value: string;
  documentPath?: string;
  fieldName?: string;
  includeUpdatedByMeta?: boolean;
  onSubmit?(value: string): void;
  onCancel?(): void;
  onBeginEdit?(): void;
  onDelete?: () => void;
}

// Component
const DynamicInputRow = ({
  label,
  name,
  placeholder,
  required = false,
  maxLength = 1000,
  disabled = false,
  locked = false,
  editOnMount = false,
  error,
  value,
  documentPath,
  fieldName,
  includeUpdatedByMeta = false,
  onSubmit,
  onCancel,
  onBeginEdit,
  onDelete,
}: Props) => {
  // Current user for meta ( if so chooses )
  const [user] = useUser();

  const [editMode, setEditMode] = useState(editOnMount);
  // Form helper (Formik)
  const { values, handleSubmit, isSubmitting, getFieldProps, setFieldValue, submitForm } =
    useFormik({
      initialValues: { [name]: value },
      onSubmit: formSubmit,
    });

  const [errorMessage, setErrorMessage] = useState<string | false | null>(false);
  // Update error as typing
  const val = values[name];
  useEffect(() => {
    if (editMode) {
      const message = (error && error(val)) || false;
      setErrorMessage(message);
    }
  }, [editMode, error, val]);

  // Dissallow submissions when already submitting OR
  // 1. Is marked as disabled
  // or
  // 2. Has error
  // or
  // 3. No changes have been made
  // or
  // 4. Is required and empty
  const submitDisabled =
    isSubmitting ||
    disabled || // 1
    !!errorMessage || // 2
    val.trim() === value.trim() || // 3
    (required && !val.trim()); // 4
  ////////////
  // Submit //
  ////////////
  async function formSubmit() {
    if (documentPath && fieldName) {
      if (user && includeUpdatedByMeta) {
        db.doc(documentPath).update({
          ...generateUpdatedByDottedMeta(user),
          [fieldName]: val.trim(),
        });
      } else {
        db.doc(documentPath).update(fieldName, val.trim());
      }
    }
    setEditMode(false);
    onSubmit && onSubmit(val.trim());
  }

  function clearInput() {
    setFieldValue(name, value);
    setEditMode(false);
    setErrorMessage('');
  }

  // Trigger begin edit whenever switching to edit mode
  useEffect(() => {
    if (editMode && onBeginEdit) {
      onBeginEdit();
    }
  }, [editMode, onBeginEdit]);

  return (
    <>
      <form onSubmit={handleSubmit} noValidate autoComplete='off'>
        <FormLabel label={label}>
          {!editMode && (
            <ValueWrapper
              locked={locked}
              onClick={event => {
                event.preventDefault();
                !locked && setEditMode(true);
              }}
            >
              {val}
              <span className='placeholder'>{!val && placeholder}</span>
              <Icon icon={locked ? 'lock' : 'edit'} iconSize='18px' />
            </ValueWrapper>
          )}
          {editMode && (
            <InputWrapper>
              {/* Input field */}
              <Input
                placeholder={placeholder ? placeholder : required ? 'Required' : ''}
                error={!!errorMessage}
                maxLength={maxLength}
                dense
                {...getFieldProps({ name })}
                autoFocus={true}
                onBlur={() => {
                  onCancel && onCancel();
                  clearInput();
                }}
              />
              {/* Save */}
              <PrimaryButton
                small
                type='submit'
                disabled={submitDisabled}
                // is mouseDown instead of click to preceed input onBlur
                onMouseDown={event => {
                  event.preventDefault();
                  submitForm();
                }}
              >
                Save
              </PrimaryButton>
              <Spacer width='4px' />
              {/* Cancel */}
              <SecondaryButton
                dull
                onClick={event => {
                  event.preventDefault();
                  onCancel && onCancel();
                  clearInput();
                }}
              >
                <Icon icon='close' iconSize='19px' />
              </SecondaryButton>
              {/* Delete button */}
              {!!onDelete && (
                <SecondaryButton
                  danger
                  // is mouseDown instead of click to preceed input onBlur
                  onMouseDown={() => {
                    // Todo: need preventDefault()?
                    // ...don't think so for modals that may come after
                    // event.preventDefault();
                    onDelete();
                  }}
                >
                  <Icon icon='delete' iconSize='19px' />
                </SecondaryButton>
              )}
            </InputWrapper>
          )}
          {!!errorMessage && <InputError>{errorMessage}</InputError>}
          <Loader show={isSubmitting} />
        </FormLabel>
      </form>
    </>
  );
};

export default DynamicInputRow;
