// Core
import { memo, HTMLProps } from 'react';
import styled from 'styled-components';
// Components
import Avatar from '../../common/Avatar';
import Spacer from '../../common/Spacer';
import Expanded from '../../common/Expanded';
import Divider from '../../common/Divider';

// Styles
const Name = styled.div`
  color: ${({ theme }) => theme.lightAccent};
  font-size: 18px;
  font-weight: bold;
`;
const StyledPersonLinkItemLoader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  @media (max-width: 424px) {
    padding: 6px 4px;
  }

  line-height: 1.4;
`;

// Component
const PersonLinkItemLoader = memo(function PersonLinkItemLoader(props: HTMLProps<HTMLDivElement>) {
  return (
    <div {...props}>
      <StyledPersonLinkItemLoader>
        <Spacer width='12px' />
        <Avatar photo={null} name={''} faded />
        <Spacer width='12px' />
        <Expanded>
          <Name>LOADING...</Name>
        </Expanded>
      </StyledPersonLinkItemLoader>
      <Divider />
    </div>
  );
});
export default PersonLinkItemLoader;
