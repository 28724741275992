import { PersonProfile } from './Person';

export const RELATIONSHIP_TYPES = [
  'Spouse',
  'Fiancé',
  'Partner',
  'Late spouse',
  'Child',
  'Parent',
  'Sibling',
  'Ex-spouse',
  'Step child',
  'Step parent',
  'Step sibling',
  'Half sibling',
  'Cousin',
  'Grandparent',
  'Grandchild',
  'Great grandparent',
  'Great grandchild',
  'Other',
] as const;
export type RelationshipType = (typeof RELATIONSHIP_TYPES)[number];

export const RELATIONSHIP_DICT: {
  [relationshipType in RelationshipType]: RelationshipType;
} = {
  Spouse: 'Spouse',
  Fiancé: 'Fiancé',
  Partner: 'Partner',
  'Late spouse': 'Spouse',
  Child: 'Parent',
  Parent: 'Child',
  Sibling: 'Sibling',
  'Ex-spouse': 'Ex-spouse',
  'Step child': 'Step parent',
  'Step parent': 'Step child',
  'Step sibling': 'Step sibling',
  'Half sibling': 'Half sibling',
  Cousin: 'Cousin',
  Grandparent: 'Grandchild',
  Grandchild: 'Grandparent',
  'Great grandparent': 'Great grandchild',
  'Great grandchild': 'Great grandparent',
  Other: 'Other',
};

export default class Relationship {
  id: string | null = null;
  profile = { ...new PersonProfile() };
  type: RelationshipType | string = '';
}
