import { type ComponentProps } from 'react';

import kebabCase from 'lodash/kebabCase';

import FormError from './FormError';
import FormLabel from './FormLabel';
import FormTextarea from './FormTextarea';

interface Props extends ComponentProps<typeof FormTextarea> {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  dense?: boolean;
  error?: string;
  minRows?: number;
  maxRows?: number;
}

const FormTextareaGroup = ({ label, labelIcon, optional, name, error, ...rest }: Props) => (
  <FormLabel
    label={label}
    labelIcon={labelIcon}
    htmlFor={kebabCase((label || '') + name)}
    optional={optional}
  >
    <FormTextarea id={kebabCase((label || '') + name)} name={name} {...rest} hasError={!!error} />
    {typeof error === 'string' ? <FormError>{error}</FormError> : null}
  </FormLabel>
);
export default FormTextareaGroup;
