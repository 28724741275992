import { memo } from 'react';
import styled from 'styled-components';

interface Props {
  padding?: number;
}
const Divider = styled.div<Props>`
  width: calc(100% - ${props => (props.padding !== undefined ? props.padding * 2 + 'px' : '32px')});
  margin: 0 auto;
  height: 1px;
  background-color: ${props => props.theme.dividerColor};
  transition: background-color 200ms ease-out; /* For theme toggle */
`;

export default memo(Divider);
