import { Fragment, Suspense, lazy, useLayoutEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Doc from '@/classes/Doc';
import Milestone from '@/classes/Milestone';

import { getFriendlyDate, pluralize } from '@/lib/helpers';

import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useMilestones, { useTypeFilteredMilestones } from '@/contexts/milestones';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';

import MilestoneGroupItem from './MilestoneGroupItem';

import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Icon from '@/components/common/Icon';
import LinkButton from '@/components/common/LinkButton';
import Loader from '@/components/common/Loader';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import StickyHeader from '@/components/common/StickyHeader';

import NotebirdIcon from '@/components/svg/NotebirdIcon';

const NoUpcomingMilestonesIllustration = lazy(
  () => import('../../svg/NoUpcomingMilestonesIllustration')
);

// Types
interface RelativeMilestone extends Doc<Milestone> {
  dateTime: DateTime;
  annualizedDateTime: DateTime;
  yearsAgo: number;
}
export interface RelativeMilestoneGroup {
  id: string;
  typeLabel: string;
  timeLabel: string;
  milestones: RelativeMilestone[];
}

// Styles
// TODO: share some of these styles with UpdatesSheet and beyond
const ScrollRef = styled.div`
  min-height: 100%;
`;
const ScrollUpNotice = styled.div`
  /* position: sticky;
  top: 0; */
  /* z-index: 1; */

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;

  background-color: ${({ theme }) => theme.sheetBackgroundColor};
  color: ${({ theme }) => theme.avatarText};
  strong {
    color: ${({ theme }) => theme.textFaded};
  }
  i {
    margin: 0 8px;
    color: ${({ theme }) => theme.avatarText};
  }
`;
const EndOfList = styled.div`
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.textFaded};
`;

// Component
const MilestonesSheet = () => {
  // When on mobile we need to know if milestones sheet is active ( for scroll-y stuff below )
  const { sheet } = useParams();
  const showMilestones = sheet === 'milestones';

  // App state
  const { locationId, groupId, groupName, contentFilter, filterMilestonesBy } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [, milestonesAreFetching] = useMilestones();
  const typeFilteredMilestones = useTypeFilteredMilestones();

  // Group milestones by day and type
  // ( beware, this one's the doozy... )
  const dayGroupedMilestones = useMemo(() => {
    // Separate into groups with start of day millis as key
    const dayGroups = groupBy(typeFilteredMilestones, ({ annualizedDateTime }) =>
      annualizedDateTime.toMillis()
    );

    // Group each day by types
    const typeGroups: {
      [millis: string]: RelativeMilestoneGroup[];
    } = {};

    // Loop over each day
    for (const millis in dayGroups) {
      // Group each day's milestone by type and customize label if necessary
      const labelGroups = groupBy(dayGroups[millis], ({ type, yearsAgo, dateTime }) => {
        switch (type) {
          case 'birthday':
            return 'Birthday';
          case 'anniversary':
            return yearsAgo === 0 ? 'Marriage' : 'Anniversary';
          case 'joinDate':
            return dateTime > DateTime.local() ? 'Will join' : 'Joined';
          default:
            return type;
        }
      });
      // Loop over each label group, group further based on yearsAgo (if necessary), and add to final output
      typeGroups[millis] = [];
      for (const typeLabel in labelGroups) {
        const labelGroup = labelGroups[typeLabel];
        const id = labelGroup.map(({ id }) => id).reduce((sum = '', id) => sum + id);

        // All ( except Joined ) labeled defaults are fine as is
        if (['Birthday', 'Marriage', 'Anniversary', 'Will join'].includes(typeLabel)) {
          typeGroups[millis].push({
            id,
            typeLabel,
            timeLabel: '',
            milestones: labelGroup,
          });
        } else {
          // Need to separate out different years for non label defaults
          const yearGroups = groupBy(labelGroup, ({ yearsAgo }) => yearsAgo);
          // Loop over year groups for this label and add
          for (const years in yearGroups) {
            const yearsAgo = parseInt(years);
            const yearGroup = yearGroups[years];
            const timeLabel = pluralize({
              count: yearsAgo,
              singular: '{x} year ago',
              plural: '{x} years ago',
            });
            typeGroups[millis].push({
              id: id + years,
              typeLabel,
              timeLabel,
              milestones: yearGroup,
            });
          }
        }
      }
    }

    // Sort and return
    return orderBy(toPairs(typeGroups), ([millis]) => millis);
  }, [typeFilteredMilestones]);

  // Separate milestone groups into past and upcoming ( upcoming is today inclusive )
  const pastMilestoneGroups = useMemo(() => {
    const todayMillis = DateTime.local().startOf('day').toMillis();
    return dayGroupedMilestones.filter(([millis]) => parseInt(millis) < todayMillis);
  }, [dayGroupedMilestones]);
  const upcomingMilestoneGroups = useMemo(() => {
    const todayMillis = DateTime.local().startOf('day').toMillis();
    return dayGroupedMilestones.filter(([millis]) => parseInt(millis) >= todayMillis);
  }, [dayGroupedMilestones]);

  // Set scroll to upcoming milestones ( skipping "past" milestones ) on mount AND
  // any time locationId, groupId, contentFilter, milestonesTypeFilter, showArchived changes
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (scrollableRef?.current && scrollToRef?.current) {
      scrollableRef.current.scrollTop = scrollToRef.current.offsetTop;
    }
  }, [
    locationId,
    groupId,
    contentFilter,
    filterMilestonesBy,
    milestonesAreFetching,
    showMilestones, // This is also important so switching to this tab on mobile triggers reset
  ]);

  return (
    <>
      {/* Header ( desktop only ) */}
      <SheetHeader
        primary
        leadingIcon='today'
        mainTitle='Milestones'
        className='hidden-mobile'
        // Header fades if has no milestones
        active={!!typeFilteredMilestones.length}
      ></SheetHeader>

      {/* Loader */}
      <Loader show={milestonesAreFetching} />

      {/* Main scrollable area */}
      <Scrollable forwardedRef={scrollableRef} endBuffer='0'>
        {/* Map over previous milestone groups ( scrolled past by default ) */}
        {/* ( and extra div wrapper so sticky headers don't prevail ) */}
        <div>
          {pastMilestoneGroups.map(([millis, milestoneGroups]) => (
            <Fragment key={millis}>
              {/* Date header */}
              <StickyHeader variant='dull' heading={getFriendlyDate(parseInt(millis))} />
              {/* Each milestone group on date */}
              {milestoneGroups.map(milestoneGroup => (
                <MilestoneGroupItem
                  key={milestoneGroup.id}
                  milestoneGroup={milestoneGroup}
                  dull // Fade past milestones' icons
                />
              ))}
            </Fragment>
          ))}
        </div>

        {/* Div wrapper to scroll to onMount ( and other list changes ) */}
        {/* also at a height to, at minimum, obscure any potential past milestones */}
        <ScrollRef ref={scrollToRef}>
          {/* Notice if actually has previous milestones */}
          {!!pastMilestoneGroups.length && (
            <ScrollUpNotice>
              <span>
                Scroll up for <strong>yesterday&apos;s milestones</strong>
              </span>
              <Icon icon='arrow_upward' iconSize='20px' />
            </ScrollUpNotice>
          )}

          {/* Map over upcoming milestone groups ( if has any ) */}
          {!!upcomingMilestoneGroups.length && (
            <div>
              {upcomingMilestoneGroups.map(([millis, milestoneGroups]) => (
                // Don't snap first one ( top of scroll ref will catch the scroll instead )
                <Fragment key={millis}>
                  {/* Date header */}
                  <StickyHeader heading={getFriendlyDate(parseInt(millis))} />
                  {/* Each milestone group on date */}
                  {milestoneGroups.map(milestoneGroup => (
                    <MilestoneGroupItem key={milestoneGroup.id} milestoneGroup={milestoneGroup} />
                  ))}
                </Fragment>
              ))}

              {/* End of list */}
              <Spacer height='64px' />
              <NotebirdIcon width='100%' height='24px' dull />
              <Spacer height='4px' />
              <EndOfList>That&apos;s all for this week</EndOfList>
            </div>
          )}

          {/* Empty notice for no upcoming milestones in this group/filter */}
          {!milestonesAreFetching &&
            !upcomingMilestoneGroups.length &&
            (groupId || contentFilter === 'following') && (
              <Padding
                padding='48px 32px'
                className='fade-in'
                key={groupName || contentFilter || undefined}
              >
                <ErrorSuspendPlaceholder width='100%' height='164px'>
                  <NoUpcomingMilestonesIllustration
                    width='100%'
                    height='164px'
                    className='fade-in'
                  />
                </ErrorSuspendPlaceholder>
                <Spacer height='16px' />
                <NoneFoundHeader>
                  No upcoming milestones{' '}
                  <NoWrap>
                    for{' '}
                    {groupName ||
                      (contentFilter === 'following' && 'followed people') ||
                      'this group'}
                  </NoWrap>
                </NoneFoundHeader>
                <NoneFoundCopy>
                  Back to{' '}
                  <LinkButton onClick={() => setAppState({ contentFilter: 'all', groupId: null })}>
                    <strong>all people</strong>
                  </LinkButton>
                </NoneFoundCopy>
              </Padding>
            )}

          {/* Empty notice for no milestones at all */}
          {!milestonesAreFetching &&
            !upcomingMilestoneGroups.length &&
            !groupId &&
            contentFilter === 'all' && (
              <Padding padding='48px 32px' className='fade-in'>
                <Suspense fallback={<div style={{ width: '100%', height: '164px' }} />}>
                  <NoUpcomingMilestonesIllustration
                    width='100%'
                    height='164px'
                    className='fade-in'
                  />
                </Suspense>
                <Spacer height='16px' />
                <NoneFoundHeader>
                  No milestones <NoWrap>this week</NoWrap>
                </NoneFoundHeader>
                <NoneFoundCopy>
                  Check back often to see <strong>birthdays, anniversaries, join dates</strong>{' '}
                  <NoWrap>and other upcoming milestones</NoWrap>
                </NoneFoundCopy>
              </Padding>
            )}

          {/* Scoll buffer needs to be INSIDE this div for all that scrollTo on mount stuff */}
          <Spacer height='32px' />
        </ScrollRef>
      </Scrollable>
    </>
  );
};
export default MilestonesSheet;
