// POTENTIAL REFACTOR: Similar to `FormikEmailInput`

import { Fragment } from 'react';
// Libs
import { useField, useFormikContext, FieldArray } from 'formik';
// Classes
import Person, { PHONE_TYPES } from '@/classes/Person';
// Form elements
import FormikInput from '@/components/formElements/FormikInput';
import FormikSelect from '@/components/formElements/FormikSelect';
import RemoveButton from './RemoveButton';
// Common components
import FlexRow from '@/components/common/FlexRow';
import { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import Expanded from '@/components/common/Expanded';

// Defaults
const MAX_PHONES = 10;
// Select options
const options = PHONE_TYPES.map(type => ({
  value: type,
  label: type,
}));

// Component
const FormikPhoneInputs = () => {
  const { isSubmitting } = useFormikContext();
  const [{ value: phones }] = useField<Person['phones']>('phones');
  return (
    <FieldArray
      name='phones'
      render={arrayHelpers => (
        <>
          {phones.map((_phone, index) => (
            <Fragment key={index}>
              <FlexRow align='start'>
                <FormikSelect
                  name={`phones.${index}.type`}
                  options={options}
                  isSearchable={false}
                />
                <Expanded>
                  <FormikInput
                    name={`phones.${index}.number`}
                    autoFocus={!phones[index].number}
                    placeholder='Enter phone number'
                    maxLength={30}
                    autoComplete='off'
                  />
                </Expanded>
                <RemoveButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
              </FlexRow>
              {/* {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null} */}
              <Spacer height='8px' />
            </Fragment>
          ))}
          <SecondaryButton
            disabled={!!phones.find(({ number }) => !number.trim()) || phones.length === MAX_PHONES}
            leadingIcon='add_circle'
            onClick={() =>
              arrayHelpers.push({
                type:
                  PHONE_TYPES.find(type => !phones.map(phone => phone.type).includes(type)) ||
                  'Other',
                number: '',
              })
            }
          >
            Add <strong>phone</strong>
          </SecondaryButton>
        </>
      )}
    />
  );
};
export default FormikPhoneInputs;
