import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Invitation, { INVITATION_EXPIRATION_DAYS } from '@/classes/Invitation';

import { fbAuth, fbFunctions, fbPerf } from '@/lib/firebase';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import type { AuthErrorCodes } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { trace } from 'firebase/performance';

import { useFormik } from 'formik';
import { DateTime } from 'luxon';

import { useSetAppState } from '@/contexts/appState';
import useAuth from '@/contexts/auth';

import useDoc from '@/hooks/useDoc';
import usePageView from '@/hooks/usePageView';

import { PasswordInput } from '@/components/formElements/FormElements';

import PrimaryButton from '@/components/common/Buttons';
import Divider from '@/components/common/Divider';
import FlexColumn from '@/components/common/FlexColumn';
import { FormLabel } from '@/components/common/Label';
import LinkButton from '@/components/common/LinkButton';
import NoWrap from '@/components/common/NoWrap';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import NotebirdLogo from '@/components/svg/NotebirdLogo';

import LoadingPage from '../LoadingPage';

// Styles
const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
`;
const LogoWrapper = styled(Link)`
  display: block;
  width: 256px;
  margin: 9vh auto 16px;
`;
const Head = styled.div`
  margin: 0 24px 32px;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
`;
const InvitationPanel = styled.div`
  position: relative;
  background-color: ${props => props.theme.sheetBackgroundColor};
  border-radius: 6px;
  margin: 0 auto 16px;
  width: 384px;
  max-width: 100%;
  max-width: 100vw;
  box-shadow: ${props => props.theme.shadow400};
`;
const Name = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;
const Email = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.linkColor};
`;
const Faded = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textTertiary};
  }
`;
const Agreement = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${props => props.theme.textFaded};
  font-size: 12px;
  line-height: 1.4;
  a {
    color: ${props => props.theme.textFaded};
    text-decoration: underline;
  }
`;

// Component
export default function InvitationPage() {
  // URL/path management
  const navigate = useNavigate();
  const { organizationId, invitationId, token } = useParams();
  // App state
  const setAppState = useSetAppState();
  // Context data
  const [auth, authIsFetching] = useAuth();
  // Fetch invitation
  const [invitation, invitationIsFetching] = useDoc<Invitation>(
    `organizations/${organizationId}/invitations/${invitationId}`
  );
  // Determine if valid invitation
  const invitationMissingOrExpired =
    !invitation ||
    !invitation.meta.createdAt ||
    DateTime.fromMillis(invitation.meta.createdAt.toMillis()).plus({
      days: INVITATION_EXPIRATION_DAYS,
    }) < DateTime.local();

  // Register page view
  usePageView({
    title: invitationIsFetching
      ? undefined
      : invitationMissingOrExpired
      ? 'Invitation Expired | Notebird'
      : 'Accept Invitation | Notebird',
  });

  // Form helper (Formik)
  const { values, handleSubmit, isSubmitting, getFieldProps } = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit,
  });

  // Only allow submissions when isn't already submitting and
  // when password isn't empty + isn't 8 or more characters,
  const submitDisabled = isSubmitting || !values.password || values.password.length < 8;
  // Submit
  async function onSubmit() {
    if (invitation && invitation.profile.email && values.password) {
      setIsConfirmationPending(true);

      // Performance trace
      const acceptTrace = trace(fbPerf, 'accept_invitation_new_user');
      acceptTrace.start();

      try {
        // Create auth account ( and automatically sign in )
        await createUserWithEmailAndPassword(
          fbAuth,
          invitation.profile.email.trim(),
          values.password
        );
        // Trigger server cleanup and user doc creation
        await httpsCallable(
          fbFunctions,
          'confirmInvitation'
        )({ organizationId, invitationId, token });
        // On completion navigate to people page
        navigate('/people', { replace: true });
      } catch (error) {
        setIsConfirmationPending(false);
        if (error instanceof FirebaseError) {
          type ErrorCode = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];
          const code = error.code as ErrorCode;
          acceptTrace.putAttribute('error', code);
          // Deal with any errors that arise
          switch (error?.code) {
            case 'auth/invalid-email':
              alert('This email address is invalid.');
              break;
            case 'auth/email-already-in-use':
              // Deal with case when email has already been claimed
              setEmailInUse(true);
              break;
            case 'auth/weak-password':
              alert("Password isn't strong enough.");
              break;
            case 'deadline-exceeded':
              alert('This invite has expired.');
              break;
            default:
              console.error(error);
              alert(`An error has occured. Please contact support.\n\nError code: ${error?.code}`);
          }
        } else {
          acceptTrace.putAttribute('error', 'UNKNOWN');
          alert(`An error has occured. Please contact support.\n\nError: ${JSON.stringify(error)}`);
          console.error(error);
        }
      }

      acceptTrace.stop();
    }
  }

  // State to determine whether invitation is processing
  // ( lots of server stuff needs to happen before the user's account is fully ready )
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  // Show loading page if auth is loading, invitation is loading, or invitation is processing
  const showLoadingPage =
    authIsFetching || invitationIsFetching || isConfirmationPending || isSubmitting;

  // State if user is logged out but trying to accept invite for existing user anyways
  const [emailInUse, setEmailInUse] = useState(false);

  // Fetching invitation
  return (
    <>
      {/* Load on fetching */}
      <LoadingPage show={showLoadingPage} />

      {/* Not fetching */}
      {!showLoadingPage && (
        <>
          {/* Missing or expired invitation */}
          {invitationMissingOrExpired && (
            <FlexColumn>
              {/* Logo */}
              <LogoWrapper to='/login'>
                <NotebirdLogo width='256' tint />
              </LogoWrapper>
              {/* Warning */}
              <Head>This invitation link has expired or does not exist</Head>
              <Spacer height='24px' />
              <Name>
                <Link to='/login'>Back</Link>
              </Name>
            </FlexColumn>
          )}

          {/* Valid invitation */}
          {!invitationMissingOrExpired && (
            <PageWrapper>
              {/* Logo */}
              <LogoWrapper to='/login'>
                <NotebirdLogo width='256' tint />
              </LogoWrapper>

              {/* User is logged in ( check to see emails match then offer to accept ) */}
              {!!auth && (
                <>
                  {/* Invitation is intended for current user */}
                  {auth.email?.toLowerCase().trim() ===
                    invitation?.profile.email.toLowerCase().trim() && (
                    <InvitationPanel>
                      <Padding padding='32px 24px'>
                        <Name>You&apos;ve been invited to join {invitation?.organizationName}</Name>
                        <Spacer height='8px' />
                        <Faded>Click the button below to get started.</Faded>
                        <Spacer height='32px' />
                        <Faded>
                          <PrimaryButton
                            onClick={async () => {
                              setIsConfirmationPending(true);

                              // Performance trace
                              const acceptTrace = trace(fbPerf, 'accept_invitation_existing_user');
                              acceptTrace.start();

                              // Trigger server cleanup
                              await httpsCallable(
                                fbFunctions,
                                'confirmInvitation'
                              )({
                                organizationId,
                                invitationId,
                                token,
                              });
                              // On completion, set active organizationID
                              // ( necessary since we have multiple orgs )
                              // then navigate to people page
                              setAppState({ organizationId });
                              navigate('/people', { replace: true });

                              acceptTrace.stop();
                            }}
                          >
                            Accept invitation
                          </PrimaryButton>
                        </Faded>
                        <Spacer height='16px' />
                        <Faded>
                          <Link to='/people'>Maybe later...</Link>
                        </Faded>
                      </Padding>
                    </InvitationPanel>
                  )}
                  {/* Invitation is NOT intended for current user */}
                  {auth.email?.toLowerCase().trim() !==
                    invitation?.profile.email.toLowerCase().trim() && (
                    <InvitationPanel>
                      <Padding padding='32px 24px'>
                        <Name>Invalid user</Name>
                        <Spacer height='16px' />
                        <Faded>
                          This invitation was intended for{' '}
                          <strong>{invitation?.profile.email}</strong>, but you&apos;re currently
                          logged in as <strong>{auth.email}</strong>
                        </Faded>
                        <Spacer height='24px' />
                        <Name>
                          Please <LinkButton onClick={() => signOut(fbAuth)}>log out</LinkButton>{' '}
                          and try again
                        </Name>
                        <Spacer height='16px' />
                        <Faded>
                          Or go back to <Link to='/people'>my account</Link>
                        </Faded>
                      </Padding>
                    </InvitationPanel>
                  )}
                </>
              )}

              {/* User not logged in ( set password and begin ) */}
              {!auth && (
                <>
                  {/* Failed submission because user already exists */}
                  {emailInUse && (
                    <InvitationPanel>
                      <Padding padding='32px 24px'>
                        <Faded>
                          An account for <strong>{invitation?.profile.email}</strong>{' '}
                          <NoWrap>already exists.</NoWrap>
                        </Faded>
                        <Spacer height='24px' />
                        <Name>
                          Please <Link to={`/login/${invitation?.profile.email}/`}>log in</Link> to
                          accept this invite.
                        </Name>
                        <Spacer height='12px' />
                      </Padding>
                    </InvitationPanel>
                  )}
                  {/* Regular ( unfailed ) version of form */}
                  {!emailInUse && (
                    <>
                      {/* Heading copy */}
                      <Head>
                        Create a password to accept your Notebird invitation to{' '}
                        {invitation?.organizationName}
                      </Head>
                      {/* Main Panel */}
                      <InvitationPanel>
                        <Padding padding='32px 24px'>
                          {/* Name */}
                          <Name>{invitation?.profile.name.full}</Name>
                          <Spacer height='4px' />
                          {/* Email */}
                          <Email>{invitation?.profile.email}</Email>
                          {/* Break */}
                          <Spacer height='24px' />
                          <Divider />
                          <Spacer height='24px' />

                          {/* Form */}
                          <form onSubmit={handleSubmit} noValidate>
                            {/* Password */}
                            <FormLabel htmlFor='password'>Set password</FormLabel>
                            <PasswordInput
                              placeholder='8 characters minimum'
                              autoComplete='new-password'
                              disabled={isSubmitting}
                              autoFocus={true}
                              {...getFieldProps({ name: 'password' })}
                            />
                            <Spacer height='24px' />

                            {/* Submit */}
                            <PrimaryButton block type='submit' disabled={submitDisabled}>
                              Get Started
                            </PrimaryButton>

                            {/* Agreement */}
                            {!submitDisabled && (
                              <Agreement>
                                By clicking {"'Get Started'"} you agree to Notebird&apos;s
                                <br />
                                <a
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  href='https://www.notebird.app/privacy'
                                >
                                  Privacy Policy
                                </a>{' '}
                                &amp;{' '}
                                <a
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  href='https://www.notebird.app/terms'
                                >
                                  Terms of Service
                                </a>
                              </Agreement>
                            )}
                          </form>
                        </Padding>
                      </InvitationPanel>
                    </>
                  )}
                </>
              )}
              <Spacer height='64px' />
            </PageWrapper>
          )}
        </>
      )}
    </>
  );
}
