import { lazy } from 'react';
import styled from 'styled-components';

import Sheet from '@/components/layout/Sheets';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Margin from '@/components/common/Margin';
import Spacer from '@/components/common/Spacer';

// Lazy load illustration
const PersonNotFoundIllustration = lazy(() => import('../../svg/PersonNotFoundIllustration'));

// Not Found
const FlexColumn = styled.div`
  height: 100%;
  padding: 64px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 28px;
    margin: 0;
  }
  p {
    font-size: 14px;
    color: ${props => props.theme.textFaded};
    margin: 0;
  }
`;

// Component
const PersonNotFoundSheets = ({ goBack }: { goBack(): void }) => {
  return (
    <>
      {/* Warning / go back */}
      <Sheet position='left'>
        <FlexColumn>
          <h1>Whoops... we couldn&apos;t find the person you&apos;re looking for!</h1>
          <Spacer height='24px' />
          <PrimaryButton
            leadingIcon='arrow_back'
            onClick={event => {
              event.preventDefault();
              goBack();
            }}
          >
            Go back
          </PrimaryButton>
          <Spacer height='24px' />
          <p>Maybe the profile has been deleted or you may have lost access.</p>
          <Spacer height='4px' />
          <p>Please check with your account admin or contact Notebird support.</p>
        </FlexColumn>
      </Sheet>
      {/* Illustration */}
      <Sheet position='right' className='hidden-mobile'>
        <FlexColumn>
          <Margin margin='-24px 0 0' />
          <ErrorSuspendPlaceholder width='256px' height='256px'>
            <PersonNotFoundIllustration width='256px' height='256px' className='fade-in' />
          </ErrorSuspendPlaceholder>
        </FlexColumn>
      </Sheet>
    </>
  );
};
export default PersonNotFoundSheets;
