import styled from 'styled-components';

interface Props {
  icon?: string;
  iconSize?: string;
}
const Icon = styled.i.attrs<Props>(({ icon, className }) => ({
  children: icon,
  className: `material-icons-round ${className || ''}`,
}))<Props>`
  flex-shrink: 0;
  color: ${props => props.theme.grey800};
  user-select: none;

  width: ${({ iconSize }) => (iconSize ? iconSize : '24px')};
  height: ${({ iconSize }) => (iconSize ? iconSize : '24px')};
  font-size: ${({ iconSize }) => (iconSize ? iconSize : '24px')};
`;
export default Icon;
