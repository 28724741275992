import { StyleSheetManager, ThemeProvider } from 'styled-components';

import BaseStyles from '@/styles/BaseStyles';
import Normalize from '@/styles/Normalize';
import cardinalTheme from '@/styles/cardinalTheme';
import cardinalThemeDark from '@/styles/cardinalThemeDark';
import darkTheme from '@/styles/darkTheme';
import orioleTheme from '@/styles/orioleTheme';
import orioleThemeDark from '@/styles/orioleThemeDark';
import parakeetTheme from '@/styles/parakeetTheme';
import parakeetThemeDark from '@/styles/parakeetThemeDark';
import primaryTheme from '@/styles/primaryTheme';

import isPropValid from '@emotion/is-prop-valid';

import useAppState from '@/contexts/appState';

// Provider
export const StylesProvider = ({ children }: { children: React.ReactNode }) => {
  const { isDarkModeEnabled, visualTheme } = useAppState();

  // Dark mode can be preloaded from localStorage to prevent white flashing on load
  const isLocalStorageDarkModeEnabled = !!localStorage.getItem('darkModeIsEnabled');
  const showDarkMode = isLocalStorageDarkModeEnabled || isDarkModeEnabled;

  // Wrap children with provider (and a couple extras)
  return (
    <StyleSheetManager
      // This implements the default behavior from styled-components v5
      shouldForwardProp={(propName, target) => {
        if (propName === 'hasError') return false; // for FormTextarea

        if (typeof target === 'string') {
          // For HTML elements, forward the prop if it is a valid HTML attribute
          return isPropValid(propName);
        }
        // For other elements, forward all props
        return true;
      }}
    >
      <ThemeProvider
        theme={() => {
          switch (visualTheme) {
            case 'cardinal':
              return showDarkMode ? cardinalThemeDark : cardinalTheme;
            case 'oriole':
              return showDarkMode ? orioleThemeDark : orioleTheme;
            case 'parakeet':
              return showDarkMode ? parakeetThemeDark : parakeetTheme;
            default:
              return showDarkMode ? darkTheme : primaryTheme;
          }
        }}
      >
        <>
          {/* Style reset and base */}
          <Normalize />
          <BaseStyles />
          {/* Wrap children with this provider */}
          {children}
        </>
      </ThemeProvider>
    </StyleSheetManager>
  );
};
