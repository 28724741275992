import { useField } from 'formik';
// Form elements
import FormikSelect from '@/components/formElements/FormikSelect';
import FormikInput from '@/components/formElements/FormikInput';
// Common components
import FlexRow from '@/components/common/FlexRow';
import Spacer from '@/components/common/Spacer';
import Expanded from '@/components/common/Expanded';

// Gender options
const genderTypeOptions = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export default function FormikGenderRow() {
  const [{ value: gender }] = useField<(typeof genderTypeOptions)[number]['value']>('gender');

  return (
    <FlexRow align='start'>
      {/* Male/Female/Other */}
      <FormikSelect
        label='Gender'
        name='gender'
        options={genderTypeOptions}
        isSearchable={false}
        isClearable
      />
      <Spacer width='16px' />
      {/* Specify other/custom-defined gender */}
      <Expanded>
        {gender === 'Other' && (
          <FormikInput label='Other gender' name='customGender' maxLength={300} />
        )}
      </Expanded>
    </FlexRow>
  );
}
