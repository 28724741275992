import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactElement | null;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

export default function ErrorSuspendPlaceholder({
  children,
  fallback,
  width = '232px',
  height = '232px',
  style,
}: Props) {
  return (
    <ErrorBoundary fallback={fallback || <div style={{ width, height, ...style }} />}>
      <Suspense fallback={fallback || <div style={{ width, height, ...style }} />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}
