import { Link } from 'react-router-dom';

import { getFriendlyDate, pluralize } from '@/lib/helpers';

import useAppState from '@/contexts/appState';
import useOrganization from '@/contexts/organization';

import useSessionState from '@/hooks/useSessionState';

import NoticeBar from './NoticeBar';

import NoWrap from '@/components/common/NoWrap';

const NoticeBars = () => {
  const {
    organizationId,
    isOrganizationAdmin,
    isActive,
    hasCardError,
    isTrialing,
    isCanceled,
    daysRemaining,
    willEnd,
  } = useAppState();
  const [organization] = useOrganization();

  // USER - SUPPORT VERIFICATION ( initiated by Notebird support )
  // No longer in use
  // const showVerificationNotice =
  //   !!user?._supportVerification?.code && user._supportVerification.closed === false;
  // useEffect(() => {
  //   if (user?._supportVerification?.seen === false) {
  //     // Mark as seen
  //     console.log('user.docPath', user.docPath);
  //     db.doc(user.docPath).update({ '_supportVerification.seen': true });
  //   }
  // }, [user]);

  // ADMINS - Trial days remaning ( 0-3 days left )
  const showDaysRemaningNotice =
    isOrganizationAdmin && isTrialing && daysRemaining <= 3.5 && daysRemaining > 0;
  const [hideDaysRemaningNotice, setHideDaysRemainingNotice] = useSessionState(
    false,
    showDaysRemaningNotice ? 'hideNotice-daysRemaining-' + Math.round(daysRemaining) : null
  );

  // ADMINS - Subscription will end notice
  const showWillEndNotice = isOrganizationAdmin && isActive && willEnd;
  const [hideWillEndNotice, setHideWillEndNotice] = useSessionState(
    false,
    showWillEndNotice ? 'hideNotice-willEnd-' + organizationId : null
  );

  // ADMINS - Show card error notice
  const showCardErrorNotice =
    isOrganizationAdmin && !isActive && hasCardError && !isTrialing && !isCanceled;

  // ADMINS - Trial expired/canceled notice
  const showSubscriptionEndedNotice =
    isOrganizationAdmin && !showCardErrorNotice && !isActive && !hasCardError;
  // (isTrialing || isCanceled);

  // NON-ADMINS - expired/canceled notice
  const showNonAdminTrialExpiredNotice = !isOrganizationAdmin && !isActive;

  return (
    <>
      {/* SUPPORT VERIFICATION NOTICE */}
      {/* No longer in use */}
      {/* <NoticeBar
        show={showVerificationNotice}
        handleClose={() =>
          user && db.doc(user.docPath).update({ '_supportVerification.closed': true })
        }
      >
        Your support{' '}
        <NoWrap>
          code is: <strong>{user?._supportVerification?.code}</strong>
        </NoWrap>
      </NoticeBar> */}

      {/* ORGANIZATION ADMINS */}
      {/* 3 or less days until trial ends */}
      <NoticeBar
        show={showDaysRemaningNotice && !hideDaysRemaningNotice}
        handleClose={() => {
          setHideDaysRemainingNotice(true);
        }}
      >
        Your trial ends{' '}
        <strong>
          in{' '}
          {pluralize({
            root: '{x} day',
            count: Math.round(daysRemaining),
          })}
          {Math.round(daysRemaining) === 0 && 'less than a day'}!
        </strong>{' '}
        <Link
          to='/account/subscription/subscribe'
          onClick={() => {
            setHideDaysRemainingNotice(true);
          }}
        >
          Upgrade now
        </Link>{' '}
        <NoWrap>to continue using Notebird.</NoWrap>
      </NoticeBar>

      {/* Card has been removed but subscription is still active */}
      <NoticeBar
        show={showWillEndNotice && !hideWillEndNotice}
        danger
        handleClose={() => {
          setHideWillEndNotice(true);
        }}
      >
        <strong>Your subscription will end</strong>{' '}
        {organization && getFriendlyDate(organization._stripe.currentPeriodEnd.toMillis())}.{' '}
        <Link
          to='/account/subscription/update-card'
          onClick={() => {
            setHideWillEndNotice(true);
          }}
        >
          Add a card
        </Link>{' '}
        to avoid service interruption.
      </NoticeBar>

      {/* There was an error processing the card */}
      <NoticeBar show={showCardErrorNotice} danger>
        There was an error processing your payment.{' '}
        <Link to='/account/subscription/update-card'>Re-enter your card info</Link> to continue
        using Notebird.
      </NoticeBar>

      {/* Trial has ended or subscription is no longer active for any reason */}
      <NoticeBar show={showSubscriptionEndedNotice} danger>
        {isTrialing ? 'Your trial has ended. ' : 'Your subscription has expired. '}
        <Link to='/account/subscription/subscribe'>Upgrade now</Link> to continue using Notebird.
      </NoticeBar>

      {/* NON-ORGNINIAZTION ADMINS */}
      {/* Trial has ended or subscription is no longer active for any reason */}
      <NoticeBar show={showNonAdminTrialExpiredNotice} danger>
        This account is no longer active. <strong>Speak with your account admin</strong> to continue
        using Notebird.
      </NoticeBar>
    </>
  );
};

export default NoticeBars;
