import { createContext, useContext, useEffect, useMemo } from 'react';
// Libs
import { db } from '@/lib/firebase';
import orderBy from 'lodash/orderBy';
// Classes
import Doc from '@/classes/Doc';
import Location from '@/classes/Location';
// Hooks
import useCollection from '@/hooks/useCollection';
// Context data
import useAppState, { useSetAppState } from './appState';

// Context ( with hook shortcut )
const fetchingLocations: [Doc<Location>[] | null, boolean] = [null, true];
const locationsContext = createContext(fetchingLocations);
const useLocations = () => useContext(locationsContext);
export default useLocations;

// Context definition w/ provider
export const LocationsProvider = ({ children }: { children: React.ReactNode }) => {
  const { uid, organizationId, isOrganizationAdmin } = useAppState();
  const setAppState = useSetAppState();

  const locations = useCollection<Location>(
    !!organizationId &&
      (isOrganizationAdmin
        ? db.collection(`organizations/${organizationId}/locations`)
        : db
            .collection(`organizations/${organizationId}/locations`)
            .where('users', 'array-contains', uid))
  );

  // Filter out locations if is group member but no groups ( and order alphabetically )
  const filteredLocations = useMemo(() => {
    const [locationDocs, locationsAreFetching] = locations;
    if (!locationDocs || !uid) return locations;

    const filteredLocations = orderBy(
      locationDocs.filter(({ admins, groupMembers }) => {
        const isLocationAdmin = admins.includes(uid);
        const hasGroups = !!groupMembers[uid]?.length;
        return isOrganizationAdmin || isLocationAdmin || hasGroups;
      }),
      'name'
    );
    return [filteredLocations, !!organizationId && locationsAreFetching] as typeof locations;
  }, [isOrganizationAdmin, locations, organizationId, uid]);

  // Keep `isSingleLocation` status up to date in appState
  const isSingleLocation = (locations[0]?.length || 1) <= 1;
  useEffect(() => {
    setAppState({ isSingleLocation });
  }, [isSingleLocation, setAppState]);

  return (
    <locationsContext.Provider value={filteredLocations}>{children}</locationsContext.Provider>
  );
};
