import { useMemo } from 'react';
// Libs
import { db } from '@/lib/firebase';
// Context data
import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';

/** A common pre-query that filters based on organization, location, and possibly groups  */
export default function useBaseQuery({
  collection,
  limit,
  excludeMemberGroups,
  showArchived,
  hideRestricted,
}: {
  collection: string;
  limit?: number;
  excludeMemberGroups?: true;
  showArchived?: boolean;
  hideRestricted?: boolean;
}) {
  const { organizationDocPath, isOnboarding, locationId, isLocationAdmin, disableQueries } =
    useAppState();
  const [groups] = useGroups();

  return useMemo(() => {
    if (organizationDocPath && !isOnboarding && locationId && groups?.length && !disableQueries) {
      let query: firebase.firestore.Query = db
        .collection(`${organizationDocPath}/${collection}`)
        .where('locationId', '==', locationId);

      // If NOT admin, limit to groups has access to
      query =
        isLocationAdmin || excludeMemberGroups
          ? query
          : query.where('groups', 'array-contains-any', groups?.map(({ id }) => id) || []);

      // Consider archived status ( limit to only active if not showing archived )
      query = showArchived ? query : query.where('isArchived', '==', false);

      // Consider 'restricted' status ( only show if has access )
      query = hideRestricted ? query.where('visibility', '==', 'groups') : query;

      // Limit total results ( if specified ) and return
      return limit ? query.limit(limit) : query;
    }
    return;
  }, [
    collection,
    disableQueries,
    excludeMemberGroups,
    groups,
    hideRestricted,
    isLocationAdmin,
    isOnboarding,
    limit,
    locationId,
    organizationDocPath,
    showArchived,
  ]);
}
