import { createContext, useContext, useMemo, useCallback, useRef, useEffect } from 'react';
// Classes
import Doc from '@/classes/Doc';
import Task from '@/classes/Task';
// Hooks
import useCollection from '@/hooks/useCollection';
// Context data
import useAppState from './appState';
import useBaseQuery from '@/hooks/useBaseQuery';

// Contexts ( with hook shortcuts )
const fetchingTasks: [Doc<Task>[] | null, boolean] = [null, true];
// Scheduled
const tasksContext = createContext(fetchingTasks);
const useTasks = () => useContext(tasksContext);
export default useTasks;
// Completed
const completedTasksContext = createContext(fetchingTasks);
export const useCompletedTasks = () => useContext(completedTasksContext);

// Context definition w/ provider
export const TasksProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const { uid, groupId, contentFilter, followedPeopleIds, filterTasksBy, isSingleUser } =
    useAppState();

  // Fetch ( and and order ) both current/open tasks as well as completed, but separately
  const baseQuery = useBaseQuery({ collection: 'tasks', showArchived: true });
  const tasksBaseQuery = useMemo(
    () =>
      // When single user, we need to go ahead and include unassigned tasks for safety
      filterTasksBy === 'Assigned' || isSingleUser
        ? baseQuery
        : filterTasksBy === 'Unassigned'
        ? baseQuery?.where('assignedTo', '==', null)
        : baseQuery?.where('assignedTo.id', '==', uid),
    [baseQuery, filterTasksBy, uid, isSingleUser]
  );
  // Current/open
  const [tasks, tasksAreFetching] = useCollection<Task>(
    tasksBaseQuery?.limit(500).where('isCompleted', '==', false).orderBy('meta.createdAt', 'desc'),
    { trace: 'fetch_tasks_uncompleted' }
  );
  // Completed
  const [completedTasks, completedTasksAreFetching] = useCollection<Task>(
    tasksBaseQuery?.limit(100).where('isCompleted', '==', true).orderBy('completedAt', 'desc'),
    { trace: 'fetch_tasks_completed' }
  );

  // Reference filter keys to pass around internally here
  // ( this is to prevent re-renders and flashing between renders )
  const assignedFilterKey = useRef(filterTasksBy === 'Assigned');
  useEffect(() => {
    assignedFilterKey.current = filterTasksBy === 'Assigned';
  }, [filterTasksBy]);
  // const followingFilterKey = useRef(contentFilter === 'following');
  // useEffect(() => {
  //   followingFilterKey.current = contentFilter === 'following';
  // }, [contentFilter]);

  // Need to manually
  const filterTasks = useCallback(
    (tasks: (typeof fetchingTasks)[0]) => {
      return (
        tasks?.filter(task => {
          // If viewing `assigned` remove current user and unassigned tasks
          if (assignedFilterKey.current && (task.assignedTo === null || task.assignedTo.id === uid))
            return false;
          // If group filter is active, remove any task not in group
          if (groupId) return task.groups.includes(groupId);
          // If `following` filter active, remove any for not followed people
          if (contentFilter === 'following') return followedPeopleIds.includes(task.person.id);
          // All others make it through
          return true;
        }) || null
      );
    },
    [contentFilter, followedPeopleIds, groupId, uid]
  );
  const filteredTasks = useMemo<typeof fetchingTasks>(
    () => [filterTasks(tasks), tasksAreFetching],
    [filterTasks, tasks, tasksAreFetching]
  );
  const filteredCompletedTasks = useMemo<typeof fetchingTasks>(
    () => [filterTasks(completedTasks), completedTasksAreFetching],
    [completedTasks, completedTasksAreFetching, filterTasks]
  );

  return (
    <tasksContext.Provider value={filteredTasks}>
      <completedTasksContext.Provider value={filteredCompletedTasks}>
        {children}
      </completedTasksContext.Provider>
    </tasksContext.Provider>
  );
};
