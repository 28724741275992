import { UserProfile } from './User';

export default class Meta {
  // Created
  createdBy = {
    id: '',
    profile: { ...new UserProfile() },
  };
  createdAt: firebase.firestore.Timestamp | null = null;
  createdInBulk = false;

  // Edited / updated
  updatedBy: {
    id: string;
    profile: UserProfile;
  } | null = null;
  updatedAt: firebase.firestore.Timestamp | null = null;
}
