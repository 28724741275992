//** Check to see if any 2 variables are deeply equal regardless of object/array order nested anywhere  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function contentMatches(x: any, y: any) {
  // Initial check to make sure both are of same type
  if (typeof x !== typeof y) return false;

  ///////////////////////
  // NULL or UNDEFINED //
  ///////////////////////
  if (x === null || y === null || x === undefined || y === undefined) return x === y;

  /////////////////////////////////
  // BOOLS, NUMBERS, and STRINGS //
  /////////////////////////////////
  if (['bigint', 'boolean', 'number', 'string'].includes(typeof x)) return x === y;

  ///////////////
  // FUNCTIONS //
  ///////////////
  if (typeof x === 'function') {
    return x === y || x.toString() === y.toString();
  }

  ////////////
  // ARRAYS //
  ////////////
  if (Array.isArray(x) && Array.isArray(y)) {
    // Do a quick check to make sure arrays are even same length at all
    if (x.length !== y.length) return false;
    const yDiff = [...y];
    for (const xVal of x) {
      // Find and remove any match from diff array
      const matchIndex = yDiff.findIndex(yVal => contentMatches(xVal, yVal));
      // If no match, we're out of luck
      if (matchIndex === -1) return false;
      // If match, remove val from yDiff and prepare to go again
      yDiff.splice(matchIndex, 1);
    }
    // If we reach this point, we've won!
    return true;
  }

  /////////////
  // OBJECTS //
  /////////////
  // TODO: ( `symbol` types would also reach here, whatever those are... )
  const xKeys = Object.keys(x);
  const yKeys = Object.keys(y);
  // Do a quick check to make sure objects are even same length at all
  if (xKeys.length !== yKeys.length) return false;
  // Do a second, slighly less quick, check to make sure same keys show up in both
  const yDiffKeys = [...yKeys];
  for (const xKey of xKeys) {
    // Find and remove any key match from diff object
    const keyMatchIndex = yDiffKeys.findIndex(yKey => contentMatches(xKey, yKey));
    // If no key match, we're out of luck
    if (keyMatchIndex === -1) return false;
    // If keys match, go one step further to ensure values match
    const xVal = x[xKey];
    const yVal = y[yDiffKeys[keyMatchIndex]];
    if (!contentMatches(xVal, yVal)) return false;
    // If full match, remove val from yDiffKeys and prepare to go again
    yDiffKeys.splice(keyMatchIndex, 1);
  }
  // If we reach this point, we've won!
  return true;
}
