import styled from 'styled-components';

import CSSTransition from 'react-transition-group/CSSTransition';

const StyledPageBlocker = styled.div<{ coverAll: boolean }>`
  /* Todo: double check this z-index */
  /* I think it needs to match filter aside to show behind
  /* manage groups modal properly on mobile */
  z-index: ${({ coverAll }) => (coverAll ? 220 : 200)};
  pointer-events: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);

  /* Mount/unmount animation */
  transition: opacity 300ms;
  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    pointer-events: none;
    opacity: 0;
  }

  @media (min-width: 375px) {
    /* This blur doesn't work well on android chrome for some reason */
    /* so we're disabling on smaller devices */
    /* backdrop-filter: blur(1.5px); */
    /* Turns out this is a performance hog any way you slice it... */
    /* Bummer, cause it looks so good... */
  }
`;

interface Props {
  show: boolean;
  coverAll?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  className?: string;
}

const PageBlocker = ({ show, coverAll = true, onClick, className }: Props) => {
  return (
    <CSSTransition in={show} timeout={300} mountOnEnter unmountOnExit>
      <StyledPageBlocker onClick={onClick} coverAll={coverAll} className={className} />
    </CSSTransition>
  );
};
export default PageBlocker;
