import { createContext, useContext } from 'react';
// Libs
import { db } from '@/lib/firebase';
// Classes
import Doc from '@/classes/Doc';
import Invitation from '@/classes/Invitation';
// Hooks
import useCollection from '@/hooks/useCollection';
// Context data
import useAppState from './appState';

// Context ( with hook shortcut )
const fetchingInvitations: [Doc<Invitation>[] | null, boolean] = [null, true];
const invitationsContext = createContext(fetchingInvitations);
const useInvitations = () => useContext(invitationsContext);
export default useInvitations;

// Context definition w/ provider
export const InvitationsProvider = ({ children }: { children: React.ReactNode }) => {
  const { organizationId, isOnboarding, isOrganizationAdmin } = useAppState();

  const invitations = useCollection<Invitation>(
    !!organizationId &&
      !isOnboarding &&
      isOrganizationAdmin &&
      db.collection(`organizations/${organizationId}/invitations`),
    { trace: 'fetch_invitations' }
  );

  // ( always empty for non organization admins )
  return (
    <invitationsContext.Provider value={isOrganizationAdmin ? invitations : [[], false]}>
      {children}
    </invitationsContext.Provider>
  );
};
