import styled from 'styled-components';

// Props
type Props = {
  forwardedRef?: React.RefObject<HTMLDivElement>;
  heading: React.ReactNode;
  variant?: 'normal' | 'danger' | 'dull';
  top?: number;
};

// Styles
const Wrapper = styled.div<{ top: number }>`
  z-index: 8;
  position: sticky;
  top: ${({ top }) => top}px;
  margin-bottom: 16px;
`;
const Heading = styled.div<{ variant: Props['variant'] }>`
  padding: 16px 24px 4px;
  @media (max-width: 767px) {
    padding: 16px 16px 4px;
  }
  background-color: ${({ theme }) => theme.sheetBackgroundColor};

  font-size: 19px;
  font-weight: bold;
  color: ${({ variant, theme }) => {
    switch (variant) {
      case 'danger':
        return theme.danger500;
      case 'dull':
        return theme.textFaded;
      default:
        return theme.textSecondary;
    }
  }};
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-left: 16px;
  @media (max-width: 767px) {
    margin-left: 12px;
  }

  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.dividerColor} 66%,
    ${({ theme }) => theme.sheetBackgroundColor} 110%
  );
`;

// Component
const StickyHeader = ({ forwardedRef, heading, variant = 'normal', top = 0 }: Props) => (
  <Wrapper ref={forwardedRef} top={top}>
    <Heading variant={variant}>{heading}</Heading>
    <Divider />
  </Wrapper>
);
export default StickyHeader;
