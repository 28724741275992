import { useEffect } from 'react';

import { fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { usePostHog } from 'posthog-js/react';
import { useIntercom } from 'react-use-intercom';

// Hook
export default function usePageView({
  title,
  analyticsTitle,
}: { title?: string; analyticsTitle?: string } = {}) {
  const { update } = useIntercom();
  const postHog = usePostHog();

  useEffect(() => {
    const prevTitle = document.title;
    if (title && title !== prevTitle) {
      // Change document title ( for analytics)
      const formattedTitle = (analyticsTitle || title).replace(' | Notebird', '');
      document.title = formattedTitle;

      // Register page view with Intercom
      update();

      // Register page view with Posthog
      postHog?.capture('$pageview', { title: formattedTitle });

      // Register page view with Firebase analytics
      logEvent(fbAnalytics, 'page_view', { page_title: formattedTitle });

      // Change document title ( just for browser tab)
      document.title = title;
    }
  }, [analyticsTitle, postHog, title, update]);
}
