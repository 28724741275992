import { useMemo } from 'react';

import { WithId } from '@/classes/Doc';
import Person from '@/classes/Person';
import Update from '@/classes/Update';

import { FlattenedTimestamps } from '@/lib/firebase';

import { SearchClient } from 'typesense';
import {
  type SearchOptions,
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';

import useAppState from '@/contexts/appState';

/** Hook to initialize Typesense Client and set up searches */
export default function useTypesense() {
  const { organizationId, typesensePeopleKey, typesenseUpdatesKey } = useAppState();

  const { peopleCollection, updatesCollection } = useMemo(() => {
    if (!typesensePeopleKey || !typesenseUpdatesKey) return {};
    const nearestHost = import.meta.env.VITE_TYPESENSE_NEAREST_HOST;
    const hosts = import.meta.env.VITE_TYPESENSE_HOSTS.split(',');
    const settings = {
      nearestNode: nearestHost
        ? {
            host: nearestHost,
            port: 443,
            protocol: 'https',
          }
        : undefined,
      nodes: hosts.map(host => ({
        host: host,
        port: 443,
        protocol: 'https',
      })),
      connectionTimeoutSeconds: 5,
    };
    return {
      peopleCollection: new SearchClient({ ...settings, apiKey: typesensePeopleKey })
        .collections<Person>(`${organizationId}_people`)
        .documents(),
      updatesClient: new SearchClient({ ...settings, apiKey: typesenseUpdatesKey }),
      updatesCollection: new SearchClient({ ...settings, apiKey: typesenseUpdatesKey })
        .collections<Update>(`${organizationId}_updates`)
        .documents(),
    };
  }, [organizationId, typesensePeopleKey, typesenseUpdatesKey]);

  if (!peopleCollection || !updatesCollection) return {};

  // return {
  //   searchPeople: peopleCollection.search.bind(peopleCollection),
  //   searchUpdates: updatesCollection.search.bind(updatesCollection),
  // };
  return {
    getTypesensePeople: async (params: SearchParams, options: SearchOptions = {}) =>
      peopleCollection.search(params, options) as Promise<
        SearchResponse<WithId<FlattenedTimestamps<Person>>>
      >,
    getTypesenseUpdates: async (params: SearchParams, options: SearchOptions = {}) =>
      updatesCollection.search(params, options) as Promise<
        SearchResponse<WithId<FlattenedTimestamps<Update>>>
      >,
  };
}
