import { lazy, useMemo, useRef, useState } from 'react';

import Doc from '@/classes/Doc';
import Task from '@/classes/Task';

import { MAX_MILLIS } from '@/lib/config';
import { getFriendlyDate } from '@/lib/helpers';

import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useUncompletedTasks from '@/contexts/tasks';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';

import TransitionWrapper from './TransitionWrapper';

import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import LinkButton from '@/components/common/LinkButton';
import Loader from '@/components/common/Loader';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import StickyHeader from '@/components/common/StickyHeader';
import TaskItem from '@/components/common/TaskItem';

// Lazy load illustration
const NoTasksIllustration = lazy(() => import('../../svg/NoTasksIllustration'));

// Component
const UncompletedTasksSheet = () => {
  // App state
  const { uid, groupId, groupName, hasSingleGroup, contentFilter, filterTasksBy, isSingleUser } =
    useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [tasks, tasksAreFetching] = useUncompletedTasks();

  // Empty state is a little different for 'My tasks' with no other filter
  const isPrimaryFilter = filterTasksBy === 'My' && !groupId && contentFilter !== 'following';

  // Group tasks by day ( and separate categories for `overdue` and `unscheduled` )
  // then flatten for proper TransitionGroup animating
  const flattenedTaskList: (string | Doc<Task>)[] = useMemo(() => {
    if (!tasks?.length) return [];

    const today = DateTime.local().startOf('day');
    const grouped = groupBy(tasks, ({ dueDate }) => {
      // Lump all 'unscheduled' together
      if (!dueDate) {
        return MAX_MILLIS;
      } else {
        const dueDateTime = DateTime.fromISO(dueDate).startOf('day');
        // Lump all 'overdue' together
        return dueDateTime < today ? 0 : dueDateTime.toMillis();
      }
    });
    return orderBy(toPairs(grouped), ([millis]) => millis).flat(2);
  }, [tasks]);

  // Value to animate elements after currently collapsing ones
  const [offset, setOffset] = useState(0);

  // Ref for transition
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SheetHeader
        primary
        active={!!tasks && !!tasks.length}
        leadingIcon='check'
        mainTitle={`${filterTasksBy} tasks`}
        className='hidden-mobile'
      />
      {/* Loader for tasks ( uncompleted ) */}
      <Loader show={tasksAreFetching} />

      {/* Tasks list ( uncompleted ) */}
      <Scrollable endBuffer='64px'>
        {/* Map over flattened task list */}
        <TransitionWrapper offset={offset}>
          {!tasksAreFetching && !!flattenedTaskList.length && (
            <>
              <TransitionGroup key={groupId + contentFilter + filterTasksBy} component={null}>
                {flattenedTaskList.map(value => (
                  <CSSTransition
                    key={typeof value === 'string' ? value : value.id}
                    timeout={{ enter: 500, exit: 395 }}
                    component={null}
                    onEnter={() => setOffset(prev => prev + (nodeRef.current?.scrollHeight ?? 0))}
                    onExit={() => setOffset(prev => prev + (nodeRef.current?.scrollHeight ?? 0))}
                    // Clear offset whenever animation completes
                    onEntered={() => setOffset(0)}
                    onExited={() => setOffset(0)}
                  >
                    {() => {
                      // Date/group header
                      if (typeof value === 'string') {
                        const millis = parseInt(value);
                        const isOverdue = millis === 0;
                        const isUnscheduled = millis === MAX_MILLIS;
                        return (
                          <StickyHeader
                            key={value}
                            forwardedRef={nodeRef}
                            heading={
                              isOverdue
                                ? 'Overdue'
                                : isUnscheduled
                                  ? 'Unscheduled'
                                  : 'Due ' + getFriendlyDate(millis)
                            }
                            variant={isOverdue ? 'danger' : isUnscheduled ? 'dull' : 'normal'}
                          />
                        );
                      }
                      // Task item
                      const task = value;
                      return (
                        <TaskItem
                          key={task.id}
                          forwardedRef={nodeRef}
                          task={task}
                          hideGroups={hasSingleGroup || !!groupId}
                          hideUser={isSingleUser || filterTasksBy !== 'Assigned'}
                          showCreatedBy={filterTasksBy === 'My' && task.meta.createdBy.id !== uid}
                        />
                      );
                    }}
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </>
          )}
        </TransitionWrapper>

        {/* Primary empty notice for MY uncompleted tasks ( + no group or followed filter ) */}
        {isPrimaryFilter && !tasksAreFetching && !tasks?.length && (
          <Padding padding='48px 32px' className='fade-in'>
            <ErrorSuspendPlaceholder width='100%' height='164px'>
              <NoTasksIllustration width='100%' height='164px' className='fade-in' />
            </ErrorSuspendPlaceholder>
            <Spacer height='16px' />
            <NoneFoundHeader>Job well done!</NoneFoundHeader>
            <NoneFoundCopy>You don&apos;t have any open tasks</NoneFoundCopy>
          </Padding>
        )}

        {/* Other empty notices */}
        {!isPrimaryFilter && !tasksAreFetching && !tasks?.length && (
          <Padding
            padding='48px 32px'
            className='fade-in'
            key={groupId + contentFilter + filterTasksBy}
          >
            <NoneFoundHeader>
              No {filterTasksBy !== 'My' && `${filterTasksBy.toLowerCase()} `}tasks
              <NoWrap>
                {!!groupName && ` for ${groupName}`}
                {!groupName && contentFilter === 'following' && ' for followed people'}
              </NoWrap>
            </NoneFoundHeader>
            <NoneFoundCopy>
              Back to{' '}
              {groupId || contentFilter === 'following' ? (
                <LinkButton onClick={() => setAppState({ groupId: null, contentFilter: 'all' })}>
                  <strong>all people</strong>
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() =>
                    setAppState({ filterTasksBy: 'My', groupId: null, contentFilter: 'all' })
                  }
                >
                  <strong>my tasks</strong>
                </LinkButton>
              )}
            </NoneFoundCopy>
          </Padding>
        )}
      </Scrollable>
    </>
  );
};
export default UncompletedTasksSheet;
