import { createContext, useContext, useEffect } from 'react';

import Doc from '@/classes/Doc';
import User from '@/classes/User';

import { db, fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { DateTime } from 'luxon';

import useDoc from '@/hooks/useDoc';

import useAppState, { useSetAppState } from './appState';
import useAuth from './auth';

// Context ( with hook shortcut )
const fetchingUser: [Doc<User> | null, boolean] = [null, true];
const userContext = createContext(fetchingUser);
const useUser = () => useContext(userContext);
export default useUser;

// Context definition w/ provider
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const { uid } = useAppState();
  const setAppState = useSetAppState();
  // Context data ( auth )
  const [auth] = useAuth();

  // Fetch user doc
  const user = useDoc<User>(!!uid && `users/${uid}`);
  const [userDoc, userIsFetching] = user;

  // Make sure current user's email always matches auth
  // ( but don't update optimistically when changing doc.email
  //   we need to give a chance for auth to update itself )
  // ( this should only really happen if a user is changing their email BACK
  //   by requesting an email change )
  useEffect(() => {
    if (auth && userDoc && userDoc?.profile.email !== auth.email) {
      db.doc(userDoc.docPath).update({ 'profile.email': auth.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userIsFetching]);

  // Make sure current user's email verification always matches auth
  useEffect(() => {
    if (auth && userDoc && userDoc?.isEmailVerified !== auth?.emailVerified) {
      db.doc(userDoc.docPath).update({ isEmailVerified: auth.emailVerified });
    }
  }, [auth, userDoc]);

  // Ensure appState's theme is always in sync with user preferences
  // ( and go ahead and keep a localStorage tag in there
  //   for proper BG color loading from frame one...
  //   see `auth` and `user` contexts plus `themeAccordion` for more implementation deets )
  useEffect(() => {
    if (userDoc) {
      const { visualTheme, isDarkModeEnabled } = userDoc.preferences;
      setAppState({ visualTheme, isDarkModeEnabled });
      isDarkModeEnabled
        ? localStorage.setItem('darkModeIsEnabled', 'true')
        : localStorage.removeItem('darkModeIsEnabled');
    }
  }, [userDoc, setAppState]);

  // Ensure user has their timzone and utc hour set to appropriately receive Early Bird Report
  useEffect(() => {
    if (
      userDoc &&
      ((userDoc.preferences.earlyBirdTimezone ?? null) === null ||
        (userDoc.preferences.earlyBirdUtcHour ?? null) === null)
    ) {
      const receiveReports = userDoc.preferences.earlyBirdReportEmails ?? true;
      const localHour = userDoc.preferences.earlyBirdLocalHour ?? 9;
      const utcHour = DateTime.local().set({ hour: localHour }).toUTC().hour;
      const timezone = DateTime.local().zoneName;
      db.doc(userDoc.docPath).update({
        'preferences.earlyBirdReportEmails': receiveReports,
        'preferences.earlyBirdLocalHour': localHour,
        'preferences.earlyBirdUtcHour': utcHour,
        'preferences.earlyBirdTimezone': timezone,
      });
    }
  }, [userDoc]);

  // Check to see if isSuperAdmin and sync appState accordingly
  // ( along with setting typesense search key if available )
  // ( this only applicable to whitelisted domains )
  useEffect(() => {
    async function getSuperSearchKey() {
      try {
        const { data: superSearchKey } = await httpsCallable<unknown, string | undefined>(
          fbFunctions,
          'getOrgsSearchKey'
        )();
        setAppState({ isSuperAdmin: !!superSearchKey, superSearchKey: superSearchKey || null });
      } catch (error) {
        console.error('Super admin error', error);
      }
    }
    if (auth?.email?.match(/@notebird.app|@notebird.dev/)) {
      getSuperSearchKey();
    } else {
      setAppState({ isSuperAdmin: false });
    }
  }, [auth, setAppState]);

  return <userContext.Provider value={user}>{children}</userContext.Provider>;
};
