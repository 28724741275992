import { useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { fbAuth } from '@/lib/firebase';
import { FirebaseError } from 'firebase/app';
import { sendPasswordResetEmail } from 'firebase/auth';
import type { AuthErrorCodes } from 'firebase/auth';

import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { object, string } from 'yup';

import usePageView from '@/hooks/usePageView';

import { InputError } from '@/components/formElements/FormElements';
import FormInputGroup from '@/components/formElements/styled/FormInputGroup';

import PrimaryButton from '@/components/common/Buttons';
import Loader from '@/components/common/Loader';
import NoWrap from '@/components/common/NoWrap';
import Spacer from '@/components/common/Spacer';

import NotebirdLogo from '@/components/svg/NotebirdLogo';

// Styles
const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
`;
const LogoWrapper = styled.div`
  width: 256px;
  margin: 9vh auto 48px;
`;
const LogInPanel = styled.div`
  position: relative;
  background-color: ${props => props.theme.sheetBackgroundColor};
  border-radius: 6px;
  margin: 0 auto 48px;
  width: 384px;
  max-width: 100%;
  max-width: 100vw;
  padding: 32px 24px;
  box-shadow: ${props => props.theme.shadow400};
`;
const Note = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 32px;
  color: ${props => props.theme.textSecondary};
`;
const UserDoesntExistLink = styled.div`
  font-weight: bold;
  margin-top: 4px;
  color: ${props => props.theme.textTertiary};
`;
const Footer = styled.div`
  position: relative;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
  line-height: 1.4;
`;

// Form Schema
const schema = object().shape({
  email: string().trim().email('Please enter a valid email address').max(250, '250 characters max'),
});

// Component
const ForgotPasswordPage = () => {
  // Register page view
  usePageView({ title: 'Forgot Password | Notebird' });

  // If user visits https://.../forgot/$email, their email will populate
  // in the email field then redirect back to the root
  const { email: emailParam = '' } = useParams();

  // State to track whether reset email was successfully sent
  const [emailSent, setEmailSent] = useState(false);

  // Simple (email not found) error state tracker
  const [emailNotFound, setEmailNotFound] = useState('');

  // Form helper (Formik)
  const { values, errors, setFieldError, submitCount, handleSubmit, isSubmitting, getFieldProps } =
    useFormik({
      initialValues: {
        email: emailParam,
      },
      onSubmit,
      validationSchema: schema,
    });

  // Only allow submissions when email ins't empty,
  // isn't already submitting, and if has submitted, no errors are present
  // (used to determine disabled state of submit button below)
  const submitDisabled =
    isSubmitting || !values.email.trim() || (!!submitCount && !isEmpty(errors));

  // Submit
  async function onSubmit() {
    setFieldError('email', undefined);
    // Try sending password reset email
    try {
      await sendPasswordResetEmail(fbAuth, values.email.trim(), {
        url: `${import.meta.env.VITE_ROOT_URL}/login/${values.email.trim()}/`,
      });
      setEmailSent(true);
    } catch (error) {
      if (error instanceof FirebaseError) {
        type ErrorCode = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];
        const code = error.code as ErrorCode;
        switch (code) {
          case 'auth/invalid-email':
            setFieldError('email', 'Please enter a valid email address');
            break;
          case 'auth/user-not-found':
            setEmailNotFound(values.email.trim());
            break;
          default:
            console.error(error);
            setFieldError(
              'email',
              `An unkown error occurred. Please try again or contact support.\n\nError code: ${error?.code}`
            );
        }
      } else {
        console.error(error);
        alert(
          `An unkown error occurred. Please try again or contact support.\n\nError: ${JSON.stringify(
            error
          )}`
        );
      }
    }
  }

  return (
    <>
      {/* Clear email from URL (but it will remain in state to populate email field) */}
      {!!emailParam && <Navigate to='/forgot' replace />}

      {/* Loader */}
      <Loader show={isSubmitting} />

      <PageWrapper>
        {/* Logo */}
        <LogoWrapper>
          <NotebirdLogo width='256' tint />
        </LogoWrapper>

        {/* Main Panel */}
        <LogInPanel>
          {/* Sent */}
          {emailSent && (
            <Note>Check your inbox for instructions on how to reset your password.</Note>
          )}

          {/* Not sent yet ( form 'n stuff ) */}
          {!emailSent && (
            <>
              {/* Instructions */}
              <Note>
                We can send you an email to <NoWrap>reset your password.</NoWrap>
              </Note>
              <Spacer height='24px' />

              {/* Form */}
              <form onSubmit={handleSubmit} noValidate>
                {/* Email Address */}
                <FormInputGroup
                  label='Email address'
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'email' })}
                  type='email'
                  autoComplete='email'
                  autoFocus
                  error={errors.email}
                  maxLength={300}
                />
                {/* User not found warning ( sign up plz? ) */}
                {!!values.email && values.email === emailNotFound && (
                  <UserDoesntExistLink>
                    <InputError>No user found with this email address</InputError>
                    You could try <Link to={'/signup/' + values.email.trim()}>signing up</Link>
                  </UserDoesntExistLink>
                )}
                <Spacer height='24px' />

                {/* Submit Button */}
                <PrimaryButton block type='submit' disabled={submitDisabled}>
                  Reset Password
                </PrimaryButton>
              </form>
            </>
          )}
        </LogInPanel>

        {/* Back to login footer */}
        <Footer>
          <Link to={`/login/${values.email.trim()}/`}>Back to login</Link>
        </Footer>

        <Spacer height='64px' />
      </PageWrapper>
    </>
  );
};

// Export
export default ForgotPasswordPage;
