import { useCallback, useMemo, useRef, useState } from 'react';

import { fbAuth } from '@/lib/firebase';
import {
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  multiFactor,
} from 'firebase/auth';

export default function useMultiFactor() {
  const recaptchaRootRef = useRef<HTMLDivElement>(null);
  const RecaptchaRoot = useCallback(() => <div ref={recaptchaRootRef} />, []);

  const recaptcha = useMemo(() => {
    let verifier: RecaptchaVerifier | null = null;
    const get = () => {
      const root = recaptchaRootRef.current;
      if (!root) throw new Error('Recaptcha root not found');
      const recaptchaSeed = Math.random().toString(36).substring(7);
      root.innerHTML = `<div id='${recaptchaSeed}' />`;
      verifier = new RecaptchaVerifier(fbAuth, recaptchaSeed, { size: 'invisible' });
      return verifier;
    };
    const clear = () => {
      verifier?.clear();
      const root = recaptchaRootRef.current;
      if (!root) throw new Error('Recaptcha root not found');
      root.innerHTML = '';
    };
    return { get, clear };
  }, []);

  // States for managing verification
  const [verificationId, setVerificationId] = useState('');
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null);
  const resetVerification = useCallback(() => {
    setVerificationId('');
    setResolver(null);
  }, []);
  const hasSentVerification = !!verificationId;

  /** Method for verifying phone number when enabling SMS multi-factor */
  const verifyPhoneNumber = useCallback(
    async (phoneNumber: string) => {
      if (!phoneNumber.trim()) return;

      const authUser = fbAuth.currentUser;
      if (!authUser) throw new Error('User not found');

      const multiFactorSession = await multiFactor(authUser).getSession();
      const phoneAuthProvider = new PhoneAuthProvider(fbAuth);
      const verifier = recaptcha.get();
      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          { phoneNumber: phoneNumber, session: multiFactorSession },
          verifier
        );
        setVerificationId(verificationId);
        // return verificationId;
      } finally {
        recaptcha.clear();
      }
    },
    [recaptcha]
  );

  /** Method for enrolling user in multi-factor SMS auth */
  const enrollMultiFactorSms = useCallback(
    async (verificationCode: string, displayName: string) => {
      if (!verificationId.trim() || !displayName.trim()) return;

      const authUser = fbAuth.currentUser;
      if (!authUser) throw new Error('User not found');

      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(authUser).enroll(multiFactorAssertion, displayName);

      resetVerification();
    },
    [resetVerification, verificationId]
  );

  /** Method for sending out SMS verfication code for logging in ( and disabling multi-factor ) */
  const sendSmsVerificationCode = useCallback(
    async (error: MultiFactorError) => {
      const resolver = getMultiFactorResolver(fbAuth, error as MultiFactorError);
      setResolver(resolver);
      const phoneAuthProvider = new PhoneAuthProvider(fbAuth);
      const verifier = recaptcha.get();
      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          { multiFactorHint: resolver.hints[0], session: resolver.session },
          verifier
        );
        setVerificationId(verificationId);
        return { resolver, verificationId };
      } finally {
        recaptcha.clear();
      }
    },
    [recaptcha]
  );

  /** Method for finalizing multi-factor sign-in */
  const finalizeSignIn = useCallback(
    async (verificationCode: string) => {
      // console.log('finalizing', { verificationCode, resolver });
      if (!verificationCode.trim() || !resolver) return;

      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await resolver.resolveSignIn(multiFactorAssertion);
    },
    [resolver, verificationId]
  );

  /** Method for unenrolling in multi-factor sms */
  const unenrollMultiFactorSms = useCallback(
    async (verificationCode: string) => {
      if (!verificationCode.trim()) return;

      const authUser = fbAuth.currentUser;
      if (!authUser) throw new Error('User not found');

      await finalizeSignIn(verificationCode);
      await multiFactor(authUser).unenroll(multiFactor(authUser).enrolledFactors[0]);
    },
    [finalizeSignIn]
  );

  return {
    RecaptchaRoot,

    resolver,

    hasSentVerification,
    resetVerification,

    verifyPhoneNumber,
    enrollMultiFactorSms,
    unenrollMultiFactorSms,

    sendSmsVerificationCode,
    finalizeSignIn,
  } as const;
}
