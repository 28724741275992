import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { fbAuth } from '@/lib/firebase';
import type { User } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';

import { usePostHog } from 'posthog-js/react';

import useAppState, { AppState, useSetAppState } from './appState';

// Context ( with hook shortcut )
const fetchingAuth: [User | null, boolean, () => Promise<void>] = [
  null,
  true,
  async () => undefined,
];
const authContext = createContext(fetchingAuth);
const useAuth = () => useContext(authContext);
export default useAuth;

// Context definition w/ provider
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState([fetchingAuth[0], fetchingAuth[1]] as const);
  const setAppState = useSetAppState();
  const { recentlyLoggedOut } = useAppState();
  const posthog = usePostHog();

  // Track this for accurate `recentlyLoggedOut` status
  const hasBeenLoggedIn = useRef(false);

  useEffect(
    () =>
      onAuthStateChanged(
        fbAuth,
        async user => {
          setAuth([user, false]);
          // Ensure userId in appState is always set appropriately
          setAppState(user ? { uid: user.uid } : { ...new AppState() });
          if (user) {
            setAppState({ uid: user.uid });
            hasBeenLoggedIn.current = true;

            posthog?.identify(user.uid, {
              email: user.email || '',
              is_email_verified: user.emailVerified,
              notebird_web_version: import.meta.env.VITE_VERSION,
            });
          }
          // Otherwise, clear and reset everything if user was logged out
          else {
            // Refresh upon log out to undo db intialization allowing
            // for reset of db.enablePersistence() upon login

            // Initial log out
            if (hasBeenLoggedIn.current) {
              // Mark as logged out and refresh
              setAppState({ recentlyLoggedOut: true });
              hasBeenLoggedIn.current = false;
              posthog?.reset();
              window.location.href = '/';
            }
            // On initial load, or on log out post-refreshx
            else {
              // Clear and reset appState ( plus dark mode booter )
              // but always preserve recently logged out status
              setAppState({ ...new AppState(), recentlyLoggedOut });
              sessionStorage.clear();
              localStorage.removeItem('darkModeIsEnabled');
            }
          }
        },
        error => {
          console.error('There was an error logging in.', error.message);
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [posthog, setAppState]
  );

  // For account/subscription and users pages to continuously check
  // poll email verified status and report back
  const refresh = useCallback(async () => {
    try {
      const user = fbAuth.currentUser;
      if (user) {
        await user.reload();
        setAuth([user, false]);
        setAppState({ uid: user.uid });
      }
    } catch (error) {
      console.error('Unable to refresh user.', error);
    }
  }, [setAppState]);

  return (
    <authContext.Provider value={[auth[0], auth[1], refresh]}>{children}</authContext.Provider>
  );
};
