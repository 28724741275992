import { memo } from 'react';
import styled from 'styled-components';
// Libs
import { HIDE_ANNIVERSARY_YEAR_PAST, HIDE_BIRTHDAY_YEAR_PAST } from '@/lib/config';
import { makeSentenceList } from '@/lib/helpers';
// Classes
import { getMilestoneIcon } from '@/classes/Milestone';
import { RelativeMilestoneGroup } from './MilestonesSheet';
// Components
import { Link } from 'react-router-dom';
import Icon from '../../common/Icon';

// Styles
const StyledMilestoneGroupItem = styled.div`
  display: flex;
  margin: 0px 24px 24px;
  @media (max-width: 1023px) {
    margin: 0 12px 24px;
  }
`;
const MilestoneIconWrapper = styled.div<{ dull: boolean }>`
  width: 32px;
  height: 32px;
  padding: 5px;

  border-radius: 50%;
  background-color: ${({ dull, theme }) => (dull ? theme.lightColor : theme.grey800)};

  i {
    color: ${({ dull, theme }) => (dull ? theme.avatarText : theme.grey000)};
  }
`;
const MilestoneBody = styled.div`
  margin-left: 16px;
`;
const MilestoneType = styled.div`
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;

  small {
    margin-left: 8px;

    font-size: 13px;
    font-weight: normal;
    color: ${({ theme }) => theme.textFaded};
  }
`;
const PeopleLinks = styled.div`
  font-size: 17px;
  color: ${({ theme }) => theme.textFaded};
  a {
    font-weight: bold;
  }
  strong {
    margin-left: 2px;
    font-size: 14px;
    color: ${({ theme }) => theme.textTertiary};
  }
`;

// Component
const MilestoneGroupItem = memo(function MilestoneGroupItem({
  milestoneGroup,
  dull = false,
}: {
  milestoneGroup: RelativeMilestoneGroup;
  dull?: boolean;
}) {
  return (
    <StyledMilestoneGroupItem>
      <MilestoneIconWrapper dull={dull}>
        <Icon icon={getMilestoneIcon(milestoneGroup.typeLabel)} iconSize={'22px'} />
      </MilestoneIconWrapper>
      <MilestoneBody>
        <MilestoneType>
          {milestoneGroup.typeLabel}
          <small>{milestoneGroup.timeLabel}</small>
        </MilestoneType>
        <PeopleLinks>
          {makeSentenceList(
            milestoneGroup.milestones.map(milestone => (
              <>
                <Link to={'/person/' + milestone.person.id}>
                  {milestone.person.profile.name.full}
                </Link>
                {/* Year labels for birthdays/anniversaries */}
                {milestone.type === 'birthday' &&
                  !!milestone.yearsAgo &&
                  milestone.yearsAgo <= HIDE_BIRTHDAY_YEAR_PAST && (
                    <strong>({milestone.yearsAgo})</strong>
                  )}
                {milestone.type === 'anniversary' &&
                  !!milestone.yearsAgo &&
                  milestone.yearsAgo <= HIDE_ANNIVERSARY_YEAR_PAST && (
                    <strong>{milestone.yearsAgo}yr</strong>
                  )}
              </>
            ))
          )}
        </PeopleLinks>
      </MilestoneBody>
    </StyledMilestoneGroupItem>
  );
});
export default MilestoneGroupItem;
