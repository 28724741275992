import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
// Layout components
import PrimaryNav from './PrimaryNav';
import DropdownMenu from './DropdownMenu';
import NoticeBars from './NoticeBars';
import FilterAside from './FilterAside/FilterAside';
import AboutNotebirdModal from './AboutNotebirdModal';
import ScheduleACallModal from './ScheduleACallModal';
import useAppState, { useSetAppState } from '@/contexts/appState';
import { useFollowedGroups } from '@/contexts/groups';
import { useLocation as useRouterLocation } from 'react-router-dom';

// Styles
export const StyledAppLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  height: 100%;
  overflow: hidden;
`;
export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 1468px;

  display: flex;
  justify-content: center;
  align-items: stretch;

  overflow: hidden;
`;

// Component
const AppLayout = ({ children }: { children: React.ReactNode }) => {
  useFilterAsideCache();
  return (
    <StyledAppLayout>
      {/* Primary Nav */}
      <PrimaryNav />
      {/* Dropdown menu */}
      <DropdownMenu />

      {/* Various notices ( generally about stripe status ) */}
      <NoticeBars />

      {/* About Notebird modal */}
      <AboutNotebirdModal />

      {/* Schedule an account setup call modal ( calendly ) */}
      <ScheduleACallModal />

      {/* Main Page Content - Wrapped */}
      <PageWrapper>
        {/* Animated aside */}
        <FilterAside />

        {/* Remaining page elements */}
        {children}
      </PageWrapper>
    </StyledAppLayout>
  );
};
export default AppLayout;

// This non-presentational component detects when a primary page ( filter-aside-showing )
// changes, then caches it to prevent flashing/blanking when animating to a page without
const useFilterAsideCache = () => {
  const { pathname } = useRouterLocation();
  // Parse page name from path
  const pageName = pathname.split('/')[1];
  const prevPageName = useRef('');

  const { filterAsidePage, isFilterAsidePageActive, isLocationAdmin } = useAppState();
  const setAppState = useSetAppState();

  // Establish default group on peoplePage for group members
  const followedGroups = useFollowedGroups();
  const defaultPeoplePageGroup = useMemo(() => {
    if (isLocationAdmin) return null;
    return followedGroups.followedGroups[0]?.id || followedGroups.unfollowedGroups[0]?.id || null;
  }, [followedGroups.followedGroups, followedGroups.unfollowedGroups, isLocationAdmin]);

  // Run on page change
  useEffect(() => {
    // Nothing needed if base page is staying the same
    if (pageName === prevPageName.current) {
      prevPageName.current = pageName;
      return;
    }

    // Relevant vars
    const asidePages = ['people', 'activity', 'tasks', 'users', 'admin'] as const;
    const isAsidePage = asidePages.includes(pageName as (typeof asidePages)[number]);

    // If to or from person page + aside status is changing ( making sure filterAside page hasn't changed )
    // just reset aside and modal stuff ( preserving stuff like group/filter selection )
    if (
      [pageName, prevPageName.current].includes('person') &&
      isFilterAsidePageActive !== isAsidePage &&
      (!isAsidePage || pageName === filterAsidePage)
    ) {
      setAppState({
        isFilterAsidePageActive: isAsidePage,
        isMobileAsideOpen: false,
        isManageGroupsModalOpen: false,
      });
      // Make sure to update prev page
      prevPageName.current = pageName;
      return;
    }

    // Otherwise, if changing aside page state (not to person page) OR if changing between aside pages...
    // reset aside, modal, and group/filter stuff appropriately
    if (isFilterAsidePageActive !== isAsidePage || (isAsidePage && pageName !== filterAsidePage)) {
      setAppState({
        filterAsidePage: isAsidePage ? (pageName as (typeof asidePages)[number]) : filterAsidePage,
        isFilterAsidePageActive: isAsidePage,
        isMobileAsideOpen: false,
        isManageGroupsModalOpen: false,
        // If group member on people page, default to first group ( followed or unfollowed )
        groupId: pageName === 'people' ? defaultPeoplePageGroup : null,
        groupName: null,
        contentFilter: 'all',
        peopleScrollCacheId: '',
        peopleScrollCacheIndex: 0,
        peopleSearchCache: '',
        filterUpdatesBy: null,
        filterMilestonesBy: null,
        filterTasksBy: 'My',
        // Might as well clear super admin stuff too
        organizationsStatusFilter: null,
        organizationsTypeFilter: null,
        showAdminCharts: false,
      });
    }

    // Make sure to update prev page
    prevPageName.current = pageName;
  }, [defaultPeoplePageGroup, filterAsidePage, isFilterAsidePageActive, pageName, setAppState]);
};
