import { type ComponentProps, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Expanded from './Expanded';
import Icon from './Icon';

// Props
export interface PrimaryButtonProps extends ComponentProps<'button'> {
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  leadingIcon?: string;
  trailingIcon?: string;
  small?: boolean;
  medium?: boolean;
  outlined?: boolean;
  block?: boolean;
  // Secondary props
  margin?: string;
  dull?: boolean;
  danger?: boolean;
}

// Main Component
const ButtonElement = memo<PrimaryButtonProps>(function ButtonElement({
  type = 'button',
  leadingIcon,
  trailingIcon,
  small = false,
  medium,
  outlined,
  block,
  margin,
  dull,
  danger,
  children,
  ...rest
}: PrimaryButtonProps) {
  return (
    <button type={type} {...rest}>
      {!!leadingIcon && <Icon icon={leadingIcon} iconSize={small ? '20px' : '22px'} />}
      {!!children && <Expanded>{children}</Expanded>}
      {!!trailingIcon && <Icon icon={trailingIcon} iconSize={small ? '20px' : '22px'} />}
    </button>
  );
});

// Styled components for export
const PrimaryButton = styled(ButtonElement)`
  /* Layout */
  appearance: none;
  display: ${({ block }) => (block ? 'flex' : 'inline-flex')};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  justify-content: center;
  align-items: center;
  padding: ${({ small, medium }) => {
    if (small) {
      return '6px 12px';
    }
    if (medium) {
      return '8px 16px';
    }
    return '12px 32px';
  }};
  white-space: nowrap;

  /* Style */
  background-color: ${({ outlined, theme }) =>
    outlined ? theme.sheetBackgroundColor : theme.primary500};
  border: 2px solid ${props => props.theme.primary500};
  border-radius: 4px;
  /* transition: background-color 200ms ease-out, border-color 200ms ease-out, opacity 200ms ease-out; */
  transition: opacity 200ms ease-out;

  /* Type */
  text-align: center;
  font-size: ${({ small }) => (small ? '14px' : '16px')};

  /* Leading / Trailing icons */
  &,
  & ${Icon} {
    color: ${({ outlined, theme }) => (outlined ? theme.linkColor : 'white')};
  }

  & > ${Icon}:first-child {
    margin: ${({ children, small }) => (children ? (small ? '0 4px 0 -4px' : '0 8px 0 -8px') : 0)};
  }
  & > ${Icon}:last-child {
    margin: ${({ children, small }) => (children ? (small ? '0 -4px 0 4px' : '0 -8px 0 8px') : 0)};
  }

  /* States */
  &:hover:not(:disabled),
  &:hover:not(.disabled) {
    background-color: ${({ outlined, theme }) =>
      outlined ? theme.sheetBackgroundColor : theme.primary600};
    border-color: ${props => props.theme.primary600};
    box-shadow: ${props => props.theme.shadow300};
  }
  &:focus:not(:disabled),
  &:focus:not(.disabled) {
    outline: none;
    text-decoration: none;
    border-color: ${props => props.theme.primary700};
    box-shadow: ${props => props.theme.shadow300};
  }
  &:active:not(:disabled),
  &:active:not(.disabled) {
    outline: none;
    background-color: ${({ outlined, theme }) => (outlined ? theme.lightAccent : theme.primary700)};
    border-color: ${props => props.theme.primary700};
  }
  &:disabled,
  &.disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;
export default PrimaryButton;

const excludeParams = [
  'leadingIcon',
  'trailingIcon',
  'small',
  'medium',
  'outlined',
  'block',
  'margin',
  'dull',
] as const;
export const PrimaryButtonLink = styled(PrimaryButton)
  .withConfig({
    shouldForwardProp: prop => !excludeParams.includes(prop as (typeof excludeParams)[number]),
  })
  .attrs(() => ({ as: Link }))``;
export const PrimaryButtonA = styled(PrimaryButton).attrs(() => ({ as: 'a' }))``;

export const DangerButton = styled(PrimaryButton)`
  background-color: ${({ outlined, theme }) =>
    outlined ? theme.sheetBackgroundColor : theme.danger500};
  border: 2px solid ${props => props.theme.danger500};

  &,
  & ${Icon} {
    color: ${({ outlined, theme }) => (outlined ? theme.danger500 : 'white')};
  }

  &:hover:not(:disabled) {
    background-color: ${({ outlined, theme }) => (outlined ? theme.hoverFade : theme.danger600)};
    border-color: ${props => props.theme.danger600};
  }
  &:focus:not(:disabled) {
    border-color: ${props => props.theme.danger800};
  }
  &:active:not(:disabled) {
    background-color: ${({ outlined, theme }) => (outlined ? theme.lightAccent : theme.danger700)};
    border-color: ${props => props.theme.danger700};
  }
`;

export const SecondaryButton = styled(ButtonElement)`
  flex-shrink: 0;
  appearance: none;

  display: ${({ block }) => (block ? 'flex' : 'inline-flex')};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  white-space: nowrap;
  font-size: 14px;
  padding: 8px;
  align-items: center;
  margin: ${props => props.margin || '0'};
  border-radius: 6px;
  transition: opacity 200ms ease-out;

  &,
  & ${Icon} {
    color: ${props => {
      // Dull = grey
      if (props.dull) {
        return props.theme.textTertiary;
      }
      // Danger = red
      if (props.danger) {
        return props.theme.danger500;
      }
      // Otherwise, blue
      return props.theme.linkColor;
    }};
  }

  /* Icons */
  & > ${Icon}:first-child {
    margin-right: 4px;
  }
  & > ${Icon}:last-child {
    margin-left: 4px;
  }

  /* States */
  &:hover,
  &:hover ${Icon}, &:focus,
  &:focus ${Icon} {
    outline: none;
    color: ${props => {
      // Dull = grey
      if (props.dull) {
        return props.theme.textSecondary;
      }
      // Danger = red
      if (props.danger) {
        return props.theme.danger600;
      }
      // Otherwise, blue
      return props.theme.linkHover;
    }};
  }

  &:focus {
    text-decoration: none;
    background-color: ${props => props.theme.hoverFade};
  }

  &:disabled,
  &.disabled,
  &:disabled ${Icon}, &.disabled ${Icon} {
    cursor: not-allowed;
    opacity: 0.5;
    color: ${props => props.theme.textFaded};
  }
`;
export const SecondaryButtonLink = styled(SecondaryButton)
  .withConfig({
    shouldForwardProp: prop => !excludeParams.includes(prop as (typeof excludeParams)[number]),
  })
  .attrs(() => ({ as: Link }))``;
export const SecondaryButtonA = styled(SecondaryButton)
  .withConfig({
    shouldForwardProp: prop => !excludeParams.includes(prop as (typeof excludeParams)[number]),
  })
  .attrs(() => ({ as: 'a' }))``;
