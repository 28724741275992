import { Dispatch, Fragment, SetStateAction, useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Doc from '@/classes/Doc';
import Milestone, { DEFAULT_MILESTONE_TYPES, getMilestoneIcon } from '@/classes/Milestone';
import Person from '@/classes/Person';
import Relationship, { RELATIONSHIP_TYPES, RelationshipType } from '@/classes/Relationship';

import firebase, { db } from '@/lib/firebase';

import {
  HIDE_ANNIVERSARY_YEAR_PAST,
  HIDE_BIRTHDAY_YEAR_PAST,
  MAX_GROUP_ASSIGNMENTS,
} from '@/lib/config';
import { getFriendlyDate } from '@/lib/helpers';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { Interweave } from 'interweave';
import { EmailMatcher, Url, UrlMatcher } from 'interweave-autolink';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { usePostHog } from 'posthog-js/react';

import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet from '@/components/layout/Sheets';

import { ReactSelectSecondary } from '@/components/formElements/ReactSelect';

import Avatar from '@/components/common/Avatar';
import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Divider from '@/components/common/Divider';
import EditDeleteMenu from '@/components/common/EditDeleteMenu';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import Loader from '@/components/common/Loader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import StickyHeader from '@/components/common/StickyHeader';

import BreezeIcon from '@/img/breezeIcon.svg?react';
import PlanningCenterIcon from '@/img/planningCenterIcon.svg?react';

// Styles
interface Props {
  active: boolean;
}
const StyledPersonInfoSheet = styled(Sheet)<Props>`
  /* Stuff for tab-switching on mobile */
  @media (max-width: 1023px) {
    // This weirdness is to have the 'add group' dropdown appear properly
    // ( just necessary when 'activity' tab is active )
    overflow: ${({ active }) => (active ? 'hidden' : 'unset')};
    flex: ${({ active }) => (active ? '1' : 'none')};
    flex-shrink: 0;
    box-shadow: none;

    ${SheetHeader} {
      box-shadow: none;
      background-color: ${({ theme }) => theme.hoverFade};
      border-bottom: 4px solid ${({ theme }) => theme.dividerColor};
    }
  }
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const PersonDetails = styled.div`
  flex: 1;
`;
const PersonName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-size: 24px;
  font-weight: bold;
  /* margin-bottom: 8px; */
`;
const GroupLabelRow = styled.div<{ hideOnMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 8px;

  @media (max-width: 1023px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`;
const DeletableGroupLabel = styled.span`
  display: inline-flex;
  align-items: center;
  padding-left: 12px;
  background-color: ${({ theme }) => theme.hoverFade};
  border-radius: 24px;

  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};

  @media (max-width: 1023px) {
    color: ${({ theme }) => theme.textSecondary};
    background-color: ${({ theme }) => theme.sheetBackgroundColor};
  }

  button {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 7px;
    i {
      color: ${({ theme }) => theme.lightAccent};
      &:hover {
        color: ${({ theme }) => theme.danger500};
      }
      @media (hover: none) {
        color: ${({ theme }) => theme.avatarText};
      }
    }
  }
`;
const ShowHideGroupsButton = styled(SecondaryButton)`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;
const MobileTabs = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    display: none !important;
  }

  margin-left: -12px;
  width: calc(100% + 24px);

  a {
    font-size: 20px;
    text-align: center;
    padding: 12px;
    flex: 1;
    display: inline-block;
    color: ${({ theme }) => theme.textSecondary};
    font-weight: bold;
    text-decoration: none;
    /* border-bottom: 4px solid ${({ theme }) => theme.dividerColor}; */
    &:hover:not(.active) {
      color: ${({ theme }) => theme.textPrimary};
      border-bottom: 4px solid ${({ theme }) => theme.lightAccent};
      margin-bottom: -4px;
    }
    &.active {
      box-shadow: ${({ theme }) => theme.shadow200};
      border-radius: 4px 4px 0 0;
      color: ${({ theme }) => theme.linkColor};
      border-bottom: 4px solid ${({ theme }) => theme.linkColor};
      margin-bottom: -4px;
      background-color: ${({ theme }) => theme.sheetBackgroundColor};
    }
  }
`;
const ArchivedStatus = styled.div`
  font-weight: bold;
  color: ${props => props.theme.textFaded};
`;
const PersonNotes = styled.pre`
  font-family: inherit;
  margin: 0 0 0 16px;
  color: ${({ theme }) => theme.textTertiary};
  line-height: 1.3;
  white-space: pre-wrap;
`;
const Section = styled.div`
  padding: 0 16px 32px;
  @media (max-width: 1023px) {
    padding: 0 12px 32px;
  }
`;
const SectionEmptyNotice = styled.div`
  margin: 0 0 -8px 16px;
  color: ${({ theme }) => theme.textTertiary};
`;
const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(60%, 1.5fr);
  gap: 16px 32px;
  align-items: baseline;
  margin-top: 8px;
`;
const GridLabel = styled.div`
  padding-left: 12px;
  color: ${({ theme }) => theme.textTertiary};
  i {
    color: ${({ theme }) => theme.avatarText};
    margin-right: 8px;
    vertical-align: text-bottom;
  }
`;
const GridInfo = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  color: ${({ theme }) => theme.textPrimary};
  span {
    display: block;
    font-size: 15px;
    font-weight: normal;
    color: ${({ theme }) => theme.textFaded};
  }
  /* Edit button ( for milestones ) */
  &:hover {
    button > i {
      color: ${({ theme }) => theme.avatarText};
    }
  }
`;
const RelationItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 8px;
  font-weight: bold;
  line-height: 1.2;

  span {
    display: block;
    font-size: 15px;
    font-weight: normal;
    color: ${({ theme }) => theme.textFaded};
  }
  /* Edit button */
  &:hover {
    button > i {
      color: ${({ theme }) => theme.avatarText};
    }
  }
`;
const MetaItem = styled.div`
  padding: 4px 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textTertiary};
  }
`;

// Component

interface PersonInfoSheetProps {
  person: Doc<Person>;
  milestones: Doc<Milestone>[] | null;
  goBack(): void;
  setEditPersonDoc: Dispatch<SetStateAction<Doc<Person> | undefined>>;
  setShowDeletePersonModal: Dispatch<SetStateAction<boolean>>;
  setShowNewRelationshipForm: Dispatch<SetStateAction<boolean>>;
  setEditRelationshipDoc: Dispatch<SetStateAction<Relationship | undefined>>;
  setDeleteRelationshipDoc: Dispatch<SetStateAction<Relationship | undefined>>;
  setShowNewMilestoneForm: Dispatch<SetStateAction<boolean>>;
  setEditMilestoneDoc: Dispatch<SetStateAction<Doc<Milestone> | undefined>>;
  setDeleteMilestoneDoc: Dispatch<SetStateAction<Doc<Milestone> | undefined>>;
  disableButtons: boolean;
}
const PersonInfoSheet = ({
  person,
  milestones,
  goBack,
  setEditPersonDoc,
  setShowDeletePersonModal,
  setShowNewRelationshipForm,
  setEditRelationshipDoc,
  setDeleteRelationshipDoc,
  setShowNewMilestoneForm,
  setEditMilestoneDoc,
  setDeleteMilestoneDoc,
  disableButtons,
}: PersonInfoSheetProps) => {
  const posthog = usePostHog();

  // App state
  const { uid, isActive, organizationId, locationId, isLocationAdmin } = useAppState();
  // Context data
  const [user] = useUser();
  const [organization] = useOrganization();
  const [groups] = useGroups();

  // Determine if user is following this person or not
  const following = useMemo(() => {
    if (user && organizationId && locationId) {
      const followedPeople = user.following[organizationId]?.[locationId]?.people;
      return !!followedPeople && followedPeople.includes(person.id);
    }
    return false;
  }, [locationId, organizationId, person.id, user]);

  // Get groups that person is in and not in
  // ( filtering out which groups the currentUser may not have access to )
  const personInGroups = useMemo(
    () => groups?.filter(group => person.groups.includes(group.id)) ?? [],
    [groups, person.groups]
  );
  const personOutGroups = useMemo(
    () => groups?.filter(group => !person.groups.includes(group.id)) ?? [],
    [groups, person.groups]
  );

  // When on mobile we need to know which sheet is active
  const { sheet } = useParams();
  const showProfile = sheet === 'profile';

  // Remove any birthdays, anniversaries, or joinDates
  // ( these are handled in the person's profile )
  const customMilestones = useMemo(
    () => milestones?.filter(milestone => !DEFAULT_MILESTONE_TYPES.includes(milestone.type)) || [],
    [milestones]
  );

  // Mark person as followed/unfollowed for the user
  const toggleFollow = useCallback(() => {
    const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
    if (!user || !organizationId || !locationId) return;
    db.doc(user.docPath).update({
      [`following.${organizationId}.${locationId}.people`]: following
        ? arrayRemove(person.id)
        : arrayUnion(person.id),
    });
    posthog?.capture(following ? 'person_unfollowed' : 'person_followed', {
      followed_people_count:
        (user.following?.[organizationId]?.[locationId]?.people?.length ?? 0) + 1,
    });
  }, [user, organizationId, locationId, following, person.id, posthog]);

  // Mark person as archived/unarchived
  const toggleIsArchived = useCallback(() => {
    if (user) {
      const inSync = person.integration ? { 'integration.inSync': false } : {};
      const personUpdate = {
        ...generateUpdatedByDottedMeta(user),
        isArchived: !person.isArchived,
        ...inSync,
      };
      db.doc(person.docPath).update(personUpdate);
      posthog?.capture(person.isArchived ? 'person_unarchived' : 'person_archived');
    }
  }, [user, person.integration, person.isArchived, person.docPath, posthog]);

  // Little helper var for buttons at the bottom
  const userSharesAllGroups =
    person.groups[0] === 'ungrouped' ||
    !difference(person.groups, groups?.map(({ id }) => id) || []).length;

  // Hide groups on mobile by default ( if more than 2 groups )
  const [collapseGroups, setCollapseGroups] = useState(personInGroups.length > 2);

  return (
    <StyledPersonInfoSheet
      position='left'
      active={showProfile}
      data-intercom-target='Person info sheet'
    >
      {/* Header info */}
      <SheetHeader primary className='fade-in' key={person.id}>
        <Spacer height='16px' mobileHeight='8px' />
        <FlexRow>
          {/* Back button */}
          <SecondaryButton leadingIcon='arrow_back' onClick={goBack}>
            <strong>Back</strong>
          </SecondaryButton>
          {/* Follow button ( if not archived ) */}
          {!person.isArchived && (
            <PrimaryButton
              small
              outlined={!following}
              onClick={toggleFollow}
              disabled={!isActive}
              data-intercom-target='Follow toggle button'
            >
              <strong>{following ? 'Following' : 'Follow'}</strong>
            </PrimaryButton>
          )}
        </FlexRow>
        <Spacer height='4px' mobileHeight='0' />

        <FlexRow>
          {/* Avatar */}
          <Avatar
            id={person.id}
            photo={person.profile.photo}
            name={person.profile.name.full}
            size={96}
            faded={person.isArchived}
          />
          <Spacer width='16px' />
          <PersonDetails>
            {/* Name */}
            <PersonName title={person.profile.name.full}>{person.profile.name.full}</PersonName>

            {/* Breeze integration link */}
            {organization?._integration?.service === 'breeze' &&
              person.integration?.service === 'breeze' &&
              !!person.integration.id && (
                <div style={{ display: 'flex', alignItems: 'center', padding: '2px 0 6px' }}>
                  <BreezeIcon width='18px' height='18px' />
                  <Spacer width='6px' />
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://${organization._integration.subdomain}.breezechms.com/people/view/${person.integration.id}`}
                  >
                    <strong>Breeze profile</strong>
                  </a>
                </div>
              )}

            {/* Planning Center integration link */}
            {organization?._integration?.service === 'planningCenter' &&
              person.integration?.service === 'planningCenter' &&
              !!person.integration.id && (
                <div style={{ display: 'flex', alignItems: 'center', padding: '2px 0 6px' }}>
                  <PlanningCenterIcon width='18px' height='18px' />
                  <Spacer width='6px' />
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://people.planningcenteronline.com/people/${person.integration.id}`}
                  >
                    <strong>Planning Center profile</strong>
                  </a>
                </div>
              )}

            {/* ShulCloud integraiton link */}
            {organization?._integration?.service === 'shulCloud' &&
              !!organization?._integration?.url &&
              person.integration?.service === 'shulCloud' &&
              !!person.integration.households[0] && (
                <div style={{ display: 'flex', alignItems: 'center', padding: '2px 0 6px' }}>
                  <Icon icon='cloud_queue' iconSize='22px' />
                  <Spacer width='6px' />
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${organization._integration.url}/admin/members.php?action=view&id=${person.integration.households[0]}`}
                  >
                    <strong>ShulCloud account</strong>
                  </a>
                </div>
              )}
            {/* Is archived notice */}
            {!!person.isArchived && <ArchivedStatus>This person is archived</ArchivedStatus>}

            {/* Groups */}
            <GroupLabelRow hideOnMobile={personInGroups.length > 2 && collapseGroups}>
              <Spacer height='4px' />
              {/* Groups list */}
              {personInGroups.map(group => (
                <DeletableGroupLabel key={group.id}>
                  {group.name}{' '}
                  <button
                    onClick={e => {
                      e.preventDefault();
                      if (user) {
                        // If removing from final group, just swap out 'ungrouped' tag
                        const groupsToUpdate =
                          person.groups.length === 1
                            ? ['ungrouped']
                            : firebase.firestore.FieldValue.arrayRemove(group.id);
                        db.doc(person.docPath).update({
                          groups: groupsToUpdate,
                          ...generateUpdatedByDottedMeta(user),
                        });
                        posthog?.capture('person_quick_removed_group', {
                          groups_count: person.groups.length - 1,
                        });
                      }
                    }}
                  >
                    <Icon icon='cancel' iconSize='22px' />
                  </button>
                </DeletableGroupLabel>
              ))}
              {/* Add group button ( if any to add ) */}
              {isActive &&
                !!personOutGroups.length &&
                personInGroups.length <= MAX_GROUP_ASSIGNMENTS && (
                  <ReactSelectSecondary
                    isSearchable={personOutGroups.length > 8}
                    small
                    inline
                    placeholder='Add to group'
                    options={
                      personOutGroups?.map(({ id, name }) => ({ value: id, label: name })) || []
                    }
                    value={null}
                    onChange={v => {
                      const groupId = v?.value;
                      if (groupId && user) {
                        // If adding first group, just swap out 'ungrouped' tag
                        const groupsToUpdate =
                          person.groups[0] === 'ungrouped'
                            ? [groupId]
                            : firebase.firestore.FieldValue.arrayUnion(groupId);
                        db.doc(person.docPath).update({
                          groups: groupsToUpdate,
                          ...generateUpdatedByDottedMeta(user),
                        });
                        posthog?.capture('person_quick_added_group', {
                          groups_count: person.groups.length + 1,
                        });
                      }
                    }}
                  />
                )}
            </GroupLabelRow>
            {/* Show/hide groups button if more than 2 groups on mobile */}
            {personInGroups.length > 2 && (
              <ShowHideGroupsButton small onClick={() => setCollapseGroups(prev => !prev)}>
                {collapseGroups ? `Show ${personInGroups.length} groups` : 'Hide groups'}
              </ShowHideGroupsButton>
            )}
          </PersonDetails>
        </FlexRow>
        <Spacer height='24px' mobileHeight='16px' />
        <MobileTabs>
          <NavLink to={`/person/${person.id}/profile`} replace>
            Profile
          </NavLink>
          <NavLink end to={`/person/${person.id}`} replace>
            Activity
          </NavLink>
        </MobileTabs>
      </SheetHeader>

      {/* Main person info area ( when done loading milestones ) */}
      <Loader show={!milestones} />
      {!!milestones && (
        <Scrollable
          className={showProfile ? 'fade-in' : 'fade-in hidden-mobile'}
          key={person.id + '-info'}
        >
          <Padding padding='0 0 0 12px' mobilePadding='0'>
            <Spacer height='16px' />
            {/* Contact Info */}
            <StickyHeader heading='Contact info' />
            <Section data-intercom-target='Contact info section'>
              {/* No contact info found */}
              {!person.emails.length && !person.phones.length && !person.addresses.length && (
                <SectionEmptyNotice>No contact info</SectionEmptyNotice>
              )}
              <SectionGrid>
                {/* Phones */}
                {person.phones.map(({ type, number }, index) => {
                  let whatsApp = '';
                  if (user?.preferences.whatsAppCode) {
                    whatsApp = number.replace(/\D/g, '');
                    if (!whatsApp.startsWith(user.preferences.whatsAppCode)) {
                      whatsApp = user.preferences.whatsAppCode + whatsApp;
                    }
                  }
                  return (
                    <Fragment key={type + number + index}>
                      <GridLabel>
                        <Icon icon={type === 'Mobile' ? 'smartphone' : 'phone'} iconSize='22px' />
                        {type}
                      </GridLabel>
                      <GridInfo>
                        <a href={`tel:${number}`}>{number}</a>
                        {['Mobile', 'Other'].includes(type) && !whatsApp && (
                          <>
                            &nbsp;&nbsp;·&nbsp;&nbsp;
                            <a href={`sms:${number}`} rel='noopener noreferrer' target='_blank'>
                              Send text
                            </a>
                          </>
                        )}
                        {!!whatsApp && (
                          <>
                            &nbsp;&nbsp;·&nbsp;&nbsp;
                            <a
                              href={`https://wa.me/?phone=${whatsApp}`}
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              WhatsApp
                            </a>
                          </>
                        )}
                      </GridInfo>
                    </Fragment>
                  );
                })}
                {!!person.phones.length &&
                  (!!person.emails.length || !!person.addresses.length) && (
                    <>
                      <GridLabel />
                      <GridInfo />
                    </>
                  )}
                {/* Emails */}
                {person.emails.map(({ type, address }, index) => (
                  <Fragment key={type + address + index}>
                    <GridLabel>
                      <Icon icon='mail' iconSize='22px' />
                      {type}
                    </GridLabel>
                    <GridInfo>
                      <a href={`mailto:${address}`}>{address}</a>
                    </GridInfo>
                  </Fragment>
                ))}
                {!!person.emails.length && !!person.addresses.length && (
                  <>
                    <GridLabel />
                    <GridInfo />
                  </>
                )}
                {/* Addresses */}
                {person.addresses.map(({ type, place }, index) => (
                  <Fragment key={(place.placeId || '') + index}>
                    <GridLabel>
                      <Icon icon='home' iconSize='22px' />
                      {type}
                    </GridLabel>
                    <GridInfo
                      title={place.mainText + (!!place.secondaryText && ', ' + place.secondaryText)}
                    >
                      <a
                        href={
                          place.placeId && place.placeId !== 'planningCenter'
                            ? `https://www.google.com/maps/search/?api=1&query=${place.mainText}, ${place.secondaryText}&query_place_id=${place.placeId}`
                            : `https://www.google.com/maps/search/?api=1&query=${place.mainText}, ${place.secondaryText}`
                        }
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        {place.mainText}
                        {place.secondaryText && <span>{place.secondaryText}</span>}
                      </a>
                    </GridInfo>
                  </Fragment>
                ))}
              </SectionGrid>
            </Section>

            {/* Details */}
            <StickyHeader heading='Details' />
            <Section data-intercom-target='Details section'>
              {/* No details found */}
              {!person.birthday &&
                !person.relationshipStatus &&
                !person.anniversary &&
                !person.gender &&
                !person.customId &&
                !person.joinDate && <SectionEmptyNotice>No details</SectionEmptyNotice>}
              <SectionGrid>
                {/* Birthday */}
                {person.birthday && (
                  <>
                    <GridLabel>
                      <Icon icon='cake' iconSize='22px' />
                      Birthday
                    </GridLabel>
                    <GridInfo>
                      {getFriendlyDate(person.birthday, {
                        hideYearPast: HIDE_BIRTHDAY_YEAR_PAST,
                      })}
                    </GridInfo>
                  </>
                )}
                {/* Relationship status */}
                {person.relationshipStatus && (
                  <>
                    <GridLabel>
                      <Icon icon='favorite' iconSize='22px' />
                      Marital status
                    </GridLabel>
                    <GridInfo>{person.relationshipStatus}</GridInfo>
                  </>
                )}
                {/* Anniversary */}
                {person.anniversary && (
                  <>
                    <GridLabel>
                      <Icon icon='loyalty' iconSize='22px' />
                      Anniversary
                    </GridLabel>
                    <GridInfo>
                      {getFriendlyDate(person.anniversary, {
                        hideYearPast: HIDE_ANNIVERSARY_YEAR_PAST,
                      })}
                    </GridInfo>
                  </>
                )}
                {/* Gender */}
                {person.gender && (
                  <>
                    <GridLabel>
                      <Icon icon='wc' iconSize='22px' />
                      Gender
                    </GridLabel>
                    <GridInfo>{person.gender}</GridInfo>
                  </>
                )}
                {/* ID Number */}
                {person.customId && (
                  <>
                    <GridLabel>
                      <Icon icon='vpn_key' iconSize='22px' />
                      ID Number
                    </GridLabel>
                    <GridInfo>{person.customId}</GridInfo>
                  </>
                )}
                {/* Join date */}
                {person.joinDate && (
                  <>
                    <GridLabel>
                      <Icon icon='mood' iconSize='22px' />
                      Joined
                    </GridLabel>
                    <GridInfo>{getFriendlyDate(person.joinDate)}</GridInfo>
                  </>
                )}
              </SectionGrid>
            </Section>

            {/* Related people */}
            <StickyHeader heading='Related people' />
            <Section data-intercom-target='Related people section'>
              {/* No related people found notice ( if archived ) */}
              {!person.relationships.length && person.isArchived && (
                <SectionEmptyNotice>
                  No related people
                  <Spacer height='8px' />
                </SectionEmptyNotice>
              )}
              {sortBy(person.relationships, ({ type }) => {
                const idx = RELATIONSHIP_TYPES.indexOf(type as RelationshipType);
                return idx === -1 ? 9999 : idx;
              }).map((relationship, index) => (
                <RelationItem
                  key={relationship.id + relationship.type + relationship.profile.name.full + index}
                >
                  <Avatar
                    id={relationship.id}
                    name={relationship.profile.name.full}
                    photo={relationship.profile.photo}
                    size={48}
                    sizeOverride={40}
                  />
                  <Spacer width='12px' />
                  <Expanded>
                    {relationship.id ? (
                      <Link to={`/person/${relationship.id}/profile`}>
                        {relationship.profile.name.full}
                      </Link>
                    ) : (
                      relationship.profile.name.full
                    )}
                    <span>{relationship.type !== 'Other' && relationship.type}</span>
                  </Expanded>
                  {/* Edit/delete button ( only if not archived and isn't disabled ) */}
                  {!disableButtons && !person.isArchived && (
                    <EditDeleteMenu
                      handleEdit={() => setEditRelationshipDoc(relationship)}
                      handleDelete={() => setDeleteRelationshipDoc(relationship)}
                    />
                  )}
                </RelationItem>
              ))}
              {/* New related person button ( only if not archived ) */}
              {!person.isArchived && (
                <>
                  <Spacer height='8px' />
                  <SecondaryButton
                    disabled={disableButtons}
                    leadingIcon='add_circle'
                    onClick={() => setShowNewRelationshipForm(true)}
                    data-intercom-target='Add relationship button'
                  >
                    Add <strong>relationship</strong>
                  </SecondaryButton>
                </>
              )}
            </Section>

            {/* Milestones ( custom ) */}
            <StickyHeader heading='Milestones' />
            <Section data-intercom-target='Milestones section'>
              {/* No milestones found notice ( if archived ) */}
              {!customMilestones.length && person.isArchived && (
                <SectionEmptyNotice>
                  No custom milestones
                  <Spacer height='8px' />
                </SectionEmptyNotice>
              )}
              {!!customMilestones.length && (
                <SectionGrid>
                  {/* Each milestone */}
                  {customMilestones.map(milestone => (
                    <Fragment key={milestone.id}>
                      <GridLabel>
                        <Icon icon={getMilestoneIcon(milestone.type)} iconSize='22px' />
                        {milestone.type}
                      </GridLabel>
                      <GridInfo>
                        <FlexRow>
                          <Expanded>
                            {milestone.date && getFriendlyDate(milestone.date)}
                            {milestone.description ? (
                              <Interweave content={milestone.description} />
                            ) : (
                              <Spacer height='6px' />
                            )}
                          </Expanded>
                          {/* Edit/delete button ( only if not archived and isn't disabled ) */}
                          {!disableButtons && !person.isArchived && (
                            <EditDeleteMenu
                              handleEdit={() => setEditMilestoneDoc(milestone)}
                              handleDelete={() => setDeleteMilestoneDoc(milestone)}
                            />
                          )}
                        </FlexRow>
                      </GridInfo>
                    </Fragment>
                  ))}
                </SectionGrid>
              )}

              {/* Add milestone button ( only if not archived ) */}
              {!person.isArchived && (
                <>
                  <Spacer height='8px' />
                  <SecondaryButton
                    disabled={disableButtons}
                    leadingIcon='add_circle'
                    onClick={() => setShowNewMilestoneForm(true)}
                    data-intercom-target='Add milestone button'
                  >
                    Add <strong>milestone</strong>
                  </SecondaryButton>
                </>
              )}
            </Section>

            {/* Notes */}
            {person.notes && (
              <>
                <StickyHeader heading='Profile notes' />
                <Section>
                  <PersonNotes>
                    <Interweave
                      content={person.notes}
                      matchers={[
                        new UrlMatcher('url', {}, props => <Url {...props} newWindow />),
                        new EmailMatcher('email'),
                      ]}
                    />
                  </PersonNotes>
                </Section>
              </>
            )}
            <Spacer height='64px' />

            {/* BUTTONS */}

            {/* Edit button ( disabled if archived ) */}
            <Padding padding='8px 16px' mobilePadding='8px 12px'>
              <SecondaryButton
                disabled={!isActive}
                leadingIcon='edit'
                onClick={() => setEditPersonDoc(person)}
                data-intercom-target='Edit person button'
              >
                <strong>Edit</strong> profile
              </SecondaryButton>
            </Padding>

            {/* Archive toggle button */}
            {/* ( group member can only archive if shares all person's groups or ungrouped ) */}
            {(isLocationAdmin || userSharesAllGroups) && (
              <>
                <Divider />
                <Padding padding='8px 16px' mobilePadding='8px 12px'>
                  <SecondaryButton
                    disabled={disableButtons}
                    leadingIcon={person.isArchived ? 'unarchive' : 'archive'}
                    dull={!person.isArchived}
                    onClick={toggleIsArchived}
                    data-intercom-target='Archive toggle button'
                  >
                    <strong>{person.isArchived ? 'Unarchive' : 'Archive'}</strong>{' '}
                    {person.profile.name.first}
                  </SecondaryButton>
                </Padding>
              </>
            )}

            {/* Delete button ( only if archived AND... user is admin or */}
            {/* group member created this person and share all person's groups or ungrouped ) */}
            {!!person.isArchived &&
              (isLocationAdmin || (person.meta.createdBy.id === uid && userSharesAllGroups)) && (
                <>
                  <Divider />
                  <Padding padding='8px 16px' mobilePadding='8px 12px'>
                    <SecondaryButton
                      disabled={disableButtons}
                      dull
                      onClick={() => setShowDeletePersonModal(true)}
                      data-intercom-target='Delete person button'
                    >
                      <strong>Permanently delete</strong>
                    </SecondaryButton>
                  </Padding>
                </>
              )}
            <Divider />
            <Spacer height='12px' />

            {/* Person created ( or imported ) by meta stuff */}
            {person.meta.createdBy && person.meta.createdAt && (
              <MetaItem>
                {person.meta.createdBy.id === 'admin' && person.integration ? (
                  <>
                    Profile imported{' '}
                    <strong>{getFriendlyDate(person.meta.createdAt.toMillis())}</strong> from{' '}
                    <strong>{startCase(person.integration.service)}</strong>
                  </>
                ) : (
                  <>
                    Profile created{' '}
                    <strong>{getFriendlyDate(person.meta.createdAt.toMillis())}</strong> by{' '}
                    <strong>{person.meta.createdBy.profile.name.display}</strong>
                  </>
                )}
              </MetaItem>
            )}
            {/* Person updated by */}
            {person.meta.updatedBy && person.meta.updatedAt && (
              <MetaItem>
                Updated <strong>{getFriendlyDate(person.meta.updatedAt.toMillis())}</strong> by{' '}
                <strong>
                  {person.meta.updatedBy.id === 'admin' && person.integration
                    ? startCase(person.integration.service)
                    : person.meta.updatedBy.profile.name.display}
                </strong>
              </MetaItem>
            )}
          </Padding>
        </Scrollable>
      )}
    </StyledPersonInfoSheet>
  );
};
export default PersonInfoSheet;
