import styled from 'styled-components';

import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import { SheetBase } from './Sheets';

// Styles
export const StyledFloatingSheet = styled(SheetBase)<{ priority: boolean }>`
  z-index: ${({ priority }) => (priority ? 151 : 150)};

  position: absolute;
  width: ${({ position }) => (position === 'full' ? '100%' : '50%')};
  height: calc(100% - 8px);
  top: 8px;

  @media (max-width: 1023px) {
    /* z-index is a little higher on mobile to overcome page header and footer */
    z-index: ${({ priority }) => (priority ? 251 : 250)};
    position: fixed;
    width: 100%;
    top: 0;
    height: 100%;
    height: calc(100% - env(safe-area-inset-bottom));
  }

  right: ${({ position }) => (position === 'left' ? 'auto' : '0')};
  left: ${({ position }) => (position === 'left' ? '0' : 'auto')};
  transform-origin: ${({ position }) =>
    position === 'left' ? 'calc(100% + 48px) center' : '-48px center'};
  backface-visibility: none;

  box-shadow: ${props => props.theme.shadow400};

  /* Mount/unmount animation */
  &.enter {
    @media (min-width: 1024px) {
      /* Just to get above the primary nav on desktop */
      z-index: 212;
    }
    opacity: 0;
    transform: perspective(1800px)
      rotateY(${({ position }) => (position === 'left' ? '84deg' : '-84deg')});
  }
  &.enter-active {
    pointer-events: none;
    transition:
      opacity 250ms,
      transform 420ms ${({ theme }) => theme.easeDecelerate};

    opacity: 1;
    transform: perspective(1800px) rotateY(0);
  }
  &.exit-active {
    pointer-events: none;
    transition:
      opacity 500ms 50ms,
      transform 550ms ${({ theme }) => theme.easeStandard};

    opacity: 0;
    transform: perspective(1800px)
      rotateZ(${({ position }) => (position === 'left' ? '-4deg' : '4deg')}) translateY(33%);
  }
`;

// Component
interface Props {
  forwardedKey?: string;
  position?: 'right' | 'left' | 'full';
  priority?: boolean;
  faded?: boolean;
  children?: React.ReactNode;
}
const AnimatedFloatingSheet = ({
  forwardedKey,
  position = 'right',
  priority = false,
  faded = false,
  children,
}: Props) => {
  return (
    <TransitionGroup component={null}>
      {!!children && (
        <CSSTransition key={forwardedKey} timeout={450}>
          <StyledFloatingSheet position={position} priority={priority} faded={faded}>
            {children}
          </StyledFloatingSheet>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
export default AnimatedFloatingSheet;
