interface DocProps {
  id: string;
  docPath: string;
}
type Doc<T> = T & DocProps;
export default Doc;

export type WithId<T> = T & { id: string };

export function docFromSnapshot<T>(snapshot: firebase.firestore.DocumentSnapshot): Doc<T> {
  const id = snapshot.id;
  const docPath = snapshot.ref.path;
  const data = snapshot.data() as T;
  return { id, docPath, ...data };
}
