import type { DefaultTheme } from 'styled-components';

export const colors = {
  primary000: '#E6F6FF',
  primary100: '#BAE3FF',
  primary200: '#7CC4FA',
  primary300: '#47A3F3',
  primary400: '#2186EB',
  primary500: '#046ADB',
  primary600: '#0452B8',
  primary700: '#034094',
  primary800: '#013075',
  primary900: '#011F54',

  grey000: '#F5F7FA',
  grey100: '#E4E7EB',
  grey200: '#CBD2D9',
  grey300: '#9AA5B1',
  grey400: '#7B8794',
  grey500: '#616E7C',
  grey600: '#52606D',
  grey700: '#3E4C59',
  grey800: '#323F4B',
  grey900: '#1F2933',

  danger000: '#FFEEEE',
  danger100: '#FACDCD',
  danger200: '#F29B9B',
  danger300: '#E66A6A',
  danger400: '#D64545',
  danger500: '#BA2525',
  danger600: '#A61B1B',
  danger700: '#911111',
  danger800: '#780A0A',
  danger900: '#610404',
};

const primaryTheme: DefaultTheme = {
  isDark: false,

  // Image Filter (to shift colors on illustrations)
  imgFilter: 'none',

  ////////////
  // Colors //
  ////////////
  ...colors,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  backgroundColor: colors.grey100,
  sheetBackgroundColor: '#FFFFFF',
  sheetFadedBackgroundColor: colors.grey000,

  textPrimary: colors.grey900,
  textSecondary: colors.grey700,
  textTertiary: colors.grey500,
  textFaded: colors.grey400,

  lightColor: '#FFFFFF',
  offLight: colors.grey100,
  lightAccent: colors.grey200,
  darkColor: colors.grey900,
  darkAccent: colors.grey800,
  linkColor: colors.primary500,
  linkHover: colors.primary600,
  dividerColor: colors.grey100,
  hoverFade: colors.grey000,
  hoverAsideFade: colors.grey000,
  primaryDark: colors.primary800,
  avatarBG: colors.grey100,
  avatarText: colors.grey300,

  /////////////
  // Shadows //
  /////////////
  shadow100: '0px 1px 3px rgba(62, 76, 89, 0.12)',
  shadow200: '0px 2px 4px rgba(62, 76, 89, 0.1)',
  shadow200Inverted: '0px -2px 4px rgba(62, 76, 89, 0.1), 0px 2px 4px rgba(62, 76, 89, 0.025)',
  shadow300: '0px 4px 8px rgba(62, 76, 89, 0.15)',
  shadow300Inverted: '0px -4px 8px rgba(62, 76, 89, 0.125), 0px 4px 8px rgba(62, 76, 89, 0.025)',
  // shadow400: '0px 8px 16px rgba(62, 76, 89, 0.15)',
  shadow400: '0px 0px 16px rgba(62, 76, 89, 0.15)',
  sheetShadows: '0px 0 32px rgba(62, 76, 89, 0.3), 0px 0px 12px rgba(62, 76, 89, 0.35)',
  // From this generator: https://brumm.af/shadows
  headerShadow: `0 -0.3px 0.5px rgba(0, 0, 0, 0.016),
                 0 -0.9px 1.8px rgba(0, 0, 0, 0.024),
                 0 -4px 8px rgba(0, 0, 0, 0.04),
                 0 0.1px 0.3px rgba(0, 0, 0, 0.028),
                 0 0.4px 0.9px rgba(0, 0, 0, 0.042),
                 0 2px 4px rgba(0, 0, 0, 0.07)`,
  headerShadowHover: `0 -0.3px 0.7px rgba(0, 0, 0, 0.024),
                      0 -1.1px 2.2px rgba(0, 0, 0, 0.036),
                      0 -5px 10px rgba(0, 0, 0, 0.06),
                      0 0.3px 0.3px rgba(0, 0, 0, 0.028),
                      0 0.9px 0.9px rgba(0, 0, 0, 0.042),
                      0 4px 4px rgba(0, 0, 0, 0.07)
  `,

  //////////
  // Misc //
  //////////

  ////////////
  // Easing //
  ////////////
  // Adapted from Google Material Motion
  // https:/'/material.io/guidelines/motion/duration-easing.html#duration-easing-natural-easing-curve's
  ////////////
  easeStandard: 'cubic-bezier(0.4, 0, 0.2, 1)',
  easeSharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  easeDecelerate: 'cubic-bezier(0, 0, 0.2, 1)',
  easeAccelerate: 'cubic-bezier(0.4, 0, 1, 1)',
};

export default primaryTheme;
