import { Fragment, useCallback, useState } from 'react';

import Doc from '@/classes/Doc';
import Group from '@/classes/Group';

import firebase, { db } from '@/lib/firebase';

import { MAX_GROUPS } from '@/lib/config';
import { pluralize } from '@/lib/helpers';
import fuzzy from '@/lib/helpers/fuzzy';
import generateCreatedByMeta from '@/lib/helpers/generateMeta';
import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import { usePostHog } from 'posthog-js/react';

import useAppState from '@/contexts/appState';
import useGroups, { useFollowedGroups } from '@/contexts/groups';
import useLocation from '@/contexts/location';
import useLocations from '@/contexts/locations';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import useCollection from '@/hooks/useCollection';

import DynamicInputRow from '@/components/formElements/DynamicInputRow';
import FormFooter from '@/components/formElements/styled/FormFooter';
import FormInputGroup from '@/components/formElements/styled/FormInputGroup';

import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import DangerCopy from '@/components/common/DangerCopy';
import Divider from '@/components/common/DividerTwo';
import Expanded from '@/components/common/Expanded';
import FadedCopy from '@/components/common/FadedCopy';
import FlexRow from '@/components/common/FlexRow';
import Margin from '@/components/common/Margin';
import Modal from '@/components/common/Modal';
import Spacer from '@/components/common/Spacer';

import Scrollable from '../Scrollable';

// Delete a group confirm modal
const ConfirmDelete = ({ group, handleCancel }: { group: Doc<Group>; handleCancel(): void }) => {
  const posthog = usePostHog();

  const { organizationId } = useAppState();

  const [locations] = useLocations();

  const [confirmInput, setConfirmInput] = useState('');

  const [updatesToDelete] = useCollection(
    db.collection(`organizations/${organizationId}/updates`).where('groups', '==', [group.id])
  );
  const [tasksToDelete] = useCollection(
    db.collection(`organizations/${organizationId}/tasks`).where('groups', '==', [group.id])
  );

  return (
    <>
      <Scrollable padding='32px 32px 0' endBuffer='48px'>
        {/* Primary warning */}
        <DangerCopy>
          Deleting this group will also
          <strong> remove all updates and tasks from {group.name}</strong>
          {locations && locations.length > 1 && ` across every location`}.
        </DangerCopy>
        <Spacer height='16px' />
        {/* What will actually be deleted warning */}
        {(!!updatesToDelete?.length || !!tasksToDelete?.length) && (
          <>
            <FadedCopy>
              <strong>
                {pluralize({
                  root: '{x} update',
                  count: updatesToDelete?.length ?? 0,
                })}
              </strong>
              {!!updatesToDelete?.length && !!tasksToDelete?.length && ' and '}
              <strong>
                {pluralize({
                  root: '{x} task',
                  count: tasksToDelete?.length ?? 0,
                })}
              </strong>{' '}
              assigned only to this group will be deleted entirely.
            </FadedCopy>
            <Spacer height='32px' />
          </>
        )}

        {/* "No turning back" warning */}
        <strong>This action can not be undone.</strong>
        <Spacer height='32px' />
        <Divider />
        <Spacer height='32px' />

        {/* User confirms deletion by typing in name of group */}
        <FormInputGroup
          label={
            <>
              Type <em>&quot;{group.name}&quot;</em>&nbsp; to confirm deletion
            </>
          }
          name='deleteGroupConfirm'
          placeholder="Type group's name"
          value={confirmInput}
          onChange={event => {
            setConfirmInput(event.target.value);
          }}
        />
      </Scrollable>

      {/* Delete footer */}
      <FormFooter
        danger
        submitButton={
          <>
            <strong>Delete</strong> group
          </>
        }
        submitDisabled={confirmInput.trim().toLowerCase() !== group.name.trim().toLowerCase()}
        handleCancel={handleCancel}
        handleSubmit={() => {
          db.doc(group.docPath).delete();
          posthog?.capture('group_deleted');
          handleCancel();
        }}
      />
    </>
  );
};

// Component
const ManageGroupsSheet = () => {
  const posthog = usePostHog();

  const { isActive, isOrganizationAdmin, hasSingleGroup } = useAppState();

  const [user] = useUser();
  const [organization] = useOrganization();
  const [location] = useLocation();
  const [groups] = useGroups();
  const { followedGroups, unfollowedGroups } = useFollowedGroups();

  // Various CRUD states
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [editGroupId, setEditGroupId] = useState('');
  const [deleteGroup, setDeleteGroup] = useState<Doc<Group>>();

  // Add/remove followed group from user doc
  const followLocationGroup = useCallback(
    (groupId: string, follow?: boolean) => {
      if (user && organization && location) {
        const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
        const addGroup = arrayUnion(groupId);
        const removeGroup = arrayRemove(groupId);
        db.doc(user.docPath).update({
          [`following.${organization.id}.${location.id}.groups`]: follow ? addGroup : removeGroup,
        });
      }
    },
    [user, organization, location]
  );

  // Restrictions for group names
  const groupNameError = useCallback(
    (value: string, ignoreId?: string) => {
      // Test user-entered name for match with reserved keyword
      const matchesReservedKeyword = [
        'default',
        'all',
        'following',
        'ungrouped',
        'admins',
        'invitations',
      ].includes(fuzzy(value));
      if (matchesReservedKeyword) return `'${value}' is a reserved keyword`;
      // Test user-entered name for already existing match
      const matchesGroup = !!groups?.find(
        ({ id, name }) =>
          id !== ignoreId &&
          isFuzzyTextMatch(value, name, {
            ignoreSpecialCharacters: true,
          })
      );
      if (matchesGroup) return 'Group names must not match';
    },
    [groups]
  );

  // Memoized manage group row
  const ManageGroupRow = useCallback(
    (group: Doc<Group>, isFollowing: boolean) => (
      <Fragment key={group.id}>
        <FlexRow>
          <Expanded>
            {isOrganizationAdmin ? (
              <DynamicInputRow
                name={'group-' + group.id}
                required
                maxLength={85}
                error={value => groupNameError(value, group.id)}
                value={group.name}
                documentPath={group.docPath}
                fieldName='name'
                includeUpdatedByMeta
                onDelete={
                  !hasSingleGroup
                    ? () => {
                        setDeleteGroup(group);
                      }
                    : undefined
                }
                onCancel={() => setEditGroupId('')}
                onBeginEdit={() => setEditGroupId(group.id)}
                onSubmit={() => {
                  posthog?.capture('group_edited');
                }}
              />
            ) : (
              <Margin margin='0 18px 0 0'>
                <strong>{group.name}</strong>
              </Margin>
            )}
          </Expanded>
          {/* Follow/unfollow button (if not editing) */}
          {editGroupId !== group.id && (
            <PrimaryButton
              small
              outlined={!isFollowing}
              onClick={() => {
                followLocationGroup(group.id, !isFollowing);
                posthog?.capture(isFollowing ? 'group_unfollowed' : 'group_followed', {
                  followed_groups_count: (followedGroups?.length ?? 1) + (isFollowing ? -1 : 1),
                });
              }}
            >
              {isFollowing ? 'Following' : 'Follow this group'}
            </PrimaryButton>
          )}
        </FlexRow>
        <Spacer height='8px' />
      </Fragment>
    ),
    [
      isOrganizationAdmin,
      hasSingleGroup,
      editGroupId,
      groupNameError,
      posthog,
      followLocationGroup,
      followedGroups?.length,
    ]
  );

  return (
    <>
      <Scrollable padding='24px 24px 0'>
        {/* Each followed group */}
        {followedGroups.map(group => ManageGroupRow(group, true))}
        {!!followedGroups.length && <Spacer height='24px' />}

        {/* Each unfollowed group */}
        {unfollowedGroups.map(group => ManageGroupRow(group, false))}
        <Spacer height='24px' />
        <Divider />
        <Spacer height='24px' />

        {/* Add group - form */}
        {isAddingGroup && (
          <DynamicInputRow
            name='newGroup'
            placeholder='New group'
            required
            maxLength={85}
            editOnMount
            error={value => groupNameError(value)}
            value=''
            onCancel={() => setIsAddingGroup(false)}
            onSubmit={value => {
              // Close form
              setIsAddingGroup(false);

              // Add to DB
              if (user && organization && location) {
                const newGroup: Group = {
                  meta: generateCreatedByMeta(user),
                  name: value.trim(),
                };
                db.doc(organization.docPath).collection('groups').add(newGroup);
                posthog?.capture('group_created', { groups_count: (groups?.length ?? 1) + 1 });
              }
            }}
          />
        )}

        {/* Add group button ( if admin and not already adding ) */}
        {isOrganizationAdmin && !isAddingGroup && (
          <SecondaryButton
            leadingIcon='add_circle'
            disabled={!isActive || (!!groups && groups.length >= MAX_GROUPS)}
            onClick={() => setIsAddingGroup(true)}
          >
            Add <strong>group</strong>
          </SecondaryButton>
        )}
      </Scrollable>
      <Modal heading='Delete group' handleClose={() => setDeleteGroup(undefined)}>
        {deleteGroup && (
          <ConfirmDelete group={deleteGroup} handleCancel={() => setDeleteGroup(undefined)} />
        )}
      </Modal>
    </>
  );
};
export default ManageGroupsSheet;
