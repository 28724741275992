import { useEffect } from 'react';

import { usePostHog } from 'posthog-js/react';

import useAppState, { useSetAppState } from '@/contexts/appState';

import { ReactSelectSecondary } from '@/components/formElements/ReactSelect';

const TASKS_FILTERS_BY = ['My', 'Assigned', 'Unassigned'] as const;
export type TasksFiltersBy = (typeof TASKS_FILTERS_BY)[number];

// Component
const TasksFilter = () => {
  const posthog = usePostHog();

  // App state
  const setAppState = useSetAppState();
  const { locationId, filterTasksBy } = useAppState();

  // Reset filters every time location changes ( and on initial load )
  useEffect(() => {
    setAppState({ filterTasksBy: 'My' });
  }, [locationId, setAppState]);

  return (
    <ReactSelectSecondary
      small
      isSearchable={false}
      options={TASKS_FILTERS_BY.map(filter => ({
        label: `${filter} tasks`,
        value: filter,
      }))}
      value={{
        label: (
          <>
            <strong>{TASKS_FILTERS_BY.find(filter => filter === filterTasksBy) || 'My'}</strong>{' '}
            tasks
          </>
        ) as React.ReactNode,
        value: TASKS_FILTERS_BY.find(filter => filter === filterTasksBy) || 'My',
      }}
      onChange={selection => {
        selection?.value &&
          setAppState({
            filterTasksBy: selection.value,
            groupId: null,
            contentFilter: 'all',
          });
        posthog?.capture('tasks_filtered', { filter: selection?.value });
      }}
    />
  );
};
export default TasksFilter;
