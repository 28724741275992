import { NavLink, useNavigate, useLocation as useRouteLocation } from 'react-router-dom';
import styled from 'styled-components';

import { fbAnalytics, fbAuth } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { signOut } from 'firebase/auth';

import { usePostHog } from 'posthog-js/react';
import CSSTransition from 'react-transition-group/CSSTransition';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useOrganization from '@/contexts/organization';
import useOrganizations from '@/contexts/organizations';

import PrimaryButton, { PrimaryButtonLink } from '@/components/common/Buttons';
import Divider from '@/components/common/DividerTwo';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import Padding from '@/components/common/Padding';
import PageBlocker from '@/components/common/PageBlocker';
import Spacer from '@/components/common/Spacer';

// Styles
const DropdownPanel = styled.div`
  pointer-events: auto;
  display: block;
  position: absolute;
  overflow-y: auto;

  top: 0;
  right: 16px;
  min-width: 256px;
  max-width: 512px;
  max-height: 100%;

  @media (max-width: 424px) {
    min-width: auto;
    right: 8px;
    width: calc(100% - 16px);
  }

  border-radius: 8px 4px 8px 8px;
  box-shadow: ${props => props.theme.shadow400};

  /* For animation */
  transform-origin: right top;
  & > * {
    background-color: ${props => props.theme.sheetBackgroundColor};
    overflow: hidden;
    &:first-child {
      border-radius: 8px 4px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }
  }
`;
const DropdownPanelWrapper = styled.div`
  z-index: 215;
  user-select: none;
  pointer-events: none;

  position: absolute;
  top: 54px;
  /* IE10+ CSS styles go here */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    right: 0;
  }
  margin: 0 auto;
  width: 100%;
  width: 100vw;
  max-width: 1468px;
  height: calc(100% - 60px);

  @media (max-width: 1023px) {
    top: 68px;
    height: calc(100% - 72px);
  }

  /* Mount/unmount animation */
  &.enter ${DropdownPanel} {
    overflow: visible;
    border-radius: 0;

    opacity: 0;
    transform: scaleX(0.8) scaleY(0.01);

    & > * {
      /* Alternating for folding effect */
      &:nth-child(odd) {
        transform-origin: right top;
        transform: skewX(-12deg);
      }
      &:nth-child(even) {
        transform-origin: right bottom;
        transform: skewX(12deg);
        /* Fold shading */
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.25;
        }
      }
    }
  }
  /* Animation end */
  &.enter-active ${DropdownPanel} {
    transition:
      opacity 300ms,
      transform 350ms ${({ theme }) => theme.easeDecelerate};

    opacity: 1;
    transform: scaleX(1) scaleY(1);
    /* Unskew folding effect and fade out shading  */
    & > * {
      transition: transform 350ms ${({ theme }) => theme.easeStandard};

      &:nth-child(odd) {
        transform: skewX(0);
      }

      &:nth-child(even) {
        transform: skewX(0);
        &::after {
          transition: opacity 350ms ${({ theme }) => theme.easeStandard};
          opacity: 0;
        }
      }
    }
  }
  /* Exit is similar to entrance, only with different easing ( and slightly shorter ) */
  &.exit ${DropdownPanel} {
    overflow: visible;
    border-radius: 0;

    & > * {
      &:nth-child(even) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0;
        }
      }
    }
  }
  &.exit-active ${DropdownPanel} {
    pointer-events: none;
    opacity: 0;
    transform: scaleX(0.9) scaleY(0.01);
    transition:
      opacity 250ms 50ms,
      transform 300ms ${({ theme }) => theme.easeAccelerate};

    & > * {
      transition: transform 300ms ${({ theme }) => theme.easeSharp};

      /* Alternating for folding effect */
      &:nth-child(odd) {
        transform-origin: right top;
        transform: skewX(-16deg);
      }
      &:nth-child(even) {
        transform-origin: right bottom;
        transform: skewX(16deg);
        /* Fold shading */
        &::after {
          transition: opacity 300ms ${({ theme }) => theme.easeSharp};
          opacity: 0.25;
        }
      }
    }
  }
`;
const DropdownItem = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 12px 16px;

  color: ${props => props.theme.textTertiary};
  font-size: 18px;
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: ${props => props.theme.textSecondary};
    background-color: ${props => props.theme.hoverFade};
  }

  i {
    margin-right: 8px;
    color: ${props => props.theme.textFaded};
  }
  i:nth-child(2) {
    margin-left: 12px;
    color: ${props => props.theme.avatarText};
  }

  &.active {
    font-weight: bold;
    color: ${props => props.theme.textSecondary};
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: ${props => props.theme.linkColor};
    }
    i {
      color: ${props => props.theme.linkColor};
    }
  }
`;
const OrganizationItem = styled.button`
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 8px 48px 8px 19px;

  color: ${props => props.theme.textTertiary};
  font-size: 20px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: ${props => props.theme.textSecondary};
    background-color: ${props => props.theme.hoverFade};
  }

  &.active {
    font-weight: bold;
    color: ${props => props.theme.linkColor};
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: ${props => props.theme.linkColor};
    }
  }
`;
const TertiaryDropdownItem = styled.button`
  display: block;
  width: 100%;
  padding: 12px 16px;

  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.grey300};
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: ${props => props.theme.textSecondary};
    background-color: ${props => props.theme.hoverFade};
  }
`;

// Component
const DropdownMenu = () => {
  const navigate = useNavigate();
  const routeLocation = useRouteLocation();

  const { isDropdownOpen, isSuperAdmin, isTrialing, isOrganizationAdmin, organizationId } =
    useAppState();
  const setAppState = useSetAppState();

  const [organization] = useOrganization();
  const [organizations] = useOrganizations();
  const posthog = usePostHog();

  return (
    <>
      <PageBlocker
        show={isDropdownOpen}
        coverAll={false}
        onClick={() => setAppState({ isDropdownOpen: false })}
      />
      <CSSTransition in={isDropdownOpen} timeout={350} mountOnEnter unmountOnExit>
        <DropdownPanelWrapper>
          <DropdownPanel onClick={() => setAppState({ isDropdownOpen: false })}>
            {/* Switch organizations list  */}
            {!!organizations &&
              organizations.length > 1 &&
              organizations.map((organization, index) => {
                return (
                  <div key={organization.id}>
                    {/* Spacer before first button */}
                    {index === 0 && <Spacer height='16px' />}
                    <OrganizationItem
                      className={organization.id === organizationId ? 'active' : ''}
                      onClick={() => {
                        if (organizationId !== organization.id) {
                          setAppState({
                            organizationId: organization.id,
                            locationId: null,
                            typesensePeopleKey: null,
                            typesenseUpdatesKey: null,
                          });
                          // If we're on a person's page, go back to people
                          // so we don't see a "missing" person from the prev org
                          routeLocation.pathname.includes('/person/') &&
                            navigate('/people', { replace: true });
                        }
                      }}
                    >
                      {organization.profile.name}
                    </OrganizationItem>
                    {/* Spacer and divider after last button */}
                    {index === organizations.length - 1 && (
                      <>
                        <Spacer height='16px' />
                        <Divider />
                      </>
                    )}
                  </div>
                );
              })}

            {/* Super admin page link ( w/ spacer before and spacer/diver after ) */}
            {isSuperAdmin && (
              <div>
                <Spacer height='8px' />
                <DropdownItem to='/admin'>
                  <Icon icon='verified_user' />
                  Admin dashboard
                </DropdownItem>
                <Spacer height='8px' />
                <Divider />
              </div>
            )}

            {/* Schedule a call modal opener - under certain conditions ( w/ divider after ) */}
            {isOrganizationAdmin &&
              organization?._integration === null &&
              organization.profile.setupMeetingStatus === 'dismissed' &&
              isTrialing && (
                <div>
                  <Padding padding='12px'>
                    <PrimaryButton
                      small
                      outlined
                      block
                      leadingIcon='event'
                      onClick={() => setAppState({ isScheduleACallModalOpen: true })}
                    >
                      <strong>Schedule a setup call</strong>
                    </PrimaryButton>
                    <Spacer height='8px' />
                    {['Breeze', 'Planning Center', 'ShulCloud'].includes(
                      organization?.profile.cms
                    ) ? (
                      <PrimaryButtonLink small block to='/account/integrations'>
                        <Icon icon='sync' iconSize='20px' />
                        <Expanded>
                          <strong>Sync with {organization?.profile.cms}</strong>
                        </Expanded>
                      </PrimaryButtonLink>
                    ) : (
                      <PrimaryButtonLink small block to='/import'>
                        <Icon icon='system_update_alt' iconSize='20px' />
                        <Expanded>
                          <strong>Import your data</strong>
                        </Expanded>
                      </PrimaryButtonLink>
                    )}
                  </Padding>
                  <Divider />
                </div>
              )}

            {/* Main nav items */}
            {/* User profile ( w/ spacer before )*/}
            <div>
              <Spacer height='16px' />
              <DropdownItem to='/profile/user' data-intercom-target='Profile page nav link'>
                <Icon icon='account_box' />
                User profile
              </DropdownItem>
            </div>
            {/* Users ( w/ conditional space after )*/}
            <div>
              <DropdownItem to='/users' data-intercom-target='Users page nav link'>
                <Icon icon='supervised_user_circle' />
                My team
              </DropdownItem>
            </div>

            {/* Admin items */}
            {isOrganizationAdmin && (
              <>
                {/* Account */}
                <DropdownItem to='/account' data-intercom-target='Account page nav link'>
                  <Icon icon='widgets' />
                  Account
                </DropdownItem>

                {/* Customizations ( w/ spacer after ) */}
                <DropdownItem
                  to='/customizations'
                  data-intercom-target='Customizations page nav link'
                >
                  <Icon icon='build' />
                  Customizations
                </DropdownItem>
              </>
            )}
            {/* Reports (w/ space after )*/}
            <div>
              <DropdownItem to='/reports' data-intercom-target='Reports page nav link'>
                <Icon icon='bar_chart' />
                Reports
              </DropdownItem>
              <Spacer height='12px' />
            </div>

            {/* Chat with us ( intercom launcher ) ( w/ divider and spacer before ) */}
            <div>
              <Divider />
              <Spacer height='12px' />
              <DropdownItem
                className='intercom-launcher'
                to='mailto:support@notebird.app'
                data-intercom-target='Chat nav link'
              >
                <Icon icon='chat_bubble_outline' />
                Chat with us
              </DropdownItem>
            </div>

            {/* Help ( w/ spacer and divider after ) */}
            <div>
              <DropdownItem
                to='https://guide.notebird.app'
                rel='noopener noreferrer'
                target='_blank'
                data-intercom-target='Help nav link'
              >
                <Icon icon='help_outline' />
                Help center
                <Icon icon='open_in_new' iconSize='18px' />
              </DropdownItem>
              <Spacer height='12px' />
              <Divider />
            </div>

            {/* Smaller end buttons */}
            {/* About ( w/ spacer before ) */}
            <div>
              <Spacer height='12px' />
              <TertiaryDropdownItem
                onClick={() => setAppState({ isAboutNotebirdModalOpen: true })}
                data-intercom-target='About modal button'
              >
                About Notebird
              </TertiaryDropdownItem>
            </div>
            {/* Log out ( w/ spacer after ) */}
            <div>
              <TertiaryDropdownItem
                onClick={() => {
                  signOut(fbAuth);
                  posthog?.capture('user_logout');
                  logEvent(fbAnalytics, 'logout');
                }}
                data-intercom-target='Log out button'
              >
                Log out
              </TertiaryDropdownItem>
              <Spacer height='8px' />
            </div>
          </DropdownPanel>
        </DropdownPanelWrapper>
      </CSSTransition>
    </>
  );
};
export default DropdownMenu;
