// Generated (in part) by:
// https://www.smooth-code.com/open-source/svgr/playground/
// (a project based on https://github.com/smooth-code/svgr)
import { type ComponentProps, memo } from 'react';
import styled from 'styled-components';

interface Props extends ComponentProps<'svg'> {
  prefix?: string;
  tint?: boolean;
  width?: string;
  height?: string;
  inverted?: boolean;
  className?: string;
}
const SvgComponent = memo(function NotebirdLogo({ prefix = '', tint, inverted, ...props }: Props) {
  return inverted ? (
    // Dark logo
    <svg viewBox='0 0 640 100.01' {...props}>
      <defs>
        <linearGradient
          id={prefix + 'notebird-logo-dark__a'}
          x1={592.44}
          y1={26.16}
          x2={645.61}
          y2={94.22}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={1} stopColor='#e4e7eb' />
        </linearGradient>
        <linearGradient
          id={prefix + 'notebird-logo-dark__b'}
          x1={516.5}
          y1={33.01}
          x2={556}
          y2={83.56}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__c'}
          x1={488.1}
          y1={1.75}
          x2={500.4}
          y2={17.49}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__d'}
          x1={474.4}
          y1={38.91}
          x2={513.9}
          y2={89.46}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__e'}
          x1={386.3}
          y1={12.44}
          x2={452.54}
          y2={97.23}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__f'}
          x1={228.65}
          y1={36.06}
          x2={274.24}
          y2={94.42}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__g'}
          x1={160.26}
          y1={22.74}
          x2={214.07}
          y2={91.62}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__h'}
          x1={93.31}
          y1={35.64}
          x2={138.08}
          y2={92.95}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo-dark__i'}
          x1={0.34}
          y1={29.55}
          x2={59.43}
          y2={105.19}
          xlinkHref={`#${prefix}notebird-logo-dark__a`}
        />
        <radialGradient
          id={prefix + 'notebird-logo-dark__j'}
          cx={314.29}
          cy={-1.38}
          r={120.96}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#2186eb' />
          <stop offset={0.12} stopColor='#197ee6' />
          <stop offset={0.42} stopColor='#096fde' />
          <stop offset={0.66} stopColor='#046adb' />
        </radialGradient>
      </defs>
      <title>Notebird</title>
      <g data-name='Layer 2'>
        <path
          d='M640 3v95.6h-15.47v-8.89a25 25 0 0 1-9.86 7.35 33.35 33.35 0 0 1-12.95 2.44 36.32 36.32 0 0 1-17.86-4.38 31.45 31.45 0 0 1-12.43-12.44 37.16 37.16 0 0 1-4.52-18.49 36.84 36.84 0 0 1 4.52-18.44 31.59 31.59 0 0 1 12.43-12.37A36.32 36.32 0 0 1 601.72 29a32.66 32.66 0 0 1 12.5 2.32 25.07 25.07 0 0 1 9.67 7V3zm-25.91 80.07a19.05 19.05 0 0 0 7.35-7.54 23.14 23.14 0 0 0 2.71-11.34 23.15 23.15 0 0 0-2.71-11.35 19.12 19.12 0 0 0-7.35-7.54 22 22 0 0 0-20.88 0 19.19 19.19 0 0 0-7.35 7.54 23.15 23.15 0 0 0-2.7 11.35 23.13 23.13 0 0 0 2.7 11.34 19.12 19.12 0 0 0 7.35 7.54 22 22 0 0 0 20.88 0z'
          fill={`url(#${prefix}notebird-logo-dark__a)`}
        />
        <path
          d='M560.47 29v15.33a21.15 21.15 0 0 0-3.74-.38q-9.4 0-14.69 5.48t-5.29 15.79V98.6h-16.11V29.77H536v10.05Q542.94 29 560.47 29z'
          fill={`url(#${prefix}notebird-logo-dark__b)`}
        />
        <path
          d='M486.87 16.56a9 9 0 0 1-3-6.89 9.06 9.06 0 0 1 3-6.9A10.32 10.32 0 0 1 494.22 0a10.62 10.62 0 0 1 7.34 2.64 8.53 8.53 0 0 1 3 6.64 9.75 9.75 0 0 1-2.9 7.16 10.07 10.07 0 0 1-7.41 2.9 10.33 10.33 0 0 1-7.38-2.78z'
          fill={`url(#${prefix}notebird-logo-dark__c)`}
        />
        <path fill={`url(#${prefix}notebird-logo-dark__d)`} d='M486.1 29.77h16.11V98.6H486.1z' />
        <path
          d='M454.58 33.38A31.62 31.62 0 0 1 467 45.75a36.83 36.83 0 0 1 4.51 18.44A37.16 37.16 0 0 1 467 82.68a31.47 31.47 0 0 1-12.44 12.44 36.54 36.54 0 0 1-18 4.38 33.36 33.36 0 0 1-13-2.44 25.1 25.1 0 0 1-9.86-7.35v8.89h-15.26V3h16.12v35.15a26.92 26.92 0 0 1 9.73-6.83A31.83 31.83 0 0 1 436.6 29a36.54 36.54 0 0 1 17.98 4.38zm-5.16 46.4q5.74-5.93 5.74-15.59t-5.74-15.6a19.48 19.48 0 0 0-14.63-5.93 20.77 20.77 0 0 0-10.44 2.64 19.1 19.1 0 0 0-7.35 7.54 23 23 0 0 0-2.71 11.35A23 23 0 0 0 417 75.53a19 19 0 0 0 7.34 7.54 20.77 20.77 0 0 0 10.44 2.64 19.44 19.44 0 0 0 14.64-5.93z'
          fill={`url(#${prefix}notebird-logo-dark__e)`}
        />
        <path
          d='M285.38 69.39h-54a18.66 18.66 0 0 0 7.41 12q6 4.45 14.89 4.45 11.34 0 18.69-7.48l8.63 9.98a29 29 0 0 1-11.73 8.38 46.52 46.52 0 0 1-36-1.68A32.33 32.33 0 0 1 220 82.47a35.45 35.45 0 0 1-4.71-18.23 36.07 36.07 0 0 1 4.58-18.12 32.42 32.42 0 0 1 12.7-12.56 37 37 0 0 1 18.3-4.51 36.15 36.15 0 0 1 18 4.44A31.57 31.57 0 0 1 281.2 46a38 38 0 0 1 4.44 18.62c0 1.12-.08 2.71-.26 4.77zm-47.62-22.88a19.21 19.21 0 0 0-6.51 12.18h39.05a19 19 0 0 0-6.3-12.11 19.2 19.2 0 0 0-13.15-4.64 19.41 19.41 0 0 0-13.09 4.57z'
          fill={`url(#${prefix}notebird-logo-dark__f)`}
        />
        <path
          d='M209.85 94.91a18.85 18.85 0 0 1-6.9 3.48 30.75 30.75 0 0 1-8.57 1.16q-11.34 0-17.53-5.93t-6.19-17.27V43.22h-11.34V30.33h11.34V14.61h16.12v15.72h18.43v12.89h-18.43V76c0 3.36.81 5.91 2.44 7.67s4 2.65 7.09 2.65a14.19 14.19 0 0 0 9-2.84z'
          fill={`url(#${prefix}notebird-logo-dark__g)`}
        />
        <path
          d='M96.8 95a32.84 32.84 0 0 1-13.08-12.53A35.44 35.44 0 0 1 79 64.24a35.13 35.13 0 0 1 4.7-18.18 33.07 33.07 0 0 1 13.1-12.5 39 39 0 0 1 18.82-4.51 39.28 39.28 0 0 1 18.95 4.51 33 33 0 0 1 13.09 12.5 35.23 35.23 0 0 1 4.7 18.18 35.54 35.54 0 0 1-4.7 18.23A32.8 32.8 0 0 1 134.57 95a39.28 39.28 0 0 1-18.95 4.51A39 39 0 0 1 96.8 95zm33.52-15.21q5.81-5.92 5.8-15.59t-5.8-15.6a19.71 19.71 0 0 0-14.7-5.93A19.49 19.49 0 0 0 101 48.64q-5.73 5.93-5.73 15.6T101 79.83a19.46 19.46 0 0 0 14.63 5.93 19.68 19.68 0 0 0 14.69-5.93z'
          fill={`url(#${prefix}notebird-logo-dark__h)`}
        />
        <path
          d='M59.94 36.65q7.86 7.6 7.86 22.56v39.44H51.69V61.27q0-9-4.25-13.6T35.32 43.1q-8.89 0-14 5.34t-5.21 15.41v34.8H0V29.82h15.34v8.89a25.31 25.31 0 0 1 10.05-7.22 36.28 36.28 0 0 1 13.67-2.44q13.02 0 20.88 7.6z'
          fill={`url(#${prefix}notebird-logo-dark__i)`}
        />
        <path
          d='M387.82 95.21c-9.22 3.7-23.41 4.8-33.6 4.8-21.8 0-35-8.77-42.12-20-11.84-18.59-6.89-38.15-5.87-44.15.91-5.37-22-.89-20.84-4.29S311.69 0 314.6 0c4.9 0 40.22 56.27 73.22 95.21z'
          fill={`url(#${prefix}notebird-logo-dark__j)`}
        />
      </g>
    </svg>
  ) : (
    // Primary Logo
    <svg viewBox='0 0 640 100.01' {...props}>
      <defs>
        <linearGradient
          id={prefix + 'notebird-logo__a'}
          x1={592.44}
          y1={26.16}
          x2={645.61}
          y2={94.22}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#29333d' />
          <stop offset={1} stopColor='#181f26' />
        </linearGradient>
        <linearGradient
          id={prefix + 'notebird-logo__b'}
          x1={516.5}
          y1={33.01}
          x2={556}
          y2={83.56}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__c'}
          x1={488.1}
          y1={1.75}
          x2={500.4}
          y2={17.49}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__d'}
          x1={474.4}
          y1={38.91}
          x2={513.9}
          y2={89.46}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__e'}
          x1={386.3}
          y1={12.44}
          x2={452.54}
          y2={97.23}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__f'}
          x1={228.65}
          y1={36.06}
          x2={274.24}
          y2={94.42}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__g'}
          x1={160.26}
          y1={22.74}
          x2={214.07}
          y2={91.62}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__h'}
          x1={93.31}
          y1={35.64}
          x2={138.08}
          y2={92.95}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <linearGradient
          id={prefix + 'notebird-logo__i'}
          x1={0.34}
          y1={29.55}
          x2={59.43}
          y2={105.19}
          xlinkHref={`#${prefix}notebird-logo__a`}
        />
        <radialGradient
          id={prefix + 'notebird-logo__j'}
          cx={314.29}
          cy={-1.38}
          r={120.96}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#2186eb' />
          <stop offset={0.12} stopColor='#197ee6' />
          <stop offset={0.42} stopColor='#096fde' />
          <stop offset={0.66} stopColor='#046adb' />
        </radialGradient>
      </defs>
      <title>{'Notebird'}</title>
      <g data-name='Layer 2'>
        <path
          d='M640 3v95.6h-15.47v-8.89a25 25 0 0 1-9.86 7.35 33.35 33.35 0 0 1-12.95 2.44 36.32 36.32 0 0 1-17.86-4.38 31.45 31.45 0 0 1-12.43-12.44 37.16 37.16 0 0 1-4.52-18.49 36.84 36.84 0 0 1 4.52-18.44 31.59 31.59 0 0 1 12.43-12.37A36.32 36.32 0 0 1 601.72 29a32.66 32.66 0 0 1 12.5 2.32 25.07 25.07 0 0 1 9.67 7V3zm-25.91 80.07a19.05 19.05 0 0 0 7.35-7.54 23.14 23.14 0 0 0 2.71-11.34 23.15 23.15 0 0 0-2.71-11.35 19.12 19.12 0 0 0-7.35-7.54 22 22 0 0 0-20.88 0 19.19 19.19 0 0 0-7.35 7.54 23.15 23.15 0 0 0-2.7 11.35 23.13 23.13 0 0 0 2.7 11.34 19.12 19.12 0 0 0 7.35 7.54 22 22 0 0 0 20.88 0z'
          fill={`url(#${prefix}notebird-logo__a)`}
        />
        <path
          d='M560.47 29v15.33a21.15 21.15 0 0 0-3.74-.38q-9.4 0-14.69 5.48t-5.29 15.79V98.6h-16.11V29.77H536v10.05Q542.94 29 560.47 29z'
          fill={`url(#${prefix}notebird-logo__b)`}
        />
        <path
          d='M486.87 16.56a9 9 0 0 1-3-6.89 9.06 9.06 0 0 1 3-6.9A10.32 10.32 0 0 1 494.22 0a10.62 10.62 0 0 1 7.34 2.64 8.53 8.53 0 0 1 3 6.64 9.75 9.75 0 0 1-2.9 7.16 10.07 10.07 0 0 1-7.41 2.9 10.33 10.33 0 0 1-7.38-2.78z'
          fill={`url(#${prefix}notebird-logo__c)`}
        />
        <path fill={`url(#${prefix}notebird-logo__d)`} d='M486.1 29.77h16.11V98.6H486.1z' />
        <path
          d='M454.58 33.38A31.62 31.62 0 0 1 467 45.75a36.83 36.83 0 0 1 4.51 18.44A37.16 37.16 0 0 1 467 82.68a31.47 31.47 0 0 1-12.44 12.44 36.54 36.54 0 0 1-18 4.38 33.36 33.36 0 0 1-13-2.44 25.1 25.1 0 0 1-9.86-7.35v8.89h-15.26V3h16.12v35.15a26.92 26.92 0 0 1 9.73-6.83A31.83 31.83 0 0 1 436.6 29a36.54 36.54 0 0 1 17.98 4.38zm-5.16 46.4q5.74-5.93 5.74-15.59t-5.74-15.6a19.48 19.48 0 0 0-14.63-5.93 20.77 20.77 0 0 0-10.44 2.64 19.1 19.1 0 0 0-7.35 7.54 23 23 0 0 0-2.71 11.35A23 23 0 0 0 417 75.53a19 19 0 0 0 7.34 7.54 20.77 20.77 0 0 0 10.44 2.64 19.44 19.44 0 0 0 14.64-5.93z'
          fill={`url(#${prefix}notebird-logo__e)`}
        />
        <path
          d='M285.38 69.39h-54a18.66 18.66 0 0 0 7.41 12q6 4.45 14.89 4.45 11.34 0 18.69-7.48l8.63 9.98a29 29 0 0 1-11.73 8.38 46.52 46.52 0 0 1-36-1.68A32.33 32.33 0 0 1 220 82.47a35.45 35.45 0 0 1-4.71-18.23 36.07 36.07 0 0 1 4.58-18.12 32.42 32.42 0 0 1 12.7-12.56 37 37 0 0 1 18.3-4.51 36.15 36.15 0 0 1 18 4.44A31.57 31.57 0 0 1 281.2 46a38 38 0 0 1 4.44 18.62c0 1.12-.08 2.71-.26 4.77zm-47.62-22.88a19.21 19.21 0 0 0-6.51 12.18h39.05a19 19 0 0 0-6.3-12.11 19.2 19.2 0 0 0-13.15-4.64 19.41 19.41 0 0 0-13.09 4.57z'
          fill={`url(#${prefix}notebird-logo__f)`}
        />
        <path
          d='M209.85 94.91a18.85 18.85 0 0 1-6.9 3.48 30.75 30.75 0 0 1-8.57 1.16q-11.34 0-17.53-5.93t-6.19-17.27V43.22h-11.34V30.33h11.34V14.61h16.12v15.72h18.43v12.89h-18.43V76c0 3.36.81 5.91 2.44 7.67s4 2.65 7.09 2.65a14.19 14.19 0 0 0 9-2.84z'
          fill={`url(#${prefix}notebird-logo__g)`}
        />
        <path
          d='M96.8 95a32.84 32.84 0 0 1-13.08-12.53A35.44 35.44 0 0 1 79 64.24a35.13 35.13 0 0 1 4.7-18.18 33.07 33.07 0 0 1 13.1-12.5 39 39 0 0 1 18.82-4.51 39.28 39.28 0 0 1 18.95 4.51 33 33 0 0 1 13.09 12.5 35.23 35.23 0 0 1 4.7 18.18 35.54 35.54 0 0 1-4.7 18.23A32.8 32.8 0 0 1 134.57 95a39.28 39.28 0 0 1-18.95 4.51A39 39 0 0 1 96.8 95zm33.52-15.21q5.81-5.92 5.8-15.59t-5.8-15.6a19.71 19.71 0 0 0-14.7-5.93A19.49 19.49 0 0 0 101 48.64q-5.73 5.93-5.73 15.6T101 79.83a19.46 19.46 0 0 0 14.63 5.93 19.68 19.68 0 0 0 14.69-5.93z'
          fill={`url(#${prefix}notebird-logo__h)`}
        />
        <path
          d='M59.94 36.65q7.86 7.6 7.86 22.56v39.44H51.69V61.27q0-9-4.25-13.6T35.32 43.1q-8.89 0-14 5.34t-5.21 15.41v34.8H0V29.82h15.34v8.89a25.31 25.31 0 0 1 10.05-7.22 36.28 36.28 0 0 1 13.67-2.44q13.02 0 20.88 7.6z'
          fill={`url(#${prefix}notebird-logo__i)`}
        />
        <path
          d='M387.82 95.21c-9.22 3.7-23.41 4.8-33.6 4.8-21.8 0-35-8.77-42.12-20-11.84-18.59-6.89-38.15-5.87-44.15.91-5.37-22-.89-20.84-4.29S311.69 0 314.6 0c4.9 0 40.22 56.27 73.22 95.21z'
          fill={`url(#${prefix}notebird-logo__j)`}
        />
      </g>
    </svg>
  );
});

const NotebirdLogo = styled(SvgComponent).attrs(props => ({
  inverted: props.theme.isDark,
}))`
  filter: ${({ tint, theme }) => (tint ? theme.imgFilter : 'none')};
`;

export default NotebirdLogo;
