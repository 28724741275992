import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Doc from '@/classes/Doc';
import Person from '@/classes/Person';

import { db } from '@/lib/firebase';

import { makeSentenceList, pluralize } from '@/lib/helpers';

import { usePostHog } from 'posthog-js/react';

import Scrollable from '@/components/layout/Scrollable';

import FormFooter from '@/components/formElements/styled/FormFooter';
import FormInputGroup from '@/components/formElements/styled/FormInputGroup';

import DangerCopy from '@/components/common/DangerCopy';
import Divider from '@/components/common/DividerTwo';
import FadedCopy from '@/components/common/FadedCopy';
import Spacer from '@/components/common/Spacer';

// Delete a person confirm segment ( for modal )
export default function DeletePersonSegment({
  person,
  handleCancel,
  updatesCount,
  milestonesCount,
  tasksCount,
}: {
  person: Doc<Person>;
  handleCancel(): void;
  updatesCount: number;
  milestonesCount: number;
  tasksCount: number;
}) {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [confirmInput, setConfirmInput] = useState('');

  const updatesCopy = pluralize({ root: '{x} update', count: updatesCount, showZero: true });
  const milestonesCopy = pluralize({
    root: '{x} milestone',
    count: milestonesCount,
    showZero: true,
  });
  const tasksCopy = pluralize({ root: '{x} task', count: tasksCount, showZero: true });

  return (
    <>
      <Scrollable padding='32px 32px 0' endBuffer='48px'>
        {/* Primary warning */}
        <DangerCopy>
          Deleting this person will also
          <strong> remove all of their updates, milestones, and tasks</strong>
        </DangerCopy>
        <Spacer height='16px' />
        {/* What will actually be deleted warning */}
        <FadedCopy>
          {makeSentenceList([
            <strong key={'updates'}>{updatesCopy}</strong>,
            <strong key={'milestones'}>{milestonesCopy}</strong>,
            <strong key={'tasks'}>{tasksCopy}</strong>,
          ])}{' '}
          will be deleted entirely.
        </FadedCopy>
        <Spacer height='32px' />

        {/* "No turning back" warning */}
        <strong>This action can not be undone.</strong>
        <Spacer height='32px' />
        <Divider />
        <Spacer height='32px' />

        {/* User confirms deletion by typing in name of group */}
        <FormInputGroup
          label={
            <>
              Type <em>&quot;{person.profile.name.full}&quot;</em>&nbsp; to confirm deletion
            </>
          }
          name='deletePersonConfirm'
          placeholder="Type person's name"
          value={confirmInput}
          onChange={event => {
            setConfirmInput(event.target.value);
          }}
        />
      </Scrollable>

      {/* Delete footer */}
      <FormFooter
        danger
        submitButton={
          <>
            <strong>Delete</strong> person
          </>
        }
        submitDisabled={
          confirmInput.trim().toLowerCase() !== person.profile.name.full.trim().toLowerCase()
        }
        handleCancel={handleCancel}
        handleSubmit={() => {
          db.doc(person.docPath).delete();
          posthog?.capture('person_deleted', {
            has_photo: !!person.profile.photo,
            groups_count: person.groups.length,
            notes_length: person.notes.length,
            gender: person.gender,
            is_integration_linked: !!person.integration,
            phones_count: person.phones.length,
            emails_count: person.emails.length,
            addresses_count: person.addresses.length,
            has_birthday: !!person.birthday,
            has_relationship_status: !!person.relationshipStatus,
            has_anniversary: !!person.anniversary,
            has_custom_id: !!person.customId,
            has_join_date: !!person.joinDate,
            relationships_count: person.relationships.length,
          });
          handleCancel();
          navigate('/people', { replace: true });
        }}
      />
    </>
  );
}
