import { ComponentProps } from 'react';
import styled from 'styled-components';

import TextareaAutosize from 'react-textarea-autosize';

import FormInput from './FormInput';

interface Props extends ComponentProps<typeof TextareaAutosize> {
  dense?: boolean;
  hasError?: boolean;
  minRows?: number;
  maxRows?: number;
}

/* const FormTextArea = styled(FormInput).attrs({ as: TextareaAutosize, async: true })` */
const FormTextArea = styled(FormInput).attrs<Props>(({ hasError, ...rest }) => ({
  as: TextareaAutosize,
  ...rest,
}))`
  white-space: pre-wrap;
  padding: 12px;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;

  resize: vertical;
`;
export default FormTextArea;
