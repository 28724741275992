import { Link, useParams } from 'react-router-dom';

import usePageView from '@/hooks/usePageView';

import MobilePageHeader, { MobileButtonGroup } from '@/components/layout/MobilePageHeader';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import MilestonesSheet from './MilestonesSheet';
import UpdatesSheet from './UpdatesSheet';

// Component
const ActivityPage = () => {
  // Register page view
  usePageView({ title: 'Activity | Notebird' });

  // When on mobile we need to know which sheet is active
  const { sheet } = useParams();
  const showMilestones = sheet === 'milestones';
  const showUpdates = !showMilestones;

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader>
        <MobileButtonGroup>
          <Link to='/activity' className={showUpdates ? 'active' : ''}>
            Updates
          </Link>
          <Link to='/activity/milestones' className={showMilestones ? 'active' : ''}>
            Milestones
          </Link>
        </MobileButtonGroup>
      </MobilePageHeader>

      {/* Updates on left sheet */}
      <Sheet position='left' className={showUpdates ? '' : 'hidden-mobile'}>
        <UpdatesSheet />
      </Sheet>

      {/* Milestones on right sheet */}
      <Sheet position='right' className={showMilestones ? '' : 'hidden-mobile'}>
        <MilestonesSheet />
      </Sheet>
    </SheetsWrapper>
  );
};
export default ActivityPage;
