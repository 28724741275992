// Core
import styled from 'styled-components';
import { animated, useTransition } from '@react-spring/web';
import { SecondaryButton } from '@/components/common/Buttons';

// Styles
const StyledNoticeBar = styled.div<{ danger: boolean }>`
  z-index: 212;

  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  width: 100vw;

  background-color: ${({ danger, theme }) => (danger ? theme.danger000 : theme.primary000)};
  border-bottom: 1px solid ${({ danger, theme }) => (danger ? theme.danger100 : theme.primary100)};
  box-shadow: ${({ theme }) => theme.shadow200};

  button i {
    color: ${({ danger, theme }) => (danger ? theme.danger700 : theme.primary700)} !important;
  }
`;
const NoticeContent = styled.div<{ danger: boolean }>`
  flex: 1;
  padding: 12px 0px 12px 46px;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: center;
  color: ${({ theme }) => theme.grey900};

  a {
    font-weight: bold;
    text-decoration: underline;
    color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.primary500)};
  }

  @media (max-width: 1023px) {
    text-align: left;
    padding: 8px 0px 8px 12px;
  }
`;

// Component
interface Props {
  children: React.ReactNode;
  show: boolean;
  handleClose?(): void;
  danger?: boolean;
}
const NoticeBar = ({ show, handleClose, danger = false, children }: Props) => {
  const noticeTransition = useTransition(show, {
    from: {
      opacity: 0,
      marginTop: -42,
    },
    enter: {
      opacity: 1,
      marginTop: 0,
    },
    leave: {
      opacity: 0,
      marginTop: -42,
    },
    config: {
      tension: 200,
    },
  });

  return (
    <>
      {noticeTransition(
        (style, item, t, i) =>
          item && (
            <animated.div
              key={i}
              style={{
                ...style,
                width: '100%',
              }}
            >
              <StyledNoticeBar danger={danger}>
                <NoticeContent danger={danger}>{children}</NoticeContent>
                {!!handleClose && (
                  <SecondaryButton
                    className='close'
                    leadingIcon='close'
                    dull
                    onClick={event => {
                      event.preventDefault();
                      handleClose();
                    }}
                  />
                )}
              </StyledNoticeBar>
            </animated.div>
          )
      )}
    </>
  );
};

export default NoticeBar;
