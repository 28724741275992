import { createContext, useContext } from 'react';
// Classes
import CurrentRelease from '@/classes/CurrentRelease';
// Hooks
import useDoc from '@/hooks/useDoc';

// Context ( with hook shortcut )
// const fetchingCurrentRelease: [CurrentRelease, boolean] = [new CurrentRelease(), true];
const currentReleaseContext = createContext(new CurrentRelease());
const useCurrentRelease = () => useContext(currentReleaseContext);
export default useCurrentRelease;

// Context definition w/ provider
export const CurrentReleaseProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentRelease] = useDoc<CurrentRelease>('_config/currentRelease');
  return (
    <currentReleaseContext.Provider value={currentRelease || new CurrentRelease()}>
      {children}
    </currentReleaseContext.Provider>
  );
};
