// Core
import { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from '@react-spring/web';
// Form elements
import FormScaffold from '../formElements/FormScaffold';
// Common components
import Padding from './Padding';
import PageBlocker from './PageBlocker';

// Styles
const ModalWrapper = styled.div<{ position: 'fixed' | 'absolute' }>`
  z-index: 10001;

  position: ${({ position }) => position};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const AnimatedModalSheet = styled(animated.div)`
  z-index: 200;

  overflow: hidden;
  width: 100%;
  max-width: 512px;
  min-height: 256px;
  max-height: calc(100% - 32px);
  margin-top: -12px;
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.sheetBackgroundColor};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow400};
  backface-visibility: hidden;

  font-size: 18px;

  @media (max-width: 536px) {
    max-width: calc(100% - 24px);
  }
`;

// Core modal component
interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  handleClose(): void;
  // The option for absolute position is necessary because a safari bug
  // doesn't allow for these to show outside parent w/ overflow:hidden
  // as in the case of modals appearing inside floating sheets
  // https://bugs.webkit.org/show_bug.cgi?id=160953
  position?: 'fixed' | 'absolute';
}
const Modal = ({ show, handleClose, position = 'fixed', children }: ModalProps) => {
  const modalTransition = useTransition(show, {
    from: {
      opacity: 0,
      transform: 'perspective(512px) scale(0.8) translateY(0%) rotateZ(0deg)',
    },
    enter: {
      opacity: 1,
      transform: 'perspective(512px) scale(1) translateY(0%) rotateZ(0deg)',
    },
    leave: {
      opacity: 0,
      transform: 'perspective(512px) scale(0.95) translateY(33%) rotateZ(3deg)',
    },
    config: {
      tension: 200,
    },
  });

  return (
    <>
      <PageBlocker
        show={show}
        onClick={event => {
          event.preventDefault();
          handleClose();
        }}
      />
      {modalTransition(
        (style, item, t, i) =>
          item && (
            <ModalWrapper key={i} position={position}>
              <AnimatedModalSheet key={i} style={style}>
                {children}
              </AnimatedModalSheet>
            </ModalWrapper>
          )
      )}
      {/* {modalTransition.map(
        ({ item, key, props }) =>
          item && (
            <ModalWrapper key={key} position={position}>
              <AnimatedModalSheet key={key} style={props}>
                {children}
              </AnimatedModalSheet>
            </ModalWrapper>
          )
      )} */}
    </>
  );
};

export default Modal;

// Form modal
interface FormModalProps {
  children: React.ReactNode;
  formTitle?: string;
  danger?: boolean;
  show: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  handleSubmit(event?: FormEvent<HTMLFormElement>): void;
  handleCancel(): void;
  handleBack?(): void;
  handleDelete?(): void;
  hideCancel?: boolean;
  submitBtn?: React.ReactNode | string;
  position?: 'fixed' | 'absolute';
}
export const FormModal = ({ show, position, ...rest }: FormModalProps) => {
  return (
    <Modal show={show} handleClose={rest.handleCancel} position={position}>
      <FormScaffold {...rest} />
    </Modal>
  );
};

// Delete form modal
const StyledDeleteModal = styled(FormModal)`
  .warning {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.25;
    color: ${props => props.theme.danger400};
    strong {
      white-space: nowrap;
    }
  }
  .notUndone {
    margin-bottom: 32px;
    font-weight: bold;
  }
  .extra {
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.theme.textFaded};
  }
`;
export const DeleteWarning = styled.div`
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 1.25;
  color: ${props => props.theme.danger400};
  /* strong {
    white-space: nowrap;
  } */
`;
export const DeleteCannotBeUndone = styled.div.attrs(({ children }) => ({
  children: children ? children : 'This action can not be undone.',
}))`
  margin-bottom: 32px;
  font-weight: bold;
`;
export const DeleteExtra = styled.div`
  font-size: 14px;
  line-height: 1.4;
  /* font-weight: bold; */
  color: ${props => props.theme.textFaded};
`;
export const DeleteList = styled.ul`
  margin-bottom: 24px;
  line-height: 1.4;
  color: ${props => props.theme.textTertiary};
`;

interface DeleteModalProps {
  children?: React.ReactNode;
  name?: string;
  show: boolean;
  handleDelete(): void;
  handleCancel(): void;
  disabled?: boolean;
}
export const DeleteModal = ({
  name = '',
  show,
  handleDelete,
  handleCancel,
  disabled = false,
  children = (
    <>
      <DeleteWarning>
        Are you sure you want to <strong>delete this {name}</strong>?
      </DeleteWarning>
      <DeleteCannotBeUndone />
    </>
  ),
  ...props
}: DeleteModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <StyledDeleteModal
      formTitle={'Delete ' + name}
      isSubmitting={isSubmitting}
      danger
      show={show}
      disabled={disabled}
      handleSubmit={async event => {
        event && event.preventDefault();
        setIsSubmitting(true);
        await handleDelete();
        await handleCancel();
        setIsSubmitting(false);
      }}
      handleCancel={handleCancel}
      submitBtn={<strong>DELETE</strong>}
      {...props}
    >
      <Padding padding='32px 32px 48px'>{children}</Padding>
    </StyledDeleteModal>
  );
};
