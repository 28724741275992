import { useRef, useCallback, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { formikSubmitIsDisabledContext } from '@/hooks/useFormikSubmitIsDisabled';

// Props
interface Props<T>
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  handleCancel?: () => void;
  disableSubmit?(values: T): boolean;
  disableEnterSubmit?: boolean;
  intercomTarget?: string;
}

// Form component
export default function FormikForm<T>({
  handleCancel,
  disableSubmit,
  disableEnterSubmit = false,
  intercomTarget = 'Formik form',
  ...rest
}: Props<T>) {
  const { isSubmitting, submitForm, values } = useFormikContext<T>();

  const formRef = useRef<HTMLFormElement>(null);
  const submitIsDisabled = disableSubmit ? disableSubmit(values) : false;
  const handleHotKeys = useCallback(
    (event: KeyboardEvent) => {
      const form = formRef.current;
      if (form) {
        // Command enter (submit-if not disabled)
        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
          !isSubmitting && !submitIsDisabled && submitForm();
        }
        // Escape (close)
        if (event.key === 'Escape') {
          event.preventDefault();
          handleCancel && handleCancel();
        }
        // Enter key (disable submit if preference set AND not textarea)
        if (
          event.key === 'Enter' &&
          disableEnterSubmit &&
          (event.target as Element)?.nodeName !== 'TEXTAREA'
        ) {
          event.preventDefault();
          return false;
        }
      }
    },
    [disableEnterSubmit, isSubmitting, submitIsDisabled, submitForm, handleCancel]
  );

  // Setup and destroy key command listeners
  useEffect(() => {
    const form = formRef.current;
    if (form) {
      form.addEventListener('keydown', handleHotKeys);
      return () => {
        form.removeEventListener('keydown', handleHotKeys);
      };
    }
  }, [handleHotKeys]);

  // Form component wrapped in submitIsDisabled context
  return (
    <formikSubmitIsDisabledContext.Provider value={submitIsDisabled}>
      <Form
        autoComplete='off'
        style={{ height: '100%' }}
        data-intercom-target={intercomTarget}
        {...rest}
        ref={formRef}
      />
    </formikSubmitIsDisabledContext.Provider>
  );
}
