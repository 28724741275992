import styled from 'styled-components';

interface Props {
  margin?: number;
  mobileMargin?: number;
}
const Divider = styled.div<Props>`
  width: calc(100% - ${({ margin }) => (margin ? margin * 2 + 'px' : '0px')});
  margin: 0 auto;
  height: 1px;
  background-color: ${({ theme }) => theme.dividerColor};
  transition: background-color 200ms ease-out; /* For theme toggle */

  @media (max-width: 1023px) {
    width: calc(100% - ${({ mobileMargin }) => (mobileMargin ? mobileMargin * 2 + 'px' : '0px')});
  }
`;

export default Divider;
