import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Divider from './Divider';
import Icon from './Icon';

// Styles
const Wrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 8px;
`;
const MenuButton = styled.button`
  z-index: 1;

  appearance: none;
  line-height: 0;
  padding: 4px 8px;
  border-radius: 6px;

  i {
    color: ${({ theme }) => theme.lightAccent};
  }
  i.hover {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.hoverFade};

    i {
      color: ${({ theme }) => theme.linkColor} !important;
    }
    i.nonhover {
      display: none;
    }
    i.hover {
      display: block;
    }
  }
`;
const MenuWrapper = styled.div`
  z-index: 5;
  position: absolute;
  top: -24px;
  right: -24px;
  padding: 24px 24px 96px 96px;
  transform: translateZ(0);
`;
const menuOpen = keyframes`
    0% { transform: scale(0); opacity: 0 }
  100% { transform: scale(1); opacity: 1 }
`;
const Menu = styled.div`
  background-color: ${({ theme }) => theme.lightColor};
  border-radius: 6px 2px 6px 6px;
  box-shadow: ${({ theme }) => theme.shadow300};
  overflow: hidden;

  transform-origin: top right;
  animation: ${menuOpen} 100ms ${({ theme }) => theme.easeDecelerate} forwards;

  button {
    width: 100%;
    display: block;
    appearance: none;
    padding: 12px 24px 12px 16px;

    font-weight: bold;
    font-size: 15px;
    text-align: left;
    color: ${({ theme }) => theme.textTertiary};
    &:first-child {
      color: ${({ theme }) => theme.linkColor};
    }

    &:hover,
    &:active,
    &:focus {
      outline: none;
      background-color: ${({ theme }) => theme.hoverFade};
      color: ${({ theme }) => theme.textSecondary};
      &:first-child {
        color: ${({ theme }) => theme.linkColor};
      }
    }
  }
`;

// Props
interface EditDeleteMenuProps {
  handleEdit?(): void;
  handleDelete?(): void;
}
// Component
const EditDeleteMenu = ({ handleEdit, handleDelete }: EditDeleteMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <Wrapper>
      {/* Toggle button */}
      <MenuButton onClick={() => setShowMenu(true)}>
        <Icon icon='edit' iconSize='22px' className='hover' />
        <Icon icon='more_horiz' iconSize='22px' className='nonhover' />
      </MenuButton>
      {showMenu && (
        <MenuWrapper onMouseLeave={() => setShowMenu(false)} onClick={() => setShowMenu(false)}>
          <Menu>
            {/* Edit Button */}
            <button
              disabled={!handleEdit}
              onClick={() => {
                handleEdit && handleEdit();
                setShowMenu(false);
              }}
            >
              Edit
            </button>
            <Divider padding={0} />
            {/* Delete button */}
            <button
              disabled={!handleDelete}
              onClick={() => {
                handleDelete && handleDelete();
                setShowMenu(false);
              }}
            >
              Delete
            </button>
          </Menu>
        </MenuWrapper>
      )}
    </Wrapper>
  );
};

// Export
export default EditDeleteMenu;
