import { memo, useMemo } from 'react';
import styled from 'styled-components';

import Photo, { PhotoSize, getPhotoUrl } from '@/classes/Photo';

import { animated, useSpring } from '@react-spring/web';

import Loader from './Loader';

const DEMO_IDS = ['caraCrane', 'gregGull', 'robinRiggs'];

// Styles
interface StyledAvatarProps {
  size: number;
  isUser: boolean;
  faded: boolean;
  sizeOverride?: number;
}
const StyledAvatar = styled.div<StyledAvatarProps>`
  position: relative;
  flex-shrink: 0;
  opacity: ${({ faded }) => (faded ? 0.4 : 1)};

  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.sizeOverride ? props.sizeOverride : props.size)}px;
  height: ${props => (props.sizeOverride ? props.sizeOverride : props.size)}px;

  overflow: hidden;

  border-radius: ${({ size, isUser }) => (isUser ? (size <= 24 ? '4px' : '6px') : '50%')};
  background-color: ${({ theme }) => theme.avatarBG};

  color: ${({ theme }) => theme.avatarText};
  font-size: ${props => (props.sizeOverride ? props.sizeOverride / 2 : props.size / 2)}px;
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;

  img.avatar-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  id?: string | null;
  name?: string;
  photo?: Photo;
  size?: PhotoSize;
  sizeOverride?: number;
  isUser?: boolean;
  faded?: boolean;
  // Only for admin org logos for right now
  clearbitLogo?: string;
  clearbitAutocomplete?: string | false;
}
// Component
const Avatar = memo(function Avatar({
  id = '',
  name = '',
  photo = null,
  size = 48,
  sizeOverride,
  isUser = false,
  faded = false,
}: Props) {
  // Handle profile pic for demo people ( cara crane, greg gull, and robing riggs )
  const demoId = useMemo(() => (DEMO_IDS.includes(id ?? '') ? id : null), [id]);

  const fadeOut = useSpring({ opacity: 0, from: { opacity: 1 }, delay: 2000 });
  const bucket = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET;
  return (
    <StyledAvatar size={size} isUser={isUser} faded={faded} sizeOverride={sizeOverride}>
      {/* Initials if not pending and no photo */}
      {!photo && !demoId && name.trim().charAt(0)}

      {/* Loader when pending token (uploading photo) */}
      <Loader show={photo === 'pending'} />

      {/* Has photo ( or is demo person ) */}
      {photo !== 'pending' && (!!photo || demoId) && (
        <>
          {/* Small, blurry photo when loading */}
          <animated.img
            className='avatar-photo'
            src={getPhotoUrl({ demoId, photo, size: 12, bucket })}
            alt={'profile picture loading for ' + name}
            style={{
              ...fadeOut,
              filter: `blur(${sizeOverride ? sizeOverride / 24 : size / 24}px)`,
              borderRadius: isUser ? (size <= 24 ? '4px' : '6px') : '50%',
            }}
          />
          {/* Img with retina, if enabled/available */}
          <img
            className='avatar-photo'
            src={getPhotoUrl({ demoId, photo, size, bucket })}
            alt={'profile picture for ' + name}
            srcSet={
              // Retina not available for these biggest sizes
              ![384, 512].includes(size)
                ? getPhotoUrl({ demoId, photo, size: (size * 2) as PhotoSize, bucket }) + ' 2x'
                : ''
            }
          />
        </>
      )}
    </StyledAvatar>
  );
});
export default Avatar;
