import { lazy, useEffect, useState } from 'react';

import ErrorSuspendPlaceholder from './ErrorSuspendPlaceholder';

const LottieAnimation = lazy(() => import('@/components/common/LottieAnimation'));

interface Props {
  path: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  loop?: boolean;
}
const LazyLottieAnimation = ({
  path,
  width = '256px',
  height = '256px',
  maxHeight = 'none',
  ...rest
}: Props) => {
  const [animationData, setAnimationData] = useState<object>();
  useEffect(() => {
    (async () => {
      const { default: data } = await import(`../../animationData/${path}.json`);
      setAnimationData(data);
    })();
  }, [path]);
  if (!animationData) return <div style={{ width, height, maxHeight }}></div>;

  return (
    <ErrorSuspendPlaceholder width={width} height={height} style={{ maxHeight }}>
      <LottieAnimation
        width={width}
        height={height}
        maxHeight={maxHeight}
        animationData={animationData}
        {...rest}
      />
    </ErrorSuspendPlaceholder>
  );
};
export default LazyLottieAnimation;
