import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useAppState from '@/contexts/appState';
import useLocations from '@/contexts/locations';
import useOrganization from '@/contexts/organization';
import useSessionIsInitializing from '@/contexts/session';

import useUrlQuery from '@/hooks/useUrlQuery';

import ActivityPage from '@/components/pages/ActivityPage';
import LoadingPage from '@/components/pages/LoadingPage';
import PeoplePage from '@/components/pages/PeoplePage';
import PersonPage from '@/components/pages/PersonPage';
import TasksPage from '@/components/pages/TasksPage';

import AppLayout from '@/components/layout/AppLayout';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Loader from '@/components/common/Loader';

// Lazy load import and admin stuff
const UsersPage = lazy(() => import('@/components/pages/UsersPage'));
const ProfilePage = lazy(() => import('@/components/pages/ProfilePage'));
const AccountPage = lazy(() => import('@/components/pages/AccountPage'));
const CustomizationsPage = lazy(() => import('@/components/pages/CustomizationsPage'));
const ReportsPage = lazy(() => import('@/components/pages/ReportsPage'));

const ImportPage = lazy(() => import('@/components/pages/ImportPage'));
const NoAccessPage = lazy(() => import('@/components/pages/NoAccessPage'));
const OnboardingPage = lazy(() => import('@/components/pages/OnboardingPage'));

const AdminPage = lazy(() => import('@/components/pages/AdminPage'));

// Component
const UserRoutes = () => {
  // Potential org type brought over from landing page
  const { orgType } = useUrlQuery();
  // App state
  const { isOnboarding } = useAppState();
  // Context data
  const sessionIsInitializing = useSessionIsInitializing();
  const [organization] = useOrganization();
  const [locations] = useLocations();

  // Onboarding page ( if onboarding ) ( Lazy Load )
  if (isOnboarding) {
    return (
      <ErrorSuspendPlaceholder fallback={<LoadingPage show />}>
        <Routes>
          <Route path='/onboarding' element={<OnboardingPage />} />
          <Route
            path='*'
            element={
              <Navigate to={orgType ? `/onboarding/?orgType=${orgType}` : '/onboarding'} replace />
            }
          />
        </Routes>
      </ErrorSuspendPlaceholder>
    );
  }

  // Missing organization or location(s) ( Lazy Load )
  if (!sessionIsInitializing && (!organization || !locations?.length))
    return (
      <ErrorSuspendPlaceholder fallback={<LoadingPage show />}>
        <NoAccessPage />
      </ErrorSuspendPlaceholder>
    );

  return (
    <>
      {/* Loading page (if fetching auth or hydrating) */}
      <LoadingPage show={sessionIsInitializing} />

      {/* Only render routes when auth isn't fetching and is completely hydrated */}
      {!sessionIsInitializing && (
        <AppLayout>
          <ErrorSuspendPlaceholder
            fallback={
              <SheetsWrapper>
                <Sheet position='left'>
                  <Loader show={true} />
                </Sheet>
                <Sheet position='right' className='hidden-mobile'>
                  <Loader show={true} />
                </Sheet>
              </SheetsWrapper>
            }
          >
            <Routes>
              {/* Main menu pages */}
              <Route path='/people' element={<PeoplePage />} />
              <Route path='/activity/:sheet?' element={<ActivityPage />} />
              <Route path='/tasks/:sheet?' element={<TasksPage />} />

              {/* Detail pages */}
              <Route path='/person/:id/:sheet?' element={<PersonPage />} />

              {/* Org/user-wide pages in drop down menu ( Lazy Load ) */}
              <Route path='/users/:id?' element={<UsersPage />} />
              <Route path='/profile/:accordion?' element={<ProfilePage />} />
              <Route path='/account/:accordion?/:modal?' element={<AccountPage />} />
              <Route path='/customizations/:accordion?' element={<CustomizationsPage />} />
              <Route path='/reports/:type?/:locationId?' element={<ReportsPage />} />
              <Route path='/import/' element={<ImportPage />} />

              {/* Super admin page ( Lazy Load ) */}
              <Route path='/admin/:organizationId?' element={<AdminPage />} />

              {/* Not found?  Redirect to people */}
              <Route path='*' element={<Navigate to='/people' replace />} />
            </Routes>
          </ErrorSuspendPlaceholder>
        </AppLayout>
      )}
    </>
  );
};
export default UserRoutes;
