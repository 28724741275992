import { type ComponentProps } from 'react';
import styled from 'styled-components';

export interface Props extends ComponentProps<'input'> {
  dense?: boolean;
  hasError?: boolean;
}

const FormInput = styled.input<Props>`
  flex: 1;
  -webkit-appearance: none;
  width: 100%;

  /* For inputs only ( not text areas - override there ) */
  padding: ${props => (props.dense ? '8px 0 8px 12px' : '12px 0 12px 12px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger600 : theme.lightAccent)};
  border-radius: 4px;
  transition: border-color 100ms ease-out;

  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.textPrimary};

  background-color: ${({ theme }) => theme.sheetBackgroundColor};

  &:hover {
    border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger400 : theme.textTertiary)};
  }
  &:focus {
    outline: none;
    border: 2px solid ${({ hasError, theme }) => (hasError ? theme.danger400 : theme.primary500)};
    box-shadow: ${props => props.theme.shadow300};
  }
  &::placeholder {
    font-weight: normal;
    color: ${({ theme }) => theme.textFaded};
    letter-spacing: 0.025em;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.textTertiary};
    background-color: ${({ theme }) => theme.hoverFade};
    opacity: 0.5;
  }

  /* For type='time' timepicker */
  &::-webkit-datetime-edit {
    padding: 0;
  }
  &::-webkit-date-and-time-value {
    text-align: left;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  /* Mainly on safari */
  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-hour-field,
  &::-webkit-datetime-edit-minute-field,
  &::-webkit-datetime-edit-second-field,
  &::-webkit-datetime-edit-millisecond-field,
  &::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export default FormInput;
