import { useMemo, useState, useRef } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
// Libs
import { DateTime } from 'luxon';
import { getFriendlyDate } from '@/lib/helpers';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
// Context data
import useAppState, { useSetAppState } from '@/contexts/appState';
import { useCompletedTasks } from '@/contexts/tasks';
// Layout components
import SheetHeader from '@/components/layout/SheetHeader';
import Scrollable from '@/components/layout/Scrollable';
// Page components
import TransitionWrapper from './TransitionWrapper';
// Common components
import Loader from '@/components/common/Loader';
import Padding from '@/components/common/Padding';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import LinkButton from '@/components/common/LinkButton';
import TaskItem from '@/components/common/TaskItem';
import StickyHeader from '@/components/common/StickyHeader';

// Component
const CompletedTasksSheet = () => {
  // App state
  const { uid, groupId, groupName, hasSingleGroup, contentFilter, filterTasksBy, isSingleUser } =
    useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [completedTasks, completedTasksAreFetching] = useCompletedTasks();

  // Empty state is a little different for 'My tasks' with no other filter
  const isPrimaryFilter = filterTasksBy === 'My' && !groupId && contentFilter !== 'following';

  // Group tasks by day then flatten for proper TransitionGroup animating
  const flattenedTaskList = useMemo(() => {
    if (!completedTasks?.length) return [];
    const grouped = groupBy(completedTasks, task =>
      task.completedAt
        ? DateTime.fromMillis(task.completedAt.toMillis()).startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    return orderBy(toPairs(grouped), ([millis]) => millis, 'desc').flat(2);
  }, [completedTasks]);

  // Value to animate elements after currently collapsing ones
  const [offset, setOffset] = useState(0);

  // Ref for transition
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SheetHeader
        primary
        active={!!completedTasks && !!completedTasks.length}
        leadingIcon='done_all'
        mainTitle='Completed tasks'
        className='hidden-mobile'
      />
      {/* Loader for completed tasks */}
      <Loader show={completedTasksAreFetching} />

      {/* Completed tasks list */}
      <Scrollable endBuffer='64px'>
        {/* Map over flattened task list */}
        <TransitionWrapper offset={offset}>
          {!completedTasksAreFetching && !!flattenedTaskList.length && (
            <>
              <TransitionGroup key={groupId + contentFilter + filterTasksBy} component={null}>
                {flattenedTaskList.map(value => (
                  <CSSTransition
                    key={typeof value === 'string' ? value : value.id}
                    nodeRef={nodeRef}
                    timeout={{ enter: 500, exit: 395 }}
                    component={null}
                    onEnter={() => setOffset(prev => prev + (nodeRef.current?.scrollHeight ?? 0))}
                    onExit={() => setOffset(prev => prev + (nodeRef.current?.scrollHeight ?? 0))}
                    // Clear offset whenever animation completes
                    onEntered={() => setOffset(0)}
                    onExited={() => setOffset(0)}
                  >
                    {() => {
                      // Date/group header
                      if (typeof value === 'string') {
                        const millis = parseInt(value);
                        return (
                          <StickyHeader
                            key={value}
                            forwardedRef={nodeRef}
                            heading={getFriendlyDate(millis)}
                            variant='dull'
                          />
                        );
                      }
                      // Task item
                      const task = value;
                      return (
                        <TaskItem
                          key={task.id}
                          forwardedRef={nodeRef}
                          task={task}
                          hideGroups={hasSingleGroup || !!groupId}
                          hideUser={
                            isSingleUser || (filterTasksBy === 'My' && task.completedBy?.id === uid)
                          }
                        />
                      );
                    }}
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </>
          )}
        </TransitionWrapper>

        {/* Empty notice */}
        {!completedTasksAreFetching && !completedTasks?.length && (
          <Padding
            padding='48px 32px'
            className='fade-in'
            key={groupId + contentFilter + filterTasksBy}
          >
            <NoneFoundHeader>
              No completed {filterTasksBy !== 'My' && `${filterTasksBy.toLowerCase()} `}tasks
              <NoWrap>
                {!!groupName && ` for ${groupName}`}
                {!groupName && contentFilter === 'following' && ' for followed people'}
              </NoWrap>
            </NoneFoundHeader>
            {!isPrimaryFilter && (
              <NoneFoundCopy>
                Back to{' '}
                {groupId || contentFilter === 'following' ? (
                  <LinkButton onClick={() => setAppState({ groupId: null, contentFilter: 'all' })}>
                    <strong>all people</strong>
                  </LinkButton>
                ) : (
                  <LinkButton
                    onClick={() =>
                      setAppState({ filterTasksBy: 'My', groupId: null, contentFilter: 'all' })
                    }
                  >
                    <strong>my tasks</strong>
                  </LinkButton>
                )}
              </NoneFoundCopy>
            )}
          </Padding>
        )}
      </Scrollable>
    </>
  );
};
export default CompletedTasksSheet;
