import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { DEFAULT_MILESTONE_TYPES } from '@/classes/Milestone';

import startCase from 'lodash/startCase';
import { usePostHog } from 'posthog-js/react';

import useAppState, { useSetAppState } from '@/contexts/appState';
import { useGroupFilteredMilestones } from '@/contexts/milestones';
import useOrganization from '@/contexts/organization';
import { useAtomValue } from 'jotai';

import { updateCountsAtom } from '@/components/pages/ActivityPage/UpdatesSheet';

import { ReactSelectSecondary } from '@/components/formElements/ReactSelect';

import Padding from '@/components/common/Padding';

// Styles
const SelectLabel = styled.div`
  display: flex;
  align-items: center;
  .label {
    flex: 1;
  }
  .count {
    margin-right: -16px;
    flex-shrink: 0;
    margin-left: 24px;
    font-size: 14px;
  }
`;

// Component
const ActivityFilters = () => {
  const posthog = usePostHog();

  // App state
  const setAppState = useSetAppState();
  const { locationId, groupId, contentFilter, filterUpdatesBy, filterMilestonesBy } = useAppState();
  // Context data
  const [organization] = useOrganization();
  const groupFilteredMilestones = useGroupFilteredMilestones();

  // Reset filters every time location, groupId, or content filter status changes
  // ( and on initial load )
  useEffect(() => {
    setAppState({ filterUpdatesBy: null, filterMilestonesBy: null });
  }, [locationId, groupId, contentFilter, setAppState]);

  const { types: updateTypeCounts } = useAtomValue(updateCountsAtom);

  // Merge built-in milestone types into organization's custom ones
  // ( includes birthday, anniversary, joinDate )
  const mergedMilestoneTypes = useMemo(
    () => [...DEFAULT_MILESTONE_TYPES, ...(organization?.preferences.milestoneTypes ?? [])],
    [organization]
  );
  // Count milestone types
  const milestoneTypeCounts = useMemo(() => {
    const typeCounts: { [key: string]: number } = {};
    mergedMilestoneTypes.forEach(type => {
      typeCounts[type] = groupFilteredMilestones.filter(
        milestone => milestone.type === type
      ).length;
    });
    return typeCounts;
  }, [mergedMilestoneTypes, groupFilteredMilestones]);

  return (
    <>
      {/* Updates filter */}
      <ReactSelectSecondary
        small
        isDisabled={updateTypeCounts.length < 2}
        isSearchable={updateTypeCounts.length >= 8}
        noOptionsMessage={() => <Padding padding='4px 24px'>No matching update types</Padding>}
        options={updateTypeCounts.map(([type, count]) => ({
          label: (
            <SelectLabel>
              <span className='label'>{!type ? 'All update types' : type}</span>
              <span className='count'>{count}</span>
            </SelectLabel>
          ),
          value: type,
          isDisabled: !count,
        }))}
        value={
          filterUpdatesBy
            ? {
                label: (
                  <>
                    <strong>{filterUpdatesBy}</strong> updates
                  </>
                ),
                value: filterUpdatesBy,
                isDisabled: false,
              }
            : {
                label: (
                  <>
                    All <strong>update types</strong>
                  </>
                ),
                value: '',
                isDisabled: false,
              }
        }
        onChange={selection => {
          setAppState({ filterUpdatesBy: selection?.value || null });
          posthog?.capture('activity_filter_updates', { filter: selection?.value });
        }}
      />
      {/* Milestones filter */}
      <ReactSelectSecondary
        small
        isDisabled={!groupFilteredMilestones.length}
        isSearchable={(organization?.preferences.milestoneTypes.length || 0) >= 8}
        noOptionsMessage={() => <Padding padding='4px 24px'>No matching milestone types</Padding>}
        options={[
          // All milestone types selector
          {
            label: (
              <SelectLabel>
                <span className='label'>
                  <strong>All milestone types</strong>
                </span>
                <span className='count'>{groupFilteredMilestones.length}</span>
              </SelectLabel>
            ),
            value: '',
          },
          // Separator
          // {
          //   label: '---',
          //   value: '---',
          //   isDisabled: true
          // },
          // Each milestone type ( including special de-camelCase format for defaults )
          {
            options: mergedMilestoneTypes.map(type => ({
              label: (
                <SelectLabel>
                  <span className='label'>
                    {DEFAULT_MILESTONE_TYPES.includes(type) ? startCase(type) : type}
                  </span>
                  <span className='count'>{milestoneTypeCounts[type]}</span>
                </SelectLabel>
              ),
              value: type,
              isDisabled: !milestoneTypeCounts[type],
            })),
          },
        ]}
        value={
          filterMilestonesBy
            ? {
                label: (
                  <>
                    <strong>
                      {DEFAULT_MILESTONE_TYPES.includes(filterMilestonesBy)
                        ? startCase(filterMilestonesBy)
                        : filterMilestonesBy}
                    </strong>{' '}
                    milestones
                  </>
                ),
                value: filterMilestonesBy,
              }
            : {
                label: (
                  <>
                    All <strong>milestone types</strong>
                  </>
                ),
                value: '',
              }
        }
        onChange={selection => {
          setAppState({ filterMilestonesBy: selection?.value || null });
          posthog?.capture('activity_filter_milestones', { filter: selection?.value });
        }}
      />
    </>
  );
};
export default ActivityFilters;
