import styled from 'styled-components';

import Divider from '@/components/common/Divider';
import Spacer from '@/components/common/Spacer';

const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.lightAccent};
`;

const AsideDivider = () => (
  <>
    <Spacer height='12px' />
    <StyledDivider padding={12} />
    <Spacer height='12px' />
  </>
);
export default AsideDivider;
