// Core
import { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
// Libs
import Cropper from 'react-easy-crop';
import type { Area } from 'react-easy-crop/types';
// Hooks
import useDebouncedCallback from '@/hooks/useDebouncedCallback';

// Styles
const Wrapper = styled.div<{ width: number }>`
  position: relative;
  width: ${props => props.width}px;
`;
const PhotoCropperWrapper = styled.div<{ width: number; height: number; isUser: boolean }>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-bottom: 4px;

  .cropper-container {
    border-radius: ${({ isUser }) => (isUser ? '6px' : '50%')};
  }
  .cropper-area {
    border: none;
  }
`;
const Slider = styled.input`
  width: 100%;
  appearance: none;
  height: 8px;
  border-radius: 4px;
  background: ${props => props.theme.lightAccent};
  outline: none;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${props => props.theme.primary500};
    border: 2px solid ${props => props.theme.primary600};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${props => props.theme.primary500};
    border: 2px solid ${props => props.theme.primary600};
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    &::-webkit-slider-thumb {
      background: ${props => props.theme.primary400};
      border: 2px solid ${props => props.theme.primary500};
    }
    &::-moz-range-thumb {
      background: ${props => props.theme.primary400};
      border: 2px solid ${props => props.theme.primary500};
    }
  }
`;

// Props
interface Props {
  previewUrl?: string;
  width?: number;
  height?: number;
  handleOnComplete?: (croppedAreaPixels: Area) => void;
  isUser?: boolean;
}

// Component
const PhotoCropper = ({
  previewUrl,
  width = 128,
  height = 128,
  handleOnComplete,
  isUser = false,
}: Props) => {
  const [photoLoaded, setPhotoLoaded] = useState(false);
  const [baseScale, setBaseScale] = useState(1);
  const [zoom, setZoom] = useState(baseScale);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [onCropComplete] = useDebouncedCallback(
    (_croppedArea, croppedAreaPixels: Area) => {
      handleOnComplete && handleOnComplete(croppedAreaPixels);
    },
    200,
    { leading: true }
  );

  // Set initial photo dimensions
  // ( done this way for some strange reason to set proper scale on load
  // and to prevent some super wacky errors )
  const handlePhotoLoad = useCallback(function (this: HTMLImageElement) {
    const aspectRatio = this.width / this.height;
    const initialBaseScale = aspectRatio < 1 ? 1 / aspectRatio : aspectRatio;
    const roundedBaseScale = Math.round(initialBaseScale * 100) / 100;
    setBaseScale(roundedBaseScale);
  }, []);
  useEffect(() => {
    setZoom(baseScale);
    setPhotoLoaded(true);
  }, [baseScale, setZoom]);

  // Load image into DOM
  useEffect(() => {
    if (previewUrl) {
      const img = new Image();
      img.addEventListener('load', handlePhotoLoad);
      img.src = previewUrl;
      return () => {
        img.removeEventListener('load', handlePhotoLoad);
      };
    }
  }, [handlePhotoLoad, previewUrl]);

  // Memoized cropsize to prevent endless re-renders
  const cropSize = useMemo(() => ({ width, height }), [width, height]);

  return (
    <Wrapper width={width}>
      <PhotoCropperWrapper width={width} height={height} isUser={isUser}>
        {photoLoaded && (
          <Cropper
            image={previewUrl || ''}
            crop={crop}
            zoom={zoom}
            aspect={width / height}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            minZoom={baseScale}
            maxZoom={baseScale * 3}
            cropShape={isUser ? 'rect' : 'round'}
            showGrid={false}
            cropSize={cropSize}
            classes={{
              containerClassName: 'cropper-container',
              mediaClassName: 'cropper-img',
              cropAreaClassName: 'cropper-area',
            }}
          />
        )}
      </PhotoCropperWrapper>
      <Slider
        type='range'
        min={baseScale}
        max={baseScale * 3}
        value={zoom}
        step={0.05}
        onChange={e => {
          const val = parseFloat(e.target.value);
          setZoom(val);
        }}
        aria-labelledby='Zoom'
      />
    </Wrapper>
  );
};

export default PhotoCropper;
