import styled from 'styled-components';

const FadedCopy = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.textFaded};
  em,
  strong {
    color: ${({ theme }) => theme.textTertiary};
  }
`;
export default FadedCopy;
