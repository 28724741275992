import { createContext, useContext, useEffect, useState } from 'react';
// Libs
import difference from 'lodash/difference';
// Classes
import Doc from '@/classes/Doc';
import Location from '@/classes/Location';
// Context data
import useAppState, { useSetAppState } from './appState';
import useUser from './user';
import useOrganization from './organization';
import useLocations from './locations';

// Context ( with hook shortcut )
const fetchingLocation: [Doc<Location> | null, boolean] = [null, true];
const locationContext = createContext(fetchingLocation);
const useLocation = () => useContext(locationContext);
export default useLocation;

// Context definition w/ provider
export const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const { uid, organizationId, locationId, isOrganizationAdmin, showArchivedPeople } =
    useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [user] = useUser();
  const [organization] = useOrganization();
  const [locations, locationsAreFetching] = useLocations();

  // Set location and id appropriately
  const [location, setLocation] = useState(fetchingLocation);
  const isOrganizationOwner = organization?.owner.id === uid;
  const inRestrictedUsers =
    isOrganizationOwner || !!organization?.restrictedAccessUsers?.includes(uid || '');
  useEffect(() => {
    if (locations?.length) {
      // Get next location based on appState's locationId
      // ( or reset if can't find it in current list )
      const nextLocation = locations.find(({ id }) => id === locationId) || locations[0];
      setLocation([nextLocation || null, false]);
      const isLocationAdmin = isOrganizationAdmin || nextLocation.admins.includes(uid || '');
      const hasRestrictedAccess = isLocationAdmin && inRestrictedUsers;
      setAppState({
        locationId: nextLocation.id,
        locationDocPath: nextLocation.docPath,
        isLocationAdmin,
        hasRestrictedAccess,
      });
    }
    // Unset if done fetching locations and yet nothing can be found
    else if (!locationsAreFetching) {
      setLocation([null, false]);
      setAppState({
        locationId: null,
        locationDocPath: null,
        isLocationAdmin: false,
        hasRestrictedAccess: false,
      });
    }
  }, [
    locationId,
    locations,
    locationsAreFetching,
    isOrganizationAdmin,
    uid,
    setAppState,
    inRestrictedUsers,
  ]);

  // Keep `followedPeople` id in sync with app state
  useEffect(() => {
    if (user && organizationId && locationId) {
      const followingLocation = user.following?.[organizationId]?.[locationId];
      if (followingLocation) {
        const { people = [], peopleArchived = [] } = followingLocation;
        const followedPeopleIds = showArchivedPeople ? people : difference(people, peopleArchived);
        setAppState({ followedPeopleIds });
        return;
      }
    }
    setAppState({ followedPeopleIds: [] });
  }, [locationId, organizationId, setAppState, showArchivedPeople, user]);

  return <locationContext.Provider value={location}>{children}</locationContext.Provider>;
};
