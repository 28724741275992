import type { DefaultTheme } from 'styled-components';
import primaryTheme, { colors } from './primaryTheme';

// Red theme
export const cardinalColors = {
  ...colors,
  primary000: '#FFE3E3',
  primary100: '#FFBDBD',
  primary200: '#FF9B9B',
  primary300: '#F86A6A',
  primary400: '#EF4E4E',
  primary500: '#E12D39',
  primary600: '#CF1124',
  primary700: '#AB091E',
  primary800: '#8A041A',
  primary900: '#610316',
};

const cardinalTheme: DefaultTheme = {
  ...primaryTheme,
  // Image hue to rotate (to red)
  imgFilter: 'hue-rotate(137deg) contrast(0.86) brightness(0.98) saturate(1.34)',

  /////////////////////
  // Color Overrides //
  /////////////////////
  ...cardinalColors,

  /////////////////////
  // Semantic Colors //
  /////////////////////
  linkColor: cardinalColors.primary500,
  linkHover: cardinalColors.primary600,
  primaryDark: cardinalColors.primary800,
};

export default cardinalTheme;
