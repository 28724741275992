import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import Place from '@/classes/Place';

import firebase, { db } from '@/lib/firebase';

import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { useField, useFormikContext } from 'formik';
import orderBy from 'lodash/orderBy';

import useAppState from '@/contexts/appState';
import useLocation from '@/contexts/location';
import useUser from '@/contexts/user';

import useDebouncedValue from '@/hooks/useDebouncedValue';

import FormikCheckbox from './FormikCheckbox';

import { SecondaryButton } from '@/components/common/Buttons';
import Expanded from '@/components/common/Expanded';

import CreatableSelect from './styled/FormCreatableSelect';
import Error from './styled/FormError';
import Label from './styled/FormLabel';

// Styles
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  button:hover {
    color: ${({ theme }) => theme.danger500};
  }
`;
const MainText = styled.div`
  font-weight: bold;
`;
const SecondaryText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};
`;

// Component props
interface Props {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name?: string;
  savePlaceName?: string;
  disabled?: boolean;
  showSaved?: boolean;
  intercomTarget?: string;
  placeholder?: string;
  onPlaceSelect?(place: Place): void;
  autoFocus?: boolean;
  captureMenuScroll?: boolean;
}
// Component
// ( this draws heavily from name/person selector in `RelationshipForm` )
const FormikAutocompletePlace = ({
  label,
  labelIcon,
  optional,
  name = 'place',
  savePlaceName,
  disabled,
  showSaved = false,
  intercomTarget = 'Place select',
  onPlaceSelect,
  ...rest
}: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [{ value: selectedPlace }, meta] = useField<Place | null>(name);
  const selectedPlaceDisplay = useMemo(
    () =>
      selectedPlace
        ? selectedPlace.secondaryText.trim()
          ? `${selectedPlace.mainText}, ${selectedPlace.secondaryText}`
          : selectedPlace.mainText
        : null,
    [selectedPlace]
  );

  // If callback specified, we can call it whenever the place changes ( but not on initial load )
  const isInitialLoad = useRef(true);
  useEffect(() => {
    if (selectedPlace && onPlaceSelect) {
      !isInitialLoad.current && onPlaceSelect && onPlaceSelect(selectedPlace);
      isInitialLoad.current = false;
    }
  }, [onPlaceSelect, selectedPlace]);

  // App state
  const { isLocationAdmin, isSingleUser } = useAppState();
  // Context data
  const [user] = useUser();
  const [location] = useLocation();

  // Value for input
  const [inputValue, setInputValue] = useState('');

  // Get saved/favorite places for this location and filter/format
  const savedPlacesItems = useMemo(
    () =>
      showSaved && location
        ? orderBy(location.savedPlaces, ['mainText', 'secondaryText'])
            .filter(({ mainText, secondaryText }) => {
              return (
                !inputValue.trim() ||
                (mainText + secondaryText)
                  .replace(/[^A-Z0-9]/gi, '')
                  .toLowerCase()
                  .includes(inputValue.replace(/[^A-Z0-9]/gi, '').toLowerCase())
              );
            })
            .map(place => ({
              label: (
                <FlexRow key={place.placeId || ''}>
                  <Expanded>
                    <MainText>{place.mainText}</MainText>
                    <SecondaryText>{place.secondaryText}</SecondaryText>
                  </Expanded>
                  {isLocationAdmin && (
                    <SecondaryButton
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        user &&
                          db.doc(location.docPath).update({
                            ...generateUpdatedByDottedMeta(user),
                            savedPlaces: firebase.firestore.FieldValue.arrayRemove(place),
                          });
                      }}
                      dull
                    >
                      Remove as preset
                    </SecondaryButton>
                  )}
                </FlexRow>
              ),
              value: place,
            })) ?? []
        : [],
    [inputValue, isLocationAdmin, location, showSaved, user]
  );

  // Load google maps javascript api w/ places library
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    // Only load if not already loaded
    if (!window['google']) {
      const script = document.createElement('script');
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=' +
        import.meta.env.VITE_GAPI_PLACES_KEY +
        '&libraries=places';
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
      };
      document.body.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);
  // Instantiate necessary places API values from google service
  const places = useMemo(
    () =>
      scriptLoaded && {
        autocompleteService: new google.maps.places.AutocompleteService(),
        sessionToken: new google.maps.places.AutocompleteSessionToken(),
      },
    [scriptLoaded]
  );
  // Search and results values to store ( from google places )
  const debouncedInputValue = useDebouncedValue(inputValue, 300);
  const [searchResults, setSearchResults] = useState<Place[]>([]);
  // const [selectedPlace, setSelectedPlace] = useState<Place | undefined>(initialPlace);
  // Run search and set results
  useEffect(() => {
    if (places && !!debouncedInputValue.trim()) {
      places.autocompleteService.getPlacePredictions(
        { input: debouncedInputValue, sessionToken: places.sessionToken },
        results => {
          const formattedResults = (results || []).map(({ place_id, structured_formatting }) => ({
            placeId: place_id,
            mainText: structured_formatting.main_text,
            secondaryText: structured_formatting.secondary_text,
          }));
          setSearchResults(formattedResults);
        }
      );
    } else {
      setSearchResults([]);
    }
  }, [debouncedInputValue, places]);
  // Formatted search results ( no duplicated with saved places though )
  const searchResultsItems = useMemo(
    () =>
      searchResults
        .filter(
          ({ placeId }) =>
            !savedPlacesItems.map(({ value: { placeId } }) => placeId).includes(placeId)
        )
        .map(place => ({
          label: (
            <div key={place.placeId || ''}>
              <MainText>{place.mainText}</MainText>
              <SecondaryText>{place.secondaryText}</SecondaryText>
            </div>
          ),
          value: place,
        })),
    [savedPlacesItems, searchResults]
  );

  // Track focus/blur
  const isSelectingPlace = useRef(false);
  const hasFocus = useRef(false);
  // If input is ever cleared all the way out ( while focused ),
  // set selected value to empty too
  useEffect(() => {
    hasFocus.current && !inputValue.trim() && selectedPlace && setFieldValue(name as never, null);
  }, [inputValue, name, selectedPlace, setFieldValue]);
  return (
    <>
      <Label
        label={label}
        labelIcon={labelIcon}
        htmlFor={name}
        optional={optional}
        data-intercom-target={intercomTarget}
      >
        <CreatableSelect
          inputId={name}
          isDisabled={disabled || isSubmitting}
          // Bypass react select's default search
          filterOption={() => true}
          // Blur so search value can be reset on focus
          blurInputOnSelect
          // Allow creation of new place as long as there's something to create
          isValidNewOption={(searchInput: string) => !!searchInput.trim()}
          formatCreateLabel={(searchInput: string) => (
            <div>
              Use &quot;<strong>{searchInput}</strong>&quot;
            </div>
          )}
          noOptionsMessage={() => 'Start typing to search nearby places'}
          // These are the options taken from location prefs merged
          // with google results and formatted nicely
          options={[...savedPlacesItems, ...searchResultsItems]}
          // This is the value when searching
          inputValue={inputValue}
          // Every time on type, set search value
          onInputChange={(val: string) => setInputValue(val)}
          // This is the currently selected value
          value={
            selectedPlace
              ? { label: selectedPlaceDisplay as React.ReactNode, value: selectedPlace }
              : { value: '' }
          }
          // Whenever a place is selected, set it as the current value
          onChange={selection => {
            // This to track whether selecting or not to
            // prevent onBlur from being too aggressive
            isSelectingPlace.current = true;

            // Creating new
            if (typeof selection?.value === 'string' && selection.value.trim()) {
              const placeId = null;
              const mainText = selection.value;
              const secondaryText = '';
              setFieldValue(name as never, { placeId, mainText, secondaryText });
            }
            // Selecting `Place` from Google results
            else if (selection && typeof selection?.value !== 'string') {
              const { placeId = null, mainText = '', secondaryText = '' } = selection.value;
              setFieldValue(name as never, { placeId, mainText, secondaryText });
            }
          }}
          // When focus, we want to return the current value to the search field
          onFocus={() => {
            hasFocus.current = true;
            // This is also when we should reset the isSelecting value
            isSelectingPlace.current = false;
            setInputValue(selectedPlaceDisplay ?? '');
          }}
          // When blurring, change value to match search IF
          // this blur isn't just triggered by 'onChange' and the name
          // is in fact different than the current person value
          onBlur={event => {
            hasFocus.current = false;
            const inputValue = event.target.value.trim();
            if (!isSelectingPlace.current && inputValue !== selectedPlaceDisplay) {
              const selection = inputValue.trim()
                ? { placeId: null, mainText: inputValue.trim(), secondaryText: '' }
                : null;
              setFieldValue(name as never, selection);
            }
          }}
          {...rest}
        />
        {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null}
      </Label>
      {!!savePlaceName &&
        isLocationAdmin &&
        !!selectedPlace?.placeId &&
        !location?.savedPlaces.map(({ placeId }) => placeId).includes(selectedPlace?.placeId) && (
          <FormikCheckbox
            disabled={disabled}
            name={savePlaceName}
            data-intercom-target={intercomTarget + ' - save'}
          >
            <strong>Save as preset</strong>
            {!isSingleUser && ' for all users'}
          </FormikCheckbox>
        )}
    </>
  );
};

export default FormikAutocompletePlace;
