import { Fragment } from 'react';

import { DateTime } from 'luxon';

// Pulled from here: https://gist.github.com/jlbruno/1535691/db35b4f3af3dcbb42babc01541410f291a8e8fac
export const getNumberOrdinal = function (x: number) {
  const s = ['th', 'st', 'nd', 'rd'],
    v = x % 100;
  return x + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const pluralize = function ({
  root = '',
  count = 0,
  singular = '',
  plural = 's',
  replace = '{x}',
  showNegative = false,
  showZero = false,
} = {}) {
  if (count === 1) {
    return (root + singular).replace(replace, count.toString());
  }
  if (count > 0 || (showZero && count === 0) || (showNegative && count !== 0)) {
    return (root + plural).replace(replace, count.toString());
  }
  return '';
};

export const makeSentenceList = function (list: (string | JSX.Element)[]) {
  if (list.length <= 1) {
    return list[0];
  } else if (list.length === 2) {
    return (
      <>
        {list[0]} and {list[1]}
      </>
    );
  } else {
    const items = [...list];
    const lastItem = items.pop();
    const nextToLastItem = items.pop();
    return (
      <>
        {items.map((item, index) => (
          <Fragment key={index}>{item}, </Fragment>
        ))}
        {nextToLastItem} and {lastItem}
      </>
    );
  }
};

export const getShortName = function (name: string) {
  const trimmedName = name.trim();
  if (trimmedName) {
    const nameArray = trimmedName.split(' ');
    let shortName = nameArray[0];
    if (nameArray.length > 1) {
      shortName += ' ' + nameArray[1].charAt(0);
    }
    return shortName;
  }
  return '';
};

/** Return date in format `Mon DD, YYYY`.
 *
 * _EX. `Jan 15, 2005` */
export const getDate = function (
  input: Date | string | number | null | false,
  { hideYearPast = 0 } = {}
) {
  if (!input) {
    return '';
  }
  let dateTime =
    typeof input === 'number'
      ? DateTime.fromMillis(input)
      : typeof input === 'string'
        ? DateTime.fromISO(input)
        : DateTime.fromJSDate(input);
  // Set hour to 12 as alternate fix for safari localeString timezone bug:
  // https://github.com/moment/luxon/issues/392
  dateTime = dateTime.set({ hour: 12 });

  // Hiding year past
  if (hideYearPast && DateTime.local().diff(dateTime).as('years') > hideYearPast) {
    return dateTime.toLocaleString({ month: 'short', day: 'numeric' });
  }
  // Regular
  return dateTime.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });
};
/** Return date in a format contextual to today.
 *
 * _EX. `Today`, `Yesterday`, `Tomorrow`, `Wed Jun 25`, or `Jan 15, 2005` */
export const getFriendlyDate = function (
  input: DateTime | Date | string | number | undefined | null | false,
  { short = false, hideYearPast = 0 } = {}
) {
  if (!input) return '';

  let dateTime =
    input instanceof DateTime
      ? input
      : typeof input === 'number'
        ? DateTime.fromMillis(input)
        : typeof input === 'string'
          ? DateTime.fromISO(input)
          : DateTime.fromJSDate(input);
  // Set hour to 12 as alternate fix for safari localeString timezone bug:
  // https://github.com/moment/luxon/issues/392
  dateTime = dateTime.set({ hour: 12 });
  const Today = DateTime.local().set({ hour: 12 });

  if (dateTime.hasSame(Today, 'day')) {
    return 'Today';
  } else if (dateTime.hasSame(Today.minus({ day: 1 }), 'day')) {
    return 'Yesterday';
  } else if (dateTime.hasSame(Today.plus({ day: 1 }), 'day')) {
    return 'Tomorrow';
  } else if (dateTime.hasSame(Today, 'year')) {
    // This year
    return dateTime.toLocaleString({
      weekday: 'short',
      month: short ? 'short' : 'long',
      day: 'numeric',
    });
  } else {
    // Hiding year past
    if (hideYearPast && Today.diff(dateTime).as('years') > hideYearPast) {
      return dateTime.toLocaleString({
        month: short ? 'short' : 'long',
        day: 'numeric',
      });
    }
    // Not this year
    return short
      ? dateTime.toLocaleString({
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : dateTime.toLocaleString(DateTime.DATE_FULL);
  }
};

export const stringToProfileName = function (fullName: string) {
  const full = fullName.trim();
  const nameParts = full.split(' ').filter(str => str !== '');
  const first = nameParts.splice(0, Math.floor(nameParts.length / 2) || 1).join(' ');
  const last = nameParts.join(' ');
  return { full, first, last };
};

// export const dateToFriendlyString = function(date: Date | null | false) {
//   if (date) {
//     const dateTime = DateTime.fromJSDate(date);
//     const now = DateTime.local();
//     // const startOfYesterday = now.minus({ day: 1 }).startOf('day');
//     // const endOfTomorrow = now.plus({ day: 1 }).endOf('day');
//     const minutesAgo = dateTime.diffNow('minutes').minutes;
//     if (minutesAgo <= 0 && minutesAgo >= -2) {
//       return 'moments ago';
//     }
//     // Within bracketed 18 hours, print relative time
//     if (dateTime.diffNow('hours').hours < 18) {
//       return dateTime.toRelative();
//     }
//     // If this year, print date/time without year
//     if (dateTime.hasSame(now, 'year')) {
//       return dateTime.toLocaleString({
//         month: 'short',
//         day: 'numeric',
//         hour: 'numeric',
//         minute: '2-digit'
//       });
//     }
//     // All else, print date/time with year
//     return dateTime.toLocaleString(DateTime.DATETIME_SHORT) || '';
//   } else {
//     return '';
//   }
// };
