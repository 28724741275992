import { Link, useParams } from 'react-router-dom';
// Hooks
import usePageView from '@/hooks/usePageView';
// Context data
import useAppState from '@/contexts/appState';
// Layout components
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';
import MobilePageHeader, { MobileButtonGroup } from '@/components/layout/MobilePageHeader';
// Page components
import UncompletedTasksSheet from './UncompletedTasksSheet';
import CompletedTasksSheet from './CompletedTasksSheet';

// Component

const TasksPage = () => {
  // Register page view
  usePageView({ title: 'Tasks | Notebird' });

  // App state
  const { filterTasksBy } = useAppState();

  // When on mobile we need to know which sheet is active
  const { sheet } = useParams();
  const showCompletedTasks = sheet === 'completed';
  const showUncompletedTasks = !showCompletedTasks;

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader>
        <MobileButtonGroup>
          <Link to='/tasks' className={showUncompletedTasks ? 'active' : ''}>
            {filterTasksBy}
            {filterTasksBy === 'My' && ' tasks'}
          </Link>
          <Link to='/tasks/completed' className={showCompletedTasks ? 'active' : ''}>
            Completed
          </Link>
        </MobileButtonGroup>
      </MobilePageHeader>

      {/* Tasks uncompleted */}
      <Sheet position='left' className={showUncompletedTasks ? '' : 'hidden-mobile'}>
        <UncompletedTasksSheet />
      </Sheet>

      {/* Completed tasks */}
      <Sheet position='right' className={showCompletedTasks ? '' : 'hidden-mobile'}>
        <CompletedTasksSheet />
      </Sheet>
    </SheetsWrapper>
  );
};
export default TasksPage;
