import styled, { css } from 'styled-components';

interface Props {
  margin?: string;
  mobileMargin?: string;
}
const Margin = styled.div<Props>`
  margin: ${({ margin }) => (margin !== undefined ? margin : '0')};
  ${({ mobileMargin }) =>
    mobileMargin !== undefined &&
    css`
      @media (max-width: 1023px) {
        margin: ${mobileMargin};
      }
    `};
`;

export default Margin;
