export const PHOTO_SIZES = [12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512] as const;
export type PhotoSize = (typeof PHOTO_SIZES)[number];

type Photo =
  | null
  | 'pending'
  | {
      path: string;
      ext: 'jpg' | 'jpeg' | 'png';
      token: string;
    };
export default Photo;

// Photo urls can be constructed with a proper token as outlined here:
// https://stackoverflow.com/a/49890021/3097099
export const getPhotoUrl = ({
  photo,
  demoId = null,
  size = 48,
  bucket = '',
}: {
  photo: Photo;
  demoId?: string | null;
  size?: PhotoSize;
  bucket?: string;
}) => {
  if (!photo && demoId) {
    const imgUrl = size === 12 ? `${demoId}_12.png` : `${demoId}.png`;
    return `/demo-avatars/${imgUrl}`;
  }
  if (!!photo && photo !== 'pending') {
    const id = photo.path.split('/').pop();
    return (
      'https://firebasestorage.googleapis.com/v0/b/' +
      bucket +
      '/o/' +
      encodeURIComponent(`${photo.path}/${id}_thumb@${size}.${photo.ext}`) +
      '?alt=media&token=' +
      photo.token
    );
  }
  return undefined;
};
