import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Doc from '@/classes/Doc';
import Person, { AddressType, RELATIONSHIP_STATUSES } from '@/classes/Person';
import Photo, { PHOTO_SIZES } from '@/classes/Photo';
import Place from '@/classes/Place';

import { db, fbAnalytics, fbStorage } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { deleteObject, ref as storageRef, uploadBytes } from 'firebase/storage';

import fuzzy from '@/lib/helpers/fuzzy';
import generateCreatedByMeta, { generateUpdatedByMeta } from '@/lib/helpers/generateMeta';

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { usePostHog } from 'posthog-js/react';
import { type SearchParams } from 'typesense/lib/Typesense/Documents';
import { array, object, string } from 'yup';

import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import useDebouncedState from '@/hooks/useDebouncedState';
import useTypesense from '@/hooks/useTypesense';

import Scrollable from '@/components/layout/Scrollable';

import FormikAddressInputs from './FormikAddressInputs';
import FormikEmailInputs from './FormikEmailInputs';
import FormikGenderRow from './FormikGenderRow';
import FormikPhoneInputs from './FormikPhoneInputs';
import FormikPhotoManager from './FormikPhotoManager';

import FormikDatePicker from '@/components/formElements/FormikDatePicker';
import FormikFooter from '@/components/formElements/FormikFooter';
import FormikForm from '@/components/formElements/FormikForm';
import FormikGroupsSelect from '@/components/formElements/FormikGroupsSelect';
import FormikHeader from '@/components/formElements/FormikHeader';
import FormikInput from '@/components/formElements/FormikInput';
import FormikSelect from '@/components/formElements/FormikSelect';
import FormikTextarea from '@/components/formElements/FormikTextarea';

import DescriptionBlock from '@/components/common/DescriptionBlock';
import Divider from '@/components/common/DividerTwo';
import Expanded from '@/components/common/Expanded';
import FlexColumn from '@/components/common/FlexColumn';
import FlexRow from '@/components/common/FlexRow';
import Margin from '@/components/common/Margin';
import Spacer from '@/components/common/Spacer';

// Styles
const DuplicatePersonResult = styled.div`
  margin-top: 8px;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
`;
const HelperText = styled.div`
  margin-top: 4px;
  font-size: 12px;
  text-align: right;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
`;

// Default form values
const schema = object().shape({
  firstName: string().trim().required('First name is required').max(1000, '1000 characters max'),
  lastName: string().trim().max(1000, '1000 characters max'),
  notes: string().trim().max(2000, '2000 characters max'),
  phones: array().of(
    object().shape({
      number: string()
        .trim()
        .matches(/^[+(0-9)._\-\s]{1,20}$/, 'Please enter a valid phone number'),
    })
  ),
  emails: array().of(
    object().shape({
      address: string().trim().email('Enter a valid email address').max(250, '250 characters max'),
    })
  ),
  customGender: string().trim().max(250, '250 characters max'),
  customId: string().trim().max(300, '300 characters max'),
});
export interface PhotoFile extends File {
  preview: string;
}
export interface PhotoCrop {
  cropX: string;
  cropY: string;
  cropWidth: string;
  cropHeight: string;
}
const emptyValues = {
  firstName: '',
  lastName: '',
  notes: '',
  groups: [] as string[],
  photoFile: undefined as PhotoFile | undefined,
  photoCrop: undefined as PhotoCrop | undefined,
  deletePhoto: false,
  phones: [] as Person['phones'],
  emails: [] as Person['emails'],
  addresses: [] as { type: AddressType; place: Place | '' }[],
  birthday: '',
  relationshipStatus: '' as Person['relationshipStatus'],
  anniversary: '',
  gender: '',
  customGender: '',
  customId: '',
  joinDate: '',
};
export type EmptyValues = typeof emptyValues;

// Relationship options
const relationshipTypeOptions = RELATIONSHIP_STATUSES.map(status => ({
  value: status,
  label: status,
}));

// Props
interface Props {
  person?: Doc<Person>;
  defaultFirstName?: string;
  defaultLastName?: string;
  handleCancel: () => void;
}
// Core form
const PersonForm = ({
  person,
  defaultFirstName = '',
  defaultLastName = '',
  handleCancel,
}: Props) => {
  const navigate = useNavigate();
  // App state
  const { uid, organizationId, locationId, groupId } = useAppState();
  // Context data
  const [user] = useUser();
  const [organization] = useOrganization();
  const [groups] = useGroups();
  const posthog = usePostHog();

  // Automatically add to group if one is currently selected ( or only has one group )
  const initialGroupId = groupId ? groupId : groups?.length === 1 ? groups[0].id : null;
  // Establish initial values ( new vs editing )
  // ( on new, could prefill name from search value )
  const initialValues = useMemo(() => {
    // Editing person initial values
    if (person) {
      const { profile, notes, phones, emails, addresses, birthday } = person;
      const { relationshipStatus, anniversary, gender, customId, joinDate } = person;
      const isOtherGender = !['Male', 'Female', 'Other', null].includes(gender);
      return {
        firstName: profile.name.first,
        lastName: profile.name.last,
        notes,
        photoFile: undefined,
        photoCrop: undefined,
        deletePhoto: false,
        groups: person.groups[0] === 'ungrouped' ? [] : person.groups,
        phones: phones,
        emails: emails,
        addresses,
        birthday: birthday || '',
        relationshipStatus,
        anniversary: anniversary || '',
        gender: isOtherGender ? 'Other' : gender || '',
        customGender: isOtherGender ? gender || '' : '',
        customId,
        joinDate: joinDate || '',
      };
    }
    // Default new person intial values
    return {
      ...emptyValues,
      firstName: defaultFirstName,
      lastName: defaultLastName,
      groups: initialGroupId ? [initialGroupId] : [],
    };
  }, [defaultFirstName, defaultLastName, initialGroupId, person]);

  // Submitter
  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      try {
        // Ensure user, org/location, groups, etc are set
        if (!uid || !user || !organizationId || !locationId || !groups) {
          throw new Error(
            'An error occurred. Please contact support. (UID, user, organization, location, and gorups required)'
          );
        }
        // Merge with 'unkown' groups if group member (filtering out ungrouped)
        const unkownGroups = person
          ? difference(
              initialValues.groups,
              groups.map(({ id }) => id)
            )
          : [];
        const personGroups = uniq([...unkownGroups, ...values.groups]);

        // Prep person doc
        const firstName = values.firstName.trim();
        const lastName = values.lastName.trim();
        const sortFirstName = fuzzy(firstName + lastName);
        const sortLastName = fuzzy(lastName + firstName);
        // Initialize photo with current (or null)
        let newPhoto: Photo = person ? person.profile.photo : null;
        // If deleting a photo, unset
        newPhoto = values.deletePhoto ? null : newPhoto;
        // If uploading photo, set to pending
        newPhoto = values.photoFile ? 'pending' : newPhoto;

        // Assemble person object
        const personFromForm: Person = {
          meta: person ? generateUpdatedByMeta(user, person.meta) : generateCreatedByMeta(user),
          integration: person?.integration ? { ...person.integration, inSync: false } : null,
          // Permissions and references
          locationId,
          groups: personGroups.length ? personGroups : ['ungrouped'],
          // Core info
          profile: {
            photo: newPhoto,
            name: {
              first: firstName,
              last: lastName,
              full: [firstName, lastName].join(' ').trim(),
            },
          },
          sortFirstName,
          sortLastName,
          // Trim and reduce multiline linebreaks to one or two:
          // https://stackoverflow.com/questions/10965433/regex-replace-multi-line-breaks-with-single-in-javascript
          notes: values.notes.trim().replace(/\n\s*\n\s*\n/g, '\n\n'),
          // Contact info
          phones: values.phones
            .map(({ type, number }) => ({ type, number: number.trim() }))
            .filter(({ number }) => !!number),
          emails: values.emails
            .map(({ type, address }) => ({ type, address: address.trim() }))
            .filter(({ address }) => !!address),
          addresses: values.addresses.filter(({ place }) => !!place) as Person['addresses'],
          // Extra details
          birthday: values.birthday || null,
          relationshipStatus: values.relationshipStatus || null,
          anniversary: values.anniversary || null,
          gender:
            values.gender === 'Other'
              ? values.customGender.trim() || 'Other'
              : values.gender || null,
          customId: values.customId.trim(),
          joinDate: values.joinDate || null,
          // Last updated
          lastUpdated: person ? person.lastUpdated : { latest: null },
          // Archived status
          isArchived: person ? person.isArchived : false,
          // Relationships ( empty if new, same if editing )
          relationships: person ? person.relationships : [],
        };

        // Add person doc to firestore
        const personRef = person
          ? db.collection(`organizations/${organizationId}/people`).doc(person.id)
          : db.collection(`organizations/${organizationId}/people`).doc();
        void personRef.set(personFromForm, { merge: true });

        // Photo stuffs
        const photoExt = !!values.photoFile && values.photoFile.name.split('.').pop();
        // If has photo currently and is deleting...
        if (person && person.profile.photo instanceof Object && values.deletePhoto) {
          // ...and if isn't replacing OR extentions differ...
          if (!values.photoFile || person.profile.photo.ext !== photoExt) {
            // ...remove previous photo + thumbs!
            try {
              // Original
              const {
                docPath,
                id,
                profile: { photo },
              } = person;
              const photoRef = storageRef(fbStorage, `${docPath}/${id}.${photo.ext}`);
              deleteObject(photoRef);
              // Each thumb
              PHOTO_SIZES.forEach(size => {
                const thumbRef = storageRef(
                  fbStorage,
                  `${docPath}/${id}_thumb@${size}.${photo.ext}`
                );
                deleteObject(thumbRef);
              });
            } catch (error) {
              console.error('Error deleting photo', error);
            }
          }
        }
        // Upload photo avatar (but don't await completion before closing out)
        if (values.photoFile) {
          const photoRef = storageRef(fbStorage, `${personRef.path}/${personRef.id}.${photoExt}`);
          uploadBytes(photoRef, values.photoFile, {
            customMetadata: {
              ...values.photoCrop,
              createdById: uid,
              organizationId,
              locationId,
              personId: personRef.id,
            },
          });
        }

        // Close form when finished
        handleCancel();
        // Navigate to person's page ( if creating new )
        !person && !!navigate && navigate('/person/' + personRef.id);

        // Analytics
        const analyticsProps = {
          has_photo: !!personFromForm.profile.photo,
          groups_count: personGroups.length,
          notes_length: personFromForm.notes.length,
          gender: personFromForm.gender,
          is_integration_linked: !!personFromForm.integration,
          phones_count: personFromForm.phones.length,
          emails_count: personFromForm.emails.length,
          addresses_count: personFromForm.addresses.length,
          has_birthday: !!personFromForm.birthday,
          has_relationship_status: !!personFromForm.relationshipStatus,
          has_anniversary: !!personFromForm.anniversary,
          has_custom_id: !!personFromForm.customId,
          has_join_date: !!personFromForm.joinDate,
          relationships_count: personFromForm.relationships.length,
        };
        posthog?.capture(!person ? 'person_created' : 'person_edited', analyticsProps);
        logEvent(fbAnalytics, !person ? 'person_create' : 'person_edit', analyticsProps);
      } catch (error) {
        // Otherwise report error in console and to user
        console.error(error);
        window.alert(error);
      }
    },
    [
      uid,
      user,
      organizationId,
      locationId,
      groups,
      person,
      initialValues.groups,
      handleCancel,
      navigate,
      posthog,
    ]
  );

  // Check for duplicates when search name changes ( with Typesense )
  const { getTypesensePeople } = useTypesense();
  const [, setDuplicatePeopleQuery, duplicatePeopleQuery] = useDebouncedState({
    defaultValue: '',
    wait: 350,
  });
  const isCreatingPerson = !person;
  const { data: duplicatePeople = [] } = useQuery({
    queryKey: ['duplicate-people-search', duplicatePeopleQuery, locationId],
    enabled:
      isCreatingPerson && !!getTypesensePeople && !!duplicatePeopleQuery.trim() && !!locationId,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const searchParams: SearchParams = {
        q: duplicatePeopleQuery,
        query_by: 'profile.name.full',
        per_page: 3,
        include_fields: ['id', 'profile.name.full'],
        num_typos: 0,
        min_len_1typo: 20,
        split_join_tokens: 'off',
        typo_tokens_threshold: 0,
        drop_tokens_threshold: 0,
      };
      const results = await getTypesensePeople?.(searchParams);
      return results?.hits?.map(hit => hit.document) ?? [];
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validate={async (values: typeof emptyValues) => {
        const { firstName, lastName } = values;
        let query = '';
        if (!person && firstName.length >= 3 && lastName.length >= 3) {
          query = `${firstName} ${lastName}`;
        }
        setDuplicatePeopleQuery(query);
      }}
    >
      <FormikForm
        intercomTarget='Person form'
        disableEnterSubmit
        handleCancel={handleCancel}
        // Dissallow submissions when either name is empty OR
        // editing person and nothing in form values has changed ( and file hasn't been uploaded of set to delete )
        disableSubmit={(values: typeof emptyValues) =>
          !values.firstName.trim() ||
          (!!person && isEqual(initialValues, values) && !values.photoFile && !values.deletePhoto)
        }
      >
        <FlexColumn>
          {/* Header */}
          <FormikHeader
            leadingIcon={person ? 'edit' : 'person_add'}
            mainTitle={person ? 'Edit profile' : 'New person'}
            handleCancel={handleCancel}
          />

          {/* Form body */}
          <Scrollable endBuffer='64px'>
            {/* Breeze sync notice ( if linked ) */}
            {organization?._integration?.service === 'breeze' &&
              (!person || !!person.integration?.id) && (
                <DescriptionBlock>
                  {`This profile ${person ? 'syncs' : 'will sync'} with Breeze. `}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://guide.notebird.app/articles/4600693'
                  >
                    Learn more
                  </a>
                </DescriptionBlock>
              )}
            {/* Planning Center sync notice ( if linked ) */}
            {organization?._integration?.service === 'planningCenter' &&
              (!person || !!person.integration?.id) && (
                <DescriptionBlock>
                  {`This profile ${person ? 'syncs' : 'will sync'} with Planning Center. `}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://guide.notebird.app/articles/3856885'
                  >
                    Learn more
                  </a>
                </DescriptionBlock>
              )}
            {/* Church Window sync notice ( if linked ) */}
            {organization?._integration?.service === 'churchWindows' &&
              (!person || !!person.integration?.id) && (
                <DescriptionBlock>
                  {`Your account is linked with Church Windows. It is recommended to manage people there. `}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://guide.notebird.app/articles/8632934#h_f4aca33838'
                  >
                    Learn more
                  </a>
                </DescriptionBlock>
              )}
            {/* ShulCloud sync notice ( if linked ) */}
            {organization?._integration?.service === 'shulCloud' &&
              (!person || !!person.integration?.id) && (
                <DescriptionBlock>
                  {`Your account is linked with ShulCloud. It is recommended to manage people there. `}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://guide.notebird.app/articles/3657333'
                  >
                    Learn more
                  </a>
                </DescriptionBlock>
              )}
            {/* Section 1 ( name, photo, groups ) */}
            <Margin margin='16px 32px 24px' mobileMargin='16px 16px 24px'>
              <FormikPhotoManager currentPhoto={person?.profile.photo} />
              <Spacer height='16px' />

              {/* Name ( first/last ) */}
              <FlexRow align='start' data-intercom-target='Person name row'>
                <Expanded>
                  <FormikInput
                    name='firstName'
                    placeholder='First name'
                    autoFocus={!person}
                    maxLength={1200}
                    data-intercom-target='Person first name'
                    autoComplete='off'
                  />
                </Expanded>
                <Spacer width='16px' />
                <Expanded>
                  <FormikInput
                    name='lastName'
                    placeholder='Last name'
                    maxLength={1200}
                    data-intercom-target='Person last name'
                    autoComplete='off'
                  />
                </Expanded>
              </FlexRow>
              {/* Show duplicate person(s) search results */}
              {duplicatePeople.map(({ id, profile }) => (
                <DuplicatePersonResult key={id}>
                  A profile for <Link to={`/person/${id}`}>{profile.name.full}</Link> already
                  exists.
                </DuplicatePersonResult>
              ))}

              <Spacer height='24px' />

              {/* Groups */}
              <FormikGroupsSelect
                required={false}
                label='Groups'
                placeholder='Add to group(s)'
                person={person}
                mergeGroups={false}
                showRemoveGroups={!!person}
                showUsers={false}
                intercomTarget='Person groups select'
              />
            </Margin>

            <div data-intercom-target='Person other sections'>
              <Divider margin={24} />
              {/* Section 2 ( contact info ) */}
              <Margin
                margin='24px 16px 24px 32px'
                mobileMargin='24px 8px 24px 12px'
                data-intercom-target='Contact info section'
              >
                {/* Phone numbers */}
                <FormikPhoneInputs />
                <Spacer height='16px' />

                {/* Email addresses */}
                <FormikEmailInputs />
                <Spacer height='16px' />

                {/* Physical addresses */}
                <FormikAddressInputs />
              </Margin>
              <Divider margin={24} />

              {/* Section 3 ( details ) */}
              <Margin
                margin='32px 32px 24px'
                mobileMargin='32px 12px 24px'
                data-intercom-target='Details section'
              >
                {/* Birthday */}
                <FlexRow>
                  <FormikDatePicker label='Birthday' name='birthday' />
                  <Spacer width='16px' />
                  <Expanded />
                </FlexRow>
                <Spacer height='24px' />

                {/* Relationship status row */}
                <FlexRow>
                  {/* Relationship status */}
                  <FormikSelect
                    label='Marital status'
                    name='relationshipStatus'
                    options={relationshipTypeOptions}
                    isSearchable={false}
                    isClearable
                  />
                  <Spacer width='16px' />
                  {/* Anniversary */}
                  <FormikDatePicker label='Anniversary' name='anniversary' />
                </FlexRow>
                <Spacer height='24px' />

                {/* Gender row */}
                <FormikGenderRow />
                <Spacer height='24px' />

                {/* ID / Joined row */}
                <FlexRow>
                  {/* ID */}
                  <FormikInput
                    label='ID number'
                    name='customId'
                    maxLength={350}
                    autoComplete='off'
                  />
                  <Spacer width='16px' />
                  {/* Joined */}
                  <FormikDatePicker label='Join date' name='joinDate' />
                </FlexRow>
              </Margin>
              <Divider margin={24} />

              {/* Section 4 ( notes ) */}
              <Margin margin='32px 32px 0' mobileMargin='32px 12px 0'>
                <FormikTextarea
                  label='Profile notes'
                  name='notes'
                  placeholder='Any other general things to know about this person...'
                  minRows={3}
                  maxRows={10}
                  maxLength={2400}
                  data-intercom-target='Notes field'
                />
                <HelperText>Do not put sensitive information here</HelperText>
              </Margin>
            </div>
          </Scrollable>
          {/* Footer */}
          <FormikFooter
            handleCancel={handleCancel}
            submitButton={
              <>
                <strong>Save</strong> person
              </>
            }
            submitIntercomTarget='Save person button'
          />
        </FlexColumn>
      </FormikForm>
    </Formik>
  );
};
export default PersonForm;
