import Meta from './Meta';
import Place from './Place';

export default class Location {
  meta = { ...new Meta() };
  name = '';
  admins: string[] = [];
  users: string[] = [];
  groupMembers: { [userId: string]: string[] } = {};
  savedPlaces: Place[] = [];
  savedTasks?: string[] = [];
  // Could be undefined for accounts created prior to v1.6.0
  importStatus?: 'standby' | 'completed' | 'rejected' | null = null;
}
