import { useEffect, useMemo, useRef, useState } from 'react';

import Doc, { docFromSnapshot } from '@/classes/Doc';

import { fbPerf } from '@/lib/firebase';
import { trace } from 'firebase/performance';

export default function useCollection<T>(
  query: firebase.firestore.Query | null | false | undefined,
  options: { ignoreCache?: boolean; trace?: string } = {}
) {
  // Compare with query from previous call to avoid re-fetches
  const queryRef = useRef<typeof query>();
  const memoizedQuery = useMemo(() => {
    if (!queryRef.current || !query || !queryRef.current.isEqual(query)) {
      queryRef.current = query;
    }
    return queryRef.current;
  }, [query]);

  // Manage stream from 'onSnapshot'
  const [stream, setStream] = useState<[Doc<T>[] | null, boolean]>([null, true]);
  useEffect(() => {
    try {
      if (memoizedQuery) {
        // Track length of fetching with performance monitoring ( if name provided )
        const collectionTrace = options.trace && trace(fbPerf, options.trace);
        collectionTrace && collectionTrace.start();

        setStream(([prevDocs]) => [prevDocs, true]);
        // Return listener to cancel on unmount
        return memoizedQuery.onSnapshot(
          {
            includeMetadataChanges: !!options.ignoreCache,
          },
          snapshot => {
            // Don't honor data from cache if setting says to ignore
            // ( mainly for when we manage cache of people on our own )
            if (options.ignoreCache && snapshot.metadata.fromCache) return;

            const docs: Doc<T>[] = [];
            snapshot.forEach(doc => {
              docs.push(docFromSnapshot<T>(doc));
            });
            setStream(([, wasFetching]) => {
              // Finish trace ( if was running at all )
              if (wasFetching && collectionTrace) {
                collectionTrace.putMetric('total_docs', docs.length);
                collectionTrace.stop();
              }
              // Return docs and notify done fetching
              return [docs, false];
            });
          },
          error => {
            console.error('Error streaming firestore collection: ', memoizedQuery, error);
          }
        );
      }
    } catch (error) {
      console.error('Error streaming firestore collection: ', memoizedQuery, error);
    }
  }, [memoizedQuery, options.ignoreCache, options.trace]);

  return stream;
}
