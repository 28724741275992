import styled from 'styled-components';

// Plain sheets
export const SheetBase = styled.div<{ position?: 'left' | 'right' | 'full'; faded?: boolean }>`
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  background-color: ${({ faded, theme }) =>
    faded ? theme.sheetFadedBackgroundColor : theme.sheetBackgroundColor};
  transition: background-color 200ms ease-out; /* For theme toggle */

  border-radius: ${({ position }) => {
    if (position === 'left') {
      return '8px 16px 16px 8px';
    }
    if (position === 'right') {
      return '16px 8px 8px 16px';
    }
    return '8px';
  }};
  @media (max-width: 1023px) {
    max-width: 100vw;
    border-radius: 0;
  }
`;
const Sheet = styled(SheetBase)<{ position: 'left' | 'right' | 'full' }>`
  z-index: ${({ position }) => (position === 'left' ? 2 : 1)};
  position: relative;

  box-shadow: ${({ theme }) => theme.shadow200};

  /* Inner shadow */
  &:after {
    z-index: 9;
    content: '';
    position: absolute;
    top: 16px;
    right: ${({ position }) => (position === 'left' ? '-2px' : 'auto')};
    left: ${({ position }) => (position === 'left' ? 'auto' : '-2px')};
    width: 2px;
    height: calc(100% - 32px);

    background-color: ${({ theme }) => theme.backgroundColor};
    box-shadow: ${({ theme }) => theme.sheetShadows};

    @media print {
      display: none;
    }
    @media (max-width: 1023px) {
      display: none;
    }
  }
`;
export default Sheet;

//** Wrapper for a pair of sheets within a page */
export const SheetsWrapper = styled.main.attrs(() => ({
  // Special class that works within FilterAside's animations
  className: 'sheets-wrapper',
}))`
  position: relative;
  flex: 1;
  /* IE10+ CSS styles go here */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 100%;
  }

  display: flex;
  justify-content: center;
  align-items: stretch;

  min-width: 0;
  max-width: 1200px;
  margin: 12px;

  /* Full width on mobile and change flex-direction */
  @media (max-width: 1023px) {
    max-width: 100%;
    margin: 0 !important;
    flex-direction: column;
  }

  /* Nothing fancy when printing */
  @media print {
    display: block;
  }
`;

// Sheet scrollable (main section)
export const SheetScrollable = styled.div`
  position: relative;
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 1 auto;
  }
`;

// Sheet Subhead Stuff
const SheetSubheadWrapper = styled.div`
  padding: 12px 24px 0;
  margin: 0;

  @media (max-width: 1023px) {
    padding: 12px 12px 0;
  }
`;
const SheetSubheadTitle = styled.h3`
  margin: 0;
  padding-bottom: 8px;

  color: ${props => props.theme.textTertiary};
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.02em;
`;
interface SheetSubheadProps {
  title?: string;
}
export const SheetSubhead = ({ title = '' }: SheetSubheadProps = {}) => (
  <SheetSubheadWrapper>
    <SheetSubheadTitle>{title}</SheetSubheadTitle>
  </SheetSubheadWrapper>
);
