import { memo } from 'react';
// Libs/helpers
import { db } from '@/lib/firebase';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
// Models
import Doc from '@/classes/Doc';
import User from '@/classes/User';
// Context data
import useAppState, { useSetAppState } from '@/contexts/appState';
import useUser from '@/contexts/user';
import useOrganization from '@/contexts/organization';
// Layout components
import SheetHeader from './SheetHeader';
// Common components
import Modal from '@/components/common/Modal';
import { SecondaryButton } from '@/components/common/Buttons';
// Misc/lib components
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

// Component
export default function ScheduleACallModal() {
  const { isScheduleACallModalOpen, organizationDocPath } = useAppState();
  const setAppState = useSetAppState();
  const [user] = useUser();
  const [organization] = useOrganization();

  return (
    <Modal
      handleClose={() => setAppState({ isScheduleACallModalOpen: false })}
      forwardedKey='calendly-modal'
    >
      {isScheduleACallModalOpen && (
        <>
          <SheetHeader
            primary
            mainTitle='Schedule a Setup Call'
            leadingIcon='event'
            trailing={
              <SecondaryButton
                leadingIcon='close'
                onClick={() => setAppState({ isScheduleACallModalOpen: false })}
              />
            }
          />
          {/* Main content */}
          {user && organizationDocPath && (
            <MemoModalContent
              user={user}
              organizationDocPath={organizationDocPath}
              organizationName={organization?.profile.name ?? ''}
              organizationCms={organization?.profile.cms ?? ''}
            />
          )}
        </>
      )}
    </Modal>
  );
}

interface ModalProps {
  user: Doc<User>;
  organizationDocPath: string;
  organizationName: string;
  organizationCms: string;
}

const MemoModalContent = memo<ModalProps>(function MemoModalContent({
  user,
  organizationDocPath,
  organizationName,
  organizationCms,
}: ModalProps) {
  useCalendlyEventListener({
    onEventScheduled: () =>
      db.doc(organizationDocPath).update({
        ...generateUpdatedByDottedMeta(user),
        'profile.setupMeetingStatus': 'scheduled',
      }),
  });

  return (
    <InlineWidget
      styles={{ position: 'relative', height: 'calc(100% - 64px)' }}
      key='calendly-scheduler'
      pageSettings={{
        backgroundColor: 'ffffff',
        hideEventTypeDetails: true,
        primaryColor: '046ADB',
        textColor: '1F2933',
      }}
      prefill={{
        customAnswers: { a1: organizationName, a3: organizationCms },
        email: user.profile.email ?? '',
        firstName: user.profile.name.first ?? '',
        lastName: user.profile.name.last ?? '',
        name: user.profile.name.full ?? '',
      }}
      url='https://calendly.com/notebird/notebird-account-setup'
    />
  );
});
