import styled, { css } from 'styled-components';

interface Props {
  padding?: string;
  mobilePadding?: string;
}
const Padding = styled.div<Props>`
  padding: ${({ padding }) => (padding != null ? padding : '0')};
  ${({ mobilePadding }) =>
    mobilePadding != null &&
    css`
      @media (max-width: 1023px) {
        padding: ${mobilePadding};
      }
    `};
`;

export default Padding;
