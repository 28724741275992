import { createContext, useContext, useEffect, useState } from 'react';

import Doc from '@/classes/Doc';
import Organization from '@/classes/Organization';
import { TERMINAL_STATES } from '@/classes/Stripe';

import { fbAnalytics } from '@/lib/firebase';
import { setUserProperties } from 'firebase/analytics';

import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';
import { useIntercom } from 'react-use-intercom';

import useAppState, { useSetAppState } from './appState';
import useOrganizations from './organizations';

// Context ( with hook shortcut )
const fetchingOrganization: [Doc<Organization> | null, boolean] = [null, true];
const organizationContext = createContext(fetchingOrganization);
const useOrganization = () => useContext(organizationContext);
export default useOrganization;

// Context definition w/ provider
export const OrganizationProvider = ({ children }: { children: React.ReactNode }) => {
  const { uid, organizationId } = useAppState();
  const setAppState = useSetAppState();
  const [organizations, organizationsAreFetching] = useOrganizations();

  const { update } = useIntercom();

  // Set organization ( and other helpers ) appropriately
  const [organization, setOrganization] = useState(fetchingOrganization);
  useEffect(() => {
    if (organizations?.length) {
      // Get next organization based on appState's organizationId
      // ( or reset if can't find it in current list )
      const nextOrganization =
        organizations.find(({ id }) => id === organizationId) || organizations[0];
      setOrganization([nextOrganization || null, false]);
      setAppState({
        organizationId: nextOrganization.id,
        organizationDocPath: nextOrganization.docPath,
        isOrganizationAdmin: nextOrganization.admins.includes(uid || ''),
        isOnboarding: nextOrganization.profile.isOnboarding,
      });
    }
    // Unset if done fetching organizations and yet nothing can be found
    else if (!organizationsAreFetching) {
      setOrganization([null, false]);
      setAppState({
        organizationId: null,
        organizationDocPath: null,
        isOrganizationAdmin: false,
        isOnboarding: false,
      });
    }
  }, [organizationId, organizations, organizationsAreFetching, uid, setAppState]);

  // Keep stripe states (isActive, isTrialing, willEnd, daysRemaining,
  // isCancelled, and hasCardError ) in sync with appState
  const stripe = organization[0]?._stripe;
  const periodEndMillis = stripe?.currentPeriodEnd?.toMillis();
  const isTrialing = stripe?.status === 'trialing';
  const willEnd = stripe?.status !== 'trialing' && !!stripe?.cancelAtPeriodEnd;
  const isCanceled = !!stripe?.status && TERMINAL_STATES.includes(stripe.status);
  const hasCardError = [
    'incomplete',
    'requires_payment_method',
    'requires_action',
    'past_due',
  ].includes(stripe?.status ?? '');
  useEffect(() => {
    if (periodEndMillis) {
      const daysRemaining = DateTime.fromMillis(periodEndMillis).diffNow('days').as('days');
      setAppState({
        isActive: daysRemaining > 0,
        isTrialing,
        daysRemaining,
        willEnd,
        isCanceled,
        hasCardError,
      });
    }
  }, [periodEndMillis, isTrialing, willEnd, isCanceled, hasCardError, setAppState]);

  // Tick on `disableQueries` when importing/integrating
  const integrationIsPending = organization[0]?._integration?.status === 'pending';
  useEffect(() => {
    setAppState({ disableQueries: integrationIsPending });
  }, [integrationIsPending, setAppState]);

  // Keep organization up to date in posthog analytics
  const posthog = usePostHog();
  const organizationName = organization[0]?.profile.name;
  const organizationType = organization[0]?.profile.type;
  useEffect(() => {
    if (organizationId && organizationName && organizationType) {
      posthog?.group('organization', organizationId, {
        name: organizationName,
        type: organizationType,
      });
    }
  }, [organizationId, organizationName, organizationType, posthog]);

  // Keep analytics and intercom's `organization_type` user property in sync
  const organization_type = organization[0]?.profile.type;
  useEffect(() => {
    if (organization_type) {
      update({
        customAttributes: { organization_type },
      });
      setUserProperties(fbAnalytics, { organization_type });
    }
  }, [organization_type, update]);

  return (
    <organizationContext.Provider value={organization}>{children}</organizationContext.Provider>
  );
};
