import { createGlobalStyle, keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

const BaseStyles = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}
html, body {
  background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.textPrimary};
    height: 100%;
    width: 100%;
    width: 100vw;
    overflow: hidden;
  }
  button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
  }
  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
    &:focus{
      outline: none;
      text-decoration: underline;
    }
  }
  .hidden-mobile {
    @media(max-width: 1023px){
      display: none !important;
    }
  }
  .shown-mobile {
    @media(min-width: 1024px){
      display: none !important;
    }
  }
  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms ${({ theme }) => theme.easeDecelerate} 150ms forwards;
  }
  .material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
`;

export default BaseStyles;
