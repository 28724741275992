// Core
import styled from 'styled-components';

interface LabelProps {
  children: React.ReactNode;
  padding?: string;
  active?: boolean;
}
// Styles
const StyledLabel = styled.div<LabelProps>`
  display: block;
  margin-bottom: 4px;
  padding: ${props => (props.padding ? props.padding : '0')};

  color: ${props => (props.active ? props.theme.textPrimary : props.theme.textTertiary)};
  font-weight: bold;
  letter-spacing: 0.025em;
`;

// Component
const Label = ({ padding, children }: LabelProps) => {
  return <StyledLabel padding={padding}>{children}</StyledLabel>;
};
export const FormLabel = ({ children, ...rest }: LabelProps & { htmlFor?: string }) => {
  return (
    <StyledLabel as='label' {...rest}>
      {children}
    </StyledLabel>
  );
};

// Export
export default Label;
