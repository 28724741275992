import { type ComponentProps } from 'react';

import { useField, useFormikContext } from 'formik';

import FormTextareaGroup from './styled/FormTextareaGroup';

interface Props extends ComponentProps<typeof FormTextareaGroup> {
  label?: React.ReactNode;
  labelIcon?: string;
  optional?: boolean;
  name: string;
  dense?: boolean;
  minRows?: number;
  maxRows?: number;
}

const FormikTextarea = ({ name, disabled, ...rest }: Props) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <FormTextareaGroup
      error={meta.touched ? meta.error : undefined}
      disabled={disabled || isSubmitting}
      {...rest}
      {...field}
    />
  );
};
export default FormikTextarea;
