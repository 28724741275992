import styled from 'styled-components';

const DangerCopy = styled.div`
  font-size: 20px;
  line-height: 1.25;
  color: ${({ theme }) => theme.danger400};
  em,
  strong {
    color: ${({ theme }) => theme.danger500};
  }
  strong {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.avatarText};
  }
`;
export default DangerCopy;
