import styled from 'styled-components';

const NoneFoundCopy = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 1.25;
  color: ${props => props.theme.textFaded};
`;
export default NoneFoundCopy;
