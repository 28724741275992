import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import CurrentRelease from '@/classes/CurrentRelease';

import { fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { compare } from 'compare-versions';
import { usePostHog } from 'posthog-js/react';

import useCurrentRelease from '@/contexts/currentRelease';

import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Loader from '@/components/common/Loader';
import NoWrap from '@/components/common/NoWrap';
import Spacer from '@/components/common/Spacer';

const Backdrop = styled.div`
  display: block;
  z-index: 20000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.grey900};
  opacity: 0.4;
  pointer-events: auto;
`;
const Panel = styled.div`
  z-index: 20001;

  position: fixed;
  left: 4px;
  bottom: 4px;
  padding: 32px 24px;
  margin-bottom: calc(env(safe-area-inset-bottom) - 4px);

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  background-color: ${({ theme }) => theme.primary000};
  border: 2px solid ${({ theme }) => theme.primary100};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow200};
`;
const CloseButton = styled(SecondaryButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const Heading = styled.div`
  margin: 8px 0 24px;
  font-weight: bold;
  font-size: 22px;
  color: ${({ theme }) => theme.primary600};
`;
const Row = styled.div`
  display: inline-flex;
  align-items: center;
`;

const UpdateNotebirdPrompt = () => {
  const currentRelease = useCurrentRelease();
  const { version, minVersion, link } = currentRelease || new CurrentRelease();
  const posthog = usePostHog();

  const [isRefreshing, setIsRefreshing] = useState(false);
  // Reset dismissed state if version or minVersion changes
  const [dismissed, setDismissed] = useState(false);
  const resetDismissedToken = `${version}${minVersion}`;
  useEffect(() => {
    setDismissed(false);
  }, [resetDismissedToken]);

  // Check to see if user is on latest version
  const [hasUpdate, forceUpdate] = useMemo(() => {
    const hasUpdate = version ? compare(import.meta.env.VITE_VERSION, version, '<') : false;
    const forceUpdate = minVersion ? compare(import.meta.env.VITE_VERSION, minVersion, '<') : false;
    return [hasUpdate, forceUpdate];
  }, [version, minVersion]);

  // Don't render if doesn't have update or has been dismissed
  if (!hasUpdate || dismissed) return null;

  return (
    <>
      <Panel className='fade-in'>
        <Loader show={isRefreshing} />

        {!forceUpdate && (
          <CloseButton leadingIcon='close' dull onClick={() => setDismissed(true)} />
        )}
        <Heading>
          {forceUpdate ? (
            <>
              Your version of Notebird <NoWrap>is out of date!</NoWrap>
            </>
          ) : (
            <>
              A new version of <NoWrap>Notebird is ready!</NoWrap>
            </>
          )}
        </Heading>
        <Row>
          <PrimaryButton
            small
            leadingIcon='refresh'
            onClick={() => {
              setIsRefreshing(true);
              const analyticsProps = {
                old_version: import.meta.env.VITE_VERSION,
                new_version: version,
              };
              posthog?.capture('version_forced_update', analyticsProps);
              logEvent(fbAnalytics, 'force_update', analyticsProps);
              window.location.reload();
            }}
            disabled={isRefreshing}
          >
            <strong>Update Notebird</strong>
          </PrimaryButton>
          <Spacer width='16px' />
          {!!link && (
            <a
              href={link}
              target='_blank'
              rel='noopener noreferrer'
              style={{ whiteSpace: 'nowrap' }}
            >
              What&apos;s new?
            </a>
          )}
        </Row>
      </Panel>
      {forceUpdate && <Backdrop />}
    </>
  );
};
export default UpdateNotebirdPrompt;
