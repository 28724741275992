import { createContext, useContext, useEffect, useMemo } from 'react';

import Doc from '@/classes/Doc';
import Group from '@/classes/Group';

import { db, fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import orderBy from 'lodash/orderBy';

import useCollection from '@/hooks/useCollection';

import useAppState, { useSetAppState } from './appState';
import useLocation from './location';
import useUser from './user';

// Contexts ( with hook shortcuts )
const fetchingGroups: [Doc<Group>[] | null, boolean] = [null, true];
const groupsContext = createContext(fetchingGroups);
const useGroups = () => useContext(groupsContext);
export default useGroups;
// Groups separated into user following and not
const fetchingFollowedGroups: { followedGroups: Doc<Group>[]; unfollowedGroups: Doc<Group>[] } = {
  followedGroups: [],
  unfollowedGroups: [],
};
const followedGroupsContext = createContext(fetchingFollowedGroups);
export const useFollowedGroups = () => useContext(followedGroupsContext);

// Context definition w/ provider
export const GroupsProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const {
    uid,
    organizationId,
    isOnboarding,
    locationId,
    isLocationAdmin,
    groupId,
    hasRestrictedAccess,
  } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [user] = useUser();
  const [location, locationIsFetching] = useLocation();

  const groupsStream = useCollection<Group>(
    !!organizationId && !isOnboarding && db.collection(`organizations/${organizationId}/groups`)
  );

  // Pick out groups for group members ( and alphabetize )
  const filteredGroups = useMemo(() => {
    const [groupDocs, groupsAreFetching] = groupsStream;
    if (locationIsFetching || !location || !groupDocs) {
      return fetchingGroups;
    }
    const orderedGroups = orderBy(groupDocs, 'name');
    if (!isLocationAdmin && location) {
      const memberGroups = location.groupMembers[uid ?? ''] ?? [];
      return [
        orderedGroups.filter(({ id }) => memberGroups.includes(id)),
        groupsAreFetching,
      ] as typeof groupsStream;
    }
    return [orderedGroups, groupsAreFetching] as typeof groupsStream;
  }, [locationIsFetching, groupsStream, isLocationAdmin, location, uid]);

  // Separate groups into followed and unfollowed for this user ( at this location )
  const [groupDocs] = filteredGroups;
  const followedGroups = useMemo(() => {
    if (groupDocs && organizationId && locationId) {
      // This was my fist line of javascript code with optional chaining and nullish coalescing operator.
      // Been waiting for this for a long time... What a happy day!!! 😂 11.21.19
      // ( I'm both a little proud an embarrased to be writing such a nerdy comment for posterity, too... )
      const followedGroupIds = user?.following?.[organizationId]?.[locationId]?.groups ?? [];
      return {
        followedGroups: groupDocs.filter(({ id }) => followedGroupIds.includes(id)),
        unfollowedGroups: groupDocs.filter(({ id }) => !followedGroupIds.includes(id)),
      };
    }
    return fetchingFollowedGroups;
  }, [groupDocs, organizationId, locationId, user]);

  // Ensure groupId is always a valid group and save group name to state
  useEffect(() => {
    if (groupId && groupDocs) {
      const group = groupDocs.find(({ id }) => id === groupId);
      setAppState(
        group ? { groupName: group.name } : { contentFilter: 'all', groupId: null, groupName: null }
      );
    }
    // No group, remove name
    else {
      setAppState({ groupName: null });
    }
  }, [groupId, groupDocs, setAppState]);

  // Keep `hasSingleGroup` app state flag in sync
  const groupsCount = groupDocs?.length || 2;
  useEffect(() => {
    setAppState({ hasSingleGroup: groupsCount === 1 });
  }, [groupsCount, setAppState]);

  // Keep `typesensePeopleKey` and `typesenseUpdatesKey` app state flag in sync
  // ( generate on load, whenever groups change, and when restricted access changes )
  const groupsEffectKey = groupDocs?.map(({ id }) => id).join(',') || '';
  useEffect(() => {
    async function generateKeys() {
      if (
        organizationId &&
        locationId &&
        groupsEffectKey &&
        location?.docPath.includes(organizationId) // This prevents an error on the server when switching organizations
      ) {
        try {
          const {
            data: { peopleKey, updatesKey },
          } = await httpsCallable<unknown, { peopleKey: string; updatesKey: string }>(
            fbFunctions,
            'generateTypesenseKeys'
          )({
            organizationId,
            locationId,
          });
          setAppState({
            typesensePeopleKey: peopleKey || null,
            typesenseUpdatesKey: updatesKey || null,
          });
        } catch (error) {
          // This might occur just after finishing onboarding or when switching organizations
          console.error('An error occurred fetching typesense keys', {
            error,
            organizationId,
            locationId,
            locationIsFetching,
          });
          setAppState({ typesensePeopleKey: null, typesenseUpdatesKey: null });
        }
      }
    }
    generateKeys();
  }, [
    groupsEffectKey,
    locationIsFetching,
    locationId,
    organizationId,
    setAppState,
    hasRestrictedAccess,
    location?.docPath,
  ]);

  return (
    <groupsContext.Provider value={filteredGroups}>
      <followedGroupsContext.Provider value={followedGroups}>
        {children}
      </followedGroupsContext.Provider>
    </groupsContext.Provider>
  );
};
