import styled from 'styled-components';
import Select from './FormSelect';
import { type GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';

type ReactCreatableSelectType = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: CreatableProps<Option, IsMulti, Group>
) => React.ReactElement;
const ReactCreatableSelect = styled(Select).attrs({
  as: CreatableSelect,
})`` as ReactCreatableSelectType;
export default ReactCreatableSelect;
