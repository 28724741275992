import styled from 'styled-components';

const NoneFoundHeader = styled.div`
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.textSecondary};
`;
export default NoneFoundHeader;
