import { useState, useRef } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

export const TransitionListWrapper = ({ children }: { children: React.ReactNode }) => (
  <TransitionGroup component={null}>{children}</TransitionGroup>
);

const StyledAccordion = styled.div<{ mountHeight: number }>`
  /* Enter */
  & > div.enter {
    opacity: 0;
    height: 0;
  }
  & > div.enter-active {
    transition: opacity 300ms 100ms, height 300ms ${({ theme }) => theme.easeStandard};
    opacity: 1;
    height: ${({ mountHeight }) => mountHeight}px;
  }
  /* Exit */
  & > div.exit {
    opacity: 1;
    height: ${({ mountHeight }) => mountHeight}px;
  }
  & > div.exit-active {
    transition: opacity 250ms, height 250ms ${({ theme }) => theme.easeStandard} 100ms;
    opacity: 0;
    height: 0;
  }
`;

const TransitionList = ({ children, style, ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  // Value to animate elements after currently collapsing ones
  const [transitionHeight, setTransitionHeight] = useState(0);
  const nodeRef = useRef<HTMLDivElement>(null);
  return (
    <StyledAccordion mountHeight={transitionHeight} style={style}>
      <CSSTransition
        {...rest}
        nodeRef={nodeRef}
        timeout={{ enter: 400, exit: 350 }}
        component={null}
        onEnter={() => setTransitionHeight(nodeRef.current?.scrollHeight ?? 0)}
        onExit={() => setTransitionHeight(nodeRef.current?.scrollHeight ?? 0)}
        // Clear offset whenever animation completes
        onEntered={() => setTransitionHeight(0)}
        onExited={() => setTransitionHeight(0)}
        unmountOnExit
      >
        <div ref={nodeRef}>{children}</div>
      </CSSTransition>
    </StyledAccordion>
  );
};
export default TransitionList;
