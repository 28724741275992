import Meta from './Meta';
import { UserProfile } from './User';

export const INVITATION_EXPIRATION_DAYS = 15;

export default class Invitation {
  meta = { ...new Meta() };
  profile = { ...new UserProfile() };
  organizationName = '';
  isOrganizationAdmin = false;
  hasRestrictedAccess = false;
  locations: { [locationId: string]: { isAdmin: boolean; groups: string[] } } = {};
}
