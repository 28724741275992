import { useMemo } from 'react';

import Doc from '@/classes/Doc';
import Person from '@/classes/Person';
import Task from '@/classes/Task';

import firebase, { db, fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { MAX_GROUP_ASSIGNMENTS } from '@/lib/config';
import generateCreatedByMeta, {
  generateUpdatedByDottedMeta,
  generateUpdatedByMeta,
} from '@/lib/helpers/generateMeta';

import { Formik } from 'formik';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { usePostHog } from 'posthog-js/react';
import { array, object, string } from 'yup';

import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useUser from '@/contexts/user';
import { useAllUsers } from '@/contexts/usersList';

import Scrollable from '@/components/layout/Scrollable';

import FormikDatePicker from '@/components/formElements/FormikDatePicker';
import FormikFooter from '@/components/formElements/FormikFooter';
import FormikForm from '@/components/formElements/FormikForm';
import FormikGroupsSelect from '@/components/formElements/FormikGroupsSelect';
import FormikHeader from '@/components/formElements/FormikHeader';
import FormikTaskNotes from '@/components/formElements/FormikTaskNotes';
import FormikUserSelect from '@/components/formElements/FormikUserSelect';

import Divider from '@/components/common/DividerTwo';
import Expanded from '@/components/common/Expanded';
import FlexColumn from '@/components/common/FlexColumn';
import FlexRow from '@/components/common/FlexRow';
import Margin from '@/components/common/Margin';
import Spacer from '@/components/common/Spacer';

// Form validation
const schema = object().shape({
  notes: string().required('Task notes are required').trim().max(500, '500 characters max'),
  groups: array().required().min(1, 'At least 1 group required'),
});

// Props
interface FormProps {
  person: Doc<Person>;
  task?: Doc<Task>;
  handleCancel: () => void;
  handleDelete?: () => void;
}

// Component
const TaskForm = ({ person, task, handleCancel }: FormProps) => {
  // App state
  const { uid, organizationDocPath, locationId, isSingleUser, isLocationAdmin, locationDocPath } =
    useAppState();
  // Context data
  const [user] = useUser();
  const allUsers = useAllUsers();
  const [groups] = useGroups();
  const posthog = usePostHog();

  // Establish initial values ( new vs editing )
  const userGroupIds = useMemo(() => groups?.map(({ id }) => id) || [], [groups]);
  const initialValues = useMemo(() => {
    const { notes, groups, dueDate, assignedTo } = task || {
      notes: null,
      // Always start with person's groups
      // ( or if user is only in one group, do that )
      groups:
        userGroupIds.length === 1
          ? [userGroupIds[0]]
          : person.groups[0] === 'ungrouped'
            ? []
            : intersection(person.groups, userGroupIds),
      assignedTo: { id: uid },
    };
    return {
      notes,
      groups,
      dueDate: dueDate || '',
      assignedToId: (isSingleUser && uid) || assignedTo?.id || '',
      saveTask: false,
    };
  }, [task, person.groups, userGroupIds, uid, isSingleUser]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => {
        if (user && organizationDocPath && locationId && locationDocPath) {
          try {
            // Merge with 'unkown' groups if group member
            const unkownGroups = person ? difference(initialValues.groups, userGroupIds) : [];
            const taskGroups = uniq([...unkownGroups, ...values.groups]);

            // Get user
            const assignedToUser = allUsers.find(({ id }) => id === values.assignedToId);
            const taskFromForm: Task = {
              meta: task ? generateUpdatedByMeta(user, task.meta) : generateCreatedByMeta(user),
              // Assigned to user
              assignedTo: assignedToUser
                ? {
                    id: assignedToUser.id,
                    profile: assignedToUser.profile,
                  }
                : null,
              // Completed by user
              // ( can't edit a completed task, so this, technically, should always be null in this form )
              completedBy: task ? task.completedBy : null,
              // Person
              person: { id: person.id, profile: person.profile },
              // Permissions and references
              locationId,
              groups: taskGroups,
              isArchived: person.isArchived,
              // Core info
              notes: values.notes?.trim() || '',
              dueDate: values.dueDate || null,
              isCompleted: task ? task.isCompleted : false,
              completedAt: task ? task.completedAt : null,
            };

            // Batch write task doc
            const batch = db.batch();
            const taskRef = task
              ? db.doc(`${organizationDocPath}/tasks/${task.id}`)
              : db.collection(`${organizationDocPath}/tasks`).doc();
            batch.set(taskRef, taskFromForm, { merge: true });

            // Make sure person is in all groups if isn't already in any given group
            // ( same as update form )
            // Remove consideration of ['ungrouped'] person
            const wasUngrouped = person.groups[0] === 'ungrouped';
            const personGroups = wasUngrouped ? [] : person.groups;
            // Find which ones are new ( out of total 10 for the person )
            const maxRemainingCount = MAX_GROUP_ASSIGNMENTS - personGroups.length;
            const newGroups = difference(values.groups, personGroups).slice(0, maxRemainingCount);
            if (newGroups.length) {
              batch.update(db.doc(person.docPath), {
                ...generateUpdatedByDottedMeta(user),
                groups: wasUngrouped
                  ? newGroups
                  : firebase.firestore.FieldValue.arrayUnion(...newGroups),
              });
            }

            // If checked, save task as a location favorite
            if (isLocationAdmin && values.saveTask && !!values.notes) {
              batch.update(db.doc(locationDocPath), {
                ...generateUpdatedByDottedMeta(user),
                savedTasks: firebase.firestore.FieldValue.arrayUnion(values.notes),
              });
            }

            // Commit batch
            batch.commit();

            // Analytics
            const analyticsProps = {
              is_assigned: !!assignedToUser,
              has_due_date: !!values.dueDate,
              notes_length: taskFromForm.notes.length,
              groups_count: taskFromForm.groups.length,
            };
            posthog?.capture(!task ? 'task_created' : 'task_edited', analyticsProps);
            logEvent(fbAnalytics, !task ? 'task_create' : 'task_edit', analyticsProps);
          } catch (error) {
            // Otherwise report error in console and to user
            console.error(error);
            window.alert(error);
          }
        }

        // Close form when finished
        handleCancel();
      }}
    >
      <FormikForm
        intercomTarget='Task form'
        // Dissallow submissions when either notes or groups are empty OR
        // editing task and nothing in form values has changed
        disableSubmit={(values: typeof initialValues) =>
          !values.notes?.trim() ||
          !values.groups.length ||
          (!!task && isEqual(initialValues, values))
        }
      >
        <FlexColumn>
          {/* Header */}
          <FormikHeader
            leadingIcon={task ? 'edit' : 'playlist_add_check'}
            mainTitle={task ? 'Edit task' : 'New task'}
            handleCancel={handleCancel}
          />

          {/* Form body */}
          <Scrollable>
            {/* Section 1 ( notes, assigned to, and due date ) */}
            <Margin margin='32px 48px 32px' mobileMargin='32px 16px'>
              {/* Notes creatable select */}
              <FormikTaskNotes
                showSaved
                autoFocus={!task}
                placeholder='Write task here...'
                savePlaceName='saveTask'
              />
              <Spacer height='24px' />

              <FlexRow>
                {/* Assigned to ( user selector ) */}
                <Expanded>
                  <FormikUserSelect
                    label='Assigned to'
                    name='assignedToId'
                    intercomTarget='Task assigned-to field'
                  />
                </Expanded>
                <Spacer width='16px' />

                {/* Due date */}
                <Expanded>
                  <FormikDatePicker
                    label='Due date'
                    name='dueDate'
                    intercomTarget='Task due date field'
                  />
                </Expanded>
              </FlexRow>
            </Margin>
            <Divider margin={24} />

            {/* Section 2 ( groups/visible to ) */}
            <Margin margin='24px 48px 0' mobileMargin='32px 16px 0'>
              <FormikGroupsSelect
                label='Task visibility'
                placeholder='Select group(s)'
                person={person}
                // Disable if user only has one group
                disabled={userGroupIds.length === 1}
                intercomTarget='Task groups select'
              />
            </Margin>
          </Scrollable>

          {/* Footer */}
          <FormikFooter
            handleCancel={handleCancel}
            submitButton={
              <>
                <strong>Save</strong> task
              </>
            }
            submitIntercomTarget='Save task button'
          />
        </FlexColumn>
      </FormikForm>
    </Formik>
  );
};
export default TaskForm;
