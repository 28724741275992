import Notebirb from './Notebirb';
import Photo from './Photo';

export class UserProfile {
  photo: Photo | null = null;
  notebirb = { ...new Notebirb() };
  preferNotebirb = false;
  email = '';
  name = {
    first: '',
    last: '',
    full: '',
    display: '',
  };
}

export const ADMIN_PROFILE: UserProfile = {
  photo: null,
  notebirb: { index: 3, colorwayIndex: 3 },
  preferNotebirb: false,
  email: 'support@notebird.app',
  name: {
    first: 'Notebird',
    last: 'Admin',
    full: 'Notebird Admin',
    display: 'admin',
  },
};

export default class User {
  _intercomId: string | null = null;
  _signedUpAt: firebase.firestore.Timestamp | null = null;
  _supportVerification: { code: string; seen: boolean; closed: boolean } | null = null;
  isEmailVerified = false;
  multiFactorSms: string | null = null;
  profile = { ...new UserProfile() };
  following: {
    [organizationId: string]: {
      [locationId: string]: {
        people?: string[];
        peopleArchived?: string[];
        groups?: string[];
      };
    };
  } = {};
  preferences = {
    visualTheme: 'primary' as 'primary' | 'cardinal' | 'oriole' | 'parakeet',
    isDarkModeEnabled: false,
    newActivityEmails: true,
    newActivityPush: true,
    newActivityType: 'peopleAndGroups' as 'people' | 'peopleAndGroups' | 'all',
    taskAssignmentEmails: true,
    taskAssignmentPush: true,
    earlyBirdReportEmails: true,
    earlyBirdLocalHour: 9,
    earlyBirdUtcHour: null as number | null,
    earlyBirdTimezone: null as string | null,
    whatsAppCode: null as string | null,
  };
}
