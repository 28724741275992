import { createContext, useContext, useMemo } from 'react';
// Libs
import firebase from '@/lib/firebase';
import { DateTime } from 'luxon';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import fuzzy from '@/lib/helpers/fuzzy';
// Classes
import { UserProfile } from '@/classes/User';
import { INVITATION_EXPIRATION_DAYS } from '@/classes/Invitation';
// Context data
import useAppState from './appState';
import useUsers from './users';
import useOrganization from './organization';
import useLocation from './location';
import useGroups from './groups';
import useInvitations from './invitations';

export class UsersListItem {
  id = '';
  docPath = '';
  isOrganizationOwner = false;
  isOrganizationAdmin = false;
  isLocationAdmin = false;
  isLocationUser = false;
  groups = [] as string[];
  hasRestrictedAccess = false;
  isInvitation = false;
  invitationCreatedAt = null as firebase.firestore.Timestamp | null;
  invitationExpiresAt = null as DateTime | null;
  isArchived = false;
  profile = { ...new UserProfile() };
}

// This context is the full list good for user lookup in profile
// or task assignments
const allUsersContext = createContext<UsersListItem[]>([]);
export const useAllUsers = () => useContext(allUsersContext);

// UsersList context ( with hook shortcut )
// ( this is used on the users page, dressed up all nice and perdy )
const filteredUsersContext = createContext<UsersListItem[]>([]);
export const useFilteredUsers = () => useContext(filteredUsersContext);

// UserCounts context ( with hook shortcut )
class UserCounts {
  all = 1;
  admins = 1;
  groups = {} as { [key: string]: number };
  invitations = 0;
  archived = 0;
}
const userCountsContext = createContext({ ...new UserCounts() });
export const useUserCounts = () => useContext(userCountsContext);

// Context definition w/ provider
export const UsersListProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const { groupId, contentFilter, showArchivedUsers } = useAppState();
  // Context data
  const [organization] = useOrganization();
  const [location] = useLocation();
  const [groups] = useGroups();

  // Format/contextualize users
  const [users] = useUsers();
  const formattedUsers = useMemo<UsersListItem[]>(() => {
    if (organization && location && users) {
      return users.map(({ id, docPath, ...profile }) => {
        const isOrganizationOwner = organization.owner.id === id;
        const isOrganizationAdmin = organization.admins.includes(id);
        const isLocationAdmin = !isOrganizationAdmin && location.admins.includes(id);
        const isLocationUser =
          !isOrganizationAdmin && !isLocationAdmin && location.users.includes(id);
        // Some admins may be members of groups from past group membership,
        // but those don't need to reflect here
        const groups = isLocationUser && location.groupMembers[id] ? location.groupMembers[id] : [];
        const hasRestrictedAccess =
          isOrganizationOwner ||
          ((isOrganizationAdmin || isLocationAdmin) &&
            organization.restrictedAccessUsers.includes(id));
        const isArchived = organization.archivedUsers.includes(id);
        return {
          id,
          docPath,
          isOrganizationOwner,
          isOrganizationAdmin,
          isLocationAdmin,
          isLocationUser,
          groups,
          hasRestrictedAccess,
          isInvitation: false,
          invitationCreatedAt: null,
          invitationExpiresAt: null,
          isArchived,
          profile,
        };
      });
    }
    return [];
  }, [location, organization, users]);

  // Format/contextualize invitations
  const [invitations] = useInvitations();
  const formattedInvitations = useMemo<UsersListItem[]>(() => {
    if (organization && location && invitations) {
      return invitations.map(
        ({ id, docPath, meta, profile, isOrganizationAdmin, locations, hasRestrictedAccess }) => {
          const userLocation = locations[location.id];
          const isLocationAdmin = !isOrganizationAdmin && userLocation?.isAdmin;
          return {
            id,
            docPath,
            isOrganizationOwner: false,
            isOrganizationAdmin,
            isLocationAdmin,
            isLocationUser: !isOrganizationAdmin && !isLocationAdmin && !!userLocation,
            groups: userLocation ? userLocation.groups : [],
            hasRestrictedAccess: (isOrganizationAdmin || isLocationAdmin) && hasRestrictedAccess,
            isInvitation: true,
            invitationCreatedAt: meta.createdAt,
            invitationExpiresAt: DateTime.fromMillis(
              meta.createdAt ? meta.createdAt.toMillis() : new Date().getTime()
            ).plus({
              days: INVITATION_EXPIRATION_DAYS,
            }),
            isArchived: false,
            profile,
          };
        }
      );
    }
    return [];
  }, [organization, location, invitations]);

  // Merge users/invitations
  const mergedUsers = useMemo(
    () =>
      orderBy(
        [...formattedUsers, ...formattedInvitations],
        [u => (u.isOrganizationAdmin || u.isLocationAdmin ? 0 : 1), u => fuzzy(u.profile.name.full)]
      ),
    [formattedInvitations, formattedUsers]
  );

  // Filter users/invitations through location ( only org admins can see users
  // across locations at once when shwoAllLocations is selected )
  const locationFilteredUsers = useMemo(
    () =>
      mergedUsers.filter(
        formattedUser =>
          formattedUser.isOrganizationAdmin ||
          formattedUser.isLocationAdmin ||
          formattedUser.isLocationUser
      ),
    [mergedUsers]
  );
  // Filter the location-primed users through archive/not-archived
  const archiveFilteredUsers = useMemo(
    () => locationFilteredUsers.filter(({ isArchived }) => showArchivedUsers || !isArchived),
    [locationFilteredUsers, showArchivedUsers]
  );
  // Only admins for 'admins' aside tab
  const filteredAdmins = useMemo(
    () =>
      archiveFilteredUsers.filter(
        ({ isOrganizationAdmin, isLocationAdmin }) => isOrganizationAdmin || isLocationAdmin
      ),
    [archiveFilteredUsers]
  );
  // Only invitations for 'Pending invitations' aside tab
  const filteredInvitations = useMemo(
    () => locationFilteredUsers.filter(({ isInvitation }) => isInvitation),
    [locationFilteredUsers]
  );
  // Ungrouped/unassigned people at this location
  const filteredUngrouped = useMemo(
    () =>
      mergedUsers.filter(
        formattedUser =>
          !formattedUser.isOrganizationAdmin &&
          !formattedUser.isLocationAdmin &&
          !formattedUser.isLocationUser
      ),
    [mergedUsers]
  );

  // Finally, determine which subset of users to show and sort by name
  const sortedUsers = useMemo(() => {
    let sortedUsers: UsersListItem[];
    if (groupId) {
      sortedUsers = archiveFilteredUsers.filter(({ groups }) => groups.includes(groupId));
    } else {
      switch (contentFilter) {
        case 'admins':
          sortedUsers = filteredAdmins;
          break;
        case 'invitations':
          sortedUsers = filteredInvitations;
          break;
        case 'ungrouped':
          sortedUsers = filteredUngrouped;
          break;
        default:
          sortedUsers = archiveFilteredUsers;
      }
    }
    return sortBy(sortedUsers, ({ profile }) => fuzzy(profile.name.full));
  }, [
    archiveFilteredUsers,
    contentFilter,
    filteredAdmins,
    filteredInvitations,
    filteredUngrouped,
    groupId,
  ]);

  // Tabulate and set userCounts
  const userCounts = useMemo<UserCounts>(
    () => ({
      all: archiveFilteredUsers.length,
      admins: filteredAdmins.length,
      groups: groups
        ? Object.assign(
            {},
            ...groups.map(({ id }) => ({
              [id]: archiveFilteredUsers.filter(({ groups }) => groups.includes(id)).length,
            })),
            { ungrouped: filteredUngrouped.length }
          )
        : {},
      invitations: filteredInvitations.length,
      archived: locationFilteredUsers.filter(({ isArchived }) => isArchived).length,
    }),
    [
      archiveFilteredUsers,
      filteredAdmins.length,
      filteredInvitations.length,
      filteredUngrouped.length,
      groups,
      locationFilteredUsers,
    ]
  );

  return (
    <allUsersContext.Provider value={mergedUsers}>
      <filteredUsersContext.Provider value={sortedUsers}>
        <userCountsContext.Provider value={userCounts}>{children}</userCountsContext.Provider>
      </filteredUsersContext.Provider>
    </allUsersContext.Provider>
  );
};

export default undefined;
