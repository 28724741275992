import { useMemo } from 'react';

import Doc, { WithId } from '@/classes/Doc';
import Person from '@/classes/Person';
import Update from '@/classes/Update';

import firebase, { db, fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { MAX_GROUP_ASSIGNMENTS } from '@/lib/config';
import generateCreatedByMeta, {
  generateUpdatedByDottedMeta,
  generateUpdatedByMeta,
} from '@/lib/helpers/generateMeta';

import { Formik } from 'formik';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';
import { useIntercom } from 'react-use-intercom';
import { array, object, string } from 'yup';

import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useLocation from '@/contexts/location';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import Scrollable from '@/components/layout/Scrollable';

import FormikAutocompletePlace from '@/components/formElements/FormikAutocompletePlace';
import FormikDatePicker from '@/components/formElements/FormikDatePicker';
import FormikFooter from '@/components/formElements/FormikFooter';
import FormikForm from '@/components/formElements/FormikForm';
import FormikGroupsSelect from '@/components/formElements/FormikGroupsSelect';
import FormikHeader from '@/components/formElements/FormikHeader';
import FormikSelect from '@/components/formElements/FormikSelect';
import FormikTextarea from '@/components/formElements/FormikTextarea';
import FormikTimePicker from '@/components/formElements/FormikTimePicker';

import Divider from '@/components/common/DividerTwo';
import Expanded from '@/components/common/Expanded';
import FlexColumn from '@/components/common/FlexColumn';
import FlexRow from '@/components/common/FlexRow';
import Margin from '@/components/common/Margin';
import Spacer from '@/components/common/Spacer';

// Form validation
const schema = object().shape({
  notes: string().trim().max(5000, '5000 characters max'),
  groups: array().required().min(1, 'At least 1 group required'),
});

// Props
interface FormProps {
  person: Doc<Person>;
  update?: WithId<Update>;
  handleCancel: () => void;
}
// Core form
const UpdateForm = ({ person, update, handleCancel }: FormProps) => {
  // App state
  const { uid, organizationDocPath, locationId, isLocationAdmin } = useAppState();
  // Context data
  const [organization] = useOrganization();
  const [location] = useLocation();
  const [user] = useUser();
  // Context data
  const [groups] = useGroups();

  const { trackEvent } = useIntercom();
  const postHog = usePostHog();

  // Establish initial values ( new vs editing )
  const userGroupIds = useMemo(() => groups?.map(({ id }) => id) || [], [groups]);
  const initialValues = useMemo(() => {
    const { type, notes, groups, place, visibility } = update || {
      type: '',
      notes: '',
      // Always start with person's groups
      // ( or if user is only in one group, do that )
      groups:
        userGroupIds.length === 1
          ? [userGroupIds[0]]
          : person.groups[0] === 'ungrouped'
            ? []
            : intersection(person.groups, userGroupIds),
      visibility: 'groups',
      place: null,
    };
    const createdAt = update?.meta.createdAt?.toMillis();
    const dateTime = createdAt
      ? DateTime.fromMillis(createdAt).startOf('minute')
      : DateTime.local().startOf('minute');
    const date = dateTime.toISODate();
    const time = dateTime.toFormat('HH:mm');
    return {
      type,
      notes,
      groups,
      isRestricted: visibility === 'restricted',
      place,
      savePlace: false,
      date,
      time,
    };
  }, [person.groups, update, userGroupIds]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => {
        try {
          if (uid && user && organizationDocPath && locationId && location) {
            // Merge with 'unkown' groups if group member
            const unkownGroups = person ? difference(initialValues.groups, userGroupIds) : [];
            const updateGroups = uniq([...unkownGroups, ...values.groups]);

            const meta = update
              ? generateUpdatedByMeta(user, update.meta)
              : generateCreatedByMeta(user);
            // Determine if new createdAt value was set
            const newDateTime = DateTime.fromISO(`${values.date}T${values.time}`);
            const oldDateTime = update?.meta.createdAt
              ? DateTime.fromMillis(update.meta.createdAt.toMillis())
              : DateTime.local();
            const diffMins = Math.abs(newDateTime.diff(oldDateTime).as('minutes'));
            const diffHours = Math.abs(newDateTime.diff(oldDateTime).as('hours'));
            if (diffMins > 1) {
              meta.createdAt = newDateTime.toJSDate() as unknown as firebase.firestore.Timestamp;
            }
            const updateFromForm: Update = {
              meta,
              // Permissions and references
              locationId: locationId,
              isArchived: update ? update.isArchived : person ? person.isArchived : false,
              groups: updateGroups,
              visibility: values.isRestricted ? 'restricted' : 'groups',
              person: {
                id: person.id,
                profile: update ? update.person.profile : person.profile,
              },
              // Core info
              type: values.type,
              // Trim and reduce multiline linebreaks to one or two:
              // https://stackoverflow.com/questions/10965433/regex-replace-multi-line-breaks-with-single-in-javascript
              notes: values.notes.trim().replace(/\n\s*\n\s*\n/g, '\n\n'),
              place: values.place || null,
            };

            // Batch write update doc
            const batch = db.batch();
            const updateRef = update
              ? db.doc(`${organizationDocPath}/updates/${update.id}`)
              : db.collection(`${organizationDocPath}/updates`).doc();
            batch.set(updateRef, updateFromForm, { merge: true });

            // If creating new update, add to the the person's 'lastUpdated' field
            // for each group (and 'latest')
            if (user && !update) {
              const timestamp =
                firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
              batch.update(db.doc(person.docPath), {
                ...generateUpdatedByDottedMeta(user),
                'lastUpdated.latest': timestamp,
                ...Object.assign(
                  {},
                  ...updateFromForm.groups.map(groupId => ({
                    [`lastUpdated.${groupId}`]: timestamp,
                  }))
                ),
              });
            }

            // Make sure person is in all groups if isn't already in any given group
            // ( same as task form )
            // Remove consideration of ['ungrouped'] person
            const wasUngrouped = person.groups[0] === 'ungrouped';
            const personGroups = wasUngrouped ? [] : person.groups;
            // Find which ones are new ( out of total 10 for the person )
            const maxRemainingCount = MAX_GROUP_ASSIGNMENTS - personGroups.length;
            const newGroups = difference(values.groups, personGroups).slice(0, maxRemainingCount);
            if (newGroups.length) {
              batch.update(db.doc(person.docPath), {
                ...generateUpdatedByDottedMeta(user),
                groups: wasUngrouped
                  ? newGroups
                  : firebase.firestore.FieldValue.arrayUnion(...newGroups),
              });
            }

            // If checked, save place as a location favorite
            if (isLocationAdmin && values.savePlace && !!values.place) {
              batch.update(db.doc(location.docPath), {
                ...generateUpdatedByDottedMeta(user),
                savedPlaces: firebase.firestore.FieldValue.arrayUnion(values.place),
              });
            }

            // Commit batch
            batch.commit();

            // Fire intercom event ( if creating new update )
            if (!update) {
              trackEvent(values.isRestricted ? 'created-restricted-update' : 'created-update');
            }

            // Analytics
            const analyticsProps = {
              type: updateFromForm.type,
              notes_length: updateFromForm.notes.length,
              groups_count: updateFromForm.groups.length,
              has_place: !!values.place,
              is_restricted: values.isRestricted,
              custom_time: diffHours > 1.5,
            };
            postHog?.capture(!update ? 'update_created' : 'update_edited', analyticsProps);
            logEvent(fbAnalytics, !update ? 'update_create' : 'update_edit', analyticsProps);
          } else {
            // Current user and org/location not set
            throw new Error('An error occurred. Please contact support. (UID not found)');
          }
        } catch (error) {
          // Otherwise report error in console and to user
          console.error(error);
          window.alert(error);
        }
        // Close form when finished
        handleCancel();
      }}
    >
      <FormikForm
        intercomTarget='Update form'
        handleCancel={handleCancel}
        // Dissallow submissions when either (type AND notes) or groups are empty OR
        // editing update and nothing in form values has changed
        disableSubmit={(values: typeof initialValues) =>
          (!values.type.trim() && !values.notes.trim()) ||
          !values.groups.length ||
          (!!update && isEqual(initialValues, values))
        }
      >
        <FlexColumn>
          {/* Header */}
          <FormikHeader
            leadingIcon={update ? 'edit' : 'post_add'}
            mainTitle={update ? 'Edit update' : 'New update'}
            handleCancel={handleCancel}
          />

          {/* Form body */}
          <Scrollable endBuffer='128px'>
            {/* Section 1 ( type, notes, and place ) */}
            <Margin margin='32px 48px 32px' mobileMargin='32px 16px'>
              {/* Update type selector */}
              <FlexRow>
                <Expanded data-intercom-target='Update type select'>
                  <FormikSelect
                    name='type'
                    placeholder='Select type'
                    options={sortBy(organization?.preferences.updateTypes || []).map(type => ({
                      value: type,
                      label: type,
                    }))}
                    isSearchable={(organization?.preferences.updateTypes.length || 1) >= 5}
                    isClearable
                  />
                </Expanded>
                <Expanded className='hidden-mobile' />
              </FlexRow>
              <Spacer height='24px' />

              {/* Notes textarea */}
              <FormikTextarea
                autoComplete='off'
                name='notes'
                placeholder='Write update notes here...'
                minRows={3}
                maxRows={20}
                autoFocus={!update}
                maxLength={5400}
                data-intercom-target='Update notes field'
              />
              <Spacer height='24px' />

              <FormikAutocompletePlace
                label='Place'
                optional
                showSaved
                placeholder='Search nearby places'
                savePlaceName='savePlace'
                // Should be able to scroll through
                // since this is at very bottom of form
                captureMenuScroll={false}
                intercomTarget='Update place select'
              />
            </Margin>
            <Divider margin={24} />

            {/* Section 2 ( groups/visible to + restricted toggle ) */}
            <Margin margin='24px 48px 0' mobileMargin='32px 16px 0'>
              <FormikGroupsSelect
                label='Update visibility'
                placeholder='Select group(s)'
                person={person}
                // Disable if user only has one group
                disabled={userGroupIds.length === 1}
                intercomTarget='Update groups select'
                showRestrictedToggle
              />
            </Margin>

            {/* Section 3 ( edit date/time ) */}
            <>
              <Spacer height='32px' />
              <Divider margin={24} />
              <Margin margin='24px 48px' mobileMargin='32px 16px'>
                <FlexRow>
                  <FormikDatePicker
                    label='Date'
                    name='date'
                    maxDate={DateTime.local().endOf('day').toJSDate()}
                    isClearable={false}
                  />
                  <Spacer width='16px' />
                  <FormikTimePicker name='time' label='Time' isClearable={false} />
                </FlexRow>
              </Margin>
            </>
          </Scrollable>

          {/* Footer */}
          <FormikFooter
            handleCancel={handleCancel}
            submitButton={
              <>
                <strong>Save</strong> update
              </>
            }
            submitIntercomTarget='Save update button'
          />
        </FlexColumn>
      </FormikForm>
    </Formik>
  );
};
export default UpdateForm;
