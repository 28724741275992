export const STRIPE_STATUSES = [
  'active',
  'incomplete',
  'incomplete_expired',
  'trialing',
  'past_due',
  'canceled',
  'unpaid',
  'paused',
  'requires_payment_method',
  'requires_action',
] as const;
export type StripeStatus = (typeof STRIPE_STATUSES)[number];
export const TERMINAL_STATES: Array<StripeStatus> = ['incomplete_expired', 'unpaid', 'canceled'];

export const STRIPE_PRICING_STRUCTURE = [
  { min: 30, month: 2, year: 1 },
  { min: 20, month: 4, year: 3 },
  { min: 10, month: 6, year: 5 },
  { min: 2, month: 8, year: 7 },
  { min: 1, month: 12, year: 10 },
];

export interface GetStripePriceProps {
  /** Interval to calculate price for */
  interval: 'month' | 'year';
  /** Number of users on the subscription */
  usersCount: number;
  /** Total coupon value off in cents. Applies to the current interval.
   * For example, if interval is month, then the value is the monthly discount. */
  couponAmountOff?: number | null;
  /** Percent off the total subscription ( in whole percentage number ) */
  couponPercentOff?: number | null;
}

export function getUserPriceAtTier({
  interval,
  usersCount,
  couponAmountOff,
  couponPercentOff,
}: GetStripePriceProps) {
  if (!couponAmountOff && !couponPercentOff)
    return STRIPE_PRICING_STRUCTURE.find(({ min }) => usersCount >= min)?.[interval] ?? 0;
  const prevPrice = getStripePrice({
    interval,
    usersCount: usersCount - 1,
    couponAmountOff,
    couponPercentOff,
  });
  const price = getStripePrice({ interval, usersCount, couponAmountOff, couponPercentOff });
  return price.amount - prevPrice.amount;
}

export function getStripePrice({
  interval,
  usersCount,
  couponAmountOff,
  couponPercentOff,
}: GetStripePriceProps) {
  let rawAmountMonthly = 0;
  for (let i = 1; i <= usersCount; i++) {
    rawAmountMonthly += getUserPriceAtTier({ interval, usersCount: i });
  }
  if (couponAmountOff) {
    const amountOff = interval === 'month' ? couponAmountOff / 100 : couponAmountOff / 100 / 12;
    rawAmountMonthly = Math.max(rawAmountMonthly - amountOff, 0);
  }
  if (couponPercentOff) {
    rawAmountMonthly *= 1 - couponPercentOff / 100;
  }

  const amountMonthly = roundCents(rawAmountMonthly);
  const amountYearly = roundCents(rawAmountMonthly * 12);
  const amount = interval === 'month' ? amountMonthly : amountYearly;

  const perUserMonthly = roundCents(rawAmountMonthly / usersCount);
  const perUserYearly = roundCents((rawAmountMonthly * 12) / usersCount);
  const perUser = interval === 'month' ? perUserMonthly : perUserYearly;

  const price = {
    /** Total cost at current interval */
    amount,
    /** Total cost by month */
    amountMonthly,
    /** Total cost by year */
    amountYearly,
    /** Cost per user at current interval */
    perUser,
    /** Cost per user by month */
    perUserMonthly,
    /** Cost per user by year */
    perUserYearly,
  };
  return price;
}

/** Round to 2 decimal places */
export function roundCents(val: number) {
  return Math.round(val * 100) / 100;
}

/** Show cents at 2 decimal places, unless cents value is 0,
 *  then show only whole number */
export function showCents(val: number | null | boolean | undefined) {
  if (typeof val !== 'number') return '';

  const cents = val % 1;
  return cents === 0 ? val.toFixed(0) : val.toFixed(2);
}
