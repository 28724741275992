// POTENTIAL REFACTOR: Similar ( but a little different )
// to `FormikPhoneInput` and `FormikaddressInput`

import { Fragment } from 'react';
// Libs
import { useField, useFormikContext, FieldArray } from 'formik';
// Classes
import { ADDRESS_TYPES, AddressType } from '@/classes/Person';
import Place from '@/classes/Place';
// Form elements
import FormikSelect from '@/components/formElements/FormikSelect';
import FormikAutocompletePlace from '@/components/formElements/FormikAutocompletePlace';
import RemoveButton from './RemoveButton';
import FormLabel from '@/components/formElements/styled/FormLabel';
// Common components
import FlexRow from '@/components/common/FlexRow';
import { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import Expanded from '@/components/common/Expanded';

// Defaults
const MAX_ADDRESSES = 10;
// Select options
const options = ADDRESS_TYPES.map(type => ({ value: type, label: type }));

// Component
const FormikAddressInputs = () => {
  const { isSubmitting } = useFormikContext();
  const [{ value: addresses }] = useField<{ type: AddressType; place: Place | '' }[]>('addresses');
  return (
    <FieldArray
      name='addresses'
      render={arrayHelpers => (
        <>
          {addresses.map((address, index) => {
            const planningCenterLock =
              typeof address.place !== 'string' && address.place?.placeId === 'planningCenter';
            return (
              <Fragment key={index}>
                <FlexRow align='start'>
                  <FormikSelect
                    name={`addresses.${index}.type`}
                    options={options}
                    isSearchable={false}
                    disabled={planningCenterLock}
                  />
                  <Expanded>
                    <FormikAutocompletePlace
                      name={`addresses.${index}.place`}
                      placeholder='Enter address'
                      autoFocus={!addresses[index].place}
                      // Should be able to scroll form window through dropdown
                      captureMenuScroll={false}
                      disabled={planningCenterLock}
                    />
                  </Expanded>
                  <RemoveButton
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={isSubmitting || planningCenterLock}
                  />
                </FlexRow>
                {planningCenterLock && <FormLabel label='Address syncing with Planning Center' />}
                <Spacer height='8px' />
              </Fragment>
            );
          })}
          <SecondaryButton
            disabled={
              !!addresses.find(({ place }) => !place || !place.mainText.trim()) ||
              addresses.length === MAX_ADDRESSES
            }
            leadingIcon='add_circle'
            onClick={() =>
              arrayHelpers.push({
                type:
                  ADDRESS_TYPES.find(
                    type => !addresses.map(address => address.type).includes(type)
                  ) || 'Other',
                place: '',
              })
            }
          >
            Add <strong>address</strong>
          </SecondaryButton>
        </>
      )}
    />
  );
};
export default FormikAddressInputs;
