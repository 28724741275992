import styled from 'styled-components';

import Select, { type GroupBase, type Props } from 'react-select';
import CreatableSelect, { type CreatableProps } from 'react-select/creatable';

type ReactSelectProps = { relative?: boolean };
type ReactSelectType = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & ReactSelectProps
) => React.ReactElement;
const ReactSelect = styled(Select).attrs({
  classNamePrefix: 'react-select',
})<ReactSelectProps>`
  .react-select__placeholder {
    /* Why did I ever put this here? It is causing problem with text overflowing-ellipsis in some places */
    /* max-width: calc(100% - 8px); */
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => props.theme.textFaded};
    letter-spacing: 0.025em;
  }

  .react-select__input {
    color: ${props => props.theme.textPrimary} !important;
    letter-spacing: 0.025em;
  }

  .react-select__control {
    padding: 2px 0;
    color: ${props => props.theme.textSecondary};
    background-color: ${props => props.theme.sheetBackgroundColor};
    border: 2px solid ${props => props.theme.lightAccent};
    border-radius: 4px;
    min-height: 46px;
    cursor: pointer;
    &:hover {
      border: 2px solid ${props => props.theme.textTertiary};
    }
    &.react-select__control--is-focused {
      outline: none;
      border: 2px solid ${props => props.theme.primary500};
      box-shadow: ${props => props.theme.shadow300};
    }
    &.react-select__control--is-disabled {
      cursor: not-allowed;
      background-color: ${props => props.theme.hoverFade};
      opacity: 0.5;
    }
  }

  .react-select__menu {
    position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
    background: none;
    box-shadow: none;
    margin-top: 2px;
    /* Extra padding is so we can assure the menu is completely scrolled into view on focus */
    /* taking into account fixed footer on floating sheet form ( but not when relative or menu placement top ) */
    padding-bottom: ${({ relative, menuPlacement }) =>
      relative || menuPlacement === 'top' ? '0' : '64px'};
    pointer-events: none; /* To blur/close properly */
    .react-select__menu-list {
      pointer-events: auto;
      padding: 0;
      /* Styles are stolen from above to ensure 'scrollIntoView' calculates properly */
      background-color: ${props => props.theme.sheetBackgroundColor};
      border: 2px solid ${props => props.theme.offLight};
      box-shadow: ${props => props.theme.shadow400};
      border-radius: 4px;
      .react-select__group {
        padding: 0;
        border-bottom: 1px solid ${props => props.theme.dividerColor};
        &:last-child {
          border-bottom: none;
        }

        .react-select__group-heading {
          margin: 0;
          padding: 16px 16px 4px;
          text-transform: none;
          font-size: 14px;
          color: ${props => props.theme.linkColor};
          font-weight: bold;
          &:empty {
            display: none;
          }
        }
      }

      .react-select__option {
        cursor: pointer;
        padding: 8px 16px;

        color: ${props => props.theme.textSecondary};
        .count {
          color: ${({ theme }) => theme.textFaded};
        }
      }
      .react-select__option--is-focused {
        background-color: ${props => props.theme.hoverFade};
        &:active {
          background-color: ${props => props.theme.offLight};
        }
      }
      .react-select__option--is-selected {
        color: white;
        background-color: ${props => props.theme.primary500};
        &:active {
          background-color: ${props => props.theme.primary600};
        }
        .count {
          color: ${({ theme }) => theme.primary100};
        }
      }
    }
  }

  .react-select__single-value {
    font-weight: bold;
    color: ${props => props.theme.textSecondary};
  }

  .react-select__multi-value {
    background-color: ${props => props.theme.hoverFade};
    .react-select__multi-value__label {
      padding: 6px 4px 6px 12px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: ${props => props.theme.textSecondary};
    }
    .react-select__multi-value__remove {
      padding: 0 8px;
      &:hover {
        background-color: ${props => props.theme.danger400};
        color: ${props => props.theme.danger000};
      }
    }
  }
` as ReactSelectType;
export default ReactSelect;

interface ReactSelectSecondaryProps {
  small?: boolean;
  inline?: boolean;
  minWidth?: string;
  rightAligned?: boolean;
  block?: boolean;
}
type ReactSelectSecondaryType = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & ReactSelectSecondaryProps
) => React.ReactElement;
export const ReactSelectSecondary = styled(ReactSelect)<ReactSelectSecondaryProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  justify-content: ${({ rightAligned }) => (rightAligned ? 'flex-end' : 'flex-start')};
  width: ${({ block }) => (block ? '100%' : 'auto')};

  .react-select__control {
    border: 2px solid rgba(0, 0, 0, 0);
    display: inline-flex;
    min-height: unset;
    min-width: ${({ block, minWidth }) => (block ? '100%' : minWidth || '128px')};
    max-width: 100%;
    padding: ${({ small }) => (small ? '0' : '2px 0')};
    background: none;

    &:hover {
      border: 2px solid ${({ theme }) => theme.offLight};
      background-color: ${props => props.theme.hoverFade};
    }
    &.react-select__control--is-focused {
      border: 2px solid ${({ theme }) => theme.offLight};
      box-shadow: none;
    }

    .react-select__value-container {
      justify-content: ${({ rightAligned }) => (rightAligned ? 'flex-end' : 'flex-start')};
    }

    .react-select__single-value {
      position: relative;
      transform: none;
      flex: 1;
      max-width: unset;
      color: ${props => props.theme.textSecondary};
      text-align: ${({ rightAligned }) => (rightAligned ? 'right' : 'left')};
      font-weight: ${({ small }) => (small ? 'normal' : 'bold')};
      font-size: ${({ small }) => (small ? '14px' : '16px')};
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      padding: 4px 8px 4px 0;
    }
  }
  .react-select__menu {
    left: 0; /* For IE 11 */

    .react-select__menu-list {
      position: ${({ rightAligned }) => (rightAligned ? 'absolute' : 'relative')};
      right: ${({ rightAligned }) => (rightAligned ? '0' : 'auto')};
      display: inline-block;
      min-width: ${({ block }) => (block ? '100%' : '152px')};
      max-width: 100%;
      padding-bottom: 12px;

      .react-select__group-heading {
        /* text-align: ${({ rightAligned }) => (rightAligned ? 'right' : 'left')}; */
      }

      .react-select__option {
        /* overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; */
        padding: 8px 32px 8px 16px;
        /* text-align: ${({ rightAligned }) => (rightAligned ? 'right' : 'left')}; */
        &.react-select__option--is-disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }
` as ReactSelectSecondaryType;

type ReactCreatableSelectType = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CreatableProps<Option, IsMulti, Group> & ReactSelectProps
) => React.ReactElement;
export const ReactCreatableSelect = styled(ReactSelect).attrs({
  as: CreatableSelect,
})`` as ReactCreatableSelectType;
