import { useMemo, useState } from 'react';
import styled from 'styled-components';
// Libs
import { MAX_GROUP_ASSIGNMENTS } from '@/lib/config';
import { makeSentenceList, pluralize } from '@/lib/helpers';
import { useField, useFormikContext } from 'formik';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import kebabCase from 'lodash/kebabCase';
// Classes
import Doc from '@/classes/Doc';
import Person from '@/classes/Person';
// Context data
import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import { useAllUsers } from '@/contexts/usersList';
// Form elements
import Select from './styled/FormSelect';
import Label from './styled/FormLabel';
import Error from './styled/FormError';
// Common components
import Padding from '@/components/common/Padding';
import Icon from '../common/Icon';
import FormikToggle from './FormikToggle';
import LinkButton from '../common/LinkButton';
import FlexRow from '../common/FlexRow';
import Expanded from '../common/Expanded';

// Styles
const DetailsWrapper = styled.div`
  background-color: ${({ theme }) => theme.hoverFade};
  border-radius: 0 0 4px 4px;
  padding: 0 12px;
  border-left: 2px solid ${({ theme }) => theme.dividerColor};
  border-right: 2px solid ${({ theme }) => theme.dividerColor};
  border-bottom: 2px solid ${({ theme }) => theme.dividerColor};
  &:empty {
    display: none;
  }
`;
const DetailRow = styled.div<{ danger?: boolean; compact?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ compact }) => (compact ? '4px' : '8px')} 0;
  font-size: 15px;
  color: ${({ theme }) => theme.textSecondary};
  border-top: 1px solid ${({ theme }) => theme.dividerColor};
  &:first-child {
    border: none;
  }
  strong {
    color: ${({ theme }) => theme.textPrimary};
  }
  i {
    color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.primary500)};
  }
  button {
    font-weight: bold;
  }
  .divider {
    align-self: stretch;
    width: 1px;
    background-color: ${({ theme }) => theme.lightAccent};
    margin: 12px 12px 0 8px;
  }
`;
const UserList = styled.dl`
  min-width: 33%;
  max-width: calc(50% - 21px);
  margin: 0;
  padding: 12px 8px 4px;
  white-space: nowrap;
  dt {
    font-weight: bold;
    margin-bottom: 4px;
  }
  dd {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    margin-bottom: 2px;
    margin-left: 4px;
    color: ${({ theme }) => theme.textPrimary};
    strong {
      font-size: 15px;
      font-weight: bold;
      color: ${({ theme }) => theme.textFaded};
    }
  }
`;

// Props
interface Props {
  label?: string;
  optional?: boolean;
  required?: boolean;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  /** Person doc for showing 'adding to group' label */
  person?: Doc<Person>;
  /** When adding groups, whether they will be replaced or merged
   * ( person form replaces, tasks/milestones merge ) */
  mergeGroups?: boolean;
  /** Show also which groups this person will be removed from
   * ( currently only used on person edit form ) */
  showRemoveGroups?: boolean;
  /** Show which users have access to these groups
   * ( used for tasks/milestones and not person form ) */
  showUsers?: boolean;
  intercomTarget?: string;
  /** Show 'restricted' toggle */
  showRestrictedToggle?: boolean;
}

// Component
const FormikGroupsSelect = ({
  label,
  optional,
  required = true,
  name = 'groups',
  placeholder = 'Select group(s)',
  disabled,
  person,
  mergeGroups = true,
  showRemoveGroups,
  showUsers = true,
  intercomTarget = 'Groups select',
  showRestrictedToggle = false,
  ...rest
}: Props) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [field, meta] = useField<string[]>(name);
  const selectedGroups = field.value;
  const [groups] = useGroups();
  const maximumGroupsSelected = selectedGroups.length >= MAX_GROUP_ASSIGNMENTS;
  const { isSingleUser, hasRestrictedAccess, uid } = useAppState();
  const allUsers = useAllUsers();

  // Restricted toggle
  const [isRestrictedField] = useField<boolean>('isRestricted');

  // Groups which will be added to person ( by name )
  const newGroupNames = useMemo(() => {
    if (person && groups) {
      // Remove consideration of ['ungrouped'] person
      const personGroups = person.groups[0] === 'ungrouped' ? [] : person.groups;
      // Find which ones are new ( out of total 10 for the person )
      const maxRemainingCount = mergeGroups
        ? MAX_GROUP_ASSIGNMENTS - personGroups.length
        : MAX_GROUP_ASSIGNMENTS;
      const newGroupIds = difference(selectedGroups, personGroups).slice(0, maxRemainingCount);
      return newGroupIds
        .map(groupId => groups.find(({ id }) => groupId === id)?.name || '')
        .filter(name => !!name);
    }
    return [];
  }, [groups, mergeGroups, person, selectedGroups]);

  // Groups which will be removed from person ( by name )
  const removeGroupNames = useMemo(() => {
    if (showRemoveGroups && person && groups) {
      // Remove consideration of ['ungrouped'] person
      const personGroups = person.groups[0] === 'ungrouped' ? [] : person.groups;
      // Find which ones were removed
      const removeGroupIds = difference(personGroups, selectedGroups);
      return removeGroupIds
        .map(groupId => groups.find(({ id }) => groupId === id)?.name || '')
        .filter(name => !!name);
    }
    return [];
  }, [groups, person, selectedGroups, showRemoveGroups]);

  // Users which have access to this group ( minus current user )
  const [showVisibleToUsers, setShowVisibleToUsers] = useState(false);
  const visibleToUsers = useMemo(() => {
    if (showUsers && !isSingleUser && selectedGroups.length) {
      return allUsers.filter(
        user =>
          user.id !== uid &&
          !user.isInvitation &&
          !user.isArchived &&
          (user.isOrganizationAdmin ||
            user.isLocationAdmin ||
            !!intersection(user.groups, selectedGroups).length) &&
          (!isRestrictedField.value || user.hasRestrictedAccess)
      );
    }
    return [];
  }, [allUsers, isRestrictedField.value, isSingleUser, selectedGroups, showUsers, uid]);
  const visibleToAdmins = useMemo(() => {
    return visibleToUsers.filter(user => user.isOrganizationAdmin || user.isLocationAdmin);
  }, [visibleToUsers]);
  const visibleToGroupMembers = useMemo(() => {
    return visibleToUsers.filter(user => !user.isOrganizationAdmin && !user.isLocationAdmin);
  }, [visibleToUsers]);

  // Filtered groups by which ones user has access to ( mainly for group members )
  const filteredSelectedGroups = useMemo(
    () =>
      selectedGroups.reduce<{ label: React.ReactNode; value: string; isDisabled?: boolean }[]>(
        (filteredGroups, groupId) => {
          const matchedGroup = groups?.find(group => group.id === groupId);
          return matchedGroup
            ? [...filteredGroups, { label: matchedGroup.name, value: matchedGroup.id }]
            : filteredGroups;
        },
        []
      ),
    [groups, selectedGroups]
  );

  const activityTypeLabel = `${isRestrictedField.value ? 'restricted ' : ''}${
    label?.includes('Task') ? 'task' : 'update'
  }`;

  return (
    <>
      <Label
        label={label}
        htmlFor={kebabCase((label || '') + name)}
        optional={optional}
        data-intercom-target={intercomTarget}
        required={required && !selectedGroups.length}
      >
        <Select
          inputId={kebabCase((label || '') + name)}
          name={name}
          placeholder={placeholder}
          isMulti
          isClearable={false}
          isSearchable={!maximumGroupsSelected && groups ? groups.length >= 8 : false}
          noOptionsMessage={() => <Padding padding='4px 24px'>No matching groups</Padding>}
          isDisabled={disabled || isSubmitting}
          value={filteredSelectedGroups}
          onChange={selections => {
            setFieldValue(
              name as never,
              selections ? selections.map(selection => selection.value) : []
            );
          }}
          onBlur={field.onBlur}
          options={
            maximumGroupsSelected
              ? [
                  {
                    label: (
                      <>
                        You&apos;ve reached the <strong>10 group limit</strong>
                      </>
                    ),
                    value: '',
                    isDisabled: true,
                  },
                ]
              : groups?.map(group => ({
                  value: group.id,
                  label: group.name,
                }))
          }
          {...rest}
        />
      </Label>

      <DetailsWrapper>
        {/* Added to group(s) */}
        {!!person && !!newGroupNames.length && (
          <DetailRow>
            <Icon icon='playlist_add' />
            <div>
              {person.profile.name.first} will be added to the{' '}
              {makeSentenceList(newGroupNames.map(name => <strong key={name}>{name}</strong>))}
              {pluralize({ root: ' group', count: newGroupNames.length })}
            </div>
          </DetailRow>
        )}
        {/* Removed from group(s) */}
        {!!person && !!removeGroupNames.length && (
          <DetailRow danger>
            <Icon icon='playlist_remove' />
            <div>
              {person.profile.name.first} will be removed from the{' '}
              {makeSentenceList(removeGroupNames.map(name => <strong key={name}>{name}</strong>))}
              {pluralize({ root: ' group', count: removeGroupNames.length })}
            </div>
          </DetailRow>
        )}

        {/* Visible to users */}
        {!!selectedGroups.length && showUsers && (
          <DetailRow>
            <Expanded>
              {/* Only you */}
              {!visibleToUsers.length && (
                <div>
                  <strong>Only you</strong> will see this {activityTypeLabel}
                </div>
              )}
              {/* 1 or 2 other users */}
              {visibleToUsers.length >= 1 && visibleToUsers.length <= 2 && (
                <div>
                  {makeSentenceList([
                    'You',
                    ...visibleToUsers.map(user => (
                      <strong key={user.id}>{user.profile.name.display}</strong>
                    )),
                  ])}{' '}
                  will see this {activityTypeLabel}
                </div>
              )}
              {/* More than 2 other users */}
              {visibleToUsers.length > 2 && (
                <div>
                  You and{' '}
                  <LinkButton
                    onClick={e => {
                      e.preventDefault();
                      setShowVisibleToUsers(prev => !prev);
                    }}
                  >
                    {visibleToUsers.length} other users
                  </LinkButton>{' '}
                  will see this {activityTypeLabel}
                </div>
              )}
              {/* Expanded breakdown */}
              {visibleToUsers.length > 2 && showVisibleToUsers && (
                <FlexRow align='start'>
                  <UserList>
                    <dt>{isRestrictedField.value ? 'Admins with Restricted Access' : 'Admins'}</dt>
                    {!visibleToAdmins.length && (
                      <dd>
                        <strong>None</strong>
                      </dd>
                    )}
                    {visibleToAdmins.map(u => (
                      <dd key={u.id}>{u.profile.name.display}</dd>
                    ))}
                  </UserList>
                  {!isRestrictedField.value && (
                    <>
                      <div className='divider' />
                      <UserList>
                        <dt>Group Members</dt>
                        {!visibleToGroupMembers.length && (
                          <dd>
                            <strong>None</strong>
                          </dd>
                        )}
                        {visibleToGroupMembers.map(u => (
                          <dd key={u.id}>{u.profile.name.display}</dd>
                        ))}
                      </UserList>
                    </>
                  )}
                </FlexRow>
              )}
            </Expanded>
          </DetailRow>
        )}

        {/* Restricted Toggle */}
        {!!selectedGroups.length && showRestrictedToggle && hasRestrictedAccess && (
          <DetailRow compact>
            <FormikToggle name='isRestricted' small>
              <strong>Restrict</strong> this update
            </FormikToggle>
          </DetailRow>
        )}
      </DetailsWrapper>

      {/* Errors */}
      {meta.touched && !!meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};
export default FormikGroupsSelect;
