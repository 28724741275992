import { useFormikContext } from 'formik';
import useFormikSubmitIsDisabled from '@/hooks/useFormikSubmitIsDisabled';
import FormFooter from './styled/FormFooter';

// Props
interface Props {
  handleDelete?(): void;
  handleCancel?(): void;
  submitButton?: React.ReactNode;
  danger?: boolean;
  submitIntercomTarget?: string;
  leading?: React.ReactNode;
}
// Component
const FormikFooter = (props: Props = {}) => {
  const { isSubmitting } = useFormikContext();
  const submitDisabled = useFormikSubmitIsDisabled();
  return <FormFooter {...props} isSubmitting={isSubmitting} submitDisabled={submitDisabled} />;
};
export default FormikFooter;
