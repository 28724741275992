import { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import random from 'lodash/random';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import Spacer from '@/components/common/Spacer';

import NotebirdIcon from '@/components/svg/NotebirdIcon';
import loader from '@/img/loader.gif';

// Defaults
const quotes = [
  'The early notebird gets the worm.',
  'Notebirds of a feather flock together.',
  'A notebird in the hand is worth two in the bush.',
];
const followUps = [
  "Hang on to your tail feathers, we're almost ready!",
  "Preparing your bird's-eye view...",
];

// Styles
const StyledLoadingPage = styled.div`
  z-index: 20000;
  background-color: ${({ theme }) => theme.backgroundColor};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100%;

  /* Mount/unmount animation */
  &.appear {
    opacity: 0;
  }
  &.appear-active {
    transition: opacity 300ms;
    opacity: 1;
  }
  &.exit-active {
    pointer-events: none;
    transition: opacity 450ms 250ms;
    opacity: 0;
  }
`;
const TextFlex = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 24px 64px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: ${props => props.theme.textFaded};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.6px;
`;

// Animations are done the following way to prevent layer compositing while CPU may be tied up
// ...discovered that doesn't happen except at the beginning and end of animation excecution
// ( presumably when mounting and unmounting from the dom )
// Loading
const loadingTextAnimation = keyframes`
  0%,
  25% {
    opacity: 1;
    transform: scale(1);
  }
  30%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
`;
const LoadingText = styled(TextFlex)`
  opacity: 0;
  animation: ${loadingTextAnimation} 10s ${props => props.theme.easeStandard} forwards;
`;

// Quote
const quoteTextAnimation = keyframes`
  0%,
  25% {
    opacity: 0;
    transform: scale(0.5);
  }
  30%,
  85% {
    opacity: 1;
    transform: scale(1);
  }
  90%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
`;
const QuoteText = styled(TextFlex)`
  opacity: 0;
  animation: ${quoteTextAnimation} 10s ${props => props.theme.easeStandard} forwards;
`;

// Follow up
const followUpTextAnimation = keyframes`
  0%,
  85% {
    opacity: 0;
    transform: scale(0.5);
  }
  90%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const FollowUpText = styled(TextFlex)`
  opacity: 0;
  animation: ${followUpTextAnimation} 10s ${props => props.theme.easeStandard} forwards;
`;

// Animated gif loader to prevent jank during potential high CPU consumption
const StyledLoader = styled.img`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 4px;
  margin-bottom: -4px;
  transform: translateZ(0);
`;

// Component with transition
const LoadingPage = ({ show = false }: { show?: boolean }) => {
  const quote = useMemo(() => quotes[random(quotes.length - 1)], []);
  const followUp = useMemo(() => followUps[random(followUps.length - 1)], []);

  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition timeout={700} appear>
          <StyledLoadingPage>
            <LoadingText>Loading...</LoadingText>

            <QuoteText>
              <NotebirdIcon width='48px' height='48px' />
              <Spacer height='8px' />
              {quote}
            </QuoteText>

            <FollowUpText>{followUp}</FollowUpText>

            <StyledLoader src={loader} alt='loading animation' />
          </StyledLoadingPage>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
export default LoadingPage;
