import { type ComponentProps } from 'react';
import styled from 'styled-components';

interface Props extends ComponentProps<'div'> {
  align?: 'center' | 'start' | 'end';
  justify?: 'center' | 'start' | 'between';
  inline?: boolean;
}
const FlexRow = styled.div.attrs<Props>(
  ({ align = 'center', justify = 'start', ...rest }: Props) => ({
    align,
    justify,
    ...rest,
  })
)`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  align-items: ${({ align }) =>
    align === 'start' ? 'flex-start' : align === 'end' ? 'flex-end' : 'center'};
  justify-content: ${({ justify }) =>
    justify === 'center' ? 'center' : justify === 'between' ? 'space-between' : 'flex-start'};
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
`;
export default FlexRow;
