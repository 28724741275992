import { memo } from 'react';
import styled from 'styled-components';
// Classes
import { getMilestoneIcon } from '@/classes/Milestone';
import { RelativeMilestone } from './ActivitySection';
// Common components
import Icon from '@/components/common/Icon';
import Spacer from '@/components/common/Spacer';
import { Interweave } from 'interweave';

// Styles
const StyledMilestoneItem = styled.div`
  margin: 0 24px;
  @media (max-width: 1023px) {
    margin: 0 16px;
  }
`;
const MilestoneFlexRow = styled.div<{ center: boolean }>`
  display: flex;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
`;
const MilestoneIconWrapper = styled.div<{ dull: boolean }>`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  padding: 6px;

  border-radius: 50%;
  background-color: ${({ dull, theme }) => (dull ? theme.lightColor : theme.grey800)};

  i {
    color: ${({ theme }) => theme.grey000};
    color: ${({ dull, theme }) => (dull ? theme.avatarText : theme.grey000)};
  }
`;
const MilestoneContent = styled.div`
  font-size: 18px;
  font-weight: bold;

  small {
    margin-left: 8px;

    font-size: 13px;
    font-weight: normal;
    color: ${({ theme }) => theme.textFaded};
  }
`;
const MilestoneDescription = styled.div`
  line-height: 1.2;
  font-weight: normal;
  font-size: 15px;
  color: ${({ theme }) => theme.textTertiary};
`;

// Component
const MilestoneItem = memo(function MilestoneItem({
  milestone,
  dull = false,
}: {
  milestone: RelativeMilestone;
  dull?: boolean;
}) {
  return (
    <StyledMilestoneItem>
      <MilestoneFlexRow center={!milestone.description}>
        <MilestoneIconWrapper dull={dull}>
          <Icon icon={getMilestoneIcon(milestone.type)} iconSize='20px' />
        </MilestoneIconWrapper>
        <MilestoneContent>
          {milestone.typeLabel}
          <small>{milestone.timeLabel}</small>
          <MilestoneDescription>
            <Interweave content={milestone.description} />
          </MilestoneDescription>
        </MilestoneContent>
      </MilestoneFlexRow>
      <Spacer height='24px' />
    </StyledMilestoneItem>
  );
});
export default MilestoneItem;
