import { lazy, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { usePostHog } from 'posthog-js/react';
import CSSTransition from 'react-transition-group/CSSTransition';

import useAppState, { useSetAppState } from '@/contexts/appState';
import { useFollowedGroups } from '@/contexts/groups';
import useLocation from '@/contexts/location';
import useLocations from '@/contexts/locations';
import { useUserCounts } from '@/contexts/usersList';
import { useAtomValue } from 'jotai';

import { updateCountsAtom } from '@/components/pages/ActivityPage/UpdatesSheet';
import { peopleCountsAtom } from '@/components/pages/PeoplePage/PeoplePage';

import Scrollable from '@/components/layout/Scrollable';

import ActivityFilters from './ActivityFilters';
import AsideDivider from './AsideDivider';
import FilterButton from './FilterButton';
import ManageGroupsSheet from './ManageGroupsSheet';
import TasksFilter from './TasksFilter';

import { Toggle } from '@/components/formElements/FormElements';
import { ReactSelectSecondary } from '@/components/formElements/ReactSelect';

import { SecondaryButton } from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Expanded from '@/components/common/Expanded';
import Margin from '@/components/common/Margin';
import Modal from '@/components/common/Modal';
import Padding from '@/components/common/Padding';
import PageBlocker from '@/components/common/PageBlocker';
import Spacer from '@/components/common/Spacer';

import NotebirdLogo from '@/components/svg/NotebirdLogo';

const AdminAside = lazy(() => import('./AdminAside'));

const PEOPLE_ORDERS_BY = ['First name', 'Last name'] as const;
export type PeopleOrdersBy = (typeof PEOPLE_ORDERS_BY)[number];

// Styles
export const Aside = styled.aside<{ isMobileOpen: boolean }>`
  flex-shrink: none;
  /* To bump right up next to sheets wrapper */
  margin-right: -12px;

  /* Sliding aside animations for desktop */
  @media (min-width: 1024px) {
    /* Aside animations */
    &.enter {
      opacity: 0;
      transform: translateX(158px);
    }
    &.enter-active,
    &.enter-done {
      transition:
        opacity 250ms,
        transform 300ms ${({ theme }) => theme.easeDecelerate};
      opacity: 1;
      transform: translate(0);
    }
    &.exit-active {
      transition:
        opacity 250ms 50ms,
        transform 300ms ${({ theme }) => theme.easeAccelerate};
      position: absolute;
      left: 0;
      opacity: 0;
      transform: translateX(158px);
    }

    /* Child sheet-wrapper animations */
    & ~ .sheets-wrapper {
      transform-origin: center right;
    }
    &.enter ~ .sheets-wrapper {
      /* Ratio of old size to new ( under 1224px ) */
      transform: scaleX(1.2925);

      /* Really crazy stuff to fake ratios in the weird widths between 1224-1468  */
      /* https://docs.google.com/spreadsheets/d/1ju8jW7hVzWAMjeYqfyTZfsWPnRgPpAyrnURcouSqPTg/edit#gid=0 */
      @media (min-width: 1224px) {
        transform: scaleX(1.216) translate(calc((100vw - 1224px) / -2));
      }
      @media (min-width: 1285px) {
        transform: scaleX(1.1145) translate(calc((100vw - 1224px) / -2));
      }
      @media (min-width: 1347px) {
        transform: scaleX(1.082) translate(calc((100vw - 1224px) / -2));
      }
      @media (min-width: 1407px) {
        transform: scaleX(1.026) translate(calc((100vw - 1224px) / -2));
      }

      /* Safe to not scale above this value ( and static translate ) */
      @media (min-width: 1453px) {
        transform: scaleX(1) translate(-122px);
      }
    }
    &.enter-active ~ .sheets-wrapper {
      transition: transform 300ms ${({ theme }) => theme.easeStandard};
      transform: scaleX(1) translate(0);
    }
    &.exit ~ .sheets-wrapper {
      /* Inverse ratio of old size to new ( under 1224px ) */
      transform: scaleX(0.774);

      /* Really crazy stuff to fake ratios in the weird widths between 1224-1468  */
      /* ( like above ) */
      @media (min-width: 1224px) {
        transform: scaleX(0.823) translate(calc((100vw - 1224px) / 2));
      }
      @media (min-width: 1285px) {
        transform: scaleX(0.873) translate(calc((100vw - 1224px) / 2));
      }
      @media (min-width: 1347px) {
        transform: scaleX(0.924) translate(calc((100vw - 1224px) / 2));
      }
      @media (min-width: 1407px) {
        transform: scaleX(0.975) translate(calc((100vw - 1224px) / 2));
      }

      /* Safe to not scale above this value ( and static translate ) */
      @media (min-width: 1453px) {
        transform: scaleX(1) translate(122px);
      }
    }
    &.exit-active ~ .sheets-wrapper {
      transition: transform 300ms ${({ theme }) => theme.easeStandard};
      transform: scaleX(1) translate(0);
    }
  }

  display: flex;
  flex-direction: column;
  position: relative;
  width: 256px;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1023px) {
    z-index: 220;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.backgroundColor};
    box-shadow: ${({ theme }) => theme.shadow400};
    ${({ isMobileOpen }) =>
      isMobileOpen
        ? css`
            opacity: 1;
            transform: translate(0);
            transition:
              opacity 250ms,
              transform 300ms ${({ theme }) => theme.easeDecelerate};
          `
        : css`
            pointer-events: none;
            opacity: 0;
            transform: translate(-256px);
            transition:
              opacity 200ms 50ms,
              transform 250ms ${({ theme }) => theme.easeStandard};
          `};
  }

  /* Don't show when printing */
  @media print {
    display: none;
  }
`;
const StyledManageGroupsButton = styled(SecondaryButton)`
  margin: 0 12px;
  margin: 0 12px env(safe-area-inset-bottom);
  & {
    color: ${({ theme }) => theme.textSecondary};
  }
  i {
    margin-right: 11px !important;
  }
  &:hover {
    background-color: ${({ theme }) => theme.hoverAsideFade};
  }
`;
const ArchivedToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  font-size: 14px;
  color: ${({ theme }) => theme.textSecondary};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.textPrimary};
  }
`;
const ArchivedToggleCount = styled.div`
  margin: 0 4px;
  color: ${props => props.theme.textFaded};
  font-size: 13px;
`;

// Component
const FilterAside = () => {
  // App state
  const {
    // Main filter aside states
    filterAsidePage,
    isFilterAsidePageActive,
    isMobileAsideOpen,
    // Others
    isManageGroupsModalOpen,
    isOrganizationAdmin,
    isLocationAdmin,
    isSingleUser,
    hasSingleGroup,
    contentFilter,
    groupId,
    showArchivedPeople,
    showArchivedUsers,
    orderPeopleBy,
    showRestrictedUpdates,
    hasRestrictedAccess,
    kidcardMode,
  } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [locations] = useLocations();
  const [location] = useLocation();
  // const [counts] = useCounts();
  const userCounts = useUserCounts();
  const peopleCounts = useAtomValue(peopleCountsAtom);
  const updateCounts = useAtomValue(updateCountsAtom);
  const { followedGroups, unfollowedGroups } = useFollowedGroups();
  const posthog = usePostHog();

  // For people sort direction dropdown
  // ( this is hardly used, so shutting it down for now )
  // const peopleOrderByDirectionOptions = useMemo(
  //   () =>
  //     (['First name', 'Last name'].includes(orderPeopleBy)
  //       ? [
  //           { label: 'A - Z', value: 'primary' },
  //           { label: 'Z - A', value: 'inverse' },
  //         ]
  //       : [
  //           { label: 'Most recent', value: 'primary' },
  //           { label: 'Oldest', value: 'inverse' },
  //         ]) as { label: React.ReactNode; value: typeof orderPeopleDirection }[],
  //   [orderPeopleBy]
  // );

  // Get count to show for different filters
  const getCount = useCallback(
    (filter: string) => {
      // People counts
      if (filterAsidePage === 'people') {
        // if (!peopleCounts.isFetched) return null;
        if (filter === 'all') return peopleCounts.filteredTotal;
        if (filter === 'following') return peopleCounts.following;
        if (filter === 'archived') return peopleCounts.archived;
        if (filter === 'ungrouped') return peopleCounts.groups['ungrouped'];
        return peopleCounts.groups[filter];
      }
      // Activity/Update counts
      if (filterAsidePage === 'activity') {
        // if (!updateCounts.isFetched) return null;
        if (filter === 'all') return updateCounts.filteredTotal;
        if (filter === 'following') return updateCounts.following;
        if (filter === 'archived') return updateCounts.archived;
        if (filter === 'restricted') return updateCounts.restricted;
        return updateCounts.groups[filter];
      }
      // Users counts
      if (filterAsidePage === 'users') {
        if (filter === 'all') return userCounts.all;
        if (filter === 'admins') return userCounts.admins;
        if (filter === 'invitations') return userCounts.invitations;
        if (filter === 'archivedUsers') return userCounts.archived;
        if (filter === 'ungrouped') return userCounts.groups['ungrouped'];
        return userCounts.groups[filter];
      }
    },
    [
      filterAsidePage,
      peopleCounts.archived,
      peopleCounts.filteredTotal,
      peopleCounts.following,
      peopleCounts.groups,
      updateCounts.archived,
      updateCounts.filteredTotal,
      updateCounts.following,
      updateCounts.groups,
      updateCounts.restricted,
      userCounts.admins,
      userCounts.all,
      userCounts.archived,
      userCounts.groups,
      userCounts.invitations,
    ]
  );

  // We need to hide groups on tasks page if only has one group
  const hideGroups = hasSingleGroup && filterAsidePage === 'tasks';

  // Turn on or off archived people visibility
  const toggleArchived = useCallback(() => {
    setAppState({ showArchivedPeople: !showArchivedPeople });
    const actionLabel = filterAsidePage === 'people' ? 'people' : 'activity';
    const action = showArchivedPeople
      ? `${actionLabel}_hide_archived`
      : `${actionLabel}_show_archived`;
    posthog?.capture(action, {
      archived_count: getCount('archived'),
    });
  }, [filterAsidePage, getCount, posthog, setAppState, showArchivedPeople]);

  return (
    <>
      {/* Page blocker for when on mobile expanded */}
      <PageBlocker
        show={isFilterAsidePageActive && isMobileAsideOpen}
        onClick={() => {
          setAppState({ isMobileAsideOpen: false });
        }}
        className='shown-mobile'
      />

      {/* Animated FilterAside */}
      <CSSTransition in={isFilterAsidePageActive} timeout={300} mountOnEnter unmountOnExit appear>
        {filterAsidePage === 'admin' ? (
          <ErrorSuspendPlaceholder>
            <AdminAside />
          </ErrorSuspendPlaceholder>
        ) : (
          <Aside isMobileOpen={isMobileAsideOpen} data-intercom-target='Filter aside'>
            <Spacer height='12px' />

            {/* Location selector */}
            {location && locations && locations?.length > 1 ? (
              <>
                <Padding padding='16px 4px 0 12px' mobilePadding='0 12px 0 12px'>
                  <ReactSelectSecondary
                    block
                    isSearchable={false}
                    options={[
                      {
                        label: 'Switch locations',
                        options: locations.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        })),
                      },
                    ]}
                    value={{ label: location.name, value: location.id }}
                    onChange={selection => {
                      // Change location while reseting all filters to default
                      if (selection?.value) {
                        setAppState({
                          locationId: selection.value,
                          isMobileAsideOpen: false,
                          contentFilter: 'all',
                          groupId: null,
                          showArchivedPeople: false,
                          showArchivedUsers: false,
                          filterUpdatesBy: null,
                          filterMilestonesBy: null,
                          filterTasksBy: 'My',
                          typesensePeopleKey: null,
                          typesenseUpdatesKey: null,
                        });
                      }
                    }}
                  />
                </Padding>
                <AsideDivider />
              </>
            ) : (
              <>
                <Margin margin='4px 12px 24px 0' className='shown-mobile'>
                  <NotebirdLogo height='24' width='100%' tint prefix='filter-aside-' />
                </Margin>
                <Spacer height='64px' className='hidden-mobile' />
              </>
            )}

            {/* Main area */}
            <Scrollable endBuffer='64px'>
              {/* 'All' button */}
              <FilterButton
                active={!groupId && contentFilter === 'all'}
                onClick={() => {
                  setAppState({
                    contentFilter: 'all',
                    groupId: null,
                    isMobileAsideOpen: false,
                  });
                }}
                // Count on people, activity, and users pages
                count={getCount('all')}
              >
                {/* 'All _____' title for different pages */}
                {/* {pageName === 'people' && 'All people'}
                {pageName === 'activity' && 'All activity'}
                {pageName === 'tasks' && 'All tasks'}
                {pageName === 'users' && 'All users'} */}
                {/* Switched it up, just All people vs All users */}
                {filterAsidePage === 'users'
                  ? 'All users'
                  : kidcardMode
                    ? 'All students'
                    : 'All people'}
              </FilterButton>

              {/* If is users page and has multiple users */}
              {/* show 'admins' button */}
              {getCount('admins') && !isSingleUser && (
                <FilterButton
                  active={!groupId && contentFilter === 'admins'}
                  onClick={() => {
                    setAppState({
                      contentFilter: 'admins',
                      groupId: null,
                      isMobileAsideOpen: false,
                    });
                  }}
                  count={getCount('admins')}
                >
                  Admins
                </FilterButton>
              )}

              {/* On user's page, show invitations if has any ( org admins only ) */}
              {!!getCount('invitations') && (
                <>
                  <AsideDivider />
                  <FilterButton
                    active={!groupId && contentFilter === 'invitations'}
                    onClick={() => {
                      setAppState({
                        contentFilter: 'invitations',
                        groupId: null,
                        isMobileAsideOpen: false,
                      });
                    }}
                    count={getCount('invitations')}
                  >
                    Pending invitations
                  </FilterButton>
                </>
              )}

              {/* 'Following' button ( if is following any people AND not on users page ) */}
              {!!getCount('following') && (
                <FilterButton
                  active={!groupId && contentFilter === 'following'}
                  onClick={() => {
                    setAppState({
                      contentFilter: 'following',
                      groupId: null,
                      isMobileAsideOpen: false,
                    });
                  }}
                  count={getCount('following')}
                >
                  Followed people
                </FilterButton>
              )}
              {!hideGroups && <AsideDivider />}

              {/* Followed groups list */}
              {!hideGroups &&
                followedGroups.map(group => (
                  <FilterButton
                    key={group.id}
                    active={groupId === group.id}
                    onClick={() => {
                      setAppState({ groupId: group.id, isMobileAsideOpen: false });
                    }}
                    // Only count on people and users pages
                    count={getCount(group.id)}
                  >
                    {group.name}
                  </FilterButton>
                ))}
              {!hideGroups && !!followedGroups.length && !!unfollowedGroups.length && (
                <AsideDivider />
              )}

              {/* Unfollowed groups list */}
              {!hideGroups &&
                unfollowedGroups.map(group => (
                  <FilterButton
                    key={group.id}
                    active={groupId === group.id}
                    onClick={() => {
                      setAppState({ groupId: group.id, isMobileAsideOpen: false });
                    }}
                    // Only count on people and users pages
                    count={getCount(group.id)}
                  >
                    {group.name}
                  </FilterButton>
                ))}

              {/* 'Ungrouped' button ( if isAdmin, has any, and on people/users page only )*/}
              {isLocationAdmin && !!getCount('ungrouped') && (
                <>
                  <Spacer height='12px' />
                  <FilterButton
                    // key={group.id}
                    active={!groupId && contentFilter === 'ungrouped'}
                    onClick={() => {
                      setAppState({
                        contentFilter: 'ungrouped',
                        groupId: null,
                        isMobileAsideOpen: false,
                      });
                    }}
                    // Only count on people and users pages
                    count={getCount('ungrouped')}
                  >
                    Ungrouped
                  </FilterButton>
                </>
              )}

              {/* Divider if not on users page */}
              {filterAsidePage !== 'users' && <AsideDivider />}

              {/* Archived people toggle ( people + activity pages, if has any archived ) */}
              {!!getCount('archived') && (
                <>
                  <Spacer height='8px' />
                  <ArchivedToggleWrapper
                    onClick={e => {
                      e.preventDefault();
                      toggleArchived();
                    }}
                  >
                    <Toggle
                      small
                      checked={showArchivedPeople}
                      onChange={e => {
                        e.preventDefault();
                        toggleArchived();
                      }}
                    />
                    <Spacer width='8px' />
                    Show archived
                    <Expanded />
                    <ArchivedToggleCount>{getCount('archived')}</ArchivedToggleCount>
                  </ArchivedToggleWrapper>
                </>
              )}

              {/* Restricted updates toggle */}
              {hasRestrictedAccess && !!getCount('restricted') && (
                <>
                  <Spacer height='8px' />
                  <ArchivedToggleWrapper
                    onClick={event => {
                      event.preventDefault();
                      setAppState({ showRestrictedUpdates: !showRestrictedUpdates });
                      posthog?.capture(
                        showRestrictedUpdates
                          ? 'activity_hide_restricted_updates'
                          : 'activity_show_restricted_updates'
                      );
                    }}
                  >
                    <Toggle
                      small
                      checked={showRestrictedUpdates}
                      onChange={event => {
                        event.preventDefault();
                        setAppState({ showRestrictedUpdates: !showRestrictedUpdates });
                        posthog?.capture(
                          showRestrictedUpdates
                            ? 'activity_hide_restricted_updates'
                            : 'activity_show_restricted_updates'
                        );
                      }}
                    />
                    <Spacer width='8px' />
                    Show restricted updates
                    <Expanded />
                    <ArchivedToggleCount>{getCount('restricted')}</ArchivedToggleCount>
                  </ArchivedToggleWrapper>
                </>
              )}

              {/* Archived users toggle ( users page, if has any archived users, and is org admin ) */}
              {/* No one but organization admins need to see archived users */}
              {getCount('archivedUsers') && isOrganizationAdmin && (
                <>
                  <AsideDivider />
                  <Spacer height='8px' />
                  <ArchivedToggleWrapper
                    onClick={event => {
                      event.preventDefault();
                      setAppState({ showArchivedUsers: !showArchivedUsers });
                    }}
                  >
                    <Toggle
                      small
                      checked={showArchivedUsers}
                      onChange={event => {
                        event.preventDefault();
                        setAppState({ showArchivedUsers: !showArchivedUsers });
                      }}
                    />
                    <Spacer width='8px' />
                    Show archived
                    <Expanded />
                    <ArchivedToggleCount>{getCount('archivedUsers')}</ArchivedToggleCount>
                  </ArchivedToggleWrapper>
                </>
              )}

              {/* People sort ( people page ) */}
              {filterAsidePage === 'people' && (
                <Padding padding='16px 12px 0'>
                  {/* Order By */}
                  <ReactSelectSecondary
                    small
                    isSearchable={false}
                    options={PEOPLE_ORDERS_BY.map(orderBy => ({
                      value: orderBy,
                      label: orderBy,
                    }))}
                    value={{
                      label: (
                        <>
                          Sort by <strong>{orderPeopleBy.toLowerCase()}</strong>
                        </>
                      ) as React.ReactNode,
                      value: orderPeopleBy,
                    }}
                    onChange={selection => {
                      if (selection?.value) {
                        setAppState({
                          orderPeopleBy: selection.value,
                          orderPeopleDirection: 'primary',
                        });
                        const analyticsProps = { filter: `${selection.value} - primary` };
                        posthog?.capture('people_ordered_by', analyticsProps);
                        logEvent(fbAnalytics, 'order_people_by', analyticsProps);
                      }
                    }}
                  />
                  {/* Order Direction */}
                  {/* <ReactSelectSecondary
                    small
                    isSearchable={false}
                    options={peopleOrderByDirectionOptions}
                    value={peopleOrderByDirectionOptions.find(
                      ({ value }) => value === orderPeopleDirection
                    )}
                    onChange={selection => {
                      if (selection?.value) {
                        setAppState({ orderPeopleDirection: selection.value });
                        const analyticsProps = {
                          filter: `${orderPeopleBy} - ${selection.value}`,
                        };
                        posthog?.capture('people_ordered_by', analyticsProps);
                        logEvent(fbAnalytics, 'order_people_by', analyticsProps);
                      }
                    }}
                  /> */}
                </Padding>
              )}

              {/* Activity filters ( activity page ) */}
              {filterAsidePage === 'activity' && (
                <Padding padding='16px 12px 0'>
                  <ActivityFilters />
                </Padding>
              )}

              {/* Tasks filter ( tasks page) */}
              {/* ( but single users don't need it ) */}
              {filterAsidePage === 'tasks' && !isSingleUser && (
                <Padding padding='16px 12px 0'>
                  <TasksFilter />
                </Padding>
              )}
            </Scrollable>

            {/* Manage groups button ( at bottom ) */}
            {/* ( only show if admin or member has more than 1 group ) */}
            {(isOrganizationAdmin || !hasSingleGroup) && (
              <div>
                <AsideDivider />
                <StyledManageGroupsButton
                  dull
                  leadingIcon='clear_all'
                  // leadingIcon="settings"
                  onClick={e => {
                    e.preventDefault();
                    setAppState({ isManageGroupsModalOpen: true });
                  }}
                  data-intercom-target='Manage groups button'
                >
                  Manage groups
                </StyledManageGroupsButton>
                <Spacer height='16px' />
              </div>
            )}
          </Aside>
        )}
      </CSSTransition>

      {/* Manage (add, edit, delete, follow) groups modal */}
      <Modal
        heading='Manage groups'
        handleClose={() => {
          setAppState({ isManageGroupsModalOpen: false });
        }}
      >
        {isManageGroupsModalOpen && <ManageGroupsSheet />}
      </Modal>
    </>
  );
};
export default FilterAside;
