import styled from 'styled-components';
import { SecondaryButton } from '@/components/common/Buttons';

const RemoveButton = styled(SecondaryButton).attrs({
  danger: true,
  trailingIcon: 'remove_circle_outline',
})`
  opacity: 0.4;
  &:focus {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
  }
`;
export default RemoveButton;
