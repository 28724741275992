import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse the query
export default function useUrlQuery<T extends { [key: string]: string | undefined }>() {
  const params = new URLSearchParams(useLocation().search);
  const entries: Record<string, string> = {};
  for (const [key, value] of params.entries()) {
    entries[key] = value;
  }
  return entries as T;
}
