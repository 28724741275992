import { useMemo } from 'react';
import styled from 'styled-components';
// Classes
import Doc from '@/classes/Doc';
import Person from '@/classes/Person';
// Context data
import { useSetAppState } from '@/contexts/appState';
import useLocations from '@/contexts/locations';
// Layout components
import Sheet, { SheetScrollable } from '@/components/layout/Sheets';
import SheetHeader from '@/components/layout/SheetHeader';
// Common components
import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import Avatar from '@/components/common/Avatar';
import FlexRow from '@/components/common/FlexRow';
import Expanded from '@/components/common/Expanded';

// Styles
const PersonName = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
const LocationName = styled.div`
  font-size: 14px;
  color: ${props => props.theme.textFaded};
`;
const SwitchNoticeSection = styled.div`
  padding: 48px;
  text-align: center;
`;
const InstructionCopy = styled.div`
  font-size: 20px;
  line-height: 1.4;
  color: ${({ theme }) => theme.textTertiary};
  strong {
    color: ${({ theme }) => theme.textSecondary};
  }
`;

// Component
// TODO: could also apply to a person not in this organization...
// ( but we're already redirecting so it would only happen when navigating history )
const ChangeLocationPersonInfoSheet = ({
  person,
  goBack,
}: {
  person: Doc<Person>;
  goBack(): void;
}) => {
  const setAppState = useSetAppState();
  const [locations] = useLocations();
  const otherLocation = useMemo(
    () => locations?.find(({ id }) => id === person.locationId),
    [locations, person.locationId]
  );

  return (
    <Sheet position='left'>
      {/* Header info */}
      <SheetHeader primary>
        <Spacer height='16px' mobileHeight='8px' />
        {/* Back button */}
        <SecondaryButton
          leadingIcon='arrow_back'
          onClick={event => {
            event.preventDefault();
            goBack();
          }}
        >
          <strong>Back</strong>
        </SecondaryButton>
        <Spacer height='4px' />

        <FlexRow>
          {/* Avatar */}
          <Avatar
            id={person.id}
            photo={person.profile.photo}
            name={person.profile.name.full}
            size={person.profile.photo ? 128 : 96}
            faded={person.isArchived}
          />
          <Spacer width='12px' />
          <Expanded>
            {/* Name */}
            <PersonName>{person.profile.name.full}</PersonName>
            <Spacer height='4px' />
            {/* Location name */}
            <LocationName>{!!otherLocation && otherLocation.name}</LocationName>
          </Expanded>
        </FlexRow>
        <Spacer height='24px' mobileHeight='16px' />
      </SheetHeader>

      {/* Main person info area */}
      <SheetScrollable>
        <SwitchNoticeSection>
          <InstructionCopy>
            Set <strong>{!!otherLocation && otherLocation.name}</strong> as your active location to
            see the full profile for <strong>{person.profile.name.first}</strong>
          </InstructionCopy>
          <Spacer height='24px' />
          <PrimaryButton
            onClick={event => {
              event.preventDefault();
              !!otherLocation && setAppState({ locationId: otherLocation.id });
            }}
          >
            <strong>Switch locations</strong>
          </PrimaryButton>
        </SwitchNoticeSection>
      </SheetScrollable>
    </Sheet>
  );
};
export default ChangeLocationPersonInfoSheet;
