// Core
import { useState, useEffect } from 'react';

function useDebouncedValue<T>(value: T, delay: number) {
  const [debouncedVal, setDebouncedVal] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedVal(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedVal;
}

export default useDebouncedValue;
